import { Field, Form, Formik, FormikErrors, FormikProps } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css, useTheme } from 'styled-components';

import ErrorMessage, {
  ErrorMessageStyles,
} from '../../components/ErrorMessage';
import Input from '../../components/Input';
import {
  TSSiteShift,
  selectSiteShiftsEntity,
  actions as siteShiftActions,
} from '../../ducks/siteShifts';
import {
  capitalizeFirstLetter,
  convertTime12to24,
  timeTo12Hours,
} from '../../utils';
import OperatingHoursHelper, {
  OPERATING_HOURS_CHART_DAYS,
} from '../../utils/operatingHoursHelper';
import Button from '../Button';
import ArrowBackIcon from '../Icons/ArrowBackIcon';
import ClockIcon from '../Icons/ClockIcon';
import EditIcon from '../Icons/EditIcon';
import PlusIcon from '../Icons/PlusIcon';
import TrashCanIcon from '../Icons/TrashCanIcon';
import ListSelector from '../ListSelector';
import Spinner from '../Spinner';
import validationSchema from './createAndEditValidationSchema';

interface TSProps {
  isActive: boolean;
  siteId: string;
}

interface TSCreateEditShiftValues {
  shiftName: string;
  shiftStartDay: string;
  shiftStartTime: string;
  shiftEndDay: string;
  shiftEndTime: string;
  shiftTimeRange?: string;
}

enum ShiftView {
  ALL = 'all',
  CREATE = 'create',
  EDIT = 'edit',
  DELETE = 'delete',
}

const initialValues = {
  shiftName: '',
  shiftStartDay: '',
  shiftStartTime: '',
  shiftEndDay: '',
  shiftEndTime: '',
};

/* TODO: Remove these styled-components after migrating to CSS modules - MR */
const ViewShiftsWrapperContainerStyled = styled.div``;

const ViewShiftsBodyStyled = styled.div`
  ${ErrorMessageStyles} {
    padding-left: 24px;
  }
`;

const HeaderWrapperStyled = styled.div`
  align-items: center;
  background-color: #fff;
  display: flex;
  height: 60px;
  justify-content: space-between;
  margin-left: 24px;
  margin-right: 16px;
`;

const NoShiftsMessageStyled = styled.div`
  margin-left: 24px;
  margin-right: 16px;
  margin-top: 16px;
`;

const AddShiftTextStyled = styled.strong`
  margin: 5px;
`;

const HeaderTitleStyled = styled.h2`
  color: ${({ theme }) => theme.colors.fontMain};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 16px;
  font-weight: 700;
  margin: 0;
`;

const ShiftContainerStyled = styled.div`
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 8px;
  color: ${({ theme }) => theme.colors.fontMain};
  font-family: ${({ theme }) => theme.fontFamily};
`;

const ShiftHeaderStyled = styled.div`
  background: linear-gradient(
      0deg,
      rgba(224, 224, 224, 0.24),
      rgba(224, 224, 224, 0.24)
    ),
    #ffffff;
  border: 1px solid #e0e0e0;
  font-size: 14px;
  font-weight: 600;
  height: 32px;
  padding: 8px 24px;
`;

const ShiftDetailStyled = styled.div<{
  isLastElement: boolean;
}>`
  height: 76px;
  margin: 16px 24px 0;
  border-bottom: ${({ isLastElement }) =>
    isLastElement ? '' : '1px solid #E0E0E0'};
`;

const ShiftTitleStyled = styled.div`
  color: #6c6d6e;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 16px;
`;

const ShiftDurationStyled = styled.div`
  align-items: center;
  display: flex;
`;

const ShiftTimeStyled = css`
  color: ${({ theme }) => theme.colors.fontMain};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 14px;
  font-weight: 400;
  text-decoration: underline;
`;

const ShiftStartTimeStyled = styled.div`
  ${ShiftTimeStyled};
  margin-left: 12px;
  margin-right: 4px;
`;

const ShiftEndTimeStyled = styled.div`
  ${ShiftTimeStyled};
  margin-left: 4px;
`;

const ShiftTimeSeparatorStyled = styled.div`
  color: #6c6d6e;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
`;

const ButtonChildrenStyled = styled.div`
  display: flex;
  align-items: center;
`;

const ButtonTextStyled = styled.div`
  margin-left: 5px;
`;

const CreateEditFormWrapperStyled = styled.div`
  margin-top: 24px;
  flex-direction: column;
  display: flex;
`;

const CreateEditHeaderStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CreateEditTitleWrapperStyled = styled.div`
  display: flex;
  align-items: center;
`;

const CreateEditTitleStyled = styled.div`
  color: ${({ theme }) => theme.colors.fontMain};
  font-family: ${({ theme }) => theme.fontFamily};
  font-weight: 700;
  font-size: 16px;
`;

const FormContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

const FieldStyled = styled(Field)`
  margin-bottom: 10px;
  width: 100%;
`;

const FormBodyStyled = styled.div`
  padding-left: 24px;
  padding-right: 32px;
  padding-top: 24px;

  ${FieldStyled} {
    border-radius: 8px;
    height: 40px;
    border-color: #e0e0e0;
  }
`;

const ShiftDetailsStyled = styled.div``;

const FieldSetStyled = styled.div`
  display: flex;
`;

const FormFooterStyled = styled.div`
  background-color: #fff;
  border-top: 1px solid #e0e0e0;
  bottom: 0;
  box-shadow: 0px -2px 2px 1px rgba(224, 224, 224, 0.25);
  left: 0;
  position: absolute;
  right: 0;
  margin-bottom: 5px;
  margin-top: 5px;
`;

const FormBottonsWrapperStyled = styled.div`
  align-items: center;
  display: flex;
  height: 55px;
  justify-content: end;
  padding-right: 30px;
`;

const FormStyled = styled(Form)`
  display: flex;
  flex-direction: column;
`;

const ShiftDetailHeadStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ShiftDetailHeadActionStyled = styled.div`
  display: flex;
  align-items: baseline;
`;

const EditDeleteCommonStyles = css`
  display: flex;
  cursor: pointer;

  > * {
    pointer-events: none;
  }

  > svg {
    margin: 3px;
  }
`;

const EditDeleteHoverStyles = css`
  &:hover {
    border-radius: 8px;
    display: flex;
    height: 30px;
    justify-content: space-between;
    padding: 5px;
  }
`;

const EditIconWrapperStyled = styled.div`
  align-items: baseline;

  ${EditDeleteCommonStyles}

  &:hover {
    ${EditDeleteHoverStyles}
    background-color: ${({ theme }) => theme.colors.primaryLight};
    color: ${({ theme }) => theme.colors.primary};
    width: 100px;
  }
`;

const DeleteIconWrapperStyled = styled.div`
  align-items: center;

  ${EditDeleteCommonStyles}

  &:hover {
    ${EditDeleteHoverStyles}
    background-color: ${({ theme }) => theme.colors.errorHover};
    color: ${({ theme }) => theme.colors.error};
    width: 114px;
  }
`;

const EditDeleteShiftTextStyled = styled.div<{
  show: boolean;
}>`
  display: ${({ show }) => (show ? 'block' : 'none')};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 14px;
  font-weight: 500;
  align-self: center;
`;

const DividerStyled = styled.hr`
  border-top: 1px solid #e0e0e0;
  margin: 5px;
  height: 15px;
`;

const DeleteConfirmationStyled = styled.div`
  margin: auto;
  margin-top: 200px;
  padding: 10px;
  text-align: center;
  width: 75%;
`;

const ConfirmationTitleCommonStyles = css`
  color: ${({ theme }) => theme.colors.fontMain};
  font-family: ${({ theme }) => theme.fontFamily};
`;

const ConfirmationTitleStyled = styled.div`
  ${ConfirmationTitleCommonStyles};
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 24px;
`;

const ConfirmationSubTitleStyled = styled.div`
  ${ConfirmationTitleCommonStyles};
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 80px;
`;

const ConfirmationButtonsStyled = styled.div`
  > button {
    margin-right: 20px;
  }
`;

const DeleteButtonWrapperStyled = styled(Button)`
  display: flex;
  align-items: center;
  margin-right: 24px;
`;

const DeleteButtonTextStyled = styled.div`
  color: ${({ theme }) => theme.colors.error};
  font-family: ${({ theme }) => theme.fontFamily};
  font-weight: 400;
  font-size: 14px;
`;

const LabelStyled = styled.label`
  display: block;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.02em;
  margin-bottom: 4px;
`;

const OpHoursLabelStyled = styled(LabelStyled)`
  color: #6c6d6e;
`;

const ErrorsWrapperStyled = styled.div`
  background-color: ${({ theme }) => theme.colors.messageError};
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  margin-bottom: 20px;
  padding: 20px;
`;

const Errors = ({ errors }: { errors: Record<string, string> }) => {
  if (!Object.keys(errors).length) {
    return <></>;
  }

  return (
    <ErrorsWrapperStyled>
      {Object.keys(errors).map((fieldName) => (
        <div key={fieldName}>{errors[fieldName]}</div>
      ))}
    </ErrorsWrapperStyled>
  );
};

const sorter = OPERATING_HOURS_CHART_DAYS.reduce(
  (acc, cur, index) => ({ ...acc, [cur.toLocaleLowerCase()]: index }),
  {}
);

const sortShiftsByDay = (shifts) => {
  return Object.keys(shifts)
    .sort((a, b) => {
      const shift1 = a.toLowerCase();
      const shift2 = b.toLowerCase();
      return sorter[shift1] - sorter[shift2];
    })
    .reduce((obj, key) => {
      obj[key] = shifts[key];
      return obj;
    }, {});
};

const validate = (values: TSCreateEditShiftValues) => {
  const errors: FormikErrors<TSCreateEditShiftValues> = {};

  const { shiftStartDay, shiftEndDay, shiftStartTime, shiftEndTime } = values;

  if (
    shiftStartDay &&
    shiftEndDay &&
    shiftStartDay.toLowerCase() === shiftEndDay.toLowerCase()
  ) {
    // eslint-disable-next-line prefer-const
    const [startTimeHour] = shiftStartTime.split(':');
    const [endTimeHour] = shiftEndTime.split(':');

    if (startTimeHour > endTimeHour) {
      errors.shiftTimeRange = 'Shift start time has to be before end time.';
    }
    if (shiftStartTime === shiftEndTime) {
      errors.shiftTimeRange =
        'Shift start day and time must be different than Shift end day and time.';
    }
  }
  return errors;
};

const CreateEditShiftForm = ({
  errors,
  isSubmitting,
  setSubmitting,
  values,
  setFieldValue,
}: FormikProps<TSCreateEditShiftValues>) => {
  const {
    createShiftMeta: { loading: createSiteShiftLoading },
  } = useSelector(selectSiteShiftsEntity);
  const [selectedStartDay, setSelectedStartDay]: [string, (string) => void] =
    useState(capitalizeFirstLetter(values?.shiftStartDay.toLocaleLowerCase()));
  const [selectedEndDay, setSelectedEndDay]: [string, (string) => void] =
    useState(capitalizeFirstLetter(values?.shiftEndDay.toLocaleLowerCase()));

  useEffect(() => {
    if (isSubmitting && !createSiteShiftLoading) {
      setSubmitting(false);
    }
  }, [isSubmitting, createSiteShiftLoading, setSubmitting]);

  const items = useMemo(
    () =>
      OPERATING_HOURS_CHART_DAYS.map((day) => ({
        name: day,
        id: day,
      })),
    []
  );

  const selectedStartDayOption = useMemo(() => {
    if (selectedStartDay) {
      return items.find((item) => item.id === selectedStartDay);
    }
  }, [items, selectedStartDay]);

  const selectedEndDayOption = useMemo(() => {
    if (selectedEndDay) {
      return items.find((item) => item.id === selectedEndDay);
    }
  }, [items, selectedEndDay]);

  const handleEndDayUpdate = useCallback(
    ({ value }: { value: string }) => {
      setFieldValue('shiftEndDay', value);
      return setSelectedEndDay(value);
    },
    [setFieldValue]
  );

  const handleStartDayUpdate = useCallback(
    ({ value }: { value: string }) => {
      setFieldValue('shiftStartDay', value);
      return setSelectedStartDay(value);
    },
    [setFieldValue]
  );

  return (
    <FormContainerStyled>
      <FormStyled>
        <FormBodyStyled>
          <OpHoursLabelStyled htmlFor='shift'>Shift Name</OpHoursLabelStyled>
          <FieldStyled
            component={Input}
            id='shiftName'
            name='shiftName'
            type='text'
          />
          <ShiftDetailsStyled>
            <OpHoursLabelStyled htmlFor='shiftStartDay'>
              Shift Start Details
            </OpHoursLabelStyled>
            <FieldSetStyled>
              <FieldStyled
                component={ListSelector}
                items={items}
                unsettable={false}
                searchable={false}
                updateValue={handleStartDayUpdate}
                selectedItem={selectedStartDayOption}
                notSetLabelText='Select start day'
                id='shiftStartDay'
                name='shiftStartDay'
                type='text'
              />
              <FieldStyled
                component={Input}
                id='shiftStartTime'
                name='shiftStartTime'
                type='time'
                pattern='[0-9]{2}:[0-9]{2}'
              />
            </FieldSetStyled>
          </ShiftDetailsStyled>
          <ShiftDetailsStyled>
            <OpHoursLabelStyled htmlFor='shiftEndDay'>
              Shift End Details
            </OpHoursLabelStyled>
            <FieldSetStyled>
              <FieldStyled
                component={ListSelector}
                items={items}
                unsettable={false}
                searchable={false}
                updateValue={handleEndDayUpdate}
                selectedItem={selectedEndDayOption}
                notSetLabelText='Select end day'
                id='shiftEndDay'
                name='shiftEndDay'
                type='text'
              />
              <FieldStyled
                component={Input}
                id='shiftEndTime'
                name='shiftEndTime'
                type='time'
                pattern='[0-9]{2}:[0-9]{2}'
              />
            </FieldSetStyled>
          </ShiftDetailsStyled>
          {errors && <Errors errors={errors} />}
        </FormBodyStyled>
        <FormFooterStyled>
          <FormBottonsWrapperStyled>
            <Button buttonType='transparent' type='reset'>
              Cancel
            </Button>
            <Button
              buttonType='primary'
              loading={createSiteShiftLoading}
              type='submit'
            >
              Save Shift
            </Button>
          </FormBottonsWrapperStyled>
        </FormFooterStyled>
      </FormStyled>
    </FormContainerStyled>
  );
};

const OpHoursConfigurationView = ({ isActive, siteId }: TSProps) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const {
    items: siteShifts,
    byId: siteShiftById,
    meta: { loading: loadingSiteShifts, error: siteShiftsError, noSiteShifts },
    createShiftMeta: { error: createShiftError, loading: createShiftLoading },
    updateShiftMeta: { error: updateShiftError, loading: updateShiftLoading },
    deleteShiftMeta: { error: deleteShiftError, loading: deleteShiftLoading },
  } = useSelector(selectSiteShiftsEntity);

  const [activeView, setActiveView]: [string, (string) => void] = useState(
    ShiftView.ALL
  );
  const [showConfirmationStep, setShowConfirmationStep]: [
    boolean,
    (boolean) => void,
  ] = useState(false);
  const [shiftToUpdate, setShiftToUpdate]: [
    TSSiteShift | undefined,
    (TSSiteShift) => void,
  ] = useState();
  const [submitting, setSubmitting] = useState(false);
  const [isEditHovering, setIsEditHovering]: [any, (any) => void] = useState(
    {}
  );
  const [isDeleteHovering, setIsDeleteHovering]: [any, (any) => void] =
    useState({});

  useEffect(() => {
    // using this prop to reset activeView when tab is not active
    isActive ? setActiveView(activeView) : setActiveView(ShiftView.ALL);
  }, [activeView, isActive]);

  useEffect(() => {
    if (createShiftError || updateShiftError || deleteShiftError) {
      const timer = setTimeout(
        () => dispatch(siteShiftActions.resetMetaAction()),
        3000
      );
      return () => clearTimeout(timer);
    }
  }, [createShiftError, updateShiftError, deleteShiftError, dispatch]);

  useEffect(() => {
    if (submitting && !loadingSiteShifts) {
      setSubmitting(false);
      if (!siteShiftsError) {
        setActiveView(ShiftView.ALL);
      }
    }
  }, [loadingSiteShifts, siteShiftsError, submitting]);

  const shiftsByDayOfWeek = useMemo(() => {
    const dayOfWeekShifts = siteShifts.reduce(
      (acc, cur) => ({ ...acc, [cur.startDay.toLowerCase()]: [] }),
      {}
    );

    siteShifts.forEach((shift) => {
      const dayShifts = dayOfWeekShifts[shift.startDay.toLowerCase()];
      dayOfWeekShifts[shift.startDay.toLowerCase()] = [...dayShifts, shift];
    });
    return sortShiftsByDay(dayOfWeekShifts);
  }, [siteShifts]);

  const createEditHeaderTitle = useMemo(() => {
    if (activeView === ShiftView.CREATE) {
      return 'Add New Shift';
    }
    return 'Edit Shift';
  }, [activeView]);

  const handleAddShift = useCallback(() => {
    setActiveView(ShiftView.CREATE);
  }, []);

  const handleBackAction = useCallback(() => {
    setActiveView(ShiftView.ALL);
  }, []);

  const handleFormSubmit = useCallback(
    (values: TSCreateEditShiftValues) => {
      const {
        shiftEndTime,
        shiftName,
        shiftStartDay,
        shiftStartTime,
        shiftEndDay,
      } = values;
      const shiftDurationInMin = OperatingHoursHelper.getDurationInMinutes({
        startDay: sorter[shiftStartDay.toLocaleLowerCase()],
        startTime: shiftStartTime,
        endDay: sorter[shiftEndDay.toLocaleLowerCase()],
        endTime: shiftEndTime,
      });

      const payload = {
        siteId,
        name: shiftName.trim(),
        startDay: shiftStartDay.toLocaleUpperCase(),
        startTime: shiftStartTime,
        durationInMinutes: shiftDurationInMin,
      };

      setSubmitting(true);
      if (activeView === ShiftView.EDIT) {
        dispatch(
          siteShiftActions.updateSiteShift({
            ...payload,
            shiftId: shiftToUpdate?.id || '',
          })
        );
      } else {
        dispatch(siteShiftActions.createSiteShift(payload));
      }
    },
    [siteId, activeView, dispatch, shiftToUpdate?.id]
  );

  const handleDeleteSiteShift = useCallback(
    (id: string) => {
      setShowConfirmationStep(true);
      setShiftToUpdate(siteShiftById[id]);
    },
    [siteShiftById]
  );

  const handleCancelShiftDelete = useCallback(() => {
    setShowConfirmationStep(false);
    if (activeView !== ShiftView.EDIT) {
      setShiftToUpdate(undefined);
    }
    setIsDeleteHovering(false);
  }, [activeView]);

  const handleConfirmDelete = useCallback(() => {
    dispatch(
      siteShiftActions.deleteSiteShift({
        siteId: shiftToUpdate?.siteId || '',
        shiftId: shiftToUpdate?.id || '',
      })
    );
    setShowConfirmationStep(false);
    setActiveView(ShiftView.ALL);
  }, [dispatch, shiftToUpdate?.id, shiftToUpdate?.siteId]);

  const handleEditSiteShiftClick = useCallback(
    (id) => {
      setActiveView(ShiftView.EDIT);
      setShiftToUpdate(siteShiftById[id]);
      setIsEditHovering({});
      setIsDeleteHovering({});
    },
    [siteShiftById]
  );

  const createEditFormInitialValues = useMemo(() => {
    if (activeView === ShiftView.EDIT) {
      return {
        ...initialValues,
        shiftName: shiftToUpdate?.name || '',
        shiftStartDay: shiftToUpdate?.startDay || '',
        shiftStartTime: shiftToUpdate?.startTime || '',
        shiftEndDay: shiftToUpdate?.endDay || '',
        shiftEndTime: convertTime12to24(shiftToUpdate?.endTime || ''),
      };
    }
    return initialValues;
  }, [shiftToUpdate, activeView]);

  const handleEditMouseOver = useCallback(
    (event: React.BaseSyntheticEvent, id) => {
      setIsEditHovering((c) => {
        return {
          ...c,
          [id]: true,
        };
      });
    },
    []
  );

  const handleEditMouseOut = useCallback(() => {
    setIsEditHovering({});
  }, []);

  const handleDeleteMouseOver = useCallback(
    (event: React.BaseSyntheticEvent) => {
      setIsDeleteHovering((c) => {
        return {
          ...c,
          [event.target.id]: true,
        };
      });
    },
    []
  );

  const handleDeleteMouseOut = useCallback(() => {
    setIsDeleteHovering({});
  }, []);

  const handleResetForm = useCallback(() => {
    setActiveView(ShiftView.ALL);
  }, []);

  return (
    <>
      <>
        {showConfirmationStep && (
          <DeleteConfirmationStyled>
            <ConfirmationTitleStyled>
              {`Are you sure you want to delete ${shiftToUpdate?.name} from operating hours?`}
            </ConfirmationTitleStyled>
            <ConfirmationSubTitleStyled>
              {`Deleting will delete the ${shiftToUpdate?.name} shift from operating hours.`}
            </ConfirmationSubTitleStyled>
            <ConfirmationButtonsStyled>
              <Button
                type='button'
                buttonType='secondaryLight'
                outlined
                onClick={handleCancelShiftDelete}
              >
                No, Keep Shift
              </Button>
              <Button
                type='button'
                buttonType='error'
                onClick={handleConfirmDelete}
              >
                Yes, Delete Shift
              </Button>
            </ConfirmationButtonsStyled>
          </DeleteConfirmationStyled>
        )}
      </>
      <ViewShiftsWrapperContainerStyled>
        {activeView === ShiftView.ALL && !showConfirmationStep && (
          <ViewShiftsBodyStyled>
            {loadingSiteShifts && !noSiteShifts && <Spinner centered />}
            {(createShiftLoading ||
              updateShiftLoading ||
              deleteShiftLoading) && <Spinner centered />}
            <HeaderWrapperStyled>
              <HeaderTitleStyled>All Shifts</HeaderTitleStyled>
              <Button
                borderRadius='small'
                buttonType='secondary'
                type='button'
                onClick={handleAddShift}
              >
                <ButtonChildrenStyled>
                  <PlusIcon color='#fff' />
                  <ButtonTextStyled>Add Shift</ButtonTextStyled>
                </ButtonChildrenStyled>
              </Button>
            </HeaderWrapperStyled>
            {siteShiftsError && <ErrorMessage message={siteShiftsError} />}
            {(createShiftError || updateShiftError || deleteShiftError) && (
              <ErrorMessage
                message={
                  createShiftError || updateShiftError || deleteShiftError
                }
              />
            )}
            {((!loadingSiteShifts && noSiteShifts) || !siteShifts.length) && (
              <NoShiftsMessageStyled>
                We have no operating hours information for this site. Click
                <AddShiftTextStyled>Add Shift</AddShiftTextStyled>
                to set operating hours.
              </NoShiftsMessageStyled>
            )}
            {!loadingSiteShifts && !siteShiftsError && (
              <>
                {Object.keys(shiftsByDayOfWeek).map((shiftDay) => (
                  <div key={shiftDay}>
                    <ShiftContainerStyled key={shiftDay}>
                      <ShiftHeaderStyled>
                        {capitalizeFirstLetter(shiftDay.toLowerCase())}
                      </ShiftHeaderStyled>
                      {shiftsByDayOfWeek[shiftDay].map(
                        (
                          { startDay, startTime, endTime, endDay, name, id },
                          index
                        ) => {
                          const isLastShiftOfDay =
                            index === shiftsByDayOfWeek[shiftDay].length - 1;
                          return (
                            <ShiftDetailStyled
                              key={id}
                              isLastElement={isLastShiftOfDay}
                            >
                              <ShiftDetailHeadStyled>
                                <ShiftTitleStyled>{name}</ShiftTitleStyled>
                                <ShiftDetailHeadActionStyled>
                                  <EditIconWrapperStyled
                                    onMouseEnter={(event) =>
                                      handleEditMouseOver(event, id)
                                    }
                                    onMouseOut={handleEditMouseOut}
                                    onClick={() => handleEditSiteShiftClick(id)}
                                  >
                                    <EditIcon
                                      color={
                                        isEditHovering[id]
                                          ? `${theme.colors.primary}`
                                          : '#6C6D6E'
                                      }
                                    />
                                    <EditDeleteShiftTextStyled
                                      show={isEditHovering[id]}
                                    >
                                      Edit Shift
                                    </EditDeleteShiftTextStyled>
                                  </EditIconWrapperStyled>
                                  <DividerStyled />
                                  <DeleteIconWrapperStyled
                                    id={id}
                                    onMouseEnter={handleDeleteMouseOver}
                                    onMouseOut={handleDeleteMouseOut}
                                    onClick={() => handleDeleteSiteShift(id)}
                                  >
                                    <TrashCanIcon
                                      color={
                                        isDeleteHovering[id]
                                          ? `${theme.colors.error}`
                                          : '#6C6D6E'
                                      }
                                    />
                                    <EditDeleteShiftTextStyled
                                      show={isDeleteHovering[id]}
                                    >
                                      Delete Shift
                                    </EditDeleteShiftTextStyled>
                                  </DeleteIconWrapperStyled>
                                </ShiftDetailHeadActionStyled>
                              </ShiftDetailHeadStyled>
                              <ShiftDurationStyled>
                                <ClockIcon color='#6C6D6E' size='16px' />
                                <ShiftStartTimeStyled>
                                  {`${timeTo12Hours(
                                    startTime
                                  )} ${capitalizeFirstLetter(
                                    startDay.toLowerCase()
                                  )}`}
                                </ShiftStartTimeStyled>
                                <ShiftTimeSeparatorStyled>
                                  to
                                </ShiftTimeSeparatorStyled>
                                <ShiftEndTimeStyled>
                                  {`${endTime} ${capitalizeFirstLetter(
                                    endDay.toLowerCase()
                                  )}`}
                                </ShiftEndTimeStyled>
                              </ShiftDurationStyled>
                            </ShiftDetailStyled>
                          );
                        }
                      )}
                    </ShiftContainerStyled>
                  </div>
                ))}
              </>
            )}
          </ViewShiftsBodyStyled>
        )}
      </ViewShiftsWrapperContainerStyled>
      <>
        {(activeView === ShiftView.CREATE || activeView === ShiftView.EDIT) &&
          !showConfirmationStep && (
            <CreateEditFormWrapperStyled>
              <CreateEditHeaderStyled>
                <CreateEditTitleWrapperStyled>
                  <Button
                    buttonType='transparent'
                    type='button'
                    onClick={handleBackAction}
                  >
                    <ArrowBackIcon color={theme.colors.fontMain} />
                  </Button>
                  <CreateEditTitleStyled>
                    {createEditHeaderTitle}
                  </CreateEditTitleStyled>
                </CreateEditTitleWrapperStyled>
                {activeView === ShiftView.EDIT && (
                  <DeleteButtonWrapperStyled
                    type='button'
                    buttonType='transparent'
                    onClick={() =>
                      handleDeleteSiteShift(shiftToUpdate?.id || '')
                    }
                  >
                    <TrashCanIcon color={theme.colors.error} size='20px' />
                    <DeleteButtonTextStyled>
                      Delete Shift
                    </DeleteButtonTextStyled>
                  </DeleteButtonWrapperStyled>
                )}
              </CreateEditHeaderStyled>
              <Formik
                initialValues={createEditFormInitialValues}
                onSubmit={handleFormSubmit}
                onReset={handleResetForm}
                validationSchema={validationSchema}
                validate={validate}
                validateOnChange={false}
                validateOnBlur={false}
              >
                {CreateEditShiftForm}
              </Formik>
            </CreateEditFormWrapperStyled>
          )}
      </>
    </>
  );
};

export default OpHoursConfigurationView;
