import {
  Badge,
  CSSProperties,
  NavLink as MantineNavLink,
  NavLinkProps,
  NavLinkStylesNames,
} from '@mantine/core';
import { PolymorphicComponentProps } from '@mantine/core/lib/core/factory/create-polymorphic-component';
import { Link, useLocation } from 'react-router-dom';
import { ParentNavLink } from '.';
import styles from './NewMainNav.module.css';

type Props = {
  styles?: Partial<Record<NavLinkStylesNames, CSSProperties>>;
  topLevel?: boolean;
} & Omit<ParentNavLink, 'component' | 'children'> &
  Omit<PolymorphicComponentProps<typeof Link, NavLinkProps>, 'to'>;

const NavLink = ({
  badge,
  children,
  gainsightId,
  icon,
  isActiveMatcher,
  isHidden,
  label,
  styles: stylesProp,
  to,
  topLevel,
  ...rest
}: Props) => {
  const location = useLocation();

  if (isHidden) return null;
  const { pathname } = location;
  let active = false;

  if (isActiveMatcher && !children) {
    active = isActiveMatcher.test(pathname);
  } else {
    const toWithoutQuery = to?.replace(/\?\S*/, '');
    const toWithoutParam = to?.replace(/\/:\S*/, '');

    const queryPathWithOptionalTrailingSlash = new RegExp(
      `^${toWithoutQuery}/?$`
    );
    const paramPathWithOptionalTrailingSlash = new RegExp(
      `^${toWithoutParam}/\\S*/?$`
    );
    active =
      queryPathWithOptionalTrailingSlash.test(pathname) ||
      paramPathWithOptionalTrailingSlash.test(pathname);
  }

  let badgeComponent;
  if (badge) {
    const { color, children, ...restBadgeProps } = badge;
    badgeComponent = (
      <Badge
        size='sm'
        color={color}
        styles={{ label: { display: 'inline-flex' } }}
        px='xs'
        {...restBadgeProps}
      >
        {children}
      </Badge>
    );
  }

  return (
    <MantineNavLink<typeof Link>
      active={active}
      className={styles.navLink}
      component={Link}
      data-gainsight-id={gainsightId}
      data-top-level={topLevel ?? !!icon}
      defaultOpened={!!children}
      label={label}
      leftSection={icon}
      rightSection={badgeComponent}
      styles={{
        ...stylesProp,
        children: {
          paddingBottom: 4,
          paddingLeft: 26,
          display: 'flex',
          flexDirection: 'column',
          gap: '4px',
          ...stylesProp?.children,
        },
      }}
      to={to ?? ''}
      {...rest}
    >
      {children}
    </MantineNavLink>
  );
};

export default NavLink;
