import {
  UilAngleDoubleDown,
  UilAngleDoubleUp,
  UilCheckCircle,
  UilPlusCircle,
} from '@iconscout/react-unicons';

import {
  Flex,
  Group,
  Stack,
  Text,
  Button as MantineButton,
  Box,
} from '@mantine/core';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { queryStringify } from '../../api/utils';
import { selectOpportunityEntity } from '../../ducks/opportunity/opportunities';
import {
  TSOpportunitySeed,
  getCombinedOpportunityAddress,
  getSeedsThatDonotBelongToAnyBatch,
  selectOpportunitySeedsEntity,
  actions as opportunitySeedsActions,
  TSOpportunityBatch,
} from '../../ducks/opportunity/opportunitySeeds';
import { truncateString } from '../../utils';
import FileThumbnailPreview from '../FileThumbnailPreview';
import OpportunityIcon from '../OpportunityIcon';
import { getTaskcountColor } from './OpportunityListTable';
import MultiSelect from '../mantine/MultiSelect';

interface ChildOpportunitiesTypes {
  childSeeds: TSOpportunitySeed[];
  batch: TSOpportunityBatch;
  setOpportunitySelectedforRemoval: React.Dispatch<
    React.SetStateAction<string>
  >;
  setShowRemoveChildFromBatchModal: React.Dispatch<
    React.SetStateAction<boolean>
  >;
}

const ChildOpportunities = ({
  childSeeds,
  batch,
  setOpportunitySelectedforRemoval,
  setShowRemoveChildFromBatchModal,
}: ChildOpportunitiesTypes) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { items: seeds, batches } = useSelector(selectOpportunitySeedsEntity);
  const { tasks } = useSelector(selectOpportunityEntity);

  const [childSeedsToShow, setChildSeedsToShow] = useState(
    childSeeds.length > 2 ? childSeeds.slice(0, 2) : childSeeds
  );
  const [
    showAddChildOpportunitiesSelector,
    setShowAddChildOpportunitiesSelector,
  ] = useState(false);

  const [selectedChildren, setSelectedChildren] = useState<string[]>([]);

  const getChildTasks = (child) =>
    tasks.filter((task) => task.projectOpportunityId == child.id);

  const handleAddToBatch = () => {
    const editBatchPayload = {
      batchId: batch.id,
      opportunityIds: [...batch.opportunityIds, ...selectedChildren],
    };
    dispatch(opportunitySeedsActions.editBatch(editBatchPayload));
  };

  const childSeedsForSelector = getSeedsThatDonotBelongToAnyBatch(
    batches,
    seeds
  ).map((child) => {
    return {
      label: `${child.ecmType} - ${child.displayId}`,
      value: child.id,
    };
  });

  return (
    <Stack my={'xl'} gap={'lg'}>
      <Group justify='space-between' h={30}>
        <Text c={'black'} fw={'700'}>
          {`${childSeeds?.length ? childSeeds.length : 'No'} Child Projects`}
        </Text>
        {!showAddChildOpportunitiesSelector && (
          <MantineButton
            leftSection={<UilPlusCircle size={16} />}
            variant='default'
            radius={'md'}
            size='compact-md'
            px={'12px'}
            onClick={() => setShowAddChildOpportunitiesSelector(true)}
          >
            Add Child Projects
          </MantineButton>
        )}
      </Group>
      {showAddChildOpportunitiesSelector && (
        <Flex
          align={'center'}
          gap={'xs'}
          bg={'white'}
          style={{ borderRadius: '8px', border: '1px solid #e0e0e0' }}
          p={'xs'}
        >
          <Box w={'100%'}>
            <MultiSelect
              rightSection={null}
              showSelectAll
              size='md'
              radius={'md'}
              maxDisplayedValues={1}
              variant='unstyled'
              placeholder='Select Child Projects'
              data={childSeedsForSelector}
              styles={{ input: { padding: '0 10px' } }}
              values={selectedChildren}
              setValues={(selectedProjects) => {
                setSelectedChildren(selectedProjects);
              }}
            />
          </Box>
          <Flex align={'center'} gap={'sm'}>
            <MantineButton
              onClick={() => {
                setShowAddChildOpportunitiesSelector(false);
                setSelectedChildren([]);
              }}
              variant='transparent'
              radius={'md'}
              size={'compact-md'}
              c={'black'}
              fw={'400'}
            >
              {' '}
              Cancel
            </MantineButton>
            <MantineButton
              onClick={handleAddToBatch}
              disabled={selectedChildren.length <= 0}
              variant='filled'
              radius={'md'}
              size={'md'}
            >
              Add Child Projects
            </MantineButton>
          </Flex>
        </Flex>
      )}

      {childSeeds?.length != 0 && (
        <Flex direction={'column'} gap={'md'}>
          {childSeedsToShow?.map((childSeed) => (
            <FileThumbnailPreview
              onClick={() => {
                navigate(
                  `${location?.pathname}?${queryStringify({
                    opportunityId: childSeed.id,
                    opportunityType: 'seed',
                    customerId: childSeed.customerId,
                  })}`
                );
              }}
              onClose={() => {
                setOpportunitySelectedforRemoval(childSeed.id);
                setShowRemoveChildFromBatchModal(true);
              }}
              title={
                <Group>
                  <Text
                    fw={'600'}
                    lh={'normal'}
                    lineClamp={2}
                    style={{ textTransform: 'capitalize' }}
                  >
                    {truncateString(
                      `${childSeed.ecmType} - ${childSeed.displayId}`,
                      100
                    ).toUpperCase()}
                  </Text>
                  {getChildTasks(childSeed).length > 0 && (
                    <>
                      {`|`}
                      <Flex
                        pos={'relative'}
                        align={'center'}
                        justify={'center'}
                        style={{ cursor: 'pointer' }}
                      >
                        <UilCheckCircle size={'22px'} />
                        <Flex
                          px={'sm'}
                          bg={getTaskcountColor(getChildTasks(childSeed))}
                          style={{
                            borderRadius: '999px',
                            fontSize: '8px',
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                          }}
                          align={'center'}
                          justify={'center'}
                          c='white'
                          fw={'700'}
                          h={'12px'}
                          w={'12px'}
                          p={0}
                        >
                          {getChildTasks(childSeed).length}
                        </Flex>
                      </Flex>
                    </>
                  )}
                </Group>
              }
              key={childSeed.id}
              fileIcon={
                <OpportunityIcon ecmType={childSeed.ecmType} iconSize='32' />
              }
              metaText={[
                getCombinedOpportunityAddress(childSeed).toUpperCase(),
              ]}
            />
          ))}
        </Flex>
      )}
      {childSeeds.length > 2 && (
        <Group
          gap={'0px'}
          align='center'
          onClick={() =>
            setChildSeedsToShow(
              childSeedsToShow.length > 2 ? childSeeds.slice(0, 2) : childSeeds
            )
          }
          style={{ cursor: 'pointer' }}
          w={'max-content'}
        >
          <Text c={'blue.6'}>
            {childSeedsToShow.length > 2
              ? `Show fewer projects`
              : `Show all ${childSeeds.length} projects`}
          </Text>
          {childSeedsToShow.length > 2 ? (
            <UilAngleDoubleUp color='#43558E' />
          ) : (
            <UilAngleDoubleDown color='#43558E' />
          )}
        </Group>
      )}
    </Stack>
  );
};

export default ChildOpportunities;
