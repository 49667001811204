import { Suspense, lazy } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import 'regenerator-runtime/runtime';
import styled, { createGlobalStyle } from 'styled-components';
import ModalContainer from '../components/GlobalModal/Container';
import { PORTFOLIO_PATH, PROJECTS_PATH } from '../constants/paths';
import PrivateRoutes from '../routes/PrivateRoutes';
import useCustomerSwitch from './useCustomerSwitch';
import useFetchUserData from './useFetchUserData';
import useRedirectLegacyPaths from './useRedirectLegacyPaths';

const AccountPage = lazy(() => import('./Account'));
const BuildingInsights = lazy(() => import('./BuildingInsights'));
const HomePage = lazy(() => import('./HomePage'));
const LoginPage = lazy(() => import('./Login'));
const ResetPasswordPage = lazy(() => import('./Login/ResetPasswordPage'));
const SignupPage = lazy(() => import('./Login/SignupPage'));
const LogoutPage = lazy(() => import('./LogoutPage'));
const Projects = lazy(() => import('./Projects'));
const Sustainability = lazy(() => import('./Sustainability'));
const VariantsPage = lazy(() => import('./VariantsPage'));
const DesignLibrary = lazy(() => import('./DesignLibrary'));
const OpportunitiesPage = lazy(
  () => import('./Opportunities/OpportunitiesPage')
);

interface TSLocationState {
  message: string;
}

export const SpinnerWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 55%;
`;

export const GlobalStyle = createGlobalStyle`
  body {
    font-family: ${({ theme }) => theme.fontFamily};
  }

  * {
    box-sizing: border-box;
  }

  color: ${({ theme }) => theme.colors.fontMain};

  a,
  a:visited,
  a:hover,
  a:active {
    text-decoration: none;
  }

  img {
    height: auto;
    max-width: 100%;
  }

  #apt-widget iframe.apt-widget-icon-frame {
    display: none;
  }
`;

const App = () => {
  useCustomerSwitch();
  useRedirectLegacyPaths();
  useFetchUserData();

  const location = useLocation();
  const state: TSLocationState = location.state as TSLocationState;
  const unauthorizedMessage =
    location?.pathname === '/login' && state ? state.message : '';

  return (
    <div>
      <GlobalStyle />
      <ModalContainer />
      <Suspense>
        <Routes>
          <Route element={<PrivateRoutes />}>
            <Route path='' element={<HomePage />} />
            <Route path={`${PORTFOLIO_PATH}/*`} element={<Sustainability />} />
            <Route path={PROJECTS_PATH} element={<OpportunitiesPage />} />
            <Route path='projects/*' element={<Projects />} />
            <Route
              path='data-explorer/:pageId'
              element={<BuildingInsights />}
            />
            <Route path='account/*' element={<AccountPage />} />
            <Route path='variants' element={<VariantsPage />} />
          </Route>
          <Route path='design-library/*' element={<DesignLibrary />} />

          <Route
            path='reset-password/:resetId'
            element={<ResetPasswordPage />}
          />
          <Route path='signup/:signupId' element={<SignupPage />} />
          <Route
            path='login/*'
            element={<LoginPage message={unauthorizedMessage} />}
          />
          <Route path='logout' element={<LogoutPage />} />
          <Route path='*' element={<Navigate to='/' />} />
        </Routes>
      </Suspense>
    </div>
  );
};

export default App;
