import styled from '@emotion/styled';
import { Divider, Flex, Group, Stack, Title } from '@mantine/core';
import { capitalizeFirstLetter } from '../../utils';

import { TSFilterDrawerModal } from '../../ducks/modal';
import { TSSiteTabs } from '../../ducks/sites';
import { Period } from '../../ducks/types';
import {
  isGroupingLockedToSite,
  isSingleSite,
  resourceTypeOptions,
} from '../../utils/getUrlSearchParams';
import ListSelector from '../ListSelector';
import Spinner from '../Spinner';
import Button from '../mantine/Button';
import DateRangePicker from '../mantine/DateRangePicker';
import Drawer from '../mantine/Drawer';
import TextButton from '../mantine/TextButton';
import useDrawerFilters, {
  selectedValuesByGroupInitialState,
} from './useDrawerFilters';

export type BuildingInsightsDrawerProps = {
  currentTab: TSSiteTabs;
  onClose: () => void;
  opened: boolean;
  resourceType: string;
} & TSFilterDrawerModal;

const BuildingInsightsDrawerStyles = styled(Drawer)`
  .mantine-Group-root {
    justify-content: space-between;
  }
  .mantine-Drawer-title {
    color: var(--mantine-color-black);
    font-size: 17px;
    width: 100%;
  }
  .mantine-Title-root {
    color: #6c6d6e;
    line-height: 20px;
    letter-spacing: 0.28px;
  }
  .mantine-Drawer-body {
    display: flex;
  }
  .mantine-Flex-root {
    flex-basis: 100%;
    width: 100%;
  }
  .DatePicker {
    width: 100%;
  }
`;

const ListSelectorStyles = styled(ListSelector)`
  .ListSelector-Dropdown--wrapper {
    margin-right: 0;
  }
  .Select__menu-outer {
    width: 100%;
  }
`;

const BuildingInsightsDrawer = ({
  currentTab,
  opened,
  onClose,
  isBasicConsumption,
}: BuildingInsightsDrawerProps) => {
  const {
    circuitMetaError,
    circuitMetaFiltersNoSite,
    circuitMetaLoading,
    getProgramStartDate,
    groupingOptions,
    handleResetFilters,
    handleApplyFilters,
    handleCategoryValueMulti,
    handleGroupingUpdate,
    handleMenuClose,
    handleMenuOpen,
    handleResolutionUpdate,
    handleResourceTypeUpdate,
    handleSiteMenuClose,
    handleSiteMenuOpen,
    handleMultiSiteValueUpdate,
    handleSingleSiteValueUpdate,
    handleUpdateDateRange,
    handleUpdateValueMulti,
    noCircuitMetaData,
    selectedGrouping,
    selectedOptionsItems,
    selectedResolution,
    selectedSitesItems,
    siteMenuOpen,
    sitesItems,
    selectedSitesId,
    valuesMultiAlphaSort,
    setSelectedValuesbyGroup,
    filtersObject,
    setFiltersObject,
    resolutionOptions,
    urlParamsFromToDates,
  } = useDrawerFilters({
    currentTab,
    onClose,
  });

  const handleClose = () => {
    setFiltersObject(filtersObject);
    setSelectedValuesbyGroup(selectedValuesByGroupInitialState(filtersObject));
    onClose();
  };

  const DrawerTitle = () => {
    return (
      <>
        <Group>
          <>Advanced Filters</>
        </Group>
        <Divider my='md' />
      </>
    );
  };

  return (
    <BuildingInsightsDrawerStyles
      size='md'
      opened={opened}
      onClose={handleClose}
      title={DrawerTitle()}
      footerActions={
        <>
          <TextButton
            data-gainsight-id='sites-drawer-query-filter-cancel'
            color='#6C6D6E'
            onClick={handleResetFilters}
          >
            Reset Filters
          </TextButton>
          <Button
            data-gainsight-id='sites-drawer-query-filter-apply'
            onClick={handleApplyFilters}
          >
            Apply
          </Button>
        </>
      }
    >
      <Stack align='flex-start' gap='md' w='100%'>
        <Stack gap='xs' w='100%'>
          <Title fz='md' fw='400'>
            Site
          </Title>
          {!isSingleSite(currentTab) ? (
            <ListSelectorStyles
              closeMenuOnSelect={false}
              gainsightTagId='sites-drawer-sites-query-filter-selector'
              isMulti
              items={sitesItems}
              key='multi-site-selection'
              menuIsOpen={siteMenuOpen}
              notSetLabelText='Selected All'
              onMenuClose={handleSiteMenuClose}
              onMenuOpen={handleSiteMenuOpen}
              selectedItems={selectedSitesItems}
              unsettable={false}
              updateValueMulti={handleMultiSiteValueUpdate}
            />
          ) : (
            <ListSelectorStyles
              closeMenuOnSelect={true}
              gainsightTagId='sites-drawer-sites-query-filter-selector'
              isMulti={false}
              items={sitesItems}
              key='single-site-selection'
              notSetLabelText='Select a Site'
              selectedItem={selectedSitesItems[0]}
              unsettable={false}
              updateValue={handleSingleSiteValueUpdate}
            />
          )}
        </Stack>
        <Stack gap='xs' w='100%'>
          <Title fz='md' fw='400'>
            Date Range
          </Title>
          <DateRangePicker
            gainsightTagId='sites-drawer-query-filter-date-range'
            endDate={urlParamsFromToDates.endDate}
            getProgramStartDate={getProgramStartDate}
            startDate={urlParamsFromToDates.startDate}
            updateDateRange={handleUpdateDateRange}
            maxDays={isBasicConsumption ? 365 : 30}
            isVerticalOrientation={true}
            period={Period.CUSTOM}
          />
        </Stack>
        <Stack w='100%' gap='xs'>
          <Title fz='md' fw='400'>
            Resource Type
          </Title>
          <ListSelectorStyles
            gainsightTagId='sites-drawer-query-filter-resource-type-selector'
            key='resource-type-selection'
            items={resourceTypeOptions}
            updateValue={handleResourceTypeUpdate}
            selectedItem={{
              id: filtersObject?.resourceType ?? '',
              name: capitalizeFirstLetter(filtersObject?.resourceType ?? ''),
            }}
            disabled={!isBasicConsumption}
            unsettable={false}
            searchable={false}
          />
        </Stack>
        <Stack w='100%' gap='xs'>
          <Title fz='md' fw='400'>
            Groups
          </Title>
          <ListSelectorStyles
            gainsightTagId='sites-drawer-query-filter-group-selector'
            key='grouping-selection'
            items={groupingOptions}
            updateValue={handleGroupingUpdate}
            selectedItem={selectedGrouping}
            unsettable={false}
            searchable={false}
            disabled={isGroupingLockedToSite(currentTab, selectedSitesId)}
          />
        </Stack>
        <Stack w='100%' gap='xs'>
          <Title fz='md' fw='400'>
            Resolution
          </Title>
          <ListSelectorStyles
            gainsightTagId='sites-drawer-query-filter-resolution-selector'
            key='resolution-selection'
            items={resolutionOptions}
            updateValue={handleResolutionUpdate}
            selectedItem={selectedResolution}
            disabled={resolutionOptions.length === 1}
            unsettable={false}
            searchable={false}
          />
        </Stack>
        {circuitMetaLoading && (
          <Flex gap='xs' justify='center'>
            <Spinner centered size='md' />
          </Flex>
        )}
        {!!circuitMetaFiltersNoSite.length &&
          !circuitMetaLoading &&
          !noCircuitMetaData &&
          !circuitMetaError && (
            <>
              {circuitMetaFiltersNoSite.map(
                ({ fieldType, values, name }, index) => {
                  const fieldName =
                    fieldType === 'categoryValue'
                      ? `${fieldType}-${name}`
                      : fieldType;
                  return (
                    <Stack w='100%' gap='xs' key={index}>
                      <Title fz='md' fw='400'>
                        {name}
                      </Title>
                      <ListSelectorStyles
                        key={fieldName}
                        gainsightTagId={`sites-drawer-query-filter-${fieldType}-selector`}
                        fieldName={fieldName}
                        items={valuesMultiAlphaSort(values, fieldType)}
                        selectedItems={selectedOptionsItems(fieldName)}
                        updateValueMulti={
                          fieldType === 'categoryValue'
                            ? handleCategoryValueMulti
                            : handleUpdateValueMulti
                        }
                        notSetLabelText={`Search ${name}`}
                        isMulti
                        unsettable={false}
                        closeMenuOnSelect={false}
                        onMenuOpen={handleMenuOpen}
                        onMenuClose={handleMenuClose}
                        allowSelectAll={fieldType !== 'circuit'}
                      />
                    </Stack>
                  );
                }
              )}
            </>
          )}
      </Stack>
    </BuildingInsightsDrawerStyles>
  );
};

export default BuildingInsightsDrawer;
