import { ReactNode, useCallback, useMemo, useRef, useState } from 'react';
import Select, { ValueContainerProps, components } from 'react-select';
import styled, { css } from 'styled-components';

import { truncateString } from '../utils';
import CheckMark from './Icons/CheckMarkIcon';
import ChevronDown from './Icons/ChevronDown';
import ChevronUp from './Icons/ChevronUp';

const NOT_SET = 'Not Set';

export type TSUpdateValueFunc = (params: any) => void;

export type TSUpdateValueFuncMulti = (newValues: any, actionMeta: any) => void;

/* an entry in the dropdown list */
export interface TSItem {
  id: string;
  name: string;
  renderFunc?: () => ReactNode | null;
}

interface TSProps {
  /* The items to render in the dropdown */
  items: Array<TSItem>;

  /* react-select props:
  https://github.com/JedWatson/react-select/tree/82910d9225f7aff73ac5052da9dc1e7f52f13de7#select-props */
  disabled?: boolean;
  fieldName?: string;
  isMulti?: boolean;
  searchable?: boolean;
  tabIndex?: number;
  closeMenuOnSelect?: boolean;
  isSingleSelectAll?: boolean;
  /* The currently selected item in the dropdown. */
  selectedItem?: null | TSItem;
  selectedItems?: Array<TSItem>;

  /* Does not render the dropdown and instead only renders its current value */
  isReadOnly?: boolean;

  /* The text the component label when the current value is not set */
  /* Label displayed with unset value when dropdown not open, i.e.
  -- Assign a Panel -- */
  notSetLabelText?: string;

  /* The text of unset item in dropdown list, i.e. 'Unassigned'
  required unsettable = true */
  notSetItemText?: string;
  notSetItemRenderFunc?: null | (() => ReactNode);

  /* The value of the unset item in the dropdown list. Defaults to null */
  notSetItemValue?: string;

  /* Callback to invoke when user selects a value */
  updateValue?: TSUpdateValueFunc;
  updateValueMulti?: TSUpdateValueFuncMulti;

  /* User has the option to clear the selected item, i.e. no entry or null */
  unsettable?: boolean;

  /* Makes the dropdown appear as plaintext until interaction, at which point
  the border and dropdown arrow render */
  hiddenMode?: boolean;

  /* indicates the field has been modified by rendering a colored dot next to it */
  edited?: boolean;
  editDotColor?: string;

  /* Enables the positioning of the edited dot marker */
  editDotBottom?: string;
  editDotLeft?: string;
  editDotRight?: string;
  editDotTop?: string;

  /* causes the dropdown to render above the field rather than the default below */
  directionUp?: boolean;

  /* when rendered inline next to other fields, the Select component has an offset
  of several pixels that makes it appear out of line. This flag fixes the styles
  so that does not occur
  Example: State dropdown on SiteForm */
  inlineFormMode?: boolean;

  /* Outlines the field in red when true */
  showRequired?: boolean;

  /* Adds a footer to the dropdown which invokes the provided callback on click */
  onFooterClick?: () => void;

  /* The text of the footer link - requires onFooterClick to be defined */
  footerLinkText?: string;

  /* Allow the component to be wrapped in a styled-component */
  className?: string;

  /* Index can be used to optimize event handlers */
  index?: number;
  noResultsText?: () => string;
  menuIsOpen?: boolean | undefined;
  onMenuOpen?: undefined | ((arg: any) => void);
  onMenuClose?: undefined | ((arg: any) => void);

  arrowColor?: string;
  allowSelectAll?: boolean;
  gainsightTagId?: string;
}

export interface TSOption {
  value: string;
  label?: ReactNode | string | null;
}

const Component = styled.div`
  position: relative;
`;

const MenuFooter = styled.div<{
  directionUp?: boolean;
}>`
  &:hover {
    color: #485da033;
  }

  background-color: #fff;
  position: absolute;
  top: ${({ directionUp }) => (directionUp ? '-40px' : 'unset')};
  bottom: ${({ directionUp }) => (directionUp ? 'unset' : '-40px')};
  left: -1px;
  width: calc(100% + 2px);
  height: 40px;
  line-height: 40px;

  font-size: 14px;
  padding-left: 9px;
  color: #4a4a4a;
  cursor: pointer;

  border: 1px solid #ccc;
  border-top-color: ${({ directionUp }) => (directionUp ? '#ccc' : '#e6e6e6')};

  border-bottom-left-radius: ${({ directionUp }) =>
    directionUp ? '0px' : '3px'};
  border-bottom-right-radius: ${({ directionUp }) =>
    directionUp ? '0px' : '3px'};
  border-top-left-radius: ${({ directionUp }) => (directionUp ? '3px' : '0px')};
  border-top-right-radius: ${({ directionUp }) =>
    directionUp ? '3px' : '0px'};
`;

const SelectorControlBorderColor = css<{
  hiddenMode: boolean;
  showRequired: boolean;
}>`
  border-color: ${({ showRequired }) => showRequired && '#D0021B'};
  border-color: ${({ hiddenMode }) =>
    hiddenMode ? 'transparent' : '#d9d9d9 #ccc #b3b3b3'};
`;

export const SelectStyles = styled.div<{
  inlineFormMode: boolean;
  hiddenMode: boolean;
  showRequired: boolean;
  disabled: boolean;
  directionUp: boolean;
  isSelectAll: boolean;
}>`
  cursor: pointer;

  .Select {
    border-radius: 4px;
    color: ${({ theme }) => theme.colors.fontMain};
    cursor: pointer;
    font-family: ${({ theme }) => theme.fontFamily};
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }

  .Select-arrow-zone {
    cursor: pointer;
    height: 16px;
    margin-right: 19px;
    margin-left: 19px;
  }

  .Select__control {
    border-radius: 8px;
    min-height: 40px;
    border-color: #e0e0e0;
  }

  .Select .Select__control {
    background-color: ${({ hiddenMode }) =>
      hiddenMode ? 'transparent' : '#fff'};
    display: ${({ inlineFormMode }) => inlineFormMode && 'inline-table'};
    ${({ hiddenMode }) => hiddenMode && SelectorControlBorderColor}
    ${({ showRequired }) => showRequired && SelectorControlBorderColor}
    top: ${({ inlineFormMode }) => inlineFormMode && '-2px'};
  }

  .Select .Select__control--is-focused,
  &:hover .Select .Select__control {
    border-color: #d9d9d9 #ccc #b3b3b3;
    box-shadow: none;
    background-color: ${({ disabled }) => !disabled && '#fff'};
    border-radius: 8px;
  }

  .Select__control--menu-is-open {
    border: 1px solid #337ab7;
    border-radius: ${({ directionUp }) =>
      directionUp ? '0 0 3px 3px' : '3px 3px 0 0'};
    padding: 0;
  }

  .Select__indicator {
    color: transparent;
    padding: 0;
  }

  .Select__option {
    min-height: 44px;
    padding: 12px 13px;
    overflow: hidden;
  }

  .Select__menu {
    box-shadow: 0px 2px 14px 1px rgba(108, 109, 110, 0.16);
    border-radius: 4px;
    margin-bottom: 0;
    margin-top: 0;
    max-height: 336px;
    position: relative;

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 4px;
      margin-bottom: 3px;
      margin-top: 0;
      width: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.5);
      background-clip: content-box;
      border: 2px transparent solid;
      border-radius: 4px;
      width: 7px;
    }

    > .Select__menu {
      border-radius: 8px;
      border-bottom-width: ${({ directionUp }) => (directionUp ? '0' : '1px')};
      border-top-width: ${({ directionUp }) => (directionUp ? '1px' : '0')};
    }

    .Select__option {
      box-sizing: border-box;
      display: flex;
      min-height: 44px;
      flex-direction: column;
      justify-content: center;
    }

    .Select__option:first-child {
      border-bottom: ${({ isSelectAll }) => isSelectAll && '1px solid #E0E0E0'};
    }
  }

  .Select__menu-list {
    border-radius: 8px;
    color: ${({ theme }) => theme.colors.fontMain};
    font-family: ${({ theme }) => theme.fontFamily};
    font-size: 14px;
    font-weight: 400;
    overflow-y: auto;
    padding-bottom: 0;
    padding-top: 0;
  }

  .Select__menu-outer {
    bottom: ${({ directionUp }) => (directionUp ? '100%' : '')};
    left: 0;
    margin-top: 5px;
    max-height: 338px;
    position: absolute;
    right: 0;
    top: ${({ directionUp }) => (directionUp ? 'auto' : '')};
  }

  .Select__option--is-selected {
    background-color: #fff;
    color: ${({ theme }) => theme.colors.fontMain};
  }

  .Select__option--is-focused {
    background-color: #485da033;
    color: ${({ theme }) => theme.colors.fontMain};
  }

  .Select__placeholder {
    color: #6c6d6e;
    font-weight: 400;
    height: 36px;
    line-height: 36px;
  }

  .Select__value-container {
    margin-left: 16px;
    padding: 0;
  }

  .Select__value-container--is-multi .Select__value-container--has-value {
    background-color: #e0e0e0;
    border-radius: 4px;
    color: ${({ theme }) => theme.colors.fontMain};
    font-size: 14px;
    font-weight: 400;
    padding: 5px;
  }

  .Select__value-container--is-multi .Select__value-container--has-total {
    color: ${({ theme }) => theme.colors.primary};
    font-size: 14px;
    font-weight: 400;
    margin-left: 5px;
  }
`;

const OptionWrapperStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LabelStyled = styled.label`
  width: 90%;
`;

/* a red dot to indicate edited status */
const Marker = styled.div<{
  edited: boolean;
  right: string;
  left: string;
  top: string;
  bottom: string;
  color: string;
}>`
  display: ${({ edited }) => (edited ? 'block' : 'none')};
  position: absolute;
  right: ${({ right }) => right};
  left: ${({ left }) => left || '6px'};
  top: ${({ top }) => top || '17px'};
  bottom: ${({ bottom }) => bottom};
  width: 6px;
  height: 6px;
  background-color: ${({ color }) => color};
  border-radius: 10px;
`;

const MultiOptionWrapperStyled = styled.div`
  input[type='checkbox'] {
    accent-color: ${({ theme }) => theme.colors.primary};
  }
`;

export const allOption: TSOption = {
  label: 'Select All',
  value: 'Select All',
};

const ListSelector = ({
  items = [],
  arrowColor = '#162447',
  allowSelectAll = true,
  className = '',
  closeMenuOnSelect = true,
  directionUp = false,
  disabled = false,
  editDotColor = '#c70d08',
  editDotLeft = '',
  editDotRight = '',
  editDotBottom = '',
  editDotTop = '',
  edited = false,
  fieldName = '',
  footerLinkText = '',
  isMulti = false,
  hiddenMode = false,
  index = 0,
  inlineFormMode = false,
  isReadOnly = false,
  notSetItemRenderFunc = null,
  notSetItemText = 'Unassigned',
  notSetItemValue = 'Unassigned',
  notSetLabelText = '',
  onFooterClick = undefined,
  searchable = true,
  selectedItem = null,
  selectedItems = [],
  showRequired = false,
  tabIndex = 0,
  updateValue = () => ({}),
  updateValueMulti = () => ({}),
  unsettable = true,
  noResultsText = () => 'None',
  menuIsOpen = undefined,
  onMenuOpen = undefined,
  onMenuClose = undefined,
  gainsightTagId = '',
}: TSProps) => {
  const componentRef = useRef(null);

  const getOptions = useMemo(() => {
    let options = items.map<TSOption>(({ id, name, renderFunc }) => ({
      value: id,
      label: renderFunc ? renderFunc() : name,
    }));

    if (unsettable) {
      options = [
        {
          value: notSetItemValue,
          label: notSetItemRenderFunc ? notSetItemRenderFunc() : notSetItemText,
        },
        ...options,
      ];
    }

    if (isMulti && allowSelectAll) {
      options = options.length ? [allOption, ...options] : [];
    }

    return options;
  }, [
    items,
    unsettable,
    isMulti,
    allowSelectAll,
    notSetItemValue,
    notSetItemRenderFunc,
    notSetItemText,
  ]);

  const renderMenu = useCallback(
    (params: any) => (
      <div className='Select__menu-outer'>
        <div className='Select__menu' role='listbox'>
          <components.Menu {...params}>{params.children}</components.Menu>
        </div>
      </div>
    ),
    []
  );

  const renderMenuList = useCallback(
    (params: any) => {
      return (
        <>
          <components.MenuList {...params}>
            {params.children}
          </components.MenuList>

          {onFooterClick && footerLinkText && (
            <MenuFooter
              data-index={index}
              directionUp={directionUp}
              onClick={onFooterClick}
            >
              {footerLinkText}
            </MenuFooter>
          )}
        </>
      );
    },
    [onFooterClick, footerLinkText, index, directionUp]
  );

  const renderDropdownIndicator = useCallback(
    (params: any) => {
      const {
        selectProps: { menuIsOpen },
      } = params;
      return (
        <components.DropdownIndicator {...params}>
          <div className='Select-arrow-zone'>
            {menuIsOpen ? (
              <ChevronUp size='15' color={arrowColor} />
            ) : (
              <ChevronDown size='15' color={arrowColor} />
            )}
          </div>
        </components.DropdownIndicator>
      );
    },
    [arrowColor]
  );

  const renderOption = useCallback((params: any) => {
    const { isSelected, label } = params;

    return (
      <components.Option {...params}>
        <MultiOptionWrapperStyled>
          <input type='checkbox' checked={isSelected} onChange={() => null} />{' '}
          <label>{label}</label>
        </MultiOptionWrapperStyled>
      </components.Option>
    );
  }, []);

  const renderSingleOption = useCallback((params: any) => {
    const { isSelected, label, value, selectedItem } = params;
    const isSelectAllOption = value === 'Unassigned';
    return (
      <components.Option {...params}>
        <OptionWrapperStyled>
          <LabelStyled>{label}</LabelStyled>
          {(isSelected || (!selectedItem && isSelectAllOption)) && (
            <CheckMark />
          )}
        </OptionWrapperStyled>
      </components.Option>
    );
  }, []);

  const renderMultiValue = useCallback(
    ({ children, ...props }: ValueContainerProps<TSOption>) => {
      // eslint-disable-next-line prefer-const
      let [values, input] = children as any;
      const { getValue, hasValue } = props;
      const newChildren: Array<any> = [];
      const nbValues = getValue().length;

      if (Array.isArray(values)) {
        if (getValue()[0]?.label === 'Select All') {
          newChildren[0] = 'Selected All';
          newChildren[1] = nbValues - 1 === 0 ? '' : `(${nbValues - 1})`;
        } else {
          newChildren[0] = `${getValue()[0]?.label}`;
          newChildren[1] = `${nbValues - 1 ? `+${nbValues - 1}` : ''}`;
        }
      }

      return (
        <components.ValueContainer {...props}>
          <>
            {hasValue && (
              <span className='Select__value-container--is-multi Select__value-container--has-value'>
                {truncateString(newChildren[0], 18)}
              </span>
            )}
            <span className='Select__value-container--is-multi Select__value-container--has-total'>
              {newChildren[1]}
            </span>
            {!hasValue && values}
          </>
          {input}
        </components.ValueContainer>
      );
    },
    []
  );

  const renderIndicatorSeparator = () => <></>;

  const value = useMemo(() => {
    if (isMulti && items.length === selectedItems?.length) {
      return getOptions;
    }

    if (isMulti) {
      return selectedItems
        ? selectedItems.map<TSOption>(
            (item) =>
              getOptions.find(({ value }) => item.id === value) || { value: '' }
          )
        : [];
    }

    return (
      (selectedItem &&
        getOptions.find(({ value }) => selectedItem.id === value)) ||
      []
    );
  }, [isMulti, selectedItem, selectedItems, items, getOptions]);

  const label = useMemo(() => {
    if (isMulti) {
      return selectedItems && selectedItems.length
        ? selectedItems.map((item) => item.name).join(', ')
        : NOT_SET;
    }
    return selectedItem ? selectedItem.name : NOT_SET;
  }, [selectedItem, selectedItems, isMulti]);

  const styles = {
    multiValueLabel: (styles: any) => ({
      ...styles,
      fontSize: '14px',
    }),
    multiValueRemove: (styles: any) => ({
      ...styles,
      ':hover': {
        backgroundColor: '#f4f4f4',
        cursor: 'pointer',
      },
    }),
  };

  const placeholder = useMemo(() => {
    return selectedItem
      ? (selectedItem.renderFunc && selectedItem.renderFunc()) ||
          selectedItem.name
      : notSetLabelText;
  }, [selectedItem, notSetLabelText]);

  const updateMenuOpen = useCallback(() => {
    return !!onMenuOpen && onMenuOpen(!menuIsOpen);
  }, [onMenuOpen, menuIsOpen]);

  const updateMenuClose = useCallback(() => {
    setSearchTerm('');
    return !!onMenuClose && onMenuClose(!menuIsOpen);
  }, [onMenuClose, menuIsOpen]);

  const [searchTerm, setSearchTerm]: [string, (arg0: string) => void] =
    useState('');

  const onSearchInputChange = (value: string, e: { action: string }) => {
    if (e.action === 'input-change') {
      setSearchTerm(value);
    }
  };

  const componentsToRender = useMemo(() => {
    // If a custom component is passed as option do not use the default
    const singleSelectComponent =
      items[0] && items[0].renderFunc
        ? {
            DropdownIndicator: renderDropdownIndicator,
            IndicatorSeparator: renderIndicatorSeparator,
            Menu: renderMenu,
            MenuList: renderMenuList,
          }
        : {
            DropdownIndicator: renderDropdownIndicator,
            IndicatorSeparator: renderIndicatorSeparator,
            Menu: renderMenu,
            MenuList: renderMenuList,
            Option: (props) => renderSingleOption({ ...props, selectedItem }),
          };

    return isMulti
      ? {
          DropdownIndicator: renderDropdownIndicator,
          IndicatorSeparator: renderIndicatorSeparator,
          Menu: renderMenu,
          MenuList: renderMenuList,
          ValueContainer: renderMultiValue,
          Option: renderOption,
        }
      : singleSelectComponent;
  }, [
    isMulti,
    items,
    renderDropdownIndicator,
    renderMenu,
    renderMenuList,
    renderMultiValue,
    renderOption,
    renderSingleOption,
    selectedItem,
  ]);

  return (
    <Component className={className} data-gainsight-id={gainsightTagId}>
      {isReadOnly && <div>{label}</div>}
      {!isReadOnly && (
        <>
          <SelectStyles
            className='ListSelector-Dropdown--wrapper'
            hiddenMode={hiddenMode}
            directionUp={directionUp}
            inlineFormMode={inlineFormMode}
            disabled={disabled}
            showRequired={showRequired}
            isSelectAll={!!notSetLabelText}
          >
            <Select
              ref={componentRef}
              backspaceRemovesValue={false}
              className='ListSelector-Dropdown Select'
              classNamePrefix='Select'
              components={componentsToRender}
              isClearable={false}
              isDisabled={disabled}
              isMulti={isMulti}
              isSearchable={searchable}
              name={fieldName}
              noOptionsMessage={noResultsText}
              inputValue={searchTerm}
              onInputChange={onSearchInputChange}
              onChange={isMulti ? updateValueMulti : updateValue}
              options={getOptions}
              placeholder={placeholder}
              styles={styles}
              tabIndex={tabIndex}
              value={value}
              menuIsOpen={menuIsOpen}
              onMenuOpen={updateMenuOpen}
              onMenuClose={updateMenuClose}
              hideSelectedOptions={false}
              closeMenuOnSelect={closeMenuOnSelect}
            />
          </SelectStyles>
          <Marker
            bottom={editDotBottom}
            color={editDotColor}
            edited={edited}
            left={editDotLeft}
            right={editDotRight}
            top={editDotTop}
          />
        </>
      )}
    </Component>
  );
};

export default ListSelector;
