import { combineReducers } from 'redux';
import { call, put, takeLatest } from 'redux-saga/effects';
import { handleSagaError } from '../../api/utils';
import { calculateAvg } from '../../utils/chartHelper';
import { filterDemoCircuitData } from '../../utils/demo';

import {
  errorAction,
  Grouping,
  initialMetaState,
  loadingMetaState,
  MeasurementTypes,
  Resolution,
  successAction,
  TSItemUsage,
  TSQuerySearchParams,
  TSUsageDataResponse,
} from '../types';

import { TSPaginationSettings } from '../../components/SmartPaginator';
import { TSState } from '../../reducers/rootReducer';
import { truncateDecimals } from '../../utils';
import {
  TSItemUsageEnhancedForVoltage,
  TSSingleSiteAnalysisState,
  TSUsageDataResponseEnhancedForVoltage,
} from './types';
import { API } from './utils';

export const types = {
  FETCH_SITE_VOLTAGE_DATA: 'singleSiteAnalysis/FETCH_SITE_VOLTAGE_DATA',
  UPDATE_SITE_VOLTAGE_DATA: 'singleSiteAnalysis/UPDATE_SITE_VOLTAGE_DATA',
  UPDATE_SITE_VOLTAGE_PAGINATION:
    'singleSiteAnalysis/UPDATE_SITE_VOLTAGE_PAGINATION',
};

export interface TSSiteVoltageDataRequest
  extends Omit<TSQuerySearchParams, 'site'> {
  customerId: string;
  site: string; // not optional
  tz: string;
}

export const enhanceResponseItemUsage = (
  itemUsage: TSItemUsage
): TSItemUsageEnhancedForVoltage => {
  const valsToUse: number[] = (
    itemUsage.rmsVoltage ? itemUsage.rmsVoltage : []
  ).filter((v) => v > 0);
  return {
    ...itemUsage,
    expanded: false,
    min: valsToUse.length ? truncateDecimals(Math.min(...valsToUse), 2) : 0,
    max: valsToUse.length ? truncateDecimals(Math.max(...valsToUse), 2) : 0,
    average: calculateAvg(valsToUse),
  };
};

export const enhanceResponse = (
  response: TSUsageDataResponse
): TSUsageDataResponseEnhancedForVoltage => {
  return {
    ...response,
    data: response.data.map((data) => enhanceResponseItemUsage(data)),
  };
};

export const actions = {
  fetchSiteVoltageData: (request: TSSiteVoltageDataRequest) => ({
    type: types.FETCH_SITE_VOLTAGE_DATA,
    request,
  }),
  updateSiteVoltageData: (payload: TSUsageDataResponseEnhancedForVoltage) => ({
    type: types.UPDATE_SITE_VOLTAGE_DATA,
    payload,
  }),
  updateSiteVoltagePagination: (payload: TSPaginationSettings) => ({
    type: types.UPDATE_SITE_VOLTAGE_PAGINATION,
    payload,
  }),
};

export const initialState: TSSingleSiteAnalysisState = {
  siteId: '',
  siteVoltageData: {
    ts: [],
    grouping: Grouping.EQUIPMENT,
    from: '',
    to: '',
    measurementTypes: [MeasurementTypes.RMS_VOLTAGE],
    units: [],
    resolution: Resolution.MINUTES,
    data: [],
  },
  siteVoltageMeta: initialMetaState,
  siteVoltagePagination: {
    currentPage: 0,
    rowsPerPage: 10,
    totalRows: 0,
  },
};

function siteId(state = initialState.siteVoltageData, action) {
  switch (action.type) {
    case types.FETCH_SITE_VOLTAGE_DATA: // update the siteId on a new request
      return action.request.site;
    default:
      return state;
  }
}

function siteVoltageData(state = initialState.siteVoltageData, action) {
  switch (action.type) {
    case types.FETCH_SITE_VOLTAGE_DATA:
      return initialState.siteVoltageData;
    case successAction(types.FETCH_SITE_VOLTAGE_DATA):
      return action.payload;
    case types.UPDATE_SITE_VOLTAGE_DATA:
      return action.payload;
    default:
      return state;
  }
}

function siteVoltageMeta(state = initialState.siteVoltageMeta, action) {
  switch (action.type) {
    case types.FETCH_SITE_VOLTAGE_DATA:
      return loadingMetaState;
    case successAction(types.FETCH_SITE_VOLTAGE_DATA):
      return initialMetaState;
    case errorAction(types.FETCH_SITE_VOLTAGE_DATA):
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
}

function siteVoltagePagination(
  state = initialState.siteVoltagePagination,
  action
) {
  switch (action.type) {
    case types.FETCH_SITE_VOLTAGE_DATA:
      return initialState.siteVoltagePagination;
    case successAction(types.FETCH_SITE_VOLTAGE_DATA):
      return {
        ...state,
        totalRows: action.payload.data.length,
      };
    case errorAction(types.FETCH_SITE_VOLTAGE_DATA):
      return initialState.siteVoltagePagination;
    case types.UPDATE_SITE_VOLTAGE_PAGINATION:
      return action.payload;
    default:
      return state;
  }
}

export default combineReducers({
  siteId,
  siteVoltageData,
  siteVoltageMeta,
  siteVoltagePagination,
});

export const selectSingleSiteAnalysisEntity = (
  state: TSState
): TSSingleSiteAnalysisState => state.entities.singleSiteAnalysis;

function* fetchSiteVoltageDataSaga({
  request,
}: {
  type: string;
  request: TSSiteVoltageDataRequest;
}): Generator<any, void, any> {
  try {
    const siteVoltageDataResponse: TSUsageDataResponse = yield call(
      API.fetchSiteVoltageData, // TODO: add API
      request
    );
    // pass through demo filter, will apply any relevant filters if this is a demo user
    // do the filter before enhancing to make sure any masked fields used in the enhance are covered
    filterDemoCircuitData(siteVoltageDataResponse);
    const enhancedResponse = enhanceResponse(siteVoltageDataResponse);

    yield put({
      type: successAction(types.FETCH_SITE_VOLTAGE_DATA),
      payload: enhancedResponse,
    });
  } catch (e) {
    yield handleSagaError(
      errorAction(types.FETCH_SITE_VOLTAGE_DATA),
      e as Error
    );
  }
}

export const sagas = [
  takeLatest(types.FETCH_SITE_VOLTAGE_DATA, fetchSiteVoltageDataSaga),
];
