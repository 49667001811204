import {
  CheckIcon,
  Combobox,
  ComboboxItem,
  ComboboxParsedItem,
  ComboboxParsedItemGroup,
  Group,
  Text,
  isOptionsGroup,
} from '@mantine/core';

const MultiSelectOptions = ({
  options,
  isItemSelected,
  showSelectAll,
}: {
  options: ComboboxParsedItem[];
  isItemSelected: (item: ComboboxParsedItem) => boolean;
  showSelectAll: boolean;
}) => {
  const Item = ({ item }: { item: ComboboxItem }) => {
    return (
      <Combobox.Option
        value={item.value}
        key={item.value}
        active={isItemSelected(item)}
      >
        <Group gap='sm' wrap='nowrap'>
          {isItemSelected(item) ? (
            <div>
              <CheckIcon size={12} />
            </div>
          ) : null}
          <Text lineClamp={2}>{item.label}</Text>
        </Group>
      </Combobox.Option>
    );
  };

  const OptionGroup = ({ group }: { group: ComboboxParsedItemGroup }) => {
    return (
      <Combobox.Group label={group.group} key={group.group}>
        {group.items.map((item) => (
          <Item item={item} key={item.value} />
        ))}
      </Combobox.Group>
    );
  };
  return (
    <Combobox.Options mah={300} style={{ overflowY: 'auto' }}>
      {!!showSelectAll && (
        <Item item={{ label: 'Select All', value: '*' }} key={'*'} />
      )}
      {options.map((groupOrItem) => {
        if (isOptionsGroup(groupOrItem)) {
          return <OptionGroup group={groupOrItem} key={groupOrItem.group} />;
        } else {
          return <Item item={groupOrItem} key={groupOrItem.value} />;
        }
      })}
    </Combobox.Options>
  );
};

export default MultiSelectOptions;
