import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import styled, { css } from 'styled-components';

import { TSSiteConfigModal } from '../../ducks/modal';
import { selectSitesEntity } from '../../ducks/sites';
import OpHoursConfigurationView from './OpHoursConfigurationView';

const LabelStyles = css`
  color: #6c6d6e;
  font-family: 'Public Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.02em;
  line-height: 16px;
`;

const ModalSiteConfigStyled = styled.div<{
  isOpHoursActive: boolean;
}>`
  .react-tabs {
    -webkit-tap-highlight-color: transparent;
    height: 90vh;
    overflow: auto;
  }

  .react-tabs__tab-list {
    margin-top: 0;
    border-top: 1px solid transparent;
    border-bottom: 1px solid #dadce0;
    border-radius: 8px 8px 0 0;
    margin-bottom: ${({ isOpHoursActive }) => isOpHoursActive && '0'};
  }

  .react-tabs__tab {
    border-bottom: 1px solid #dadce0;
    color: #6c6d6e;
    cursor: pointer;
    display: inline-block;
    list-style: none;
    margin-right: 24px;
    padding: 8px 0;
    position: relative;
    top: 1px;
  }

  .react-tabs__tab--selected {
    color: inherit;
    font-weight: 600;
  }

  .react-tabs__tab--disabled {
    cursor: default;
  }

  .react-tabs__tab:focus {
    outline: none;
  }

  .react-tabs__tab:focus:after {
    content: '';
    position: absolute;
    height: 5px;
    left: -4px;
    right: -4px;
    bottom: -5px;
    background: #fff;
  }

  .react-tabs__tab-panel {
    display: none;
  }

  .react-tabs__tab-panel--selected {
    display: block;

    > label {
      ${LabelStyles}
    }
  }
`;

export const ViewFieldStyled = styled.label`
  display: block;
  margin-bottom: 16px;
`;

export const ViewFieldLabelStyled = styled.div`
  font-weight: 600;
  margin-bottom: 8px;
`;

export const FieldValueStyles = css`
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-family: 'Montserrat';
  padding: 10px 16px;
  width: 100%;
  font-weight: 400;
`;

export const ViewFieldValueStyled = styled.input`
  background-color: #f5f5f5;
  font-size: 14px;
  color: #162447;
  ${FieldValueStyles};
`;

export const FieldGridStyled = styled.div`
  align-items: flex-end;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;

  > label {
    ${LabelStyles}
  }
`;

export const TabHighlightStyled = styled.div`
  bottom: 0;
  height: 4px;
  position: absolute;
  width: 100%;

  .react-tabs__tab:hover & {
    background-color: #dadce0;
    border-radius: 2px 2px 0 0;
  }

  .react-tabs__tab--selected &,
  .react-tabs__tab--selected:hover & {
    background-color: ${({ theme }) => theme.colors.primary};
    border-radius: 2px 2px 0 0;
  }
`;

export const SiteInfoTabPanelStyled = styled.div`
  padding-left: 24px;
  padding-right: 32px;
`;

export const FormFieldWidthWrapperFullWidthStyles = css`
  grid-column: 1/-1;
`;

export const ViewInputField = ({ label, value }: any) => (
  <ViewFieldStyled>
    <ViewFieldLabelStyled>{`${label}`}</ViewFieldLabelStyled>
    <ViewFieldValueStyled readOnly value={value || value === 0 ? value : ''} />
  </ViewFieldStyled>
);

const ViewFieldGrid = ({ items }: { items: any[] }) => (
  <FieldGridStyled>
    {items.map(({ label, value }) => (
      <ViewInputField label={label} key={label} value={value} />
    ))}
  </FieldGridStyled>
);

const SiteConfigurationView = (props: TSSiteConfigModal) => {
  const { siteId, onlyOpHours } = props;
  const { byId: sitesById } = useSelector(selectSitesEntity);
  const currentSite = sitesById[siteId] || {};
  const {
    validName,
    addressFormatted,
    lat,
    lng,
    squareFootage,
    electricUtilityRate,
  } = currentSite;

  const [selectedTab, setSelectedTab] = useState(0);

  const opportunityDetailItems = useMemo(
    () => [
      { label: 'Latitude', value: lat || 0 },
      { label: 'Longitude', value: lng || 0 },
    ],
    [lat, lng]
  );

  const OpHoursActiveTab = useMemo(() => {
    return selectedTab === 1 || onlyOpHours || false;
  }, [selectedTab, onlyOpHours]);

  return (
    <ModalSiteConfigStyled isOpHoursActive={OpHoursActiveTab}>
      <Tabs
        forceRenderTabPanel
        onSelect={setSelectedTab}
        selectedIndex={selectedTab}
      >
        <TabList style={{ paddingLeft: '24px' }}>
          {!onlyOpHours && (
            <Tab data-gainsight-id='site-configuration-info'>
              Site Info
              <TabHighlightStyled />
            </Tab>
          )}
          <Tab
            key='opHoursTab'
            data-gainsight-id='site-configuration-operating-hours'
          >
            Operating Hours
            <TabHighlightStyled />
          </Tab>
        </TabList>
        {!onlyOpHours && (
          <SiteInfoTabPanelStyled>
            <TabPanel>
              <ViewInputField label='Site Name' value={validName} />
              <ViewInputField label='Address' value={addressFormatted} />
              <ViewFieldGrid items={opportunityDetailItems} />
              <ViewInputField label='Square Feet' value={squareFootage || ''} />
              <ViewInputField
                label='Utility Rate (Cost $ per kWh)'
                value={electricUtilityRate || ''}
              />
            </TabPanel>
          </SiteInfoTabPanelStyled>
        )}
        <TabPanel key='opHoursTab'>
          <OpHoursConfigurationView
            isActive={OpHoursActiveTab}
            siteId={siteId}
          />
        </TabPanel>
      </Tabs>
    </ModalSiteConfigStyled>
  );
};

export default SiteConfigurationView;
