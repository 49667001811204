/* eslint-disable @typescript-eslint/no-unused-vars */
import { combineReducers } from 'redux';
import { call, put, takeLatest } from 'redux-saga/effects';
import { handleSagaError } from '../../api/utils';
import { TSState } from '../../reducers/rootReducer';
import { filterDemoCircuitData } from '../../utils/demo';
import {
  Resolution,
  ResourceType,
  TSItemUsage,
  TSMetaState,
  TSUsageDataResponse,
} from '../types';
import {
  TSResourceItemUsage,
  TSResourceMetaDataResponse,
  TSResourceUsageDataRequest,
} from './electric';
import { API, enhanceGroupUsageData } from './utils';

interface TSWaterUsageMetaState extends TSMetaState {
  noWaterDataAvailable: boolean;
}

export interface TSWaterUsageEntityState {
  water: Array<TSResourceItemUsage>;
  waterResponseMetaData: TSResourceMetaDataResponse;
  meta: TSWaterUsageMetaState;
}

export const types = {
  FETCH_WATER_USAGE_DATA: 'FETCH_WATER_USAGE_DATA',
  FETCH_WATER_USAGE_DATA_SUCCESS: 'FETCH_WATER_USAGE_DATA_SUCCESS',
  FETCH_WATER_USAGE_META_DATA_RESPONSE_SUCCESS:
    'FETCH_WATER_USAGE_META_DATA_RESPONSE_SUCCESS',
  FETCH_WATER_USAGE_DATA_ERROR: 'FETCH_WATER_USAGE_DATA_ERROR',
  RESET_WATER_USAGE_DATA: 'RESET_WATER_USAGE_DATA',
};

export const actions = {
  fetchWaterUsageData: (request: TSResourceUsageDataRequest) => ({
    type: types.FETCH_WATER_USAGE_DATA,
    ...request,
  }),
  resetWaterUsageData: () => ({
    type: types.RESET_WATER_USAGE_DATA,
  }),
};

export const initialState: TSWaterUsageEntityState = {
  water: [],
  waterResponseMetaData: {
    ts: [],
    grouping: '',
    from: '',
    to: '',
    resolution: Resolution.DAILY,
  },
  meta: {
    noWaterDataAvailable: false,
    loading: true,
    error: '',
  },
};

function entityItems(action, state) {
  const newItems: Array<TSItemUsage> = Object.values(action.payload);
  return state
    .filter(
      (item) => !newItems.find((newItem) => newItem.groupId === item.groupId)
    )
    .concat(newItems);
}

function water(state = initialState.water, action) {
  switch (action.type) {
    case types.FETCH_WATER_USAGE_DATA:
    case types.RESET_WATER_USAGE_DATA:
      return initialState.water;
    case types.FETCH_WATER_USAGE_DATA_SUCCESS:
      return entityItems(action, state);
    default:
      return state;
  }
}

function waterResponseMetaData(
  state = initialState.waterResponseMetaData,
  action
) {
  switch (action.type) {
    case types.FETCH_WATER_USAGE_DATA:
    case types.RESET_WATER_USAGE_DATA:
      return initialState.waterResponseMetaData;
    case types.FETCH_WATER_USAGE_META_DATA_RESPONSE_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}

function meta(state = initialState.meta, action): TSWaterUsageMetaState {
  switch (action.type) {
    case types.FETCH_WATER_USAGE_DATA:
      return {
        ...state,
        loading: true,
        error: '',
        noWaterDataAvailable: false,
      };
    case types.FETCH_WATER_USAGE_DATA_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
        noWaterDataAvailable: false,
      };
    case types.FETCH_WATER_USAGE_DATA_SUCCESS:
      return {
        ...state,
        error: '',
        loading: false,
        noWaterDataAvailable: !action.payload[0],
      };
    default:
      return state;
  }
}

export default combineReducers({
  water,
  waterResponseMetaData,
  meta,
});

export const selectWaterUsageEntity = (
  state: TSState
): TSWaterUsageEntityState => state.entities.waterUsage;

// sagas
function* fetchWaterUsageSaga({
  type,
  ...request
}: TSResourceUsageDataRequest & { type: string }): Generator<any, void, any> {
  try {
    const waterUsageData: TSUsageDataResponse = yield call(API.fetchUsageData, {
      ...request,
      resourceType: ResourceType.WATER,
    });

    // pass through demo filter, will apply any relevant filters if this is a demo user
    // do the filter before enhancing to make sure any masked fields used in the enhance are covered
    filterDemoCircuitData(waterUsageData);

    const { data, ...metaData } = waterUsageData;

    yield put({
      type: types.FETCH_WATER_USAGE_DATA_SUCCESS,
      payload: data.map((group) => {
        return enhanceGroupUsageData(group, ResourceType.WATER);
      }),
    });
    yield put({
      type: types.FETCH_WATER_USAGE_META_DATA_RESPONSE_SUCCESS,
      payload: metaData,
    });
  } catch (e) {
    yield handleSagaError(types.FETCH_WATER_USAGE_DATA_ERROR, e as Error);
  }
}

export const sagas = [
  takeLatest(types.FETCH_WATER_USAGE_DATA, fetchWaterUsageSaga),
];
