import { all } from 'redux-saga/effects';
import { sagas as preferencesSagas } from '../ducks/Profile/Preferences';
import { sagas as authSagas } from '../ducks/auth';
import { sagas as circuitDataSaga } from '../ducks/circuit/circuitData';
import { sagas as circuitMetaSaga } from '../ducks/circuit/circuitMeta';
import { sagas as customerCircuitCategoriesSagas } from '../ducks/customerCircuitCategories/customerCategories';
import { sagas as customersSagas } from '../ducks/customers';
import { sagas as energyStarSaga } from '../ducks/energyStar/energyStar';
import { sagas as opportunitySagas } from '../ducks/opportunity/opportunities';
import { sagas as opportunitySeedsSagas } from '../ducks/opportunity/opportunitySeeds';
import { sagas as savingsAggregationsSagas } from '../ducks/projects/aggregations';
import { sagas as invoiceSagas } from '../ducks/projects/invoice';
import { sagas as performanceSagas } from '../ducks/projects/performance';
import { sagas as savingsSagas } from '../ducks/projects/savings';
import { sagas as accountSavedQueriesSagas } from '../ducks/savedQueries/accountQueries';
import { sagas as singleSiteAnalysisSaga } from '../ducks/singleSiteAnalysis/singleSiteAnalysis';
import { sagas as siteShiftSagas } from '../ducks/siteShifts';
import { sagas as siteSagas } from '../ducks/sites';
import { sagas as electricUsageSagas } from '../ducks/usageByCustomer/electric';
import { sagas as naturalGasSagas } from '../ducks/usageByCustomer/naturalGas';
import { sagas as waterUsageSagas } from '../ducks/usageByCustomer/water';
import { sagas as electricityMetaDataSaga } from '../ducks/usageByCustomerMetaData/electricityMetaData';
import { sagas as naturalGasMetaDataSaga } from '../ducks/usageByCustomerMetaData/naturalGasMetaData';
import { sagas as waterMetaDataSaga } from '../ducks/usageByCustomerMetaData/waterMetaData';

function* rootSaga() {
  yield all([
    ...authSagas,
    ...customersSagas,
    ...siteSagas,
    ...siteShiftSagas,
    ...circuitDataSaga,
    ...circuitMetaSaga,
    ...savingsSagas,
    ...invoiceSagas,
    ...performanceSagas,
    ...savingsAggregationsSagas,
    ...electricityMetaDataSaga,
    ...waterMetaDataSaga,
    ...naturalGasMetaDataSaga,
    ...electricUsageSagas,
    ...waterUsageSagas,
    ...naturalGasSagas,
    ...opportunitySagas,
    ...opportunitySeedsSagas,
    ...customerCircuitCategoriesSagas,
    ...accountSavedQueriesSagas,
    ...energyStarSaga,
    ...preferencesSagas,
    ...singleSiteAnalysisSaga,
  ]);
}

export default rootSaga;
