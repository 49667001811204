import {
  createTheme,
  CSSVariablesResolver,
  defaultVariantColorsResolver,
  MantineProvider,
  VariantColorsResolver,
} from '@mantine/core';

import { PropsWithChildren } from 'react';
const variantColorResolver: VariantColorsResolver = (input) => {
  const defaultResolvedColors = defaultVariantColorsResolver(input);

  if (input.variant === 'primary-outline') {
    return {
      background: 'var(--mantine-color-white)',
      hover: 'var(--mantine-color-blue-5)',
      color: 'var(--mantine-color-blue-5)',
      border:
        'calc(0.0625rem * var(--mantine-scale)) solid var(--mantine-color-blue-5)',
    };
  }
  if (input.variant === 'secondary') {
    return {
      background: 'var(--mantine-color-green-5)',
      hover: 'var(--mantine-color-green-6)',
      color: 'var(--mantine-color-white)',
      border:
        'calc(0.0625rem * var(--mantine-scale)) solid var(--mantine-color-green-5)',
    };
  }
  if (input.variant === 'danger') {
    return {
      background: 'var(--mantine-color-red-4)',
      hover: 'var(--mantine-color-red-5)',
      color: 'var(--mantine-color-white)',
      border:
        'calc(0.0625rem * var(--mantine-scale)) solid var(--mantine-color-red-4)',
    };
  }

  return defaultResolvedColors;
};

const mantineTheme = createTheme({
  black: '#162447',
  colors: {
    gray: [
      // TODO: can we just use mantine's defaults here
      '#f9f9f9',
      '#f5f5f5',
      '#e9ecef', // here and below are mantine defaults:
      '#dee2e6',
      '#ced4da',
      '#adb5bd',
      '#868e96',
      '#495057',
      '#343a40',
      '#212529',
    ],
    blue: [
      '#E9EBF4',
      '#ABB4D3',
      '#7A89BB',
      '#6578B4',
      '#5268AE',
      '#485DA0',
      '#43558E',
      '#3E4D7E',
      '#354064',
      '#2C354F',
    ],
    red: [
      '#DA8787',
      '#D56C6C',
      '#D15252',
      '#CF3A3A',
      '#C02F2F',
      '#AF2A2A',
      '#9A2929',
      '#882727',
      '#782626',
      '#6B2424',
    ],
    green: [
      '#F3FBEB',
      '#E3EBDB',
      '#D2E1C1',
      '#BDD0A7',
      '#ACC68F',
      '#9DBD79',
      '#8EB166',
      '#80A457',
      '#739250',
      '#67824A',
    ],
    dark: [
      '#64708D',
      '#586483',
      '#4C5A7B',
      '#425073',
      '#38486C',
      '#2F3F66',
      '#273454',
      '#242C3F',
      '#202530',
      '#162447',
    ],
    yellow: [
      '#fff9db',
      '#fff3bf',
      '#ffec99',
      '#ffe066',
      '#ffd43b',
      '#fcc419',
      '#fab005',
      '#f59f00',
      '#f08c00',
      '#e67700',
    ],
  },
  primaryShade: 4,
  primaryColor: 'blue',
  radius: {
    sm: '0.25rem',
    md: '0.5rem',
    lg: '0.75rem',
  },
  defaultRadius: 'sm',
  fontSizes: {
    xs: '0.625rem',
    sm: '0.75rem',
    md: '0.875rem',
    lg: '1rem',
    xl: '1.25rem',
  },
  fontFamily: "'Public Sans', sans-serif",
  headings: {
    sizes: {
      h1: {
        fontSize: '1.25rem',
        fontWeight: '600',
        lineHeight: '1.55',
      },
      h2: {
        fontSize: '1rem',
        fontWeight: '600',
        lineHeight: '1.5',
      },
      h3: {
        fontSize: '0.875rem',
        fontWeight: '600',
        lineHeight: '1.2',
      },
      h4: {
        fontSize: '0.76471rem',
        fontWeight: '500',
        lineHeight: '1.54',
      },
    },
  },
  spacing: {
    xs: '0.25rem',
    sm: '0.5rem',
    md: '0.75rem',
    lg: '1rem',
    xl: '1.25rem',
    '2xl': '1.5rem',
  },
  variantColorResolver,
});

export const variableResolver: CSSVariablesResolver = (theme) => ({
  variables: {
    '--mantine-color-error': theme.colors.red[4],
  },
  light: {
    '--mantine-color-error': theme.colors.red[4],
  },
  dark: {
    '--mantine-color-error': theme.colors.red[4],
  },
});

export const CustomMantineProvider = ({ children }: PropsWithChildren) => (
  <MantineProvider theme={mantineTheme} cssVariablesResolver={variableResolver}>
    {children}
  </MantineProvider>
);

export default mantineTheme;
