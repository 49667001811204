import { useSelector } from 'react-redux';
import { energyStarEntity } from '../../../ducks/energyStar/energyStar';
import { TSEnergyStarEntityState } from '../../../ducks/energyStar/energyStarTypes';
import { SubModalHeading, SubModalSubHeading } from '../OnboardingModal';

const Confirmation = () => {
  const energyStarState: TSEnergyStarEntityState =
    useSelector(energyStarEntity);
  return (
    <>
      <SubModalHeading>Success!</SubModalHeading>
      <SubModalSubHeading>
        Our system detected{' '}
        <b>
          {energyStarState?.customerStatus?.numberOfPendingProperties}{' '}
          properties
        </b>{' '}
        that have been shared with Redaptive from your Energy Star Portfolio
        Manager Account.
      </SubModalSubHeading>
    </>
  );
};

export default Confirmation;
