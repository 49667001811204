/* eslint-disable react/display-name */
import { forwardRef } from 'react';
import styled, { CSSProp, css } from 'styled-components';
import VerticalBarsLoader from './VerticalBarsLoader';

export type TSButtonType =
  | 'primary'
  | 'secondary'
  | 'light'
  | 'secondaryLight'
  | 'transparent'
  | 'default'
  | 'error';

export type TSButtonBorder = 'xSmall' | 'small' | 'medium' | 'large';

interface TSProps {
  borderRadius?: TSButtonBorder;
  buttonType?: TSButtonType;
  children: React.ReactNode;
  disabled?: boolean;
  loading?: boolean;
  outlined?: boolean;
  type?: 'button' | 'submit' | 'reset';
  onClick?: (event: any) => void;
  title?: string;
  gainsightTagId?: string;
}

export const buttonColors: { [property in TSButtonType]: string } = {
  primary: '#fff',
  secondary: '#fff',
  light: '#000',
  secondaryLight: '#485DA0',
  transparent: '#6C6D6E',
  default: '#6C6D6E',
  error: '#C02F2F',
};

const buttonStyles: { [Property in TSButtonType]: CSSProp } = {
  primary: css`
    background-color: ${({ theme }) => theme.colors.secondary};
    color: ${buttonColors.primary};
  `,
  secondary: css`
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${buttonColors.secondary};
  `,
  light: css`
    background-color: #fff;
    color: ${buttonColors.light};
  `,
  secondaryLight: css`
    background: ${({ theme }) => theme.colors.primaryLight};
    color: ${buttonColors.secondaryLight};
  `,
  transparent: css`
    background-color: transparent;
    color: ${buttonColors.transparent};
  `,
  default: css`
    background-color: #e0e0e0;
    color: ${buttonColors.default};
  `,
  error: css`
    background-color: ${({ theme }) => theme.colors.error};
    color: ${buttonColors.primary};
  `,
};

const buttonBorderRadius: { [Property in TSButtonBorder]: CSSProp } = {
  xSmall: css`
    border-radius: 4px;
  `,
  small: css`
    border-radius: 8px;
  `,
  medium: css`
    border-radius: 15px;
  `,
  large: css`
    border-radius: 18px;
  `,
};

export const ButtonStyled = styled.button<{
  borderRadius: string;
  buttonType: TSButtonType;
  outlined: boolean;
  type: string;
}>`
  ${({ buttonType }) => buttonStyles[buttonType]};

  ${({ borderRadius }) => buttonBorderRadius[borderRadius]};
  border: ${({ outlined, buttonType }) =>
    outlined ? `1px solid ${buttonColors[buttonType]}` : 0};
  cursor: pointer;
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 12px;
  font-weight: 600;
  height: 36px;
  min-width: 64px;
  padding: 0 16px;

  &:disabled {
    cursor: not-allowed;
    filter: saturate(30%) opacity(80%);
  }
`;

const LoadingWrapperStyled = styled.div`
  display: flex;
  justify-content: center;
`;

const Button = forwardRef<HTMLButtonElement, TSProps>(
  (
    {
      borderRadius = 'small',
      buttonType = 'default',
      children,
      disabled = false,
      loading = false,
      outlined = false,
      onClick = () => ({}),
      type = 'button',
      title = '',
      gainsightTagId = '',
      ...rest
    }: TSProps,
    ref
  ) => {

    return (
      <ButtonStyled
        borderRadius={borderRadius}
        disabled={disabled}
        outlined={outlined}
        onClick={onClick}
        buttonType={buttonType}
        type={type}
        title={title}
        data-gainsight-id={gainsightTagId}
        {...rest}
        ref={ref}
      >
        <>
          {!loading && children}
          {loading && (
            <LoadingWrapperStyled>
              <VerticalBarsLoader color={buttonColors[buttonType]} />
            </LoadingWrapperStyled>
          )}
        </>
      </ButtonStyled>
    );
  }
);

export default Button;
