/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from 'axios';
import moment, { Moment } from 'moment';
import { combineReducers } from 'redux';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { defaultHeaders, denaliApiBaseUrl } from '../../api';
import {
  getCurrentSelectedQueryId,
  handleAxiosError,
  handleSagaError,
} from '../../api/utils';
import { DATE_YEAR_MONTH_DAY_FORMAT } from '../../constants';
import { STORAGE_CUSTOMER_SELECTED_QUERY_ID } from '../../constants/api';
import { savedQueries as savedQueriesMockData } from '../../mockData/accountSavedQueries/savedQueries';
import { naturallySortEmptyLastCaseInsensitive } from '../../utils';
import { getOptionsDates } from '../../utils/optionsDates';
import { isVariantActive } from '../../utils/variants';
import { Period, ResourceType, TSMetaState } from '../types';

export interface TSAccountSavedQuery {
  id: string;
  customerId: string;
  name: string;
  parameters: {
    query: {
      buildingSystem?: string[];
      categoryValue?: string[];
      circuit?: string[];
      equipment?: string[];
      fromDate: string;
      grouping: string;
      groupingId?: string | null;
      meter?: string[];
      period?: string | null;
      resolution: string;
      resource?: ResourceType;
      site: string[];
      toDate: string;
    };
  };
}

type TSDeleteResponse = {
  status: string;
  message: string;
};

export type TSAccountSavedQueriesResponse = {
  results: Array<TSAccountSavedQuery>;
};

interface TSAccountSavedQueriesState extends TSMetaState {
  noSavedQueries: boolean;
}

export interface TSAccountSavedQueriesEntityState {
  byId: {
    [id: string]: TSAccountSavedQuery;
  };
  items: Array<TSAccountSavedQuery>;
  meta: TSAccountSavedQueriesState;
  selectedQueryId: string;
}

interface TSState {
  entities: {
    accountSavedQueries: TSAccountSavedQueriesEntityState;
  };
}

export const naturallySortBySavedQueryName = (
  a: TSAccountSavedQuery,
  b: TSAccountSavedQuery
) => naturallySortEmptyLastCaseInsensitive(a.name, b.name);

export const types = {
  FETCH_ACCOUNT_SAVED_QUERIES: 'FETCH_ACCOUNT_SAVED_QUERIES',
  FETCH_ACCOUNT_SAVED_QUERIES_SUCCESS: 'FETCH_ACCOUNT_SAVED_QUERIES_SUCCESS',
  FETCH_ACCOUNT_SAVED_QUERIES_ERROR: 'FETCH_ACCOUNT_SAVED_QUERIES_ERROR',
  CREATE_ACCOUNT_SAVED_QUERY: 'CREATE_ACCOUNT_SAVED_QUERY',
  CREATE_ACCOUNT_SAVED_QUERY_SUCCESS: 'CREATE_ACCOUNT_SAVED_QUERY_SUCCESS',
  CREATE_ACCOUNT_SAVED_QUERY_ERROR: 'CREATE_ACCOUNT_SAVED_QUERY_ERROR',
  SWITCH_SELECTED_QUERY: 'SWITCH_SELECTED_QUERY',
  DELETE_ACCOUNT_SAVED_QUERY: 'DELETE_ACCOUNT_SAVED_QUERY',
  DELETE_ACCOUNT_SAVED_QUERY_SUCCESS: 'DELETE_ACCOUNT_SAVED_QUERY_SUCCESS',
  DELETE_ACCOUNT_SAVED_QUERY_ERROR: 'DELETE_ACCOUNT_SAVED_QUERY_ERROR',
};

export const actions = {
  fetchAccountSavedQueries: (customerId: string): any => ({
    type: types.FETCH_ACCOUNT_SAVED_QUERIES,
    customerId,
  }),
  createAccountSavedQuery: (payload: Omit<TSAccountSavedQuery, 'id'>) => ({
    type: types.CREATE_ACCOUNT_SAVED_QUERY,
    payload,
  }),
  switchSelectedQuery: (id: string): any => ({
    type: types.SWITCH_SELECTED_QUERY,
    id,
  }),
  deleteAccountSavedQuery: (id: string): any => ({
    type: types.DELETE_ACCOUNT_SAVED_QUERY,
    id,
  }),
};

export const initialState: TSAccountSavedQueriesEntityState = {
  byId: {},
  items: [],
  meta: {
    loading: true,
    error: '',
    noSavedQueries: false,
  },
  selectedQueryId: '',
};

function entityById(action, state) {
  if (action.type === types.DELETE_ACCOUNT_SAVED_QUERY_SUCCESS) {
    return {
      ...action.payload.reduce(
        (acc, cur) => ({
          ...acc,
          [cur.id]: cur,
        }),
        {}
      ),
    };
  }
  return {
    ...state,
    ...action.payload.reduce(
      (acc, cur) => ({
        ...acc,
        [cur.id]: cur,
      }),
      {}
    ),
  };
}

function entityItems(action, state) {
  const newItems: Array<TSAccountSavedQuery> = Object.values(action.payload);
  if (action.type === types.DELETE_ACCOUNT_SAVED_QUERY_SUCCESS) {
    return newItems;
  }
  return state
    .filter((item) => !newItems.find((newItem) => newItem.id === item.id))
    .concat(newItems);
}

function byId(state = initialState.byId, action) {
  switch (action.type) {
    case types.FETCH_ACCOUNT_SAVED_QUERIES:
      return initialState.byId;
    case types.FETCH_ACCOUNT_SAVED_QUERIES_SUCCESS:
    case types.CREATE_ACCOUNT_SAVED_QUERY_SUCCESS:
    case types.DELETE_ACCOUNT_SAVED_QUERY_SUCCESS:
      return entityById(action, state);
    default:
      return state;
  }
}

function items(state = initialState.items, action) {
  switch (action.type) {
    case types.FETCH_ACCOUNT_SAVED_QUERIES:
      return initialState.items;
    case types.FETCH_ACCOUNT_SAVED_QUERIES_SUCCESS:
    case types.CREATE_ACCOUNT_SAVED_QUERY_SUCCESS:
    case types.DELETE_ACCOUNT_SAVED_QUERY_SUCCESS:
      return entityItems(action, state);
    default:
      return state;
  }
}

function meta(state = initialState.meta, action) {
  switch (action.type) {
    case types.FETCH_ACCOUNT_SAVED_QUERIES:
      return {
        ...state,
        error: '',
        loading: true,
        noSavedQueries: false,
      };
    case types.FETCH_ACCOUNT_SAVED_QUERIES_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case types.FETCH_ACCOUNT_SAVED_QUERIES_SUCCESS:
      return {
        ...state,
        error: '',
        loading: false,
        noSavedQueries: !action.payload[0],
      };
    default:
      return state;
  }
}

function selectedQueryId(state = initialState.selectedQueryId, action) {
  switch (action.type) {
    case types.SWITCH_SELECTED_QUERY:
      localStorage.setItem(STORAGE_CUSTOMER_SELECTED_QUERY_ID, action.id);
      return action.id;
    case types.FETCH_ACCOUNT_SAVED_QUERIES_SUCCESS: {
      return getCurrentSelectedQueryId();
    }
    default:
      return state;
  }
}

export default combineReducers({
  byId,
  items,
  meta,
  selectedQueryId,
});

export const selectCurrentSelectedQueryId = (state: TSState): string =>
  state.entities.accountSavedQueries.selectedQueryId;

export const selectAccountSavedQueriesEntity = (
  state: TSState
): TSAccountSavedQueriesEntityState => state.entities.accountSavedQueries;

const enhanceSavedQuery = (
  savedQuery: TSAccountSavedQuery
): TSAccountSavedQuery => {
  const { period, fromDate, toDate, groupingId } = savedQuery.parameters.query;
  let [startDate, endDate]: Array<Moment> = [moment(fromDate), moment(toDate)];

  if (period && period !== Period.CUSTOM) {
    [startDate, endDate] = period && getOptionsDates({})[period];
  }

  return {
    ...savedQuery,
    parameters: {
      query: {
        ...savedQuery.parameters.query,
        fromDate: startDate.format(DATE_YEAR_MONTH_DAY_FORMAT).toString(),
        toDate: endDate.format(DATE_YEAR_MONTH_DAY_FORMAT).toString(),
        groupingId: groupingId ?? '',
        period: period ? period : Period.CUSTOM,
      },
    },
  };
};

export const API = {
  fetchAccountSavedQueries: (customerId: string) => {
    if (isVariantActive('mock')) {
      const mockData = savedQueriesMockData(customerId);
      return Promise.resolve(mockData).then(
        (data) => new Promise((resolve) => setTimeout(() => resolve(data), 200))
      );
    }
    const url = `${denaliApiBaseUrl()}/account/saved-queries?customerId=${customerId}`;

    return axios
      .get(url, { headers: defaultHeaders() })
      .then(({ data }: { data: TSAccountSavedQueriesResponse }) => data)
      .catch(handleAxiosError);
  },
  createAccountSavedQuery: (payload: Omit<TSAccountSavedQuery, 'id'>) => {
    if (isVariantActive('mock')) {
      const mockData = savedQueriesMockData(payload.customerId);
      return Promise.resolve(mockData).then(
        (data) => new Promise((resolve) => setTimeout(() => resolve(data), 200))
      );
    }

    const url = `${denaliApiBaseUrl()}/account/saved-queries`;

    return axios
      .post(url, payload, { headers: defaultHeaders() })
      .then(({ data }: { data: TSAccountSavedQuery }) => data)
      .catch(handleAxiosError);
  },
  deleteAccountSavedQuery: (id: string) => {
    const url = `${denaliApiBaseUrl()}/account/saved-queries/${id}`;

    return axios
      .delete(url, { headers: defaultHeaders() })
      .then(({ data }: { data: TSDeleteResponse }) => data)
      .catch(handleAxiosError);
  },
};

function* fetchAccountSavedQueriesSaga({
  type,
  customerId,
}: {
  type: string;
  customerId: string;
}): Generator<any, void, any> {
  try {
    const savedQueries: TSAccountSavedQueriesResponse = yield call(
      API.fetchAccountSavedQueries,
      customerId
    );
    yield put({
      type: types.FETCH_ACCOUNT_SAVED_QUERIES_SUCCESS,
      payload: savedQueries?.results
        .map((savedQuery) => enhanceSavedQuery(savedQuery))
        .sort(naturallySortBySavedQueryName),
    });
  } catch (e) {
    yield handleSagaError(types.FETCH_ACCOUNT_SAVED_QUERIES_ERROR, e as Error);
  }
}

function* createAccountSavedQuerySaga({
  type,
  payload,
}: {
  type: string;
  payload: Omit<TSAccountSavedQuery, 'id'>;
}): Generator<any, void, any> {
  const { items: existingSavedQueries } = yield select(
    selectAccountSavedQueriesEntity
  );
  try {
    const createdSavedQuery: TSAccountSavedQuery = yield call(
      API.createAccountSavedQuery,
      payload
    );

    const { id } = createdSavedQuery;

    yield put({
      type: types.SWITCH_SELECTED_QUERY,
      id,
    });

    yield put({
      type: types.CREATE_ACCOUNT_SAVED_QUERY_SUCCESS,
      payload: [...existingSavedQueries, createdSavedQuery].sort(
        naturallySortBySavedQueryName
      ),
    });
  } catch (e) {
    yield handleSagaError(types.CREATE_ACCOUNT_SAVED_QUERY_ERROR, e as Error);
  }
}

function* deleteAccountSavedQuerySaga({
  type,
  id,
}: {
  type: string;
  id: string;
}): Generator<any, void, any> {
  const { items: existingSavedQueries } = yield select(
    selectAccountSavedQueriesEntity
  );
  try {
    yield call(API.deleteAccountSavedQuery, id);

    const updateFilters = existingSavedQueries.filter(
      ({ id: queryId }) => queryId !== id
    );

    yield put({
      type: types.DELETE_ACCOUNT_SAVED_QUERY_SUCCESS,
      payload: updateFilters.sort(naturallySortBySavedQueryName),
    });
  } catch (e) {
    yield handleSagaError(types.DELETE_ACCOUNT_SAVED_QUERY_ERROR, e as Error);
  }
}

export const sagas = [
  takeLatest(types.FETCH_ACCOUNT_SAVED_QUERIES, fetchAccountSavedQueriesSaga),
  takeLatest(types.CREATE_ACCOUNT_SAVED_QUERY, createAccountSavedQuerySaga),
  takeLatest(types.DELETE_ACCOUNT_SAVED_QUERY, deleteAccountSavedQuerySaga),
];
