import * as Yup from 'yup';

const createAndEditValidationSchema = Yup.object().shape({
  shiftName: Yup.string()
    .label('Shift Name')
    .ensure() // Transforms undefined and null values to an empty string.
    .test(
      'Only Empty?',
      'Shift Name cannot be only empty characters',
      (value) => {
        const isValid = value.split(' ').join('').length !== 0;

        return isValid;
      }
    )
    .required(),
  shiftStartDay: Yup.string().label('Shift Start Day').required(),
  shiftStartTime: Yup.string().label('Shift Start Time').required(),
  shiftEndDay: Yup.string().label('Shift End Day').required(),
  shiftEndTime: Yup.string().label('Shift End Time').required(),
});

export default createAndEditValidationSchema;
