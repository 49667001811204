import styled from 'styled-components';
import TextInput from '../../mantine/TextInput';
import { useContext } from 'react';
import {
  BACKEND_ERROR,
  ESOnboardingContext,
  SubModalHeading,
} from '../OnboardingModal';

const TextInputStyled = styled(TextInput)`
  margin-bottom: 20px;
`;

const ErrorText = styled.p`
  color: #c02f2f;
  font-size: 13px;
  font-weight: 400;
`;

const Authenticate = () => {
  const { formik } = useContext(ESOnboardingContext);
  const { handleChange, touched, errors, isSubmitting, setFieldTouched } =
    formik;

  const handleBlur = (e) => {
    setFieldTouched(e.target.name, true);
  };

  return (
    <>
      <SubModalHeading>Authenticate</SubModalHeading>
      <TextInputStyled
        label='Energy Star Portfolio Manager Username'
        placeholder='Enter username'
        name='username'
        onChange={handleChange}
        onBlur={handleBlur}
        error={
          touched.username &&
          errors.username &&
          errors.username !== BACKEND_ERROR
            ? errors.username
            : null
        }
        disabled={isSubmitting}
      />
      <TextInputStyled
        label='Associated Email with Energy Star Account'
        placeholder='Enter email'
        type='email'
        name='email'
        onChange={handleChange}
        onBlur={handleBlur}
        error={touched.email && errors.email}
        disabled={isSubmitting}
      />
      {errors?.username === BACKEND_ERROR && (
        <ErrorText>
          Entered details don&apos;t match the Account ID entered in previous
          step.
        </ErrorText>
      )}
    </>
  );
};

export default Authenticate;
