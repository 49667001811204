import FileSaver from 'file-saver';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';

import { Parser } from '@json2csv/plainjs';
import { camelCase, isEmpty } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { usePrevious } from '@mantine/hooks';
import useSavedFilters from '../../components/BuildingInsightsSavedQueries/useSavedFilter';
import { TSItem } from '../../components/ListSelector';
import {
  API_DATE_FORMAT,
  DATE_FORMAT_CSV,
  DATE_FORMAT_DATA_API_REQUEST,
  DATE_FORMAT_DATA_API_RESPONSE_NO_TZ,
  DATE_YEAR_MONTH_DAY_FORMAT,
  LAST_30_DAYS,
  LAST_7_DAYS,
  LAST_MONTH,
  THIS_MONTH,
  baseOptions,
} from '../../constants';
import {
  TSGetCircuitData,
  actions as circuitDataActions,
  selectCircuitsDataEntity,
} from '../../ducks/circuit/circuitData';
import {
  actions as circuitsMetaDataActions,
  selectCircuitMetaDataEntity,
} from '../../ducks/circuit/circuitMeta';
import {
  TSCustomersEntityState,
  selectCustomersEntity,
} from '../../ducks/customers';
import { actions as modalActions } from '../../ducks/modal';
import {
  actions as accountSavedQueriesActions,
  selectAccountSavedQueriesEntity,
} from '../../ducks/savedQueries/accountQueries';
import {
  TSSiteVoltageDataRequest,
  selectSingleSiteAnalysisEntity,
  actions as singleSiteAnalysisActions,
} from '../../ducks/singleSiteAnalysis/singleSiteAnalysis';
import { TSSiteTabs, selectSitesEntity } from '../../ducks/sites';
import {
  Grouping,
  MeasurementTypes,
  Period,
  Resolution,
  ResourceType,
  TSQuerySearchParams,
} from '../../ducks/types';
import { TSActionTypes, gainsightPXGlobalContext } from '../../utils/gainsight';
import {
  defaultQuerySearchParams,
  getResolutionOptions,
  getUrlSearchParams,
  isBasicConsumption,
  validateAndSetDefaultsForUrlParams,
} from '../../utils/getUrlSearchParams';

export const defaultGroupingOptions = [
  { name: 'Site', id: 'site' },
  { name: 'Panel', id: 'panel' },
  { name: 'Building System', id: 'buildingSystem' },
  { name: 'Circuit', id: 'circuit' },
  { name: 'Equipment Name', id: 'equipment' },
  { name: 'Meter', id: 'meter' },
];

const filtersOutsideDrawer = [
  'site',
  'fromDate',
  'toDate',
  'resolution',
  'grouping',
  'groupingId',
  'period',
  'resourceType',
];

const measurementsText = {
  electricity: 'Electric (kWh)',
  water: 'Water (Gal)',
  'natural-gas': 'Natural Gas (CCF)',
};

const measurement = (resourceType: ResourceType, currentTab: TSSiteTabs) => {
  if (
    currentTab === TSSiteTabs.PEAK_USAGE &&
    resourceType === ResourceType.ELECTRICITY
  ) {
    return 'Electric (kW)';
  }
  return measurementsText[resourceType];
};

const useBuildingInsightsFilters = ({ currentTab }) => {
  const {
    isSaveFilterModalOpen,
    period,
    resetQueryParams,
    setPeriod,
    updateIsSaveFilterModalOpen,
    updateSelectedQuery,
    saveQuerySubmit,
  } = useSavedFilters();

  const dispatch = useDispatch();
  const location = useLocation();
  const previousLocation = usePrevious(location || {});

  const [searchParams, setSearchParams] = useSearchParams();
  // The serialize function here would be responsible for
  // creating an object of { key: value } pairs from the
  // fields in the form that make up the query.
  const serializeFormQuery = useMemo(() => {
    return Object.fromEntries(searchParams);
  }, [searchParams]);

  const {
    byId: sitesById,
    items: sites,
    meta: { loading: sitesLoading },
  } = useSelector(selectSitesEntity);

  const {
    currentCustomerId,
    meta: { loading: customersLoading },
  }: TSCustomersEntityState = useSelector(selectCustomersEntity);

  const {
    items: circuitMetaFilters,
    meta: {
      loading: circuitMetaFiltersLoading,
      error: circuitMetaFiltersError,
    },
  } = useSelector(selectCircuitMetaDataEntity);

  const { items: circuitUsageData, summary: circuitUsageSummaryData } =
    useSelector(selectCircuitsDataEntity);

  const { siteVoltageData } = useSelector(selectSingleSiteAnalysisEntity);

  const { byId: accountSavedQueriesById } = useSelector(
    selectAccountSavedQueriesEntity
  );

  const [isFiltersDrawerOpen, updateIsFiltersDrawerOpen] = useState(false);
  useEffect(() => {
    if (isSaveFilterModalOpen) {
      dispatch(
        modalActions.showSaveFilterModal({
          opened: isSaveFilterModalOpen,
          onSubmit: (name) => {
            saveQuerySubmit(name, serializeFormQuery);
          },
          onClose: () => updateIsSaveFilterModalOpen(false),
        })
      );
    } else {
      dispatch(modalActions.hideModal());
    }
  }, [
    isSaveFilterModalOpen,
    updateIsSaveFilterModalOpen,
    saveQuerySubmit,
    serializeFormQuery,
    dispatch,
  ]);

  const maxDaysAllowed = useMemo(() => {
    return serializeFormQuery.resolution === '1day' ? 365 : 30;
  }, [serializeFormQuery.resolution]);

  const selectedSites = useMemo(
    () => serializeFormQuery.site?.split(',').filter((id) => id) ?? [],
    [serializeFormQuery]
  );

  const groupingOptions = useMemo(() => {
    if (!circuitMetaFiltersLoading && !circuitMetaFiltersError) {
      return circuitMetaFilters.map(({ name, id }) => {
        return {
          name: name,
          id: id,
        };
      });
    }
    return defaultGroupingOptions;
  }, [circuitMetaFilters, circuitMetaFiltersLoading, circuitMetaFiltersError]);

  const selectedGrouping = useMemo(() => {
    const { grouping, groupingId } = serializeFormQuery;
    if (groupingId) {
      // remove .split.join once real ids are flowing through
      return groupingOptions.find(
        (item) =>
          item.id === serializeFormQuery?.groupingId?.split('+').join(' ')
      );
    }
    return groupingOptions.find((item) => item.id === grouping);
  }, [serializeFormQuery, groupingOptions]);

  const multiSiteCsvData = useMemo(() => {
    const { ts: timeseries } = circuitUsageSummaryData;

    return circuitUsageData.map((group) => {
      const { groupName } = group;
      const dataUsageToUse =
        currentTab === TSSiteTabs.PEAK_USAGE ? 'kWUsage' : 'usage';
      const usageLabeled = group[dataUsageToUse].map((value, i) => [
        moment(timeseries[i], DATE_FORMAT_DATA_API_RESPONSE_NO_TZ).format(
          DATE_FORMAT_CSV
        ),
        value,
      ]);
      return Object.fromEntries([
        ['Group', groupName],
        [
          'Resource',
          `${measurement(
            serializeFormQuery.resourceType as ResourceType,
            currentTab
          )}`,
        ],
        ...usageLabeled,
      ]);
    });
  }, [
    circuitUsageData,
    circuitUsageSummaryData,
    serializeFormQuery,
    currentTab,
  ]);

  const singleSiteCsvVoltageData = useMemo(() => {
    const { ts: timeseries, data } = siteVoltageData;
    return data.map(({ rmsVoltage, groupName }) => {
      const usageLabeled =
        rmsVoltage?.map((value, i) => [
          moment(timeseries[i], DATE_FORMAT_DATA_API_RESPONSE_NO_TZ).format(
            DATE_FORMAT_CSV
          ),
          value,
        ]) ?? [];

      return Object.fromEntries([
        ['Group', groupName],
        ['Resource', 'Electric (V)'],
        ...usageLabeled,
      ]);
    });
  }, [siteVoltageData]);

  const csv = useMemo(() => {
    const csvData =
      currentTab === TSSiteTabs.VOLTAGE_ANALYSIS
        ? singleSiteCsvVoltageData
        : multiSiteCsvData;

    if (isEmpty(csvData)) {
      return null;
    }
    const json2csvParser = new Parser();
    const csv = json2csvParser.parse(csvData);

    return new Blob([csv], { type: 'text/csv' });
  }, [multiSiteCsvData, singleSiteCsvVoltageData, currentTab]);

  const saveCSV = useCallback(() => {
    const { fromDate, toDate, resolution } = serializeFormQuery;
    const fromFormatted = moment(fromDate).format(API_DATE_FORMAT);
    const toFormatted = moment(toDate).format(API_DATE_FORMAT);
    const fileName =
      `Data Explorer - ${fromFormatted} to ${toFormatted} - ` +
      `${selectedGrouping?.name} - ${resolution}.csv`;
    return FileSaver.saveAs(csv, fileName);
  }, [csv, selectedGrouping?.name, serializeFormQuery]);

  const timezone = useMemo(() => {
    if (selectedSites.length === 1) {
      return sitesById[selectedSites[0]]?.address.timezone;
    }
    return 'America/Chicago';
  }, [selectedSites, sitesById]);

  const getProgramStartDate = useCallback(() => {
    if (sites.length && !sitesLoading) {
      const sitesId = selectedSites[0]
        ? selectedSites
        : sites.map((site) => site.id);
      const selectedSitesIngestionDate = sitesId
        .map((site) => moment(sitesById[site].ingestionDataStart))
        .filter((ingestionDate) => ingestionDate.isValid());

      return moment(
        moment.min(selectedSitesIngestionDate),
        DATE_FORMAT_DATA_API_REQUEST
      );
    }
    return moment('2010', 'YYYY');
  }, [selectedSites, sitesById, sites, sitesLoading]);

  const didSwitchPages = previousLocation?.pathname != location?.pathname;

  const measurementTypes = getMeasurementTypeForLocationAndUrlParams(
    location,
    serializeFormQuery as Partial<TSQuerySearchParams>
  );

  const didSwitchMeasurementTypes =
    measurementTypes != serializeFormQuery.measurementTypes;

  useEffect(() => {
    if (didSwitchPages || didSwitchMeasurementTypes) {
      // run the urlParams through validation:
      const updatedSearchParams = getUrlSearchParams(
        serializeFormQuery as Partial<TSQuerySearchParams>,
        currentTab,
        sites,
        didSwitchPages
      );
      setSearchParams(updatedSearchParams);
      setPeriod(serializeFormQuery.period);
    }
  }, [
    serializeFormQuery,
    sites,
    currentTab,
    setSearchParams,
    measurementTypes,
    didSwitchPages,
    didSwitchMeasurementTypes,
    selectedSites,
    setPeriod,
  ]);

  useEffect(() => {
    if (
      !customersLoading &&
      currentCustomerId &&
      !sitesLoading &&
      sites.length
    ) {
      const queryParams = {
        ...serializeFormQuery,
        site: serializeFormQuery.site,
        customerId: currentCustomerId,
        tz: timezone,
      };

      if (currentTab === TSSiteTabs.VOLTAGE_ANALYSIS && queryParams.site) {
        dispatch(
          singleSiteAnalysisActions.fetchSiteVoltageData(
            queryParams as any as TSSiteVoltageDataRequest
          )
        );
      } else {
        dispatch(
          circuitDataActions.fetchCircuitData(queryParams as TSGetCircuitData)
        );
      }
    }
  }, [
    currentCustomerId,
    customersLoading,
    sitesLoading,
    serializeFormQuery,
    timezone,
    dispatch,
    sites,
    currentTab,
  ]);

  useEffect(() => {
    if (currentCustomerId) {
      dispatch(
        accountSavedQueriesActions.fetchAccountSavedQueries(currentCustomerId)
      );
    }
  }, [currentCustomerId, dispatch]);

  useEffect(() => {
    // fetch circuitsMetaData if categoryValue is present on the searchParams
    if (currentCustomerId && sites.length) {
      dispatch(
        circuitsMetaDataActions.fetchCircuitMetaData({
          customerId: currentCustomerId,
          site: selectedSites.join(','),
          resourceType: serializeFormQuery.resourceType,
        })
      );
    }
  }, [
    selectedSites,
    sites.length,
    currentCustomerId,
    serializeFormQuery.categoryValue,
    serializeFormQuery.resourceType,
    dispatch,
  ]);

  const categoryValueSelected = useMemo(() => {
    const categoryValues = circuitMetaFilters.filter(
      (filter) => filter.fieldType === 'categoryValue'
    );
    const categoryValuesSelected =
      serializeFormQuery.categoryValue?.split(',') || [];
    const selectedCategoryValueName: string[] = [];

    categoryValues.map((category) => {
      const categoryValueIds = category.values.map((value) => value.id);
      // save parent of each category value selected
      categoryValuesSelected.forEach((valueId) => {
        if (categoryValueIds.includes(valueId)) {
          return selectedCategoryValueName.push(category.name);
        } else {
          return;
        }
      });
    });
    // resolved an array of parents removing duplication for final count
    return [...new Set([...selectedCategoryValueName])];
  }, [circuitMetaFilters, serializeFormQuery]);

  const handleResolutionUpdate = ({ value }: { value: string }) => {
    setSearchParams({
      ...serializeFormQuery,
      resolution: value.toLocaleLowerCase(),
    });
  };

  // non-mantine format
  const handleSingleSiteUpdate = useCallback(
    ({ value }: { value: string }) => {
      // send the updated context to gainsight:
      gainsightPXGlobalContext(TSActionTypes.set, {
        siteId: value ? value : '',
        siteName: value ? sites[value] : '',
      });
      setSearchParams({
        ...serializeFormQuery,
        site: value,
      });
    },
    [sites, serializeFormQuery, setSearchParams]
  );

  const handleResourceTypeUpdate = useCallback(
    ({ value }: { value: string }) => {
      const { period, fromDate, toDate, grouping, resolution, site } =
        serializeFormQuery;

      const dateRange = {
        ...(fromDate && toDate && { fromDate, toDate }),
      };

      // Try to preserve following params
      const updatedUrlParams = {
        ...defaultQuerySearchParams,
        site,
        resourceType: value as ResourceType,
        period: period as Period,
        grouping: grouping as Grouping,
        resolution: resolution as Resolution,
        ...dateRange,
      };

      validateAndSetDefaultsForUrlParams(
        updatedUrlParams,
        currentTab,
        sites,
        false
      );
      setSearchParams(updatedUrlParams);
    },
    [setSearchParams, serializeFormQuery, currentTab, sites]
  );

  const handleGroupingUpdate = useCallback(
    ({ value }: { value: string }) => {
      const camelCaseValue = camelCase(value);
      const defaultOptionsValues = defaultGroupingOptions.map(
        (option) => option.id
      );

      const updatedGrouping = defaultOptionsValues.includes(camelCaseValue)
        ? camelCaseValue
        : 'categoryValue';
      const updatedGroupingId = defaultOptionsValues.includes(camelCaseValue)
        ? ''
        : value;

      setSearchParams({
        ...serializeFormQuery,
        grouping: updatedGrouping,
        groupingId: updatedGroupingId,
      });
    },
    [serializeFormQuery, setSearchParams]
  );

  const handleUpdateDateRange = useCallback(
    ({ startDate, endDate, selectedOptionDate }) => {
      setPeriod(selectedOptionDate ?? Period.CUSTOM);

      const validStartDate = moment(startDate)?.isValid()
        ? moment(startDate)
        : moment(serializeFormQuery.fromDate);
      const validEndDate = moment(endDate)?.isValid()
        ? moment(endDate)
        : moment(serializeFormQuery.toDate);

      const updatedFromDate = validStartDate
        .startOf('day')
        .format(DATE_YEAR_MONTH_DAY_FORMAT);
      const updatedToDate = validEndDate
        .endOf('day')
        .format(DATE_YEAR_MONTH_DAY_FORMAT);

      if (selectedOptionDate === Period.CUSTOM) {
        setSearchParams({
          ...serializeFormQuery,
          fromDate: updatedFromDate,
          toDate: updatedToDate,
          period: selectedOptionDate,
        });
      } else {
        delete serializeFormQuery['fromDate'];
        delete serializeFormQuery['toDate'];

        setSearchParams({
          ...serializeFormQuery,
          period: selectedOptionDate,
        });
      }
    },
    [serializeFormQuery, setPeriod, setSearchParams]
  );

  const openSiteConfigModal = useCallback(() => {
    if (selectedSites.length === 1) {
      dispatch(modalActions.showSiteConfigModal({ siteId: selectedSites[0] }));
    }
  }, [dispatch, selectedSites]);

  const openQueryBuilderModal = useCallback(() => {
    dispatch(
      modalActions.showQueryBuilderModal({
        sitesId: selectedSites,
        isBasicConsumption: isBasicConsumption(currentTab),
      })
    );
    updateIsFiltersDrawerOpen(true);
  }, [dispatch, selectedSites, currentTab]);

  const filtersAppliedInsideModal = useMemo(() => {
    return Object.keys(serializeFormQuery).filter(
      (filter) =>
        !filtersOutsideDrawer.includes(filter) &&
        serializeFormQuery[filter] !== ''
    );
  }, [serializeFormQuery]);

  const FilterButtonText = useMemo(() => {
    return filtersAppliedInsideModal.length ? `Edit Filters` : 'Add Filters';
  }, [filtersAppliedInsideModal]);

  const urlParamsFromToDates = useMemo(() => {
    const { fromDate, toDate, period } = serializeFormQuery;

    return {
      startDate: period !== Period.CUSTOM ? null : moment(fromDate).toDate(),
      endDate: period !== Period.CUSTOM ? null : moment(toDate).toDate(),
    };
  }, [serializeFormQuery]);

  const relativeDatesOptions = useMemo(() => {
    const relativeDatesToShow = [
      LAST_7_DAYS,
      LAST_30_DAYS,
      LAST_MONTH,
      THIS_MONTH,
    ];
    return baseOptions.filter((option) =>
      relativeDatesToShow.includes(option.value)
    );
  }, []);

  // non-mantine format
  const urlParamsSelectedSite: TSItem | undefined = useMemo(() => {
    if (
      serializeFormQuery.site &&
      sitesById &&
      sitesById[serializeFormQuery.site]
    ) {
      return {
        id: serializeFormQuery.site,
        name: sitesById[serializeFormQuery.site].validName,
      } as TSItem;
    } else {
      return undefined;
    }
  }, [serializeFormQuery, sitesById]);

  // is the resource dropdown enabled
  const allowResourceSelection = isBasicConsumption(currentTab);

  const closeFiltersDrawer = () => {
    updateIsFiltersDrawerOpen(false);
  };

  const handleResetQueryParams = () => {
    resetQueryParams();
    const updatedUrlParams = Object.fromEntries(
      getUrlSearchParams(defaultQuerySearchParams, currentTab, sites, false)
    );
    setSearchParams(updatedUrlParams);
  };

  const handleUpdateQueryParams = (id: string) => {
    const {
      parameters: { query },
    } = accountSavedQueriesById[id];
    const parsedQueryParams = updateSelectedQuery(JSON.stringify(query));

    dispatch(accountSavedQueriesActions.switchSelectedQuery(id));

    const updatedQueryParams = validateAndSetDefaultsForUrlParams(
      {
        ...parsedQueryParams,
        groupingId: parsedQueryParams.groupingId ?? '',
      },
      currentTab,
      sites,
      false
    );
    setSearchParams(updatedQueryParams as any);
  };

  const handleSaveQuerySubmit = (name) => {
    saveQuerySubmit(name, serializeFormQuery);
  };

  const resolutionOptions =
    getResolutionOptions(currentTab, serializeFormQuery?.resourceType, {
      fromDate: serializeFormQuery.fromDate,
      toDate: serializeFormQuery.toDate,
    }) ?? [];

  return {
    categoryValueSelected,
    circuitMetaFiltersLoading,
    closeFiltersDrawer,
    selectedGrouping,
    handleResolutionUpdate,
    handleResourceTypeUpdate,
    openQueryBuilderModal,
    FilterButtonText,
    filtersAppliedInsideModal,
    getProgramStartDate,
    groupingOptions,
    handleGroupingUpdate,
    handleUpdateDateRange,
    handleUpdateQueryParams,
    isFiltersDrawerOpen,
    openSiteConfigModal,
    period,
    relativeDatesOptions,
    saveCSV,
    selectedSites,
    updateIsFiltersDrawerOpen,
    urlParamsFromToDates,
    updateIsSaveFilterModalOpen,
    isSaveFilterModalOpen,
    handleSaveQuerySubmit,
    handleResetQueryParams,
    serializeFormQuery,
    sites,
    sitesLoading,
    handleSingleSiteUpdate,
    urlParamsSelectedSite,
    allowResourceSelection,
    resolutionOptions,
    maxDaysAllowed,
  };
};

const getMeasurementTypeForLocationAndUrlParams = (
  location: any,
  urlParams: TSQuerySearchParams | Partial<TSQuerySearchParams>
) => {
  // is this the voltage page?
  const isVoltage = location?.pathname.includes('voltage-analysis');
  const resource = urlParams.resourceType;
  if (isVoltage) {
    return MeasurementTypes.RMS_VOLTAGE;
  } else if ('electricity' == ResourceType.ELECTRICITY) {
    return resource;
  } else {
    return MeasurementTypes.VOLUME;
  }
};

export default useBuildingInsightsFilters;
