import styled from '@emotion/styled';
import { Button, ButtonProps, createPolymorphicComponent } from '@mantine/core';
import { PolymorphicComponentProps } from '@mantine/core/lib/core/factory/create-polymorphic-component';

const TextButton = ({
  children,
  ...rest
}: PolymorphicComponentProps<'button', ButtonProps>) => {
  const _StyledButton = styled(Button)`
    padding: 0;
    border: none;

    &:hover {
      background-color: transparent;
      text-decoration: underline;
    }

    .mantine-Button-label {
      gap: 0.25rem;
    }
  `;
  const StyledButton = createPolymorphicComponent<'button', ButtonProps>(
    _StyledButton
  );

  return (
    <StyledButton
      m='none'
      p='none'
      variant='transparent'
      color='dark.2'
      fw={400}
      size='lg'
      {...rest}
    >
      {children}
    </StyledButton>
  );
};

export default TextButton;
