import styled from '@emotion/styled';
import { Group, Modal, ModalProps } from '@mantine/core';
import { Formik } from 'formik';
import { ChangeEvent } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { lowerTrim } from '../../api/utils';
import { selectAccountSavedQueriesEntity } from '../../ducks/savedQueries/accountQueries';
import Button from '../mantine/Button';
import TextButton from '../mantine/TextButton';
import TextInput from '../mantine/TextInput';

export type SaveQueryModalForm = {
  selectedQueryName?: string;
  onSubmit: (name: string) => void;
} & ModalProps;

const ModalStyles = styled(Modal)`
  .mantine-Modal-overlay {
    --overlay-z-index: 10002 !important;
  }

  .mantine-Modal-title {
    font-size: 18px;
    font-weight: 700;
  }

  .mantine-Modal-inner {
    z-index: 10003;
  }
`;

const SaveFilterModalForm = ({
  opened,
  onClose,
  onSubmit,
  selectedQueryName,
}: SaveQueryModalForm) => {
  const { items: accountSavedQueries } = useSelector(
    selectAccountSavedQueriesEntity
  );

  const QueryNameSchema = Yup.object().shape({
    queryName: Yup.string()
      .trim('Filter Name cannot include leading and trailing spaces')
      .strict(true)
      .min(1, 'Filter Name must be at least 1 character')
      .max(50, 'Filter Name must be no longer than 50 characters')
      .test('uniqueness', 'Filter Name must be unique', (value) => {
        const cleanedQueries = accountSavedQueries.map(({ name }) =>
          lowerTrim(name)
        );
        const trimmedValue = lowerTrim(value ?? '');
        return !cleanedQueries.includes(trimmedValue ?? '');
      })
      .required('Required'),
  });

  const handleClose = () => {
    onClose();
  };

  return (
    <Formik
      initialValues={{
        queryName: selectedQueryName || '',
      }}
      onSubmit={({ queryName }) => onSubmit(queryName)}
      validationSchema={QueryNameSchema}
      validateOnChange={false}
      validateOnBlur
    >
      {({
        handleChange,
        values,
        touched,
        errors,
        resetForm,
        dirty,
        isValid,
        submitForm,
        setErrors,
      }) => {
        const handleQueryNameChange = (e: ChangeEvent<any>) => {
          handleChange(e);
        };
        const clearStateAndCancel = () => {
          setErrors({});
          handleClose();
          resetForm();
        };
        const handleSaveChanges = () => {
          submitForm();
        };

        return (
          <ModalStyles
            opened={opened}
            onClose={handleClose}
            title='Save Filter'
            centered
            size='md'
            padding='xl'
          >
            {/* Modal content */}
            <TextInput
              size='md'
              radius='sm'
              placeholder='Filter Name'
              name='queryName'
              value={values.queryName}
              onChange={handleQueryNameChange}
              error={touched.queryName && errors.queryName}
            />
            <Group mt='xl' gap='xl' align='center' justify='center'>
              <TextButton
                type='button'
                color='gray.6'
                onClick={clearStateAndCancel}
              >
                Cancel
              </TextButton>
              <Button
                color='blue.6'
                disabled={!dirty && isValid}
                onClick={handleSaveChanges}
              >
                Save
              </Button>
            </Group>
          </ModalStyles>
        );
      }}
    </Formik>
  );
};

export default SaveFilterModalForm;
