import styled from '@emotion/styled';
import {
  Combobox,
  Pill,
  useCombobox,
  PillsInput,
  Input,
  MantineSize,
  useMantineTheme,
  isOptionsGroup,
  PillsInputProps,
  ComboboxData,
  getParsedComboboxData,
} from '@mantine/core';
import { useState } from 'react';
import MultiSelectOptions from './MultiSelectOptions';

const ComboboxStyled = styled(Combobox)`
  .mantine-Pill-root {
    border-radius: 4px;
  }

  .mantine-PillsInput-input {
    display: inline-flex;
  }

  .mantine-PillGroup-group {
    overflow: hidden;
    flex-wrap: nowrap;
  }

  .mantine-InputPlaceholder-placeholder {
    color: black;
  }
`;

type Props = {
  data: ComboboxData;
  /* Max number of values displayed before (+[n] more) pill is shown instead */
  maxDisplayedValues?: number;
  size?: MantineSize;
  placeholder?: string;
  /* Max width of pills displayed in input field */
  pillMaxWidth?: number;
  values?: string[];
  setValues?: (values: string[]) => void;
  showSelectAll?: boolean;
} & PillsInputProps;

const MultiSelect = ({
  data: unparsedData,
  disabled,
  error,
  maxDisplayedValues = 3,
  placeholder,
  size = 'md',
  pillMaxWidth = 200,
  values: exteriorValues,
  setValues: setExteriorValues,
  showSelectAll = false,
  ...rest
}: Props) => {
  const theme = useMantineTheme();

  const [search, setSearch] = useState('');

  const combobox = useCombobox({
    onDropdownClose: () => {
      combobox.resetSelectedOption();
      combobox.focusTarget();
      setSearch('');
    },
    onDropdownOpen: () => {
      combobox.updateSelectedOptionIndex('active');
      combobox.focusSearchInput();
    },
  });

  const data = getParsedComboboxData(unparsedData); // converts any strings into items

  // makes a default onchange handler if one is not passed (maybe just require this??  useful for storybook...)
  const [interiorValues, setInteriorValues] = useState<string[]>([]);
  const values = exteriorValues ?? interiorValues;
  const setValues = setExteriorValues ?? setInteriorValues;

  const flattenOptions = (options) => {
    const flattened = [] as any;

    const flattenItem = (item) => {
      if (isOptionsGroup(item)) {
        item.items.forEach(flattenItem);
      } else {
        flattened.push(item);
      }
    };

    options.forEach(flattenItem);
    return flattened;
  };

  const handleValueSelect = (val: string) => {
    if (val == '*') {
      setValues(
        // case: all options are selected already, hence deselect all
        values.length == filteredOptionsFlat.length
          ? []
          : filteredOptionsFlat.map((option) => option.value)
      );
    } else
      setValues(
        values.includes(val)
          ? values.filter((v) => v !== val)
          : [...values, val]
      );
  };
  const handleValueRemove = (val: string) => {
    setValues(values.filter((v) => v !== val));
  };

  const isItemSelected = (item): boolean => {
    if (item.value == '*') {
      return filteredOptionsFlat.length == values.length;
    } else return values.includes(item.value);
  };

  const filterData = (data, fnFilter) => {
    const dataToShow = [] as any;
    data.forEach((groupOrItem) => {
      if (isOptionsGroup(groupOrItem)) {
        dataToShow.push({
          ...groupOrItem,
          items: filterData(groupOrItem.items, fnFilter),
        });
      } else {
        // it's an item
        if (fnFilter(groupOrItem)) {
          dataToShow.push(groupOrItem);
        }
      }
    });
    return dataToShow;
  };

  const getDisplayValues = () => {
    const allItems = data.reduce((acc, groupOrItem) => {
      if (isOptionsGroup(groupOrItem)) {
        acc.push(...groupOrItem.items);
      } else {
        acc.push(groupOrItem);
      }
      return acc;
    }, [] as any);
    const selectedVals = allItems.filter((item) => isItemSelected(item));
    return selectedVals.slice(
      0,
      maxDisplayedValues === selectedVals.length
        ? maxDisplayedValues
        : maxDisplayedValues - 1
    );
  };
  const displayValues = getDisplayValues();

  // build the options components for display, filtered by any search string
  const filteredOptions = filterData(
    data,
    (val) =>
      val.label &&
      val.label.toLowerCase().includes((search ?? '').toLowerCase().trim())
  );
  const filteredOptionsFlat: Array<{ label: string; value: string }> =
    flattenOptions(filteredOptions);

  return (
    <ComboboxStyled
      store={combobox}
      onOptionSubmit={handleValueSelect}
      withinPortal={false}
      size={size}
      disabled={disabled}
    >
      <Combobox.DropdownTarget>
        <PillsInput
          disabled={disabled}
          error={error}
          onClick={() => combobox.toggleDropdown()}
          pointer
          rightSection={
            values && values.length ? (
              <Combobox.ClearButton
                size='sm'
                onMouseDown={(event) => event.preventDefault()}
                onClear={() => {
                  setValues([]);
                }}
                aria-label='Clear values'
              />
            ) : (
              <Combobox.Chevron
                size={size}
                c={error ? theme.colors.red[4] : undefined}
              />
            )
          }
          rightSectionPointerEvents={values === null ? 'none' : 'all'}
          size={size}
          {...rest}
        >
          <Pill.Group data-testid='pill-group'>
            {displayValues.map((item) => (
              <Pill
                size={size}
                key={item.value}
                withRemoveButton
                onRemove={() => handleValueRemove(item.value)}
                maw={pillMaxWidth}
              >
                {item.label}
              </Pill>
            ))}
            {values.length > maxDisplayedValues && maxDisplayedValues === 1 && (
              <Pill size={size}>{values.length} selected</Pill>
            )}
            {values.length > maxDisplayedValues && maxDisplayedValues > 1 && (
              <Pill size={size}>
                +{values.length - (maxDisplayedValues - 1)} more
              </Pill>
            )}
            {values.length === 0 && placeholder && (
              <Input.Placeholder>{placeholder}</Input.Placeholder>
            )}

            <Combobox.EventsTarget>
              <PillsInput.Field
                type='hidden'
                onKeyDown={(event) => {
                  if (event.key === 'Backspace') {
                    event.preventDefault();
                    handleValueRemove(values[values.length - 1]);
                  }
                }}
              />
            </Combobox.EventsTarget>
          </Pill.Group>
        </PillsInput>
      </Combobox.DropdownTarget>
      <Combobox.Dropdown>
        <Combobox.Search
          value={search}
          onChange={(event) => setSearch(event.currentTarget.value)}
          placeholder='Search'
        />
        {!filteredOptionsFlat.length ? (
          <Combobox.Empty>Nothing found</Combobox.Empty>
        ) : (
          <MultiSelectOptions
            options={filteredOptions}
            isItemSelected={isItemSelected}
            showSelectAll={showSelectAll && !!filteredOptionsFlat.length}
          />
        )}
      </Combobox.Dropdown>
    </ComboboxStyled>
  );
};

export default MultiSelect;
