import {
  TextInput as MantineTextInput,
  TextInputProps as MantineTextInputProps,
} from '@mantine/core';

const TextInput = ({ size = 'md', ...rest }: MantineTextInputProps) => {
  return <MantineTextInput size={size} {...rest} />;
};

export default TextInput;
