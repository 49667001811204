/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from 'axios';
import { combineReducers } from 'redux';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { apiBaseUrl, defaultHeaders, denaliApiBaseUrl } from '../api/index';
import {
  handleAxiosError,
  handleSagaError,
  queryStringify,
} from '../api/utils';
import { DATA_EXPLORER_PATH } from '../constants/paths';
import sitesMockData from '../mockData/sites';
import siteMockData, { siteContractMockData } from '../mockData/sites/site';
import { naturallySortEmptyLastCaseInsensitive } from '../utils';
import { filterDemoSites } from '../utils/demo';
import { isVariantActive } from '../utils/variants';
import { selectCurrentCustomerId } from './customers';
import { TSSiteShift, enhanceSiteShift } from './siteShifts';
import {
  Grouping,
  Period,
  Resolution,
  ResourceType,
  TSMetaState,
  TSQuerySearchParams,
} from './types';

export enum TSSiteTabs {
  BASIC_CONSUMPTION = 'consumptionAnalysis',
  OPERATING_HOURS = 'operatingHours',
  PEAK_USAGE = 'peakDemand',
  VOLTAGE_ANALYSIS = 'voltageAnalysis',
  PROJECTS_PERFORMANCE = 'projectsPerformance',
  PROJECTS_INVOICE = 'projectsInvoice',
  PORTFOLIO_CONSUMPTION = 'portfolio-consumption',
  ESG_REPORTING = 'esg-reporting',
  BENCHMARKING = 'benchmarking',
}

export enum TSSiteUrls {
  BASIC_CONSUMPTION = 'consumption-analysis',
  OPERATING_HOURS = 'operating-hours',
  PEAK_USAGE = 'peak-demand',
  VOLTAGE_ANALYSIS = 'voltage-analysis',
  ESG_REPORTING = 'esg-reporting',
}

export interface TSSiteAddress {
  address1: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  timezone: string;
}
export interface TSSiteResponse {
  active: boolean;
  address: TSSiteAddress;
  display: string;
  currencyCode: string;
  customerId?: string;
  enableEquipmentView: boolean;
  firstBillDate?: string;
  id: string;
  ingestionDataStart: string;
  ingestionSourceId: string;
  lat: number | 0;
  lng: number | 0;
  locale: string;
  name: string | null;
  source: string;
  squareFootage: number | null;
  siteShifts: Array<TSSiteShift>;
  theme: string;
  electricUtilityRate: number;
  validName: string;
  timezone: string;
  meterCounts: {
    naturalGas: number;
    water: number;
    electricity: number;
  };
}

export type TSSitesResponse = Array<TSSiteResponse>;

export interface TSSiteEDResponse {
  id: string;
  ingestionSourceId: string;
  customerId: string;
  name: string;
  source: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  latLng: {
    lat: number;
    lng: number;
  };
  locale: string;
  currencyCode: string;
  timezone: string;
  theme: string;
  squareFootage: number;
  enableEquipmentView: true;
  active: true;
  ingestionDataStart: string;
  contracts: [
    {
      id: string;
      opportunityId: string;
      opportunityName: string;
      opportunityType: string;
    },
  ];
  electricUtilityRate: number;
  firstBillDate: string;
}

export type TSSitesEDResponse = Array<TSSiteEDResponse>;

export interface TSSiteContractDTO {
  id: string;
  opportunityId: string;
  energyCommitment: number;
  energyRate: number;
  utilityRate: number;
  monthlyBlock: number;
  resourceUnit: string;
  type: string;
}
export interface TSSiteContractResponse {
  siteId: string;
  contractDTOList: Array<TSSiteContractDTO>;
}

export interface TSSite extends TSSiteResponse {
  url: string;
  addressFormatted: string;
}

interface TSSitesMetaState extends TSMetaState {
  noSites: boolean;
  siteId: string;
}

export interface TSSitesEntityState {
  byId: {
    [id: string]: TSSite;
  };
  byIdED: {
    [id: string]: TSSiteEDResponse;
  };
  items: Array<TSSite>;
  itemsED: Array<TSSiteEDResponse>;
  meta: TSSitesMetaState;
  sitesEDMeta: TSSitesMetaState;
  siteMeta: TSMetaState;
  site: { customerId: string };
}

interface TSState {
  entities: {
    sites: TSSitesEntityState;
  };
}

export const naturallySortSitesByValidName = (a: TSSite, b: TSSite) =>
  naturallySortEmptyLastCaseInsensitive(a.validName, b.validName);

export const types = {
  CLEAR_SITES: 'CLEAR_SITES',
  FETCH_SITES: 'FETCH_SITES',
  FETCH_SITES_SUCCESS: 'FETCH_SITES_SUCCESS',
  FETCH_SITES_ERROR: 'FETCH_SITES_ERROR',
  FETCH_SITE: 'FETCH_SITE',
  FETCH_SITE_SUCCESS: 'FETCH_SITE_SUCCESS',
  FETCH_SITE_ERROR: 'FETCH_SITE_ERROR',
  FETCH_SITE_CONTRACT: 'FETCH_SITE_CONTRACT',
  FETCH_SITE_CONTRACT_SUCCESS: 'FETCH_SITE_CONTRACT_SUCCESS',
  FETCH_SITE_CONTRACT_ERROR: 'FETCH_SITE_CONTRACT_ERROR',
  FETCH_SITES_ED: 'FETCH_SITES_ED',
  FETCH_SITES_ED_SUCCESS: 'FETCH_SITES_ED_SUCCESS',
  FETCH_SITES_ED_ERROR: 'FETCH_SITES_ED_ERROR',
};

export const actions = {
  clearSites: () => ({ type: types.CLEAR_SITES }),
  fetchSites: (customerId?: string) => ({
    type: types.FETCH_SITES,
    customerId,
  }),
  fetchSite: (siteId: string) => ({ type: types.FETCH_SITE, siteId }),
  fetchSiteContract: (siteId: string) => ({
    type: types.FETCH_SITE_CONTRACT,
    siteId,
  }),
  fetchSitesED: (customerId: string) => ({
    type: types.FETCH_SITES_ED,
    customerId,
  }),
};

export const initialState: TSSitesEntityState = {
  byId: {},
  byIdED: {},
  items: [],
  itemsED: [],
  meta: {
    error: '',
    loading: true,
    noSites: true,
    siteId: '',
  },
  sitesEDMeta: {
    error: '',
    loading: false,
    noSites: false,
    siteId: '',
  },
  site: {
    customerId: '',
  },
  siteMeta: {
    error: '',
    loading: true,
  },
};

function entityById(action, state) {
  return {
    ...state,
    ...action.payload.reduce(
      (acc, cur) => ({
        ...acc,
        [cur.id]: cur,
      }),
      {}
    ),
  };
}

function insertSiteContract(action, state) {
  const {
    payload: { siteId, contractDTOList },
  }: { payload: TSSiteContractResponse } = action;
  if (state && state[siteId]) {
    return {
      ...state,
      [siteId]: {
        ...state[siteId],
        contractDTOList,
      },
    };
  }
}

function entityItems(action, state) {
  const newItems: Array<TSSite> = Object.values(action.payload);
  return state
    .filter((item) => !newItems.find((newItem) => newItem.id === item.id))
    .concat(newItems);
}

function byId(state = initialState.byId, action) {
  switch (action.type) {
    case types.CLEAR_SITES:
    case types.FETCH_SITES:
      return initialState.byId;
    case types.FETCH_SITES_SUCCESS:
      return entityById(action, state);
    case types.FETCH_SITE_CONTRACT_SUCCESS:
      return insertSiteContract(action, state);
    default:
      return state;
  }
}

function byIdED(state = initialState.byIdED, action) {
  switch (action.type) {
    case types.CLEAR_SITES:
    case types.FETCH_SITES_ED:
      return initialState.byId;
    case types.FETCH_SITES_ED_SUCCESS:
      return entityById(action, state);
    default:
      return state;
  }
}

function items(state = initialState.items, action) {
  switch (action.type) {
    case types.CLEAR_SITES:
    case types.FETCH_SITES:
      return initialState.items;
    case types.FETCH_SITES_SUCCESS:
      return entityItems(action, state);
    default:
      return state;
  }
}

function itemsED(state = initialState.itemsED, action) {
  switch (action.type) {
    case types.CLEAR_SITES:
    case types.FETCH_SITES_ED:
      return initialState.itemsED;
    case types.FETCH_SITES_ED_SUCCESS:
      return entityItems(action, state);
    default:
      return state;
  }
}

function meta(state = initialState.meta, action) {
  switch (action.type) {
    case types.FETCH_SITES:
      return {
        ...state,
        error: '',
        loading: true,
        noSites: false,
      };
    case types.FETCH_SITES_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case types.FETCH_SITES_SUCCESS:
      return {
        ...state,
        error: '',
        loading: false,
        noSites: !action.payload[0],
      };
    case types.FETCH_SITE:
      return {
        ...state,
        siteId: action.siteId,
      };
    case types.CLEAR_SITES:
      return initialState.meta;
    default:
      return state;
  }
}

function sitesEDMeta(state = initialState.meta, action) {
  switch (action.type) {
    case types.FETCH_SITES_ED:
      return {
        ...state,
        error: '',
        loading: true,
        noSites: false,
      };
    case types.FETCH_SITES_ED_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case types.FETCH_SITES_ED_SUCCESS:
      return {
        ...state,
        error: '',
        loading: false,
        noSites: !action.payload[0],
      };
    case types.CLEAR_SITES:
      return initialState.meta;
    default:
      return state;
  }
}

function siteMeta(state = initialState.siteMeta, action) {
  switch (action.type) {
    case types.FETCH_SITE:
      return {
        ...state,
        error: '',
        loading: true,
      };
    case types.FETCH_SITE_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case types.FETCH_SITE_SUCCESS:
      return {
        ...state,
        error: '',
        loading: false,
      };
    case types.CLEAR_SITES:
      return initialState.siteMeta;
    default:
      return state;
  }
}

function site(state = initialState.site, action) {
  switch (action.type) {
    case types.FETCH_SITE:
      return initialState.site;
    case types.FETCH_SITE_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}

export default combineReducers({
  byId,
  byIdED,
  items,
  itemsED,
  meta,
  sitesEDMeta,
  siteMeta,
  site,
});

export const selectSitesEntity = (state: TSState): TSSitesEntityState =>
  state.entities.sites;

export const enhanceSite = (
  site: TSSiteResponse,
  urlParams: TSQuerySearchParams
): TSSite => ({
  ...site,
  addressFormatted:
    `${site.address.address1}${site.address.address1 ? ', ' : ''}${
      site.address.city
    }, ` + `${site.address.state} ${site.address.postalCode}`,
  url: `${DATA_EXPLORER_PATH}/consumption-analysis?${queryStringify(
    urlParams
  )}`,
  siteShifts: site?.siteShifts?.map((shift) => enhanceSiteShift(shift)),
});

export const API = {
  fetchSites: (customerId: string) => {
    if (isVariantActive('mock')) {
      return Promise.resolve(sitesMockData).then(
        (data) => new Promise((resolve) => setTimeout(() => resolve(data), 200))
      );
    }
    const url = `${denaliApiBaseUrl()}/customers/${customerId}/sites?includeFields=siteShifts,meterCounts`;
    return axios
      .get(url, { headers: defaultHeaders() })
      .then(({ data }: { data: TSSitesResponse }) => data)
      .catch(handleAxiosError);
  },
  fetchSite: (siteId: string) => {
    if (isVariantActive('mock')) {
      return Promise.resolve(siteMockData).then(
        (data) => new Promise((resolve) => setTimeout(() => resolve(data), 200))
      );
    }
    const url = `${denaliApiBaseUrl()}/sites/${siteId}`;
    return axios
      .get(url, { headers: defaultHeaders() })
      .then(({ data }: { data: TSSiteResponse }) => data)
      .catch(handleAxiosError);
  },
  fetchSiteContract: (siteId: string) => {
    if (isVariantActive('mock')) {
      return Promise.resolve(siteContractMockData).then(
        (data) => new Promise((resolve) => setTimeout(() => resolve(data), 200))
      );
    }
    const url = `${apiBaseUrl()}/sites/${siteId}/contracts`;
    return axios
      .get(url, { headers: defaultHeaders() })
      .then(({ data }: { data: TSSiteResponse }) => data)
      .catch(handleAxiosError);
  },
  // To Fetch Sites information from Energy Dashboard
  fetchSitesED: (customerId: string) => {
    if (isVariantActive('mock')) {
      return Promise.resolve(sitesMockData).then(
        (data) => new Promise((resolve) => setTimeout(() => resolve(data), 200))
      );
    }
    const url = `${apiBaseUrl()}/sites?customerId=${customerId}`;
    return axios
      .get(url, { headers: defaultHeaders() })
      .then(({ data }: { data: TSSitesEDResponse }) => data)
      .catch(handleAxiosError);
  },
};

function* fetchSitesSaga({
  type,
  customerId,
}: {
  type: string;
  customerId?: string;
}): Generator<any, void, any> {
  try {
    const currentCustomerId = yield select(selectCurrentCustomerId);
    const id = customerId || currentCustomerId;
    let sites: TSSitesResponse = yield call(API.fetchSites, id);

    // pass through demo filter, will apply any relevant filters if this is a demo user
    // do the filter before enhancing to make sure any masked fields used in the enhance are covered
    sites = filterDemoSites(sites);

    yield put({
      type: types.FETCH_SITES_SUCCESS,
      payload: sites.map((site) =>
        enhanceSite(site, {
          ...{
            resolution: Resolution.DAILY,
            grouping: Grouping.SITE,
            site: '',
            resourceType: ResourceType.ELECTRICITY,
            period: Period.LAST_30_DAYS,
          },
          site: site.id,
        })
      ),
    });
  } catch (e) {
    yield handleSagaError(types.FETCH_SITES_ERROR, e as Error);
  }
}

function* fetchSiteSaga({
  type,
  siteId,
}: {
  type: string;
  siteId: string;
}): Generator<any, void, any> {
  try {
    const site: TSSiteResponse = yield call(API.fetchSite, siteId);
    yield put({
      type: types.FETCH_SITE_SUCCESS,
      payload: { customerId: site.customerId },
    });
  } catch (e) {
    yield handleSagaError(types.FETCH_SITE_ERROR, e as Error);
  }
}

function* fetchSiteContractSaga({
  type,
  siteId,
}: {
  type: string;
  siteId: string;
}): Generator<any, void, any> {
  try {
    const site: TSSiteResponse = yield call(API.fetchSiteContract, siteId);
    yield put({
      type: types.FETCH_SITE_CONTRACT_SUCCESS,
      payload: site,
    });
  } catch (e) {
    yield handleSagaError(types.FETCH_SITE_CONTRACT_ERROR, e as Error);
  }
}

function* fetchSitesEDSaga({
  type,
  customerId,
}: {
  type: string;
  customerId: string;
}): Generator<any, void, any> {
  try {
    const sites: TSSitesEDResponse = yield call(API.fetchSitesED, customerId);

    // pass through demo filter, will apply any relevant filters if this is a demo user
    // do the filter before enhancing to make sure any masked fields used in the enhance are covered
    // sites = filterDemoSites(sites);

    yield put({
      type: types.FETCH_SITES_ED_SUCCESS,
      payload: sites,
    });
  } catch (e) {
    yield handleSagaError(types.FETCH_SITES_ED_ERROR, e as Error);
  }
}

export const sagas = [
  takeLatest(types.FETCH_SITES, fetchSitesSaga),
  takeLatest(types.FETCH_SITE, fetchSiteSaga),
  takeLatest(types.FETCH_SITE_CONTRACT, fetchSiteContractSaga),
  takeLatest(types.FETCH_SITES_ED, fetchSitesEDSaga),
];
