import { combineReducers } from 'redux';
import { TSState } from '../../reducers/rootReducer';
import { TSMetaState } from '../types';
import { defaultHeaders, gcApiBaseUrl } from '../../api';
import { handleAxiosError, handleSagaError } from '../../api/utils';
import axios from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';

export interface TSPreferences {
  currencyPreference: string;
  unitFormatPreference: string;
}
export interface TSUpdatePreferences {
  userId: string;
  currencyPreference: string;
  unitFormatPreference: string;
}

export interface TSPreferencesEntityState {
  preferences: { currencyPreference: string; unitFormatPreference: string };
  preferencesMeta: TSMetaState;
}

export const types = {
  FETCH_USER_PREFERENCES: 'FETCH_USER_PREFERENCES',
  FETCH_USER_PREFERENCES_SUCCESS: 'FETCH_USER_PREFERENCES_SUCCESS',
  FETCH_USER_PREFERENCES_ERROR: 'FETCH_USER_PREFERENCES_ERROR',
  UPDATE_USER_PREFERENCES: 'UPDATE_USER_PREFERENCES',
  UPDATE_USER_PREFERENCES_SUCCESS: 'UPDATE_USER_PREFERENCES_SUCCESS',
  UPDATE_USER_PREFERENCES_ERROR: 'UPDATE_USER_PREFERENCES_ERROR',
};

export const actions = {
  fetchUserPreferences: (userId: string) => ({
    type: types.FETCH_USER_PREFERENCES,
    userId,
  }),
  updateUserPreferences: (payload: TSUpdatePreferences) => ({
    type: types.UPDATE_USER_PREFERENCES,
    payload,
  }),
};

export const initialPreferencesState: TSPreferencesEntityState = {
  preferences: { currencyPreference: '', unitFormatPreference: '' },
  preferencesMeta: {
    loading: false,
    error: '',
  },
};

function preferencesByUserId(
  state = initialPreferencesState.preferences,
  action
) {
  switch (action.type) {
    case types.FETCH_USER_PREFERENCES:
    case types.UPDATE_USER_PREFERENCES:
      return initialPreferencesState.preferences;
    case types.FETCH_USER_PREFERENCES_SUCCESS:
    case types.UPDATE_USER_PREFERENCES_SUCCESS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

function defaultMeta(state: TSMetaState, actionTypePrefix: string, action) {
  switch (action.type) {
    case actionTypePrefix:
      return {
        ...state,
        error: '',
        loading: true,
      };
    case `${actionTypePrefix}_ERROR`:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case `${actionTypePrefix}_SUCCESS`:
      return {
        ...state,
        error: '',
        loading: false,
      };
    default:
      return state;
  }
}
function preferencesMeta(
  state = initialPreferencesState.preferencesMeta,
  action
) {
  return defaultMeta(
    state,
    types.FETCH_USER_PREFERENCES || types.UPDATE_USER_PREFERENCES,
    action
  );
}

export default combineReducers({
  preferences: preferencesByUserId,
  preferencesMeta,
});

export const selectPreferencesEntity = (
  state: TSState
): TSPreferencesEntityState => state.entities.settings;

export const API = {
  fetchUserPreferences: (userId: string) => {
    const url = `${gcApiBaseUrl()}/admin/users/${userId}/settings`;
    return axios
      .get(url, { headers: defaultHeaders() })
      .then((response) => {
        return response.data;
      })
      .catch(handleAxiosError);
  },
  updateUserPreferences: ({
    userId,
    currencyPreference,
    unitFormatPreference,
  }: TSUpdatePreferences) => {
    const url = `${gcApiBaseUrl()}/admin/users/${userId}/settings`;
    return axios
      .put(
        url,
        { currencyPreference, unitFormatPreference },
        { headers: defaultHeaders() }
      )
      .then((response) => {
        return response.data;
      })
      .catch(handleAxiosError);
  },
};

function* fetchPreferencesForUsersSaga({
  userId,
}: {
  type: string;
  userId: string;
}): Generator<any, void, any> {
  try {
    let preferences: TSPreferences = yield call(
      API.fetchUserPreferences,
      userId
    );
    if (preferences) {
      preferences.currencyPreference ||= 'USD';
      preferences.unitFormatPreference ||= 'comma';
    } else {
      preferences = {
        currencyPreference: 'USD',
        unitFormatPreference: 'comma',
      };
    }
    yield put({
      type: types.FETCH_USER_PREFERENCES_SUCCESS,
      payload: preferences,
    });
  } catch (e) {
    yield handleSagaError(types.FETCH_USER_PREFERENCES_ERROR, e as Error);
  }
}

function* updatePreferencesForUsersSaga({
  payload,
}: {
  type: string;
  payload: TSUpdatePreferences;
}): Generator<any, void, any> {
  try {
    const preferences: TSPreferences = yield call(
      API.updateUserPreferences,
      payload
    );
    yield put({
      type: types.UPDATE_USER_PREFERENCES_SUCCESS,
      payload: preferences,
    });
  } catch (e) {
    yield handleSagaError(types.UPDATE_USER_PREFERENCES_ERROR, e as Error);
  }
}

export const sagas = [
  takeLatest(types.FETCH_USER_PREFERENCES, fetchPreferencesForUsersSaga),
  takeLatest(types.UPDATE_USER_PREFERENCES, updatePreferencesForUsersSaga),
];
