import moment, { Moment } from 'moment';
import {
  LAST_12_MONTHS,
  LAST_30_DAYS,
  LAST_3_MONTHS,
  LAST_60_DAYS,
  LAST_6_MONTHS,
  LAST_7_DAYS,
  LAST_MONTH,
  LAST_YEAR,
  PROGRAM_TO_DATE,
  THIS_MONTH,
  YEAR_TO_DATE,
} from '../constants';

type TSOptionsDates = {
  programStartDate?: Moment;
};

const getYearToDateRange = ({ lastMonth, programStartDate }: any) => {
  const startOfYear = moment().startOf('year');
  const start = programStartDate.isBefore(startOfYear)
    ? startOfYear
    : moment(programStartDate);
  let end;

  if (
    moment().isSame(start, 'month') ||
    moment().isSame(moment().startOf('year').add(1, 'month'), 'month')
  ) {
    end = moment().startOf('year').endOf('month');
  } else {
    end = moment(lastMonth);
  }

  return [start, end];
};

const getProgramToDateRange = ({ lastMonth, programStartDate }: any) => {
  let start;
  const end = moment(lastMonth);
  if (
    !programStartDate ||
    (programStartDate && moment().isSame(programStartDate, 'month')) ||
    (programStartDate && programStartDate.isAfter(moment()))
  ) {
    start = moment().subtract(12, 'months').startOf('month');
  } else {
    start = moment(programStartDate);
  }
  return [start, end];
};

export const getOptionsDates = ({
  programStartDate = moment('2010', 'YYYY'),
}: TSOptionsDates): any => {
  const yesterday = moment().endOf('day').subtract(1, 'day').startOf('minute');
  const lastMonth = moment()
    .subtract(1, 'months')
    .endOf('month')
    .startOf('minute');

  return {
    [LAST_7_DAYS]: [moment().startOf('day').subtract(7, 'days'), yesterday],
    [LAST_30_DAYS]: [moment().subtract(30, 'days'), yesterday],
    [LAST_60_DAYS]: [moment().subtract(60, 'days'), yesterday],
    [THIS_MONTH]: [moment().startOf('month'), yesterday],
    [LAST_MONTH]: [moment().subtract(1, 'months').startOf('month'), lastMonth],
    [LAST_3_MONTHS]: [
      moment().subtract(3, 'months').startOf('month'),
      lastMonth,
    ],
    [LAST_6_MONTHS]: [
      moment().subtract(6, 'months').startOf('month'),
      lastMonth,
    ],
    [LAST_12_MONTHS]: [
      moment().subtract(12, 'months').startOf('month'),
      lastMonth,
    ],
    [LAST_YEAR]: [
      moment().subtract(1, 'years').startOf('year'),
      moment().subtract(1, 'years').endOf('year'),
    ],
    [YEAR_TO_DATE]: getYearToDateRange({ lastMonth, programStartDate }),
    [PROGRAM_TO_DATE]: getProgramToDateRange({
      lastMonth,
      programStartDate,
    }),
  };
};
