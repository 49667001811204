/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from 'axios';
import moment from 'moment';
import { combineReducers } from 'redux';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { defaultHeaders, opportunityApiBaseUrl } from '../../api';
import { handleAxiosError, handleSagaError } from '../../api/utils';
import { EMPTY_METRIC_STRING } from '../../constants/strings';
import {
  opportunityBatches,
  labels as opportunityLabels,
  mlov as opportunityMasterListOfValues,
  opportunitySeeds,
  userEmails as opportunityUserEmails,
} from '../../mockData/opportunities';
import { TSState } from '../../reducers/rootReducer';
import {
  findOpportunitiesMaxValue,
  findOpportunitiesMinValue,
} from '../../utils';
import { isVariantActive } from '../../utils/variants';
import { UserTrackedAction } from '../gainsight';
import { TSListResponseMetaState, TSMetaState } from '../types';
import {
  TSOpportunityTaskResponse,
  types as opportunityTypes,
} from './opportunities';
import { TSOpportunityEntityType, TSProjectStage } from './opportunityTypes';

// Interfaces

interface TSLabel {
  id: string;
  text: string;
  color: string;
  title: string; // the group title
}
export interface TSMasterListOfValuesRequest {
  customerID: string;
  type?:
    | 'string'
    | 'ECM_TYPE'
    | 'UNIT'
    | 'CUSTOM_FIELD_TITLE'
    | 'SOLAR_ECM_SPECIFIC_FIELDS'
    | 'LIGHTING_ECM_SPECIFIC_FIELDS'
    | 'LIGHTING_CONTROL_ECM_SPECIFIC_FIELDS'
    | 'HVAC_CONTROL_ECM_SPECIFIC_FIELDS'
    | 'BMSAAS_CONTROL_ECM_SPECIFIC_FIELDS'
    | 'GLOBAL_FIELDS'
    | 'BATCH_FIELDS';
  value?: string;
}
export type TSMasterListOfValuesResponse = Array<
  TSMasterListOfValuesRequest & {
    id: string;
    value: string;
    sequenceNumber?: number;
    customerId?: string;
    metadata?: string;
  }
>;

export type TSLabels = Array<TSLabel>;
export type TSUserEmail = {
  email: string;
};

export type TSOpportunityBaseResponse = {
  id: string;
  title: string;
  displayId: string;
  siteId: string;
  siteName: string;
  customerId: string;
  description: string;
  projectStage: TSProjectStage;
  bookmark: boolean;
  hide: boolean;
  hideReason: string;
  ecmType: string;
  annualAvoidedCo2e: number;
  simplePaybackPeriod: number;
  annualPayment: number;
  contractRate: number;
  annualEnergySavedOrGeneratedKwh: number;
  scheduleTermInMonths: number;
  annualNetCostSavings: number;
  totalNetCost: number;
  totalContractValue: number;
  annualAvoidedEnergyCost: number;
  annualAvoidedMaintenanceCost: number;
  annualAvoidedOtherCost: number;
  annualAvoidedTotalCost: number;
  created?: string;
  modified?: string;
  createdBy?: string;
  modifiedBy?: string;
  labelIds: Array<string>;
  followers: Array<string>;
  ecmTypeSpecificFields?: { [key: string]: string };
  sequenceDetails?: Array<{
    id: string;
    sequenceNumber: number;
    isPrimary?: boolean;
  }>;
  customFields?: Array<{
    id: string;
    title: string;
    value: string;
    unit: string;
    isCustomField: boolean;
    isPrimary: boolean;
    helperText?: string;
  }>;
};

export type TSOpportunitySeedResponse = TSOpportunityBaseResponse & {
  approvalStatus?: string;
  siteId: string;
  siteName: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  postalcode: string;
  country: string;
};

export type TSOpportunitySeedsResponse = TSOpportunitySeedResponse[];

export type TSOpportunityBatchResponse = TSOpportunityBaseResponse & {
  opportunityIds: string[];
  locked: boolean;
};

export type TSOpportunityBatchesResponse = TSOpportunityBatchResponse[];

// this is the local/enhanced version
export interface TSOpportunityBase extends TSOpportunityBaseResponse {
  selected: boolean;
  entityType: TSOpportunityEntityType;
  tasks?: Array<TSOpportunityTaskResponse>;
}

export type TSOpportunitySeed = TSOpportunityBase & TSOpportunitySeedResponse;

export type TSOpportunityBatch = TSOpportunityBase &
  TSOpportunityBatchResponse & {
    subRows?: TSOpportunitySeed[];
  };

export interface TSCreateBatchPayload {
  title: string;
  description: string;
  projectStage: TSProjectStage;
  customerId: string;
  opportunityIds: string[];
  followers: string[];
}
export interface TSCreateOpportunityRequestPayload {
  opportunityId?: string;
  title: string;
  description: string;
  annualAvoidedCo2e: string;
  simplePaybackPeriod: string;
  contractRate: string;
  annualEnergySavedOrGeneratedKwh: string;
  scheduleTermInMonths: string;
  annualAvoidedEnergyCost: string;
  annualAvoidedMaintenanceCost: string;
  annualAvoidedOtherCost: string;
  annualAvoidedTotalCost: string;
  annualNetCostSavings: string;
  totalNetCost: string;
  annualPayment: string;
  totalContractValue: string;
  customerId: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  postalcode: string;
  country: string;
  ecmType: string;
  projectStage: TSProjectStage;
  customFields: Array<{
    id: string;
    title: string;
    value: string;
    unit: string;
  }>;
  sequenceDetails: Array<{
    id: string;
    sequenceNumber: number;
    isPrimary?: boolean;
  }>;
  ecmTypeSpecificFields: { [key: string]: string };
}
interface TSRejectBatchPayload {
  opportunityId;
  reason: string;
}
export interface TSEditBatchPayload {
  opportunityIds: Array<string>;
  batchId: string;
  addedOpportunityId?: string;
  removedOpportunityId?: string;
}

export interface TSEditBatchMetadataPayload {
  batchId: string;
  title: string;
  description: string;
  annualAvoidedCo2e: string | number;
  simplePaybackPeriod: string | number;
  annualAvoidedEnergyCost: string | number;
  annualAvoidedMaintenanceCost: string | number;
  annualAvoidedOtherCost: string | number;
  annualAvoidedTotalCost: string | number;
  annualNetCostSavings: string | number;
  labelIds?: Array<string> | undefined;
  customFields?: Array<{
    id: string;
    title: string;
    value: string;
    unit: string;
  }>;
  sequenceDetails?: Array<{
    id: string;
    sequenceNumber: number;
    isPrimary?: boolean;
  }>;
  removedLabelId?: string;
  gainsightUserAction?: UserTrackedAction;
}

export interface TSEditOpportunityPayload {
  opportunityId: string;
  description: string;
  title: string;
}

export interface TSTransitionOpportunityPayload {
  opportunityId: string;
  projectStage: TSProjectStage;
  rankAfterOpportunityId: string;
  newProjectSequence: string[];
}

export type TSTransitionOpportunityPayloadPlusGainsight =
  TSTransitionOpportunityPayload & {
    oldProjectStage: TSProjectStage;
    gainsightUserAction: UserTrackedAction;
  };
export interface TSEditOpportunityLabelsPayload {
  opportunityId: string;
  labelIds: Array<string>;
  removedLabelId: string; // used for gainsight
}

export interface TSAddLabelsRequestPayload {
  customerId: string;
  labels: { id?: string; text: string }[];
  opportunityIds?: Array<string>;
  batchIds?: Array<string>;
}

interface TSAddLabelsResponsePayload {
  labels: TSLabels;
  opportunities: Array<TSOpportunitySeed>;
  batches: Array<TSOpportunityBatch>;
}

export interface TSEditOpportunityFollowersRequestPayload {
  opportunityId: string;
  followers: Array<string>;
}
export interface TSEditBatchFollowersRequestPayload {
  batchId: string;
  followers: Array<string>;
}

export interface TSEditCustomerSettingsRequestPayload {
  customerSettings: TSCustomerSettings;
  customerId: string;
}

export interface TSCurrentFiltersState {
  selectedAddresses: { values: string[]; isActive: boolean };
  selectedEcmTypes: { values: string[]; isActive: boolean };
  selectedProjectStages: { values: string[]; isActive: boolean };
  selectedLabels: { values: string[]; isActive: boolean };
  costSavings: { values: Array<number>; isActive: boolean };
  co2Savings: { values: Array<number>; isActive: boolean };
  simplePaybackPeriod: { values: Array<number>; isActive: boolean };
  selectedTaskFilter: { value: string; isActive: boolean };
  bookmarkedOnly: boolean;
  selectedOnly: boolean;
  searchKey: string;
}

export interface TSSeedsById {
  [id: string]: TSOpportunitySeed;
}

export interface TSCustomerSettings {
  columns: any; // Will add type as part of TEAL-722
  projectSequence: string[];
}

export interface TSOpportunitySeedsEntityState {
  byId: TSSeedsById;
  items: Array<TSOpportunitySeed>;
  meta: TSListResponseMetaState;
  labels: TSLabels;
  labelsMeta: TSMetaState;
  userEmails: Array<TSUserEmail>;
  userEmailsMeta: TSMetaState;
  batches: Array<TSOpportunityBatch>;
  batchesMeta: TSListResponseMetaState;
  rejectBatchMeta: TSMetaState;
  createBatchesMeta: TSMetaState;
  editOpportunityLabelsMeta: TSMetaState;
  editBatchMeta: TSMetaState;
  editBatchMetadataMeta: TSMetaState;
  editOpportunityFollowersMeta: TSMetaState;
  editBatchFollowersMeta: TSMetaState;
  addLabelsMeta: TSMetaState;
  selectedSeeds: any; // this is a special format used by the table, looks like: {id1: true, id2: true}
  currentFilters: TSCurrentFiltersState;
  createOpportunityMeta: TSMetaState;
  updateOpportunityMeta: TSMetaState;
  masterListOfValues: TSMasterListOfValuesResponse;
  fetchMasterListOfValuesMeta: TSMetaState;
  createValueInMasterListOfValuesMeta: TSMetaState;
  customerSettings: TSCustomerSettings;
  customerSettingsMeta: TSMetaState;
}

//TODO: if we like these we can move them to a common place for more use
export const initialMetaState = {
  loading: false,
  error: '',
};

export const initialListResponseMetaState = {
  ...initialMetaState,
  noResults: false,
  currentId: '',
};

// Redux types
export const types = {
  FETCH_OPPORTUNITY_SEEDS: 'opportunity/FETCH_OPPORTUNITY_SEEDS',
  FETCH_OPPORTUNITY_SEEDS_SUCCESS:
    'opportunity/FETCH_OPPORTUNITY_SEEDS_SUCCESS',
  FETCH_OPPORTUNITY_SEEDS_ERROR: 'opportunity/FETCH_OPPORTUNITY_SEEDS_ERROR',
  FETCH_OPPORTUNITY_BATCHES: 'opportunity/FETCH_OPPORTUNITY_BATCHES',
  FETCH_OPPORTUNITY_BATCHES_SUCCESS:
    'opportunity/FETCH_OPPORTUNITY_BATCHES_SUCCESS',
  FETCH_OPPORTUNITY_BATCHES_ERROR:
    'opportunity/FETCH_OPPORTUNITY_BATCHES_ERROR',
  UPDATE_BOOKMARK_FOR_SEED: 'opportunity/UPDATE_BOOKMARK_FOR_SEED',
  UPDATE_BOOKMARK_FOR_SEED_SUCCESS:
    'opportunity/UPDATE_BOOKMARK_FOR_SEED_SUCCESS',
  UPDATE_BOOKMARK_FOR_SEED_ERROR: 'opportunity/UPDATE_BOOKMARK_FOR_SEED_ERROR',
  UPDATE_BOOKMARK_FOR_BATCH: 'opportunity/UPDATE_BOOKMARK_FOR_BATCH',
  UPDATE_BOOKMARK_FOR_BATCH_SUCCESS:
    'opportunity/UPDATE_BOOKMARK_FOR_BATCH_SUCCESS',
  UPDATE_BOOKMARK_FOR_BATCH_ERROR:
    'opportunity/UPDATE_BOOKMARK_FOR_BATCH_ERROR',
  UPDATE_SELECTED_OPPORTUNITIES: 'opportunity/UPDATE_SELECTED_OPPORTUNITIES',
  UPDATE_CURRENT_FILTERS: 'opportunity/UPDATE_CURRENT_FILTERS',
  RESET_FILTERS: 'opportunity/RESET_FILTERS',
  CREATE_OPPORTUNITY_BATCH: 'opportunity/CREATE_OPPORTUNITY_BATCH',
  CREATE_OPPORTUNITY_BATCH_SUCCESS:
    'opportunity/CREATE_OPPORTUNITY_BATCH_SUCCESS',
  CREATE_OPPORTUNITY_BATCH_ERROR: 'opportunity/CREATE_OPPORTUNITY_BATCH_ERROR',
  REJECT_BATCH: 'opportunity/REJECT_BATCH',
  REJECT_BATCH_SUCCESS: 'opportunity/REJECT_BATCH_SUCCESS',
  REJECT_BATCH_ERROR: 'opportunity/REJECT_BATCH_ERROR',
  EDIT_BATCH: 'opportunity/EDIT_BATCH',
  EDIT_BATCH_SUCCESS: 'opportunity/EDIT_BATCH_SUCCESS',
  EDIT_BATCH_ERROR: 'opportunity/EDIT_BATCH_ERROR',
  TRANSITION_BATCH: 'opportunity/TRANSITION_BATCH',
  TRANSITION_BATCH_SUCCESS: 'opportunity/TRANSITION_BATCH_SUCCESS',
  TRANSITION_BATCH_ERROR: 'opportunity/TRANSITION_BATCH_ERROR',
  EDIT_BATCH_METADATA: 'opportunity/EDIT_BATCH_METADATA',
  EDIT_BATCH_METADATA_SUCCESS: 'opportunity/EDIT_BATCH_METADATA_SUCCESS',
  EDIT_BATCH_METADATA_ERROR: 'opportunity/EDIT_BATCH_METADATA_ERROR',
  EDIT_OPPORTUNITY: 'opportunity/EDIT_OPPORTUNITY',
  EDIT_OPPORTUNITY_SUCCESS: 'opportunity/EDIT_OPPORTUNITY_SUCCESS',
  EDIT_OPPORTUNITY_ERROR: 'opportunity/EDIT_OPPORTUNITY_ERROR',
  TRANSITION_OPPORTUNITY: 'opportunity/TRANSITION_OPPORTUNITY',
  TRANSITION_OPPORTUNITY_SUCCESS: 'opportunity/TRANSITION_OPPORTUNITY_SUCCESS',
  TRANSITION_OPPORTUNITY_ERROR: 'opportunity/TRANSITION_OPPORTUNITY_ERROR',
  EDIT_OPPORTUNITY_LABELS: 'opportunity/EDIT_OPPORTUNITY_LABELS',
  EDIT_OPPORTUNITY_LABELS_SUCCESS:
    'opportunity/EDIT_OPPORTUNITY_LABELS_SUCCESS',
  EDIT_OPPORTUNITY_LABELS_ERROR: 'opportunity/EDIT_OPPORTUNITY_LABELS_ERROR',
  FETCH_LABELS: 'opportunity/FETCH_LABELS',
  FETCH_LABELS_SUCCESS: 'opportunity/FETCH_LABELS_SUCCESS',
  FETCH_LABELS_ERROR: 'opportunity/FETCH_LABELS_ERROR',
  ADD_LABELS: 'opportunity/ADD_LABELS',
  ADD_LABELS_SUCCESS: 'opportunity/ADD_LABELS_SUCCESS',
  ADD_LABELS_ERROR: 'opportunity/ADD_LABELS_ERROR',
  EDIT_OPPORTUNITY_FOLLOWERS: 'opportunity/EDIT_OPPORTUNITY_FOLLOWERS',
  EDIT_OPPORTUNITY_FOLLOWERS_SUCCESS:
    'opportunity/EDIT_OPPORTUNITY_FOLLOWERS_SUCCESS',
  EDIT_OPPORTUNITY_FOLLOWERS_ERROR:
    'opportunity/EDIT_OPPORTUNITY_FOLLOWERS_ERROR',
  EDIT_BATCH_FOLLOWERS: 'opportunity/EDIT_BATCH_FOLLOWERS',
  EDIT_BATCH_FOLLOWERS_SUCCESS: 'opportunity/EDIT_BATCH_FOLLOWERS_SUCCESS',
  EDIT_BATCH_FOLLOWERS_ERROR: 'opportunity/EDIT_BATCH_FOLLOWERS_ERROR',
  FETCH_USER_EMAILS: 'opportunity/FETCH_USER_EMAILS',
  FETCH_USER_EMAILS_SUCCESS: 'opportunity/FETCH_USER_EMAILS_SUCCESS',
  FETCH_USER_EMAILS_ERROR: 'opportunity/FETCH_USER_EMAILS_ERROR',
  FETCH_CUSTOMER_SETTINGS: 'opportunity/FETCH_CUSTOMER_SETTINGS',
  FETCH_CUSTOMER_SETTINGS_SUCCESS:
    'opportunity/FETCH_CUSTOMER_SETTINGS_SUCCESS',
  FETCH_CUSTOMER_SETTINGS_ERROR: 'opportunity/FETCH_CUSTOMER_SETTINGS_ERROR',
  EDIT_CUSTOMER_SETTINGS: 'opportunity/EDIT_CUSTOMER_SETTINGS',
  EDIT_CUSTOMER_SETTINGS_SUCCESS: 'opportunity/EDIT_CUSTOMER_SETTINGS_SUCCESS',
  EDIT_CUSTOMER_SETTINGS_ERROR: 'opportunity/EDIT_CUSTOMER_SETTINGS_ERROR',
  CREATE_OPPORTUNITY: 'CREATE_OPPORTUNITY',
  CREATE_OPPORTUNITY_SUCCESS: 'CREATE_OPPORTUNITY_SUCCESS',
  CREATE_OPPORTUNITY_ERROR: 'CREATE_OPPORTUNITY_ERROR',
  UPDATE_OPPORTUNITY: 'UPDATE_OPPORTUNITY',
  UPDATE_OPPORTUNITY_SUCCESS: 'UPDATE_OPPORTUNITY_SUCCESS',
  UPDATE_OPPORTUNITY_ERROR: 'UPDATE_OPPORTUNITY_ERROR',
  FETCH_MASTER_LIST_OF_VALUES: 'FETCH_MASTER_LIST_OF_VALUES',
  FETCH_MASTER_LIST_OF_VALUES_SUCCESS: 'FETCH_MASTER_LIST_OF_VALUES_SUCCESS',
  FETCH_MASTER_LIST_OF_VALUES_ERROR: 'FETCH_MASTER_LIST_OF_VALUES_ERROR',
  CREATE_VALUE_IN_MASTER_LIST_OF_VALUES:
    'CREATE_VALUE_IN_MASTER_LIST_OF_VALUES',
  CREATE_VALUE_IN_MASTER_LIST_OF_VALUES_SUCCESS:
    'CREATE_VALUE_IN_MASTER_LIST_OF_VALUES_SUCCESS',
  CREATE_VALUE_IN_MASTER_LIST_OF_VALUES_ERROR:
    'CREATE_VALUE_IN_MASTER_LIST_OF_VALUES_ERROR',
};

// Redux actions
export const actions = {
  fetchOpportunitySeeds: (customerID: string) => ({
    type: types.FETCH_OPPORTUNITY_SEEDS,
    customerID,
  }),
  fetchOpportunityBatches: (customerID: string) => ({
    type: types.FETCH_OPPORTUNITY_BATCHES,
    customerID,
  }),
  createOpportunityBatch: (payload: TSCreateBatchPayload) => ({
    type: types.CREATE_OPPORTUNITY_BATCH,
    payload,
  }),
  setBookmarkForSeed: (payload: { id: string; isBookmarked: boolean }) => ({
    type: types.UPDATE_BOOKMARK_FOR_SEED,
    payload,
  }),
  setBookmarkForBatch: (payload: { id: string; isBookmarked: boolean }) => ({
    type: types.UPDATE_BOOKMARK_FOR_BATCH,
    payload,
  }),
  setSelectedSeeds: (payload: any) => ({
    type: types.UPDATE_SELECTED_OPPORTUNITIES,
    payload,
  }),
  setCurrentFilters: (
    payload: TSCurrentFiltersState & { gainsightUserAction: UserTrackedAction }
  ) => ({
    type: types.UPDATE_CURRENT_FILTERS,
    payload,
  }),
  resetFilters: (payload?: Array<TSOpportunitySeed>) => ({
    type: types.RESET_FILTERS,
    payload,
  }),
  rejectBatch: (payload: TSRejectBatchPayload) => ({
    type: types.REJECT_BATCH,
    payload,
  }),
  editBatch: (payload: TSEditBatchPayload) => ({
    type: types.EDIT_BATCH,
    payload,
  }),
  transitionBatch: (payload: TSTransitionOpportunityPayloadPlusGainsight) => ({
    type: types.TRANSITION_BATCH,
    payload,
  }),
  editBatchMetadata: (payload: TSEditBatchMetadataPayload) => ({
    type: types.EDIT_BATCH_METADATA,
    payload,
  }),
  editOpportunity: (payload: TSEditOpportunityPayload) => ({
    type: types.EDIT_OPPORTUNITY,
    payload,
  }),
  transitionOpportunity: (
    payload: TSTransitionOpportunityPayloadPlusGainsight
  ) => ({
    type: types.TRANSITION_OPPORTUNITY,
    payload,
  }),
  editOpportunityLabels: (payload: TSEditOpportunityLabelsPayload) => ({
    type: types.EDIT_OPPORTUNITY_LABELS,
    payload,
  }),
  fetchLabels: (customerId: string) => ({
    type: types.FETCH_LABELS,
    customerId,
  }),
  addLabels: (payload: TSAddLabelsRequestPayload) => ({
    type: types.ADD_LABELS,
    payload,
  }),
  editOpportunityFollowers: (
    payload: TSEditOpportunityFollowersRequestPayload
  ) => ({
    type: types.EDIT_OPPORTUNITY_FOLLOWERS,
    payload,
  }),
  editBatchFollowers: (payload: TSEditBatchFollowersRequestPayload) => ({
    type: types.EDIT_BATCH_FOLLOWERS,
    payload,
  }),
  fetchUserEmails: (customerId: string) => ({
    type: types.FETCH_USER_EMAILS,
    customerId,
  }),
  createOpportunity: (payload: TSCreateOpportunityRequestPayload) => ({
    type: types.CREATE_OPPORTUNITY,
    payload,
  }),
  updateOpportunity: (payload: TSCreateOpportunityRequestPayload) => ({
    type: types.UPDATE_OPPORTUNITY,
    payload,
  }),
  fetchMasterListOfValues: (payload: TSMasterListOfValuesRequest) => ({
    type: types.FETCH_MASTER_LIST_OF_VALUES,
    payload,
  }),
  createValueInMasterListOfValues: (payload: TSMasterListOfValuesRequest) => ({
    type: types.CREATE_VALUE_IN_MASTER_LIST_OF_VALUES,
    payload,
  }),
  fetchCustomerSettings: (customerId: string) => ({
    type: types.FETCH_CUSTOMER_SETTINGS,
    customerId,
  }),
  editCustomerSettings: (payload: TSEditCustomerSettingsRequestPayload) => ({
    type: types.EDIT_CUSTOMER_SETTINGS,
    payload,
  }),
};

// Redux initial state
export const initialState: TSOpportunitySeedsEntityState = {
  byId: {},
  items: [],
  meta: initialListResponseMetaState,
  labels: [],
  labelsMeta: {
    loading: true,
    error: '',
  },
  userEmails: [],
  userEmailsMeta: initialMetaState,
  customerSettings: { columns: {}, projectSequence: [] },
  customerSettingsMeta: initialMetaState,
  batches: [],
  batchesMeta: initialListResponseMetaState,
  createBatchesMeta: initialMetaState,
  editBatchMeta: initialMetaState,
  createOpportunityMeta: initialMetaState,
  editBatchMetadataMeta: initialMetaState,
  rejectBatchMeta: initialMetaState,
  addLabelsMeta: initialMetaState,
  editOpportunityLabelsMeta: initialMetaState,
  editOpportunityFollowersMeta: initialMetaState,
  editBatchFollowersMeta: initialMetaState,
  selectedSeeds: {},
  currentFilters: {
    selectedAddresses: { values: [], isActive: false },
    selectedEcmTypes: { values: [], isActive: false },
    selectedProjectStages: { values: [], isActive: false },
    selectedLabels: { values: [], isActive: false },
    costSavings: { values: [0, 10000000], isActive: false },
    co2Savings: { values: [0, 10000000], isActive: false },
    simplePaybackPeriod: { values: [0, 10000000], isActive: false },
    selectedTaskFilter: { value: '', isActive: false },
    bookmarkedOnly: false,
    selectedOnly: false,
    searchKey: '',
  },
  masterListOfValues: [],
  fetchMasterListOfValuesMeta: initialMetaState,
  createValueInMasterListOfValuesMeta: initialMetaState,
  updateOpportunityMeta: initialMetaState,
};

function entityItems(action, state) {
  const newItems: Array<TSOpportunitySeed> = Object.values(action.payload);
  return state
    .filter((item) => !newItems.find((newItem) => newItem.id === item.id))
    .concat(newItems);
}

function deleteEntityItem(action, state) {
  return state.filter((item: TSOpportunitySeed) => item.id !== action.payload);
}

function updateEntityItem(action, state) {
  return state.map((item) =>
    item.id === action.payload.id
      ? action.payload.batch ?? action.payload.seed
      : item
  );
}
function createEntityItem(action, state) {
  return [action.payload].concat(state);
}

function updateEntityItemProjectStage(
  id: string,
  newProjectStage: TSProjectStage,
  state
) {
  return state.map((item) =>
    item.id === id ? { ...item, projectStage: newProjectStage } : item
  );
}

function updateEntityItems(payloadItems, state) {
  if (payloadItems) {
    return state
      .map((stateItem) => {
        const matchingItem = payloadItems.find(
          (payloadItem) => payloadItem.id == stateItem.id
        );
        return matchingItem ? matchingItem : stateItem;
      })
      .concat(
        payloadItems.filter(
          (payloadItem) =>
            !state.some((stateItem) => stateItem.id === payloadItem.id)
        )
      );
  } else return state;
}

function deleteEntityItemById(action, state) {
  const { [action.payload]: omit, ...res } = state;
  return res;
}

function updateSelectedStatus(selectedIds, seeds: Array<TSOpportunityBase>) {
  // selectedIds is in format {seedId1: true, seedId: true}
  return seeds.map((seed) => ({
    ...seed,
    selected: Object.prototype.hasOwnProperty.call(selectedIds, seed.id),
  }));
}

function updateBookmarkedStatusForEntities(
  payload: { id: string; isBookmarked: boolean },
  seeds: Array<TSOpportunitySeed | TSOpportunityBatch>
) {
  return seeds.map((seed) => ({
    ...seed,
    bookmark: seed.id == payload.id ? payload.isBookmarked : seed.bookmark,
  }));
}

function updateBookmarkedStatusForById(
  payload: { id: string; isBookmarked: boolean },
  seeds: TSSeedsById
) {
  const seedsCopy = Object.assign({}, seeds);
  seedsCopy[payload.id] = {
    ...seedsCopy[payload.id],
    bookmark: payload.isBookmarked,
  };
  return seedsCopy;
}

function byId(state = initialState.byId, action) {
  switch (action.type) {
    case opportunityTypes.REJECT_OPPORTUNITY_SUCCESS:
      return deleteEntityItemById(action, state);
    case types.UPDATE_BOOKMARK_FOR_SEED:
      return updateBookmarkedStatusForById(action.payload, state);
    default:
      return state;
  }
}

function selectedSeeds(state = initialState.selectedSeeds, action) {
  switch (action.type) {
    case types.UPDATE_SELECTED_OPPORTUNITIES:
      return action.payload;
    default:
      return state;
  }
}

function currentFilters(state = initialState.currentFilters, action) {
  switch (action.type) {
    case types.UPDATE_CURRENT_FILTERS: {
      return action.payload;
    }
    case types.RESET_FILTERS: {
      if (action.payload) {
        return getDefaultFiltersWithOpportunityLimits(action.payload);
      } else {
        return initialState.currentFilters;
      }
    }
    default:
      return state;
  }
}

function items(state = initialState.items, action) {
  switch (action.type) {
    case types.FETCH_OPPORTUNITY_SEEDS:
      return initialState.items;
    case types.FETCH_OPPORTUNITY_SEEDS_SUCCESS:
      return entityItems(action, state);
    case opportunityTypes.REJECT_OPPORTUNITY_SUCCESS:
      return deleteEntityItem(action, state);
    case types.UPDATE_SELECTED_OPPORTUNITIES:
      return updateSelectedStatus(action.payload, state);
    case types.UPDATE_BOOKMARK_FOR_SEED:
      return updateBookmarkedStatusForEntities(action.payload, state);
    case types.EDIT_OPPORTUNITY_SUCCESS:
      return updateEntityItem(action, state);
    case types.TRANSITION_OPPORTUNITY: {
      // update the state here, to avoid the blinking problem if moving multiple
      const typedPayload = action.payload as TSTransitionOpportunityPayload;
      return updateEntityItemProjectStage(
        typedPayload.opportunityId,
        typedPayload.projectStage,
        state
      );
    }
    case types.TRANSITION_OPPORTUNITY_SUCCESS:
      return updateEntityItem(action, state);
    case types.EDIT_OPPORTUNITY_LABELS_SUCCESS:
      return updateEntityItem(action, state);
    case types.EDIT_OPPORTUNITY_FOLLOWERS_SUCCESS:
      return updateEntityItem(action, state);
    case types.ADD_LABELS_SUCCESS:
      return updateEntityItems(action.payload.opportunities, state);
    case types.CREATE_OPPORTUNITY_SUCCESS:
      return createEntityItem(action, state);
    case types.UPDATE_OPPORTUNITY_SUCCESS:
      return updateEntityItem(action, state);
    default:
      return state;
  }
}

function labels(state = initialState.labels, action) {
  switch (action.type) {
    case types.FETCH_LABELS:
      return initialState.labels;
    case types.FETCH_LABELS_SUCCESS:
      return action.payload;
    case types.ADD_LABELS_SUCCESS:
      return updateEntityItems(action.payload.labels, state);
    default:
      return state;
  }
}

function masterListOfValues(state = initialState.masterListOfValues, action) {
  switch (action.type) {
    case types.FETCH_MASTER_LIST_OF_VALUES:
      return initialState.masterListOfValues;
    case types.FETCH_MASTER_LIST_OF_VALUES_SUCCESS:
      return action.payload;
    case types.CREATE_VALUE_IN_MASTER_LIST_OF_VALUES_SUCCESS:
      return createEntityItem(action, state);
    default:
      return state;
  }
}
function fetchMasterListOfValuesMeta(
  state = initialState.fetchMasterListOfValuesMeta,
  action
) {
  switch (action.type) {
    case types.FETCH_MASTER_LIST_OF_VALUES:
      return {
        ...state,
        error: '',
        loading: true,
      };
    case types.FETCH_MASTER_LIST_OF_VALUES_SUCCESS:
      return {
        ...state,
        error: '',
        loading: false,
      };
    case types.FETCH_MASTER_LIST_OF_VALUES_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
}

function createValueInMasterListOfValuesMeta(
  state = initialState.createValueInMasterListOfValuesMeta,
  action
) {
  switch (action.type) {
    case types.CREATE_VALUE_IN_MASTER_LIST_OF_VALUES:
      return {
        ...state,
        error: '',
        loading: true,
      };
    case types.CREATE_VALUE_IN_MASTER_LIST_OF_VALUES_SUCCESS:
      return {
        ...state,
        error: '',
        loading: false,
      };
    case types.CREATE_VALUE_IN_MASTER_LIST_OF_VALUES_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
}

function labelsMeta(state = initialState.labelsMeta, action) {
  switch (action.type) {
    case types.FETCH_LABELS:
      return {
        ...state,
        error: '',
        loading: true,
      };
    case types.FETCH_LABELS_SUCCESS:
      return {
        ...state,
        error: '',
        loading: false,
      };
    case types.FETCH_LABELS_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
}

function userEmails(state = initialState.userEmails, action) {
  switch (action.type) {
    case types.FETCH_USER_EMAILS:
      return initialState.items;
    case types.FETCH_USER_EMAILS_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
function userEmailsMeta(state = initialState.userEmailsMeta, action) {
  switch (action.type) {
    case types.FETCH_USER_EMAILS:
      return {
        ...state,
        error: '',
        loading: true,
      };
    case types.FETCH_USER_EMAILS_SUCCESS:
      return {
        ...state,
        error: '',
        loading: false,
      };
    case types.FETCH_USER_EMAILS_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
}

function customerSettings(state = initialState.customerSettings, action) {
  switch (action.type) {
    case types.FETCH_CUSTOMER_SETTINGS:
      return initialState.customerSettings;
    case types.FETCH_CUSTOMER_SETTINGS_SUCCESS:
      return action.payload;
    case types.EDIT_CUSTOMER_SETTINGS_SUCCESS:
      return action.payload;
    case types.TRANSITION_BATCH:
    case types.TRANSITION_OPPORTUNITY:
      return action.payload.newProjectSequence
        ? { ...state, projectSequence: action.payload.newProjectSequence }
        : state; // no change
    default:
      return state;
  }
}

function customerSettingsMeta(
  state = initialState.customerSettingsMeta,
  action
) {
  switch (action.type) {
    case types.FETCH_CUSTOMER_SETTINGS:
      return {
        ...state,
        error: '',
        loading: true,
      };
    case types.FETCH_CUSTOMER_SETTINGS_SUCCESS:
      return {
        ...state,
        error: '',
        loading: false,
      };
    case types.FETCH_CUSTOMER_SETTINGS_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
}

function addLabelsMeta(state = initialState.addLabelsMeta, action) {
  switch (action.type) {
    case types.ADD_LABELS:
      return {
        ...state,
        error: '',
        loading: true,
      };
    case types.ADD_LABELS_SUCCESS:
      return {
        ...state,
        error: '',
        loading: false,
      };
    case types.ADD_LABELS_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
}

function createOpportunityMeta(
  state = initialState.createOpportunityMeta,
  action
) {
  switch (action.type) {
    case types.CREATE_OPPORTUNITY:
      return {
        ...state,
        error: '',
        loading: true,
      };
    case types.CREATE_OPPORTUNITY_SUCCESS:
      return {
        ...state,
        error: '',
        loading: false,
      };
    case types.CREATE_OPPORTUNITY_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
}
function updateOpportunityMeta(
  state = initialState.updateOpportunityMeta,
  action
) {
  switch (action.type) {
    case types.UPDATE_OPPORTUNITY:
      return {
        ...state,
        error: '',
        loading: true,
      };
    case types.UPDATE_OPPORTUNITY_SUCCESS:
      return {
        ...state,
        error: '',
        loading: false,
      };
    case types.UPDATE_OPPORTUNITY_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
}

function editOpportunityLabelsMeta(
  state = initialState.editOpportunityLabelsMeta,
  action
) {
  switch (action.type) {
    case types.EDIT_OPPORTUNITY_LABELS:
      return {
        ...state,
        error: '',
        loading: true,
      };
    case types.EDIT_OPPORTUNITY_LABELS_SUCCESS:
      return {
        ...state,
        error: '',
        loading: false,
      };
    case types.EDIT_OPPORTUNITY_LABELS_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
}

function editOpportunityFollowersMeta(
  state = initialState.editOpportunityFollowersMeta,
  action
) {
  switch (action.type) {
    case types.EDIT_OPPORTUNITY_FOLLOWERS:
      return {
        ...state,
        error: '',
        loading: true,
      };
    case types.EDIT_OPPORTUNITY_FOLLOWERS_SUCCESS:
      return {
        ...state,
        error: '',
        loading: false,
      };
    case types.EDIT_OPPORTUNITY_FOLLOWERS_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
}

function editBatchFollowersMeta(
  state = initialState.editBatchFollowersMeta,
  action
) {
  switch (action.type) {
    case types.EDIT_BATCH_FOLLOWERS:
      return {
        ...state,
        error: '',
        loading: true,
      };
    case types.EDIT_BATCH_FOLLOWERS_SUCCESS:
      return {
        ...state,
        error: '',
        loading: false,
      };
    case types.EDIT_BATCH_FOLLOWERS_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
}

function onCreateBatch(action, state) {
  const newItem: TSOpportunityBatch = action.payload;
  return state.concat(newItem);
}

function batches(state = initialState.batches, action) {
  switch (action.type) {
    case types.FETCH_OPPORTUNITY_BATCHES:
      return initialState.batches;
    case types.FETCH_OPPORTUNITY_BATCHES_SUCCESS:
      return entityItems(action, state);
    case types.UPDATE_SELECTED_OPPORTUNITIES:
      return updateSelectedStatus(action.payload, state);
    case types.CREATE_OPPORTUNITY_BATCH_SUCCESS:
      return onCreateBatch(action, state);
    case types.UPDATE_BOOKMARK_FOR_BATCH:
      return updateBookmarkedStatusForEntities(action.payload, state);
    case types.REJECT_BATCH_SUCCESS:
      return deleteEntityItem(action, state);
    case types.EDIT_BATCH_SUCCESS:
      return updateEntityItem(action, state);
    case types.TRANSITION_BATCH: {
      // update the state here, to avoid the blinking problem if moving multiple
      const typedPayload = action.payload as TSTransitionOpportunityPayload;
      return updateEntityItemProjectStage(
        typedPayload.opportunityId,
        typedPayload.projectStage,
        state
      );
    }
    case types.TRANSITION_BATCH_SUCCESS:
      return updateEntityItem(action, state);
    case types.EDIT_BATCH_METADATA_SUCCESS:
      return updateEntityItem(action, state);
    case types.EDIT_BATCH_FOLLOWERS_SUCCESS:
      return updateEntityItem(action, state);
    case types.ADD_LABELS_SUCCESS:
      return updateEntityItems(action.payload.batches, state);
    default:
      return state;
  }
}

function createBatchesMeta(state = initialState.createBatchesMeta, action) {
  switch (action.type) {
    case types.CREATE_OPPORTUNITY_BATCH:
      return {
        ...state,
        error: '',
        loading: true,
      };
    case types.CREATE_OPPORTUNITY_BATCH_SUCCESS:
      return {
        ...state,
        error: '',
        loading: false,
      };
    case types.CREATE_OPPORTUNITY_BATCH_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
}

function rejectBatchMeta(state = initialState.rejectBatchMeta, action) {
  switch (action.type) {
    case types.REJECT_BATCH:
      return {
        ...state,
        error: '',
        loading: true,
      };
    case types.REJECT_BATCH_SUCCESS:
      return {
        ...state,
        error: '',
        loading: false,
      };
    case types.REJECT_BATCH_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
}

function editBatchMetadataMeta(
  state = initialState.editBatchMetadataMeta,
  action
) {
  switch (action.type) {
    case types.EDIT_BATCH_METADATA:
      return {
        ...state,
        error: '',
        loading: true,
      };
    case types.EDIT_BATCH_METADATA_SUCCESS:
      return {
        ...state,
        error: '',
        loading: false,
      };
    case types.EDIT_BATCH_METADATA_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
}

function editBatchMeta(state = initialState.editBatchMeta, action) {
  switch (action.type) {
    case types.EDIT_BATCH:
      return {
        ...state,
        error: '',
        loading: true,
      };
    case types.EDIT_BATCH_SUCCESS:
      return {
        ...state,
        error: '',
        loading: false,
      };
    case types.EDIT_BATCH_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
}

function meta(state = initialState.meta, action) {
  switch (action.type) {
    case types.FETCH_OPPORTUNITY_SEEDS:
      return {
        ...state,
        error: '',
        loading: true,
        noResults: false,
      };
    case types.FETCH_OPPORTUNITY_SEEDS_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case types.FETCH_OPPORTUNITY_SEEDS_SUCCESS:
      return {
        ...state,
        error: '',
        loading: false,
        noResults: !action.payload[0],
      };
    default:
      return state;
  }
}

function batchesMeta(state = initialState.batchesMeta, action) {
  switch (action.type) {
    case types.FETCH_OPPORTUNITY_BATCHES:
      return {
        ...state,
        error: '',
        loading: true,
        noResults: false,
      };
    case types.FETCH_OPPORTUNITY_BATCHES_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case types.FETCH_OPPORTUNITY_BATCHES_SUCCESS:
      return {
        ...state,
        error: '',
        loading: false,
        noResults: !action.payload[0],
      };
    default:
      return state;
  }
}

export default combineReducers({
  byId,
  items,
  meta,
  labels,
  labelsMeta,
  selectedSeeds,
  currentFilters,
  batches,
  batchesMeta,
  createBatchesMeta,
  rejectBatchMeta,
  editBatchMeta,
  editBatchMetadataMeta,
  addLabelsMeta,
  editOpportunityLabelsMeta,
  editOpportunityFollowersMeta,
  editBatchFollowersMeta,
  userEmails,
  userEmailsMeta,
  createOpportunityMeta,
  updateOpportunityMeta,
  masterListOfValues,
  fetchMasterListOfValuesMeta,
  createValueInMasterListOfValuesMeta,
  customerSettings,
  customerSettingsMeta,
});

export const getDefaultFiltersWithOpportunityLimits = (
  opportunitySeeds: Array<TSOpportunitySeed>
): TSCurrentFiltersState => {
  return {
    ...initialState.currentFilters,
    costSavings: {
      values: [
        findOpportunitiesMinValue(
          opportunitySeeds,
          'annualNetCostSavings',
          true
        ),
        findOpportunitiesMaxValue(
          opportunitySeeds,
          'annualNetCostSavings',
          true
        ),
      ],
      isActive: false,
    },
    co2Savings: {
      values: [
        findOpportunitiesMinValue(opportunitySeeds, 'annualAvoidedCo2e', true),
        findOpportunitiesMaxValue(opportunitySeeds, 'annualAvoidedCo2e', true),
      ],
      isActive: false,
    },

    simplePaybackPeriod: {
      values: [
        findOpportunitiesMinValue(
          opportunitySeeds,
          'simplePaybackPeriod',
          true
        ),
        findOpportunitiesMaxValue(
          opportunitySeeds,
          'simplePaybackPeriod',
          true
        ),
      ],
      isActive: false,
    },
  };
};

export const getSelectedSeeds = (
  seeds: Array<TSOpportunityBase>,
  filters: TSCurrentFiltersState,
  labels: TSLabels
): Array<TSOpportunityBase> => {
  return getSeedsThatMatchFilter(
    seeds,
    { ...filters, selectedOnly: true },
    labels
  );
};

export const getTasksBySelectedFilter = (tasks, selectedFilter) => {
  const today = moment().endOf('day');
  const endOfThisWeek = moment().endOf('week');

  switch (selectedFilter) {
    case 'Incomplete':
      return tasks.filter((task) => task.status !== 'COMPLETED');
    case 'Overdue':
      return tasks.filter(
        (task) => task.dueDate && moment(task.dueDate).isBefore(today, 'day')
      );
    case 'Due today':
      return tasks.filter(
        (task) => task.dueDate && moment(task.dueDate).isSame(today, 'day')
      );
    case 'Due this week':
      return tasks.filter(
        (task) =>
          task.dueDate &&
          moment(task.dueDate).isBefore(endOfThisWeek, 'day') &&
          moment(task.dueDate).isAfter(today, 'day')
      );
    default:
      return tasks;
  }
};

export const getSeedsThatMatchFilter = (
  opportunities: Array<TSOpportunityBase>,
  filters: TSCurrentFiltersState,
  labels: TSLabels
): Array<TSOpportunityBase> => {
  return opportunities.filter((opportunity) => {
    // This function runs over each individual opportunity to check filter criteria
    const shouldBeReturned = (opportunity: TSOpportunityBase) => {
      const {
        annualAvoidedCo2e,
        annualNetCostSavings,
        simplePaybackPeriod,
        ecmType,
        projectStage,
        bookmark,
        selected,
        entityType,
        displayId,
        labelIds,
        title,
        tasks,
      } = opportunity;

      // Filter tasks based on selected task filter
      if (filters.selectedTaskFilter.isActive) {
        if (tasks && tasks.length > 0) {
          const filteredTasks = getTasksBySelectedFilter(
            tasks,
            filters.selectedTaskFilter.value
          );
          if (filteredTasks?.length <= 0) return false;
        } else {
          // Case when there is no task
          return false;
        }
      }

      // // keyword
      const matchingSearchLabelIds = labels
        ?.filter((label) =>
          label.text.toLowerCase().includes(filters.searchKey.toLowerCase())
        )
        .map((label) => label.id);

      // Filter by CO2 savings
      if (filters.co2Savings) {
        const [minCO2Savings, maxCO2Savings] = filters.co2Savings.values;
        const co2Savings = annualAvoidedCo2e;
        if (co2Savings < minCO2Savings || co2Savings > maxCO2Savings) {
          return false;
        }
      }

      // Filter by cost savings
      if (filters.costSavings) {
        const [minCostSavings, maxCostSavings] = filters.costSavings.values;
        const costSavings = annualNetCostSavings; //parseFloat(annualNetCostSavings);
        if (costSavings < minCostSavings || costSavings > maxCostSavings) {
          return false;
        }
      }

      // Filter by simple payback period
      if (filters.simplePaybackPeriod) {
        const [minSimplePaybackPeriod, maxSimplePaybackPeriod] =
          filters.simplePaybackPeriod.values;
        const savings = simplePaybackPeriod; //parseFloat(kwhSavings);
        if (
          savings < minSimplePaybackPeriod ||
          savings > maxSimplePaybackPeriod
        ) {
          return false;
        }
      }

      // Filter by selected ECM type
      if (
        filters.selectedEcmTypes.values &&
        filters.selectedEcmTypes.values.length > 0 &&
        !filters.selectedEcmTypes.values.includes(ecmType)
      ) {
        return false;
      }

      // Filter by project stage
      if (
        filters.selectedProjectStages.values &&
        filters.selectedProjectStages.values.length > 0 &&
        !filters.selectedProjectStages.values.includes(projectStage)
      ) {
        return false;
      }

      // Filter by selected label
      if (
        filters.selectedLabels.values &&
        filters.selectedLabels.values.length > 0
      ) {
        const seedLabels = labels.filter((label) =>
          labelIds.includes(label.id)
        );
        const hasMatchingLabel = !!seedLabels.find((seedLabel) =>
          filters.selectedLabels.values.includes(seedLabel.id)
        );
        if (!hasMatchingLabel) return false;
      }

      // seed-specific filter logic
      if (entityType == 'seed') {
        const { address1, city, state } = opportunity as TSOpportunitySeed;

        // Filter by selected address
        if (
          filters.selectedAddresses.values &&
          filters.selectedAddresses.values.length > 0 &&
          !filters.selectedAddresses.values.includes(address1)
        ) {
          return false;
        }

        // keyword
        if (
          filters.searchKey &&
          !address1.toLowerCase().includes(filters.searchKey) &&
          !title?.toLowerCase().includes(filters.searchKey) &&
          !ecmType?.toLowerCase().includes(filters.searchKey) &&
          !displayId.toLowerCase().includes(filters.searchKey) &&
          !city.toLowerCase().includes(filters.searchKey) &&
          !state.toLowerCase().includes(filters.searchKey) &&
          (matchingSearchLabelIds.length <= 0 ||
            !labelIds.some((label) => matchingSearchLabelIds.includes(label)))
        ) {
          return false;
        }
      }
      // end seed-specific filter logic

      // batch-specific filter logic
      if (entityType == 'batch') {
        const { title } = opportunity as TSOpportunityBatch;

        // Filter by selected address
        if (
          filters.selectedAddresses &&
          filters.selectedAddresses.values.length > 0
        ) {
          return false;
        }

        if (
          filters.searchKey &&
          !title?.toLowerCase().includes(filters.searchKey) &&
          !ecmType?.toLowerCase().includes(filters.searchKey) &&
          !displayId?.toLowerCase().includes(filters.searchKey) &&
          (matchingSearchLabelIds.length <= 0 ||
            !labelIds.some((label) => matchingSearchLabelIds.includes(label)))
        ) {
          return false;
        }
      }
      // end batch-specific filter logic

      if (filters.bookmarkedOnly && !bookmark) {
        return false;
      }

      if (filters.selectedOnly && !selected) {
        return false;
      }
      return true; // Include item in the filtered array
    };

    // if the current seed is not a batch, check for filter criteria
    if (opportunity.entityType == 'seed') {
      return shouldBeReturned(opportunity);
    } else if (opportunity.entityType == 'batch') {
      // if the current seed is a batch, check for filter criteria and check each child for filter criteria
      // if the batch or even one child fulfils the criteria, we return the enire batch
      if (shouldBeReturned(opportunity)) {
        return true;
      } else {
        if ((opportunity as TSOpportunityBatch).subRows?.length) {
          const matchingChildren = (
            opportunity as TSOpportunityBatch
          ).subRows?.filter((seed) => shouldBeReturned(seed));
          return matchingChildren?.length != 0;
        }
      }
    }
  });
};

export const getDisplayAddress = (
  opportunity: TSOpportunityBase,
  addressType: 'short' | 'full' = 'short'
) => {
  return opportunity.entityType == 'seed'
    ? getCombinedOpportunityAddress(
        opportunity as TSOpportunitySeed,
        addressType
      )
    : getCombinedBatchAddress(opportunity as TSOpportunityBatch);
};

export const getCombinedOpportunityAddress = (
  seed: TSOpportunitySeed,
  addressType: 'short' | 'full' = 'short'
) => {
  const { address1, city, state, postalcode, country } = seed;
  const addressParts =
    addressType == 'full'
      ? [address1, city, state, postalcode, country]
      : [city, state];
  const combinedAddress = addressParts
    .filter((part) => part !== undefined && part !== '')
    .join(', ');
  return combinedAddress;
};

const getCombinedBatchAddress = (batch: TSOpportunityBatch) => {
  const { subRows } = batch;

  if (subRows?.length) {
    const firstItem = subRows[0];
    const areAddressesSame = subRows.every(
      (item) =>
        item.address1 === firstItem.address1 &&
        item.address2 === firstItem.address2 &&
        item.state === firstItem.state &&
        item.city === firstItem.city
    );
    if (areAddressesSame) {
      // Return the values from child
      return getCombinedOpportunityAddress(firstItem);
    } else {
      return 'Multiple Addresses';
    }
  } else {
    return EMPTY_METRIC_STRING;
  }
};

export const combineSeedsBatchesAndTasks = (
  seeds: Array<TSOpportunitySeed>,
  batches: Array<TSOpportunityBatch>,
  tasks: Array<TSOpportunityTaskResponse>
): ({ displayAddress: string } & TSOpportunityBase)[] => {
  const result: ({ displayAddress: string } & TSOpportunityBase)[] = [];

  const tasksByOpportunityId: { [key: string]: TSOpportunityTaskResponse[] } =
    {};
  const tasksByBatchId: { [key: string]: TSOpportunityTaskResponse[] } = {};

  tasks.forEach((task) => {
    if (task.projectOpportunityId) {
      if (!tasksByOpportunityId[task.projectOpportunityId]) {
        tasksByOpportunityId[task.projectOpportunityId] = [];
      }
      tasksByOpportunityId[task.projectOpportunityId].push(task);
    } else if (task.projectBatchId) {
      if (!tasksByBatchId[task.projectBatchId]) {
        tasksByBatchId[task.projectBatchId] = [];
      }
      tasksByBatchId[task.projectBatchId].push(task);
    }
  });

  if (batches.length) {
    batches.forEach((batch: any) => {
      const matchingSeeds = seeds
        .filter((seed) => batch?.opportunityIds?.includes(seed.id))
        .map((childSeed) => {
          const seedTasks = tasksByOpportunityId[childSeed.id] || [];
          return {
            ...childSeed,
            displayAddress: getCombinedOpportunityAddress(childSeed),
            parent: {
              id: batch.id,
              title: batch.title,
              displayId: batch.displayId,
            },
            tasks: seedTasks,
          };
        });
      if (matchingSeeds.length > 0) {
        batch = {
          ...batch,
          subRows: matchingSeeds,
          tasks: tasksByBatchId[batch.id] || [],
        };
        seeds = seeds.filter((seed) => !batch.opportunityIds.includes(seed.id));
      }
      result.push({ ...batch, displayAddress: getCombinedBatchAddress(batch) });
    });
  }

  if (seeds.length) {
    seeds.forEach((seed) => {
      const seedTasks = tasksByOpportunityId[seed.id] || [];
      result.push({
        ...seed,
        displayAddress: getCombinedOpportunityAddress(seed),
        tasks: seedTasks,
      });
    });
  }
  // Sort the final output
  result.sort((a, b) => {
    if (a.entityType == 'seed') {
      return 1;
    }
    if (b.entityType == 'batch') {
      return -1;
    }
    return 0;
  });

  return result;
};

export const getParentBatchForSeed = (
  seed: TSOpportunitySeed,
  batches: Array<TSOpportunityBatch>
): TSOpportunityBatch | null => {
  const matchingBatches = batches.filter((batch) =>
    batch.opportunityIds.includes(seed.id)
  );
  if (matchingBatches.length) {
    return matchingBatches[0]; // This is assuming the seed belongs to at most one batch
  }
  return null;
};

export const getSeedsForBatch = (
  batch: TSOpportunityBatch,
  seeds: Array<TSOpportunitySeed>
): Array<TSOpportunitySeed> => {
  return seeds.filter((seed) => batch.opportunityIds?.includes(seed.id));
};
export const getSeedsThatDonotBelongToAnyBatch = (
  batches: Array<TSOpportunityBatch>,
  seeds: Array<TSOpportunitySeed>
): Array<TSOpportunitySeed> => {
  return seeds.filter((opp) => {
    return !batches.some((batch) => batch.opportunityIds.includes(opp.id));
  });
};

export const selectOpportunitySeedsEntity = (
  state: TSState
): TSOpportunitySeedsEntityState => state.entities.opportunitySeeds;

export const enhanceSeed = (
  seed: TSOpportunitySeedResponse
): TSOpportunitySeed => ({
  ...seed,
  selected: false,
  entityType: TSOpportunityEntityType.SEED,
  // could set the url here for clicking on the seed's site
});
export const enhanceBatch = (
  batch: TSOpportunityBatchResponse
): TSOpportunityBatch => ({
  ...batch,
  selected: false,
  entityType: TSOpportunityEntityType.BATCH,
});

// Request subset of customers who are authorized to see the opportunity feed
export const API = {
  fetchOpportunitySeeds: (customerId) => {
    if (isVariantActive('mock')) {
      return Promise.resolve(opportunitySeeds).then(
        (data) => new Promise((resolve) => setTimeout(() => resolve(data), 200))
      );
    }

    const url = `${opportunityApiBaseUrl()}/opportunities?customerId=${customerId}`;
    return axios
      .get(url, { headers: defaultHeaders() })
      .then((response) => {
        return response.data.results;
      })
      .catch(handleAxiosError);
  },
  fetchLabels: (customerId) => {
    if (isVariantActive('mock')) {
      return Promise.resolve(opportunityLabels).then(
        (data) => new Promise((resolve) => setTimeout(() => resolve(data), 200))
      );
    }

    const url = `${opportunityApiBaseUrl()}/metadata/labels?customerId=${customerId}`;
    return axios
      .get(url, { headers: defaultHeaders() })
      .then((response) => {
        return response.data.results;
      })
      .catch(handleAxiosError);
  },
  setBookmarkForSeed: (opportunityId, isBookmarked) => {
    const url = `${opportunityApiBaseUrl()}/opportunities/${opportunityId}/bookmark`;
    return axios({
      method: 'patch',
      url: url,
      headers: defaultHeaders(),
      data: { status: isBookmarked },
    }).catch(handleAxiosError);
  },
  setBookmarkForBatch: (opportunityId, isBookmarked) => {
    const url = `${opportunityApiBaseUrl()}/batches/${opportunityId}/bookmark`;
    return axios({
      method: 'patch',
      url: url,
      headers: defaultHeaders(),
      data: { status: isBookmarked },
    }).catch(handleAxiosError);
  },
  createOpportunityBatch: (request: TSCreateBatchPayload) => {
    if (isVariantActive('mock')) {
      return Promise.resolve(opportunityBatches[0]).then(
        (data) => new Promise((resolve) => setTimeout(() => resolve(data), 200))
      );
    }
    const url = `${opportunityApiBaseUrl()}/batches`;
    return axios({
      method: 'post',
      url: url,
      headers: defaultHeaders(),
      data: request,
    })
      .then(({ data }: { data: TSOpportunityBatch }) => data)
      .catch(handleAxiosError);
  },
  fetchOpportunityBatches: (customerId) => {
    if (isVariantActive('mock')) {
      return Promise.resolve(opportunityBatches).then(
        (data) => new Promise((resolve) => setTimeout(() => resolve(data), 200))
      );
    }

    const url = `${opportunityApiBaseUrl()}/batches?customerId=${customerId}`;
    return axios
      .get(url, { headers: defaultHeaders() })
      .then((response) => {
        return response.data.results;
      })
      .catch(handleAxiosError);
  },
  rejectBatch: (payload: TSRejectBatchPayload) => {
    const { opportunityId, reason } = payload;
    const url = `${opportunityApiBaseUrl()}/batches/${opportunityId}/reject`;
    return axios({
      method: 'patch',
      url: url,
      headers: defaultHeaders(),
      data: { reason },
    }).catch(handleAxiosError);
  },
  editBatch: (payload: TSEditBatchPayload) => {
    const { opportunityIds, batchId } = payload;
    const url = `${opportunityApiBaseUrl()}/batches/${batchId}/opportunities`;
    return axios({
      method: 'patch',
      url: url,
      headers: defaultHeaders(),
      data: { opportunityIds },
    })
      .then((response) => {
        return response.data;
      })
      .catch(handleAxiosError);
  },
  transitionBatch: (payload: TSTransitionOpportunityPayload) => {
    const { opportunityId, ...restOfData } = payload;
    const url = `${opportunityApiBaseUrl()}/batches/${opportunityId}/transition`;
    return axios({
      method: 'post',
      url: url,
      headers: defaultHeaders(),
      data: restOfData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(handleAxiosError);
  },
  editBatchMetadata: (payload: TSEditBatchMetadataPayload) => {
    const { batchId, ...restPayload } = payload;
    const url = `${opportunityApiBaseUrl()}/batches/${batchId}`;
    return axios({
      method: 'patch',
      url: url,
      headers: defaultHeaders(),
      data: restPayload,
    })
      .then((response) => {
        return response.data;
      })
      .catch(handleAxiosError);
  },
  editOpportunity: (payload: TSEditOpportunityPayload) => {
    const { opportunityId, description, title } = payload;
    const url = `${opportunityApiBaseUrl()}/opportunities/${opportunityId}`;
    return axios({
      method: 'patch',
      url: url,
      headers: defaultHeaders(),
      data: { description, title },
    })
      .then((response) => {
        return response.data;
      })
      .catch(handleAxiosError);
  },
  transitionOpportunity: (payload: TSTransitionOpportunityPayload) => {
    const { opportunityId, ...restOfData } = payload;
    const url = `${opportunityApiBaseUrl()}/opportunities/${opportunityId}/transition`;
    return axios({
      method: 'post',
      url: url,
      headers: defaultHeaders(),
      data: restOfData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(handleAxiosError);
  },
  editOpportunityLabels: (payload: TSEditOpportunityLabelsPayload) => {
    const { opportunityId, labelIds } = payload;
    const url = `${opportunityApiBaseUrl()}/opportunities/${opportunityId}/labels`;
    return axios({
      method: 'patch',
      url: url,
      headers: defaultHeaders(),
      data: { labelIds },
    })
      .then((response) => {
        return response.data;
      })
      .catch(handleAxiosError);
  },
  addLabels: (payload: TSAddLabelsRequestPayload) => {
    const { customerId, labels, opportunityIds, batchIds } = payload;
    const url = `${opportunityApiBaseUrl()}/metadata/labels/assign`;
    return axios({
      method: 'post',
      url: url,
      headers: defaultHeaders(),
      data: { customerId, labels, opportunityIds, batchIds },
    })
      .then((response) => {
        return response.data;
      })
      .catch(handleAxiosError);
  },
  editOpportunityFollowers: (
    payload: TSEditOpportunityFollowersRequestPayload
  ) => {
    const { opportunityId, followers } = payload;
    const url = `${opportunityApiBaseUrl()}/opportunities/${opportunityId}/followers`;
    return axios({
      method: 'patch',
      url: url,
      headers: defaultHeaders(),
      data: { followers },
    })
      .then((response) => {
        return response.data;
      })
      .catch(handleAxiosError);
  },
  editBatchFollowers: (payload: TSEditBatchFollowersRequestPayload) => {
    const { batchId, followers } = payload;
    const url = `${opportunityApiBaseUrl()}/batches/${batchId}/followers`;
    return axios({
      method: 'patch',
      url: url,
      headers: defaultHeaders(),
      data: { followers },
    })
      .then((response) => {
        return response.data;
      })
      .catch(handleAxiosError);
  },
  fetchUserEmails: (customerId) => {
    if (isVariantActive('mock')) {
      return Promise.resolve(opportunityUserEmails).then(
        (data) => new Promise((resolve) => setTimeout(() => resolve(data), 200))
      );
    }
    const url = `${opportunityApiBaseUrl()}/users?customerId=${customerId}`;
    return axios({
      method: 'get',
      url: url,
      headers: defaultHeaders(),
    })
      .then((response) => {
        return response.data.results;
      })
      .catch(handleAxiosError);
  },
  createOpportunity: (payload: TSCreateOpportunityRequestPayload) => {
    const url = `${opportunityApiBaseUrl()}/opportunities`;
    return axios({
      method: 'post',
      url: url,
      headers: defaultHeaders(),
      data: payload,
    })
      .then((response) => {
        return response.data;
      })
      .catch(handleAxiosError);
  },
  updateOpportunity: (payload: TSCreateOpportunityRequestPayload) => {
    const { opportunityId } = payload;
    const url = `${opportunityApiBaseUrl()}/opportunities/${opportunityId}`;
    return axios({
      method: 'put',
      url: url,
      headers: defaultHeaders(),
      data: payload,
    })
      .then((response) => {
        return response.data;
      })
      .catch(handleAxiosError);
  },
  fetchMasterListOfValues: (payload: TSMasterListOfValuesRequest) => {
    if (isVariantActive('mock')) {
      return Promise.resolve(opportunityMasterListOfValues).then(
        (data) => new Promise((resolve) => setTimeout(() => resolve(data), 200))
      );
    }
    const { customerID } = payload;
    const url = `${opportunityApiBaseUrl()}/customers/${customerID}/mlov`;
    return axios({
      method: 'get',
      url: url,
      headers: defaultHeaders(),
    })
      .then((response) => {
        return response.data.results;
      })
      .catch(handleAxiosError);
  },
  createValueInMasterListOfValues: (payload: TSMasterListOfValuesRequest) => {
    const { customerID, type, value } = payload;
    const url = `${opportunityApiBaseUrl()}/customers/${customerID}/mlov`;
    return axios({
      method: 'post',
      url: url,
      headers: defaultHeaders(),
      data: {
        type,
        value,
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(handleAxiosError);
  },
  fetchCustomerSettings: (customerId) => {
    const url = `${opportunityApiBaseUrl()}/customers/${customerId}/settings`;
    return axios({
      method: 'get',
      url: url,
      headers: defaultHeaders(),
    })
      .then((response) => {
        return response.data;
      })
      .catch(handleAxiosError);
  },
  editCustomerSettings: (payload: TSEditCustomerSettingsRequestPayload) => {
    const { customerId, customerSettings } = payload;
    const url = `${opportunityApiBaseUrl()}/customers/${customerId}/settings`;
    return axios({
      method: 'patch',
      url: url,
      headers: defaultHeaders(),
      data: customerSettings,
    })
      .then((response) => {
        return response.data;
      })
      .catch(handleAxiosError);
  },
};

function* fetchOpportunitySeedsSaga(data): Generator<any, void, any> {
  try {
    const payload: TSOpportunitySeedsResponse = yield call(
      API.fetchOpportunitySeeds,
      data.customerID
    );

    const seeds = payload.map((seed) => enhanceSeed(seed));
    yield put({
      type: types.FETCH_OPPORTUNITY_SEEDS_SUCCESS,
      payload: seeds,
    });

    yield put({
      type: types.UPDATE_CURRENT_FILTERS,
      payload: getDefaultFiltersWithOpportunityLimits(seeds),
    });
  } catch (e) {
    yield handleSagaError(types.FETCH_OPPORTUNITY_SEEDS_ERROR, e as Error);
  }
}

function* fetchLabelsSaga({
  customerId,
}: {
  type: string;
  customerId: string;
}): Generator<any, void, any> {
  try {
    const labels = yield call(API.fetchLabels, customerId);

    yield put({
      type: types.FETCH_LABELS_SUCCESS,
      payload: labels,
    });
  } catch (e) {
    yield handleSagaError(types.FETCH_LABELS_ERROR, e as Error);
  }
}

function* fetchOpportunityBatchesSaga(data): Generator<any, void, any> {
  try {
    const payload: TSOpportunityBatchesResponse = yield call(
      API.fetchOpportunityBatches,
      data.customerID
    );

    const batches = payload.map((batch) => enhanceBatch(batch));
    yield put({
      type: types.FETCH_OPPORTUNITY_BATCHES_SUCCESS,
      payload: batches,
    });

    // TODO: do the batches affect the filters?
    /*
    yield put({
      type: types.UPDATE_CURRENT_FILTERS,
      payload: getDefaultFiltersWithOpportunityLimits(batches),
    });
    */
  } catch (e) {
    yield handleSagaError(types.FETCH_OPPORTUNITY_BATCHES_ERROR, e as Error);
  }
}

function* setBookmarkForSeedSaga(data): Generator<any, void, any> {
  const { id, isBookmarked } = data.payload;
  try {
    yield call(API.setBookmarkForSeed, id, isBookmarked);
  } catch (e) {
    yield handleSagaError(types.UPDATE_BOOKMARK_FOR_SEED_ERROR, e as Error);
  }
}

function* setBookmarkForBatchSaga(data): Generator<any, void, any> {
  const { id, isBookmarked } = data.payload;
  try {
    yield call(API.setBookmarkForBatch, id, isBookmarked);
  } catch (e) {
    yield handleSagaError(types.UPDATE_BOOKMARK_FOR_BATCH_ERROR, e as Error);
  }
}

function* createOpportunityBatchSaga(data): Generator<any, void, any> {
  const { followers, ...payloadToSend } = data.payload;
  try {
    const payload = yield call(API.createOpportunityBatch, payloadToSend);
    const batch = enhanceBatch(payload);

    yield put({
      type: types.CREATE_OPPORTUNITY_BATCH_SUCCESS,
      payload: batch,
    });
    if (followers.length) {
      try {
        const batchPayload: TSOpportunityBatch = yield call(
          API.editBatchFollowers,
          {
            batchId: batch.id,
            followers: followers,
          }
        );
        const updatedBatch = enhanceBatch(batchPayload);

        yield put({
          type: types.EDIT_BATCH_FOLLOWERS_SUCCESS,
          payload: { id: updatedBatch.id, batch: updatedBatch },
        });
      } catch (e) {
        yield handleSagaError(types.EDIT_BATCH_FOLLOWERS_ERROR, e as Error);
      }
    }
  } catch (e) {
    yield handleSagaError(types.CREATE_OPPORTUNITY_BATCH_ERROR, e as Error);
  }
}

function* rejectBatchSaga({
  payload,
}: {
  type: string;
  payload: TSRejectBatchPayload;
}): Generator<any, void, any> {
  try {
    yield call(API.rejectBatch, payload);
    yield put({
      type: types.REJECT_BATCH_SUCCESS,
      payload: payload.opportunityId,
    });
  } catch (e) {
    yield handleSagaError(types.REJECT_BATCH_ERROR, e as Error);
  }
}

function* editBatchSaga({
  payload,
}: {
  type: string;
  payload: TSEditBatchPayload;
}): Generator<any, void, any> {
  try {
    const batchPayload = yield call(API.editBatch, payload);
    const batch = enhanceBatch(batchPayload);

    yield put({
      type: types.EDIT_BATCH_SUCCESS,
      payload: { id: payload.batchId, batch },
    });
  } catch (e) {
    yield handleSagaError(types.EDIT_BATCH_ERROR, e as Error);
  }
}

function* transitionBatchSaga({
  payload,
}: {
  type: string;
  payload: TSTransitionOpportunityPayloadPlusGainsight;
}): Generator<any, void, any> {
  try {
    const { oldProjectStage, gainsightUserAction, ...payloadToSend } = payload;
    const batchPayload = yield call(API.transitionBatch, payloadToSend);
    const batch = enhanceBatch(batchPayload);
    yield put({
      type: types.TRANSITION_BATCH_SUCCESS,
      payload: { id: payload.opportunityId, batch },
    });
  } catch (e) {
    yield handleSagaError(types.TRANSITION_BATCH_ERROR, e as Error);
  }
}

function* editBatchMetadataSaga({
  payload,
}: {
  type: string;
  payload: TSEditBatchMetadataPayload;
}): Generator<any, void, any> {
  try {
    const batchPayload = yield call(API.editBatchMetadata, payload);
    const batch = enhanceBatch(batchPayload);

    yield put({
      type: types.EDIT_BATCH_METADATA_SUCCESS,
      payload: { id: payload.batchId, batch },
    });
  } catch (e) {
    yield handleSagaError(types.EDIT_BATCH_METADATA_ERROR, e as Error);
  }
}

function* editOpportunitySaga({
  payload,
}: {
  type: string;
  payload: TSEditOpportunityPayload;
}): Generator<any, void, any> {
  try {
    const seedPayload = yield call(API.editOpportunity, payload);
    const seed = enhanceSeed(seedPayload);

    yield put({
      type: types.EDIT_OPPORTUNITY_SUCCESS,
      payload: { id: payload.opportunityId, seed },
    });
  } catch (e) {
    yield handleSagaError(types.EDIT_OPPORTUNITY_ERROR, e as Error);
  }
}

function* transitionOpportunitySaga({
  payload,
}: {
  type: string;
  payload: TSTransitionOpportunityPayloadPlusGainsight;
}): Generator<any, void, any> {
  try {
    const { oldProjectStage, gainsightUserAction, ...payloadToSend } = payload;
    const seedPayload = yield call(API.transitionOpportunity, payloadToSend);
    const seed = enhanceSeed(seedPayload);
    yield put({
      type: types.TRANSITION_OPPORTUNITY_SUCCESS,
      payload: { id: payload.opportunityId, seed },
    });
  } catch (e) {
    yield handleSagaError(types.TRANSITION_OPPORTUNITY_ERROR, e as Error);
  }
}

function* editOpportunityLabelsSaga({
  payload,
}: {
  type: string;
  payload: TSEditOpportunityLabelsPayload;
}): Generator<any, void, any> {
  try {
    const seedPayload = yield call(API.editOpportunityLabels, payload);
    const seed = enhanceSeed(seedPayload);

    yield put({
      type: types.EDIT_OPPORTUNITY_LABELS_SUCCESS,
      payload: { id: payload.opportunityId, seed },
    });
  } catch (e) {
    yield handleSagaError(types.EDIT_OPPORTUNITY_ERROR, e as Error);
  }
}

function* addLabelsSaga({
  payload,
}: {
  type: string;
  payload: TSAddLabelsRequestPayload;
}): Generator<any, void, any> {
  try {
    const { labels, batches, opportunities }: TSAddLabelsResponsePayload =
      yield call(API.addLabels, payload);

    yield put({
      type: types.ADD_LABELS_SUCCESS,
      payload: {
        labels,
        opportunities: opportunities
          ? opportunities.map((seed) => enhanceSeed(seed))
          : undefined,
        batches: batches
          ? batches.map((batch) => enhanceBatch(batch))
          : undefined,
      },
    });
  } catch (e) {
    yield handleSagaError(types.ADD_LABELS_ERROR, e as Error);
  }
}

function* editOpportunityFollowersSaga({
  payload,
}: {
  type: string;
  payload: TSEditOpportunityFollowersRequestPayload;
}): Generator<any, void, any> {
  try {
    const seedPayload: TSOpportunitySeed = yield call(
      API.editOpportunityFollowers,
      payload
    );
    const seed = enhanceSeed(seedPayload);

    yield put({
      type: types.EDIT_OPPORTUNITY_FOLLOWERS_SUCCESS,
      payload: { id: payload.opportunityId, seed },
    });
  } catch (e) {
    yield handleSagaError(types.EDIT_OPPORTUNITY_FOLLOWERS_ERROR, e as Error);
  }
}

function* editBatchFollowersSaga({
  payload,
}: {
  type: string;
  payload: TSEditBatchFollowersRequestPayload;
}): Generator<any, void, any> {
  try {
    const batchPayload: TSOpportunityBatch = yield call(
      API.editBatchFollowers,
      payload
    );
    const batch = enhanceBatch(batchPayload);

    yield put({
      type: types.EDIT_BATCH_FOLLOWERS_SUCCESS,
      payload: { id: payload.batchId, batch },
    });
  } catch (e) {
    yield handleSagaError(types.EDIT_BATCH_FOLLOWERS_ERROR, e as Error);
  }
}

function* fetchUserEmailsSaga({
  customerId,
}: {
  type: string;
  customerId: string;
}): Generator<any, void, any> {
  try {
    const userEmails: Array<TSUserEmail> = yield call(
      API.fetchUserEmails,
      customerId
    );

    yield put({
      type: types.FETCH_USER_EMAILS_SUCCESS,
      payload: userEmails,
    });
  } catch (e) {
    yield handleSagaError(types.FETCH_USER_EMAILS_ERROR, e as Error);
  }
}

function* createOpportunitySaga({
  payload,
}: {
  type: string;
  payload: TSCreateOpportunityRequestPayload;
}): Generator<any, void, any> {
  try {
    const opportunityPayload = yield call(API.createOpportunity, payload);
    const seed = enhanceSeed(opportunityPayload);

    yield put({
      type: types.CREATE_OPPORTUNITY_SUCCESS,
      payload: seed,
    });
  } catch (e) {
    yield handleSagaError(types.CREATE_OPPORTUNITY_ERROR, e as Error);
  }
}
function* updateOpportunitySaga({
  payload,
}: {
  type: string;
  payload: TSCreateOpportunityRequestPayload;
}): Generator<any, void, any> {
  try {
    const opportunityPayload = yield call(API.updateOpportunity, payload);
    const seed = enhanceSeed(opportunityPayload);

    yield put({
      type: types.UPDATE_OPPORTUNITY_SUCCESS,
      payload: { id: seed.id, seed },
    });
  } catch (e) {
    yield handleSagaError(types.UPDATE_OPPORTUNITY_ERROR, e as Error);
  }
}

function* fetchMasterListOfValuesSaga({
  payload,
}: {
  type: string;
  payload: TSMasterListOfValuesRequest;
}): Generator<any, void, any> {
  try {
    const masterListOfValuesPayload = yield call(
      API.fetchMasterListOfValues,
      payload
    );
    yield put({
      type: types.FETCH_MASTER_LIST_OF_VALUES_SUCCESS,
      payload: masterListOfValuesPayload,
    });
  } catch (e) {
    yield handleSagaError(types.FETCH_MASTER_LIST_OF_VALUES_ERROR, e as Error);
  }
}

function* createValueInMasterListOfValuesSaga({
  payload,
}: {
  type: string;
  payload: TSMasterListOfValuesRequest;
}): Generator<any, void, any> {
  try {
    const mlovPayload = yield call(
      API.createValueInMasterListOfValues,
      payload
    );

    yield put({
      type: types.CREATE_VALUE_IN_MASTER_LIST_OF_VALUES_SUCCESS,
      payload: mlovPayload,
    });
  } catch (e) {
    yield handleSagaError(
      types.CREATE_VALUE_IN_MASTER_LIST_OF_VALUES_ERROR,
      e as Error
    );
  }
}

function* fetchCustomerSettingsSaga({
  customerId,
}: {
  type: string;
  customerId: string;
}): Generator<any, void, any> {
  try {
    const customerSettings: TSCustomerSettings = yield call(
      API.fetchCustomerSettings,
      customerId
    );

    yield put({
      type: types.FETCH_CUSTOMER_SETTINGS_SUCCESS,
      payload: customerSettings,
    });
  } catch (e) {
    yield handleSagaError(types.FETCH_CUSTOMER_SETTINGS_ERROR, e as Error);
  }
}

function* editCustomerSettingsSaga({
  payload,
}: {
  type: string;
  payload: TSEditCustomerSettingsRequestPayload;
}): Generator<any, void, any> {
  try {
    const customerSettings: TSCustomerSettings = yield call(
      API.editCustomerSettings,
      payload
    );

    yield put({
      type: types.EDIT_CUSTOMER_SETTINGS_SUCCESS,
      payload: customerSettings,
    });
  } catch (e) {
    yield handleSagaError(types.EDIT_CUSTOMER_SETTINGS_ERROR, e as Error);
  }
}

export const sagas = [
  takeLatest(types.UPDATE_BOOKMARK_FOR_SEED, setBookmarkForSeedSaga),
  takeLatest(types.CREATE_OPPORTUNITY_BATCH, createOpportunityBatchSaga),
  takeLatest(types.FETCH_OPPORTUNITY_SEEDS, fetchOpportunitySeedsSaga),
  takeLatest(types.FETCH_OPPORTUNITY_BATCHES, fetchOpportunityBatchesSaga),
  takeLatest(types.UPDATE_BOOKMARK_FOR_BATCH, setBookmarkForBatchSaga),
  takeLatest(types.REJECT_BATCH, rejectBatchSaga),
  takeLatest(types.EDIT_BATCH, editBatchSaga),
  takeEvery(types.TRANSITION_BATCH, transitionBatchSaga),
  takeLatest(types.EDIT_BATCH_METADATA, editBatchMetadataSaga),
  takeLatest(types.EDIT_OPPORTUNITY, editOpportunitySaga),
  takeEvery(types.TRANSITION_OPPORTUNITY, transitionOpportunitySaga),
  takeLatest(types.EDIT_OPPORTUNITY_LABELS, editOpportunityLabelsSaga),
  takeLatest(types.FETCH_LABELS, fetchLabelsSaga),
  takeLatest(types.ADD_LABELS, addLabelsSaga),
  takeLatest(types.EDIT_OPPORTUNITY_FOLLOWERS, editOpportunityFollowersSaga),
  takeLatest(types.EDIT_BATCH_FOLLOWERS, editBatchFollowersSaga),
  takeLatest(types.FETCH_USER_EMAILS, fetchUserEmailsSaga),
  takeLatest(types.CREATE_OPPORTUNITY, createOpportunitySaga),
  takeLatest(types.UPDATE_OPPORTUNITY, updateOpportunitySaga),
  takeLatest(types.FETCH_MASTER_LIST_OF_VALUES, fetchMasterListOfValuesSaga),
  takeLatest(
    types.CREATE_VALUE_IN_MASTER_LIST_OF_VALUES,
    createValueInMasterListOfValuesSaga
  ),
  takeLatest(types.FETCH_CUSTOMER_SETTINGS, fetchCustomerSettingsSaga),
  takeLatest(types.EDIT_CUSTOMER_SETTINGS, editCustomerSettingsSaga),
];
