import moment from 'moment';
import { ResourceType } from '../ducks/types';

export const API_DATE_FORMAT = 'YYYY-MM-DD';
export const PROGRAM_TO_DATE = 'PROGRAM_TO_DATE';
export const YEAR_TO_DATE = 'YEAR_TO_DATE';
export const URL_DATE_FORMAT = 'YYYY-MM-DDTHH:mm[Z]';
export const LAST_3_MONTHS = 'LAST_3_MONTHS';
export const LAST_6_MONTHS = 'LAST_6_MONTHS';
export const LAST_7_DAYS = 'LAST_7_DAYS';
export const LAST_12_MONTHS = 'LAST_12_MONTHS';
export const LAST_MONTH = 'LAST_MONTH';
export const LAST_30_DAYS = 'LAST_30_DAYS';
export const LAST_60_DAYS = 'LAST_60_DAYS';
export const LAST_YEAR = 'LAST_YEAR';
export const THIS_MONTH = 'THIS_MONTH';
export const CUSTOM = 'CUSTOM';
export const API_DEFAULT_START_DATE: string = moment()
  .subtract(12, 'months')
  .startOf('month')
  .format(API_DATE_FORMAT);

export const RES_HOURLY = 'hourly';
export const RES_MINUTES = '15min';
export const SELECTOR_DATE_FORMAT = 'MM/DD/YY';
export const DATE_RANGE_PICKER_FORMAT = 'MMM D, YYYY';
export const DATE_RANGE_PICKER_MONTHLY_FORMAT = 'MMM YYYY';
export const DATE_SHORT_MONTH_FORMAT = 'MMM YYYY';
export const DATE_SHORT_MONTH_AND_YEAR_FORMAT = 'MMM YY';
export const CARD_DATE_FORMAT = 'YYYY-MM-DD h:mmA';
export const HOURLY_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const RES_DAILY = 'daily';
export const DAILY_DATE_FORMAT = 'MMM DD';
export const DATE_FORMAT_DATA_API_REQUEST = 'YYYY-MM-DDTHH:mm:ss';
export const DATE_YEAR_MONTH_DAY_FORMAT = 'YYYY-MM-DD';
export const DATE_YEAR_MONTH_FORMAT_API_REQUEST = 'YYYY-MM';
export const DATE_FORMAT_SIMPLE_WITH_TIME = 'YYYY-MM-DD h:mmA z';
export const DATE_FORMAT_DATA_API_RESPONSE = 'YYYY-MM-DDTHH:mm:ssZ';
export const DATE_FORMAT_DATA_API_RESPONSE_NO_TZ = 'YYYY-MM-DDTHH:mm:ss';
export const DATE_FORMAT_TIMESTAMP = 'MMMM DD, YYYY hh:mm:ss A z';
export const DATE_FORMAT_TOOLTIP = 'dddd, MMMM D YYYY, h:mm A z';
export const DATE_FORMAT_TOOLTIP_NO_TZ = 'dddd, MMMM D YYYY, h:mm A';
export const DATE_FORMAT_NATURAL = 'h:mm A, D MMM YYYY';
export const DATE_FORMAT_CSV = 'MM/DD/YY h:mm A';
export const PEAK_DEMAND_CHART_DATE_FORMAT = 'h:mm A, D MMM YYYY';
export const DATE_FORMAT_INVOICE = 'MM/DD/YYYY';
export const DATE_FORMAT_INVOICE_DETAIL_MODAL = 'MMM DD, YYYY';

export const DAYS_OF_WEEK = [
  'SUNDAY',
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
];

export const baseOptions = [
  { label: 'Last 7 Days', value: LAST_7_DAYS },
  { label: 'Last 30 Days', value: LAST_30_DAYS },
  { label: 'Last 60 Days', value: LAST_60_DAYS },
  { label: 'This Month', value: THIS_MONTH },
  { label: 'Last Month', value: LAST_MONTH },
  { label: 'Last 3 Months', value: LAST_3_MONTHS },
  { label: 'Last 6 Months', value: LAST_6_MONTHS },
  { label: 'Last 12 Months', value: LAST_12_MONTHS },
  { label: 'Last Year', value: LAST_YEAR },
  { label: 'Year to Date', value: YEAR_TO_DATE },
  { label: 'All', value: PROGRAM_TO_DATE },
];

export const UNICODE_DASH = '\u2013';

export const second = 1000;
export const minute = 60 * second;
export const hour = 60 * minute;
export const day = 24 * hour; // this is a typical day, but does not cover daylight savings exceptions...

export const resourcesMapName = {
  [ResourceType.ELECTRICITY]: 'Electric',
  [ResourceType.WATER]: 'Water',
  [ResourceType.NATURAL_GAS]: 'Gas',
};

export const DEFAULT_ELECTRIC_UTILITY_RATE = 0.12;
