import { Group, Paper } from '@mantine/core';
import { DatePicker, MonthPicker } from '@mantine/dates';
import '@mantine/dates/styles.css';
import moment, { Moment } from 'moment';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as consts from '../../../constants';
import { baseOptions } from '../../../constants';
import { Period } from '../../../ducks/types';
import useComponentToggle from '../../../hooks/useComponentToggle';
import useWindowSize from '../../../hooks/useWindowSize';
import CalendarIcon from '../../Icons/CalendarIcon';
import Button from '../Button';
import styles from './DateRangePicker.module.css';

interface DateRangePickerProps {
  gainsightTagId?: string;
  period?: string;
  options?: Array<any>;
  isVerticalOrientation?: boolean;
  getProgramStartDate: () => Moment;
  maxDays?: number;
  maxMonths?: number;
  startDate: Date | null;
  endDate: Date | null;
  updateDateRange: (any) => void;
  isMonthlyDateRangePicker?: boolean;
}

const defaultFormat = 'MMM D, YYYY';

const inputText = (start, end, isMonthlyDateRangePicker) => {
  const format = isMonthlyDateRangePicker
    ? consts.DATE_RANGE_PICKER_MONTHLY_FORMAT
    : defaultFormat;
  const startDate = moment(start, consts.DATE_FORMAT_DATA_API_REQUEST);

  const startText = startDate.format(format);
  let text = startText;
  const endText = end
    ? moment(end, consts.DATE_FORMAT_DATA_API_REQUEST).format(format)
    : '';
  if (end && endText !== startText) {
    text = `${text} - ${endText}`;
  }
  return text;
};

const DateRangePicker = ({
  gainsightTagId,
  period = '',
  options = [],
  isVerticalOrientation,
  getProgramStartDate,
  maxDays,
  maxMonths,
  startDate,
  endDate,
  updateDateRange,
  isMonthlyDateRangePicker,
}: DateRangePickerProps) => {
  const windowSize = useWindowSize();
  const componentRef = useRef(null);
  const toggleRef = useRef(null);
  const programStartDate = getProgramStartDate();

  const { isComponentVisible: isDropdownVisible, setIsComponentVisible } =
    useComponentToggle({
      componentRef,
      toggleRef,
    });

  const [value, setValue] = useState<[Date | null, Date | null]>([
    startDate,
    endDate,
  ]);
  const [calendarOrientation, setCalendarOrientation]: [
    string,
    (string) => void,
  ] = useState('horizontal');
  const [selectedPeriod, updateSelectedPeriod] = useState(period);

  const calendarText = useMemo(() => {
    if (selectedPeriod !== Period.CUSTOM) {
      return baseOptions.find((option) => option.value === selectedPeriod)
        ?.label;
    }
    return inputText(value[0], value[1], isMonthlyDateRangePicker);
  }, [value, selectedPeriod, isMonthlyDateRangePicker]);

  const handleOptionSelect: any = useCallback(
    (value: string) => () => {
      updateSelectedPeriod(value);
      setValue([null, null]);
    },
    []
  );

  const handleDateChange = (dates) => {
    setValue(dates);
    updateSelectedPeriod(Period.CUSTOM);
  };

  const handleCancelClick: any = () => {
    setValue([startDate, endDate]);
    updateSelectedPeriod(period);
    setIsComponentVisible(false);
  };

  const handleApplyClick = useCallback(() => {
    const [updatedStartDate, updatedEndDate] =
      selectedPeriod === Period.CUSTOM ? value : [null, null];

    if (selectedPeriod !== Period.CUSTOM) {
      setValue([updatedStartDate, updatedEndDate]);
    }
    setIsComponentVisible(false);

    return (
      updateDateRange &&
      updateDateRange({
        startDate: updatedStartDate,
        endDate: updatedEndDate ?? updatedStartDate,
        selectedOptionDate: selectedPeriod,
      })
    );
  }, [value, selectedPeriod, updateDateRange, setIsComponentVisible]);

  const maxDate = () => {
    if (value[0] && !value[1] && maxDays) {
      const plusDays = moment(value[0]).add(maxDays, 'days');
      if (plusDays > moment()) {
        return moment().toDate();
      } else {
        return plusDays.toDate();
      }
    }
    return moment().toDate();
  };

  const minDate = () => {
    if (value[0] && !value[1] && maxDays) {
      if (moment(value[0]).subtract(maxDays, 'days') < programStartDate) {
        return programStartDate.toDate();
      }
      return moment(value[0]).subtract(maxDays, 'days').toDate();
    }
    return programStartDate.toDate();
  };

  const maxDateForMonthly = () => {
    if (value[0] && !value[1] && maxMonths) {
      const plusMonths = moment(value[0]).add(maxMonths, 'months');
      if (plusMonths > moment()) {
        return moment().toDate();
      } else {
        return plusMonths.toDate();
      }
    }
    return moment().toDate();
  };

  const minDateForMonthly = () => {
    if (value[0] && !value[1] && maxMonths) {
      return moment(value[0]).subtract(maxMonths, 'months').toDate();
    }
    return programStartDate.toDate();
  };

  useEffect(() => {
    if (windowSize[0] <= 960 || isVerticalOrientation) {
      setCalendarOrientation('vertical');
    } else {
      setCalendarOrientation('horizontal');
    }
  }, [windowSize, isVerticalOrientation]);

  useEffect(() => {
    if (!isDropdownVisible) {
      updateSelectedPeriod(period);
      setValue([startDate, endDate]);
    }
  }, [isDropdownVisible, startDate, endDate, period]);

  return (
    <>
      <div
        className={styles.input}
        data-gainsight-id={gainsightTagId}
        ref={toggleRef}
      >
        <span>{calendarText}</span>
        <CalendarIcon size='16' />
      </div>
      {isDropdownVisible && (
        <div className={styles.dropdown}>
          <div className={styles.dropdownInner}>
            <Paper
              shadow='xs'
              p='s'
              withBorder
              ref={componentRef}
              mt='sm'
              w='fit-content'
            >
              <Group wrap='nowrap' align='baseline' gap={0}>
                {!isMonthlyDateRangePicker ? (
                  <DatePicker
                    className={styles.date}
                    type='range'
                    value={value}
                    defaultDate={value[0] ?? undefined}
                    onChange={handleDateChange}
                    maxLevel='year'
                    numberOfColumns={calendarOrientation === 'vertical' ? 1 : 2}
                    size='sm'
                    minDate={minDate()}
                    maxDate={maxDate()}
                    firstDayOfWeek={0}
                    allowSingleDateInRange
                    styles={{
                      calendarHeaderLevel: {
                        fontSize: 'large',
                        fontColor: 'black',
                      },
                    }}
                  />
                ) : (
                  <MonthPicker
                    type='range'
                    value={value}
                    defaultDate={value[0] ?? undefined}
                    onChange={handleDateChange}
                    maxLevel='year'
                    numberOfColumns={calendarOrientation === 'vertical' ? 1 : 2}
                    size='md'
                    minDate={minDateForMonthly()}
                    maxDate={maxDateForMonthly()}
                  />
                )}
                {calendarOrientation === 'horizontal' && options.length > 0 && (
                  <Group className={styles.options}>
                    {options?.map((option) => {
                      const activeClass =
                        selectedPeriod === option.value
                          ? styles.optionItemActive
                          : '';
                      return (
                        <div
                          className={`${styles.optionItem} ${activeClass}`}
                          onClick={handleOptionSelect(option.value)}
                          key={option.value}
                        >
                          {option.label}
                        </div>
                      );
                    })}
                  </Group>
                )}
              </Group>
              <Group justify='flex-end' className={styles.actionButtons}>
                <Button
                  size='sm'
                  type='button'
                  variant='filled'
                  color='#E0E0E0'
                  styles={{
                    label: {
                      color: '#6d6e6f',
                    },
                  }}
                  onClick={handleCancelClick}
                >
                  Cancel
                </Button>
                <Button
                  size='sm'
                  type='button'
                  color='#9dbd79'
                  variant='filled'
                  onClick={handleApplyClick}
                >
                  Apply
                </Button>
              </Group>
            </Paper>
          </div>
        </div>
      )}
    </>
  );
};

export default DateRangePicker;
