/* eslint-disable @typescript-eslint/no-unused-vars */
import { combineReducers } from 'redux';
import { call, put, takeLatest } from 'redux-saga/effects';
import { handleSagaError } from '../../api/utils';
import { TSState } from '../../reducers/rootReducer';
import { filterDemoCircuitData } from '../../utils/demo';
import {
  Resolution,
  ResourceType,
  TSMetaState,
  TSQuerySearchParams,
  TSUsageDataResponse,
} from '../types';
import { API, enhanceGroupUsageData } from './utils';

export interface TSResourceUsageDataRequest extends TSQuerySearchParams {
  customerId: string;
}

interface TSElectricUsageMetaState extends TSMetaState {
  noElectricDataAvailable: boolean;
}

// can we combine this + TSGroupUsageEnhanced?
export interface TSResourceItemUsage {
  groupName: string;
  groupId: string;
  calcSpend: number;
  usage: Array<number>;
  totalUsage: number; // calculating this after data is returned
}

export interface TSResourceMetaDataResponse {
  ts: string[];
  grouping: string;
  from: string;
  to: string;
  resolution: Resolution;
}

export interface TSElectricUsageEntityState {
  electric: Array<TSResourceItemUsage>;
  electricResponseMetaData: TSResourceMetaDataResponse;
  meta: TSElectricUsageMetaState;
}

export const types = {
  FETCH_ELECTRIC_USAGE_DATA: 'FETCH_ELECTRIC_USAGE_DATA',
  FETCH_ELECTRIC_USAGE_DATA_SUCCESS: 'FETCH_ELECTRIC_USAGE_DATA_SUCCESS',
  FETCH_ELECTRIC_USAGE_META_DATA_RESPONSE_SUCCESS:
    'FETCH_ELECTRIC_USAGE_META_DATA_RESPONSE_SUCCESS',
  FETCH_ELECTRIC_USAGE_DATA_ERROR: 'FETCH_ELECTRIC_USAGE_DATA_ERROR',
  RESET_ELECTRIC_USAGE_DATA: 'RESET_ELECTRIC_USAGE_DATA',
};

export const actions = {
  fetchElectricUsageData: (request: TSResourceUsageDataRequest) => ({
    type: types.FETCH_ELECTRIC_USAGE_DATA,
    ...request,
  }),
  resetElectricUsageData: () => ({
    type: types.RESET_ELECTRIC_USAGE_DATA,
  }),
};

export const initialState: TSElectricUsageEntityState = {
  electric: [],
  electricResponseMetaData: {
    ts: [],
    grouping: '',
    from: '',
    to: '',
    resolution: Resolution.DAILY,
  },
  meta: {
    noElectricDataAvailable: true,
    loading: true,
    error: '',
  },
};

function electric(state = initialState.electric, action) {
  switch (action.type) {
    case types.FETCH_ELECTRIC_USAGE_DATA:
    case types.RESET_ELECTRIC_USAGE_DATA:
      return initialState.electric;
    case types.FETCH_ELECTRIC_USAGE_DATA_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}

function electricResponseMetaData(
  state = initialState.electricResponseMetaData,
  action
) {
  switch (action.type) {
    case types.RESET_ELECTRIC_USAGE_DATA:
    case types.FETCH_ELECTRIC_USAGE_DATA:
      return initialState.electricResponseMetaData;
    case types.FETCH_ELECTRIC_USAGE_META_DATA_RESPONSE_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}

function meta(state = initialState.meta, action): TSElectricUsageMetaState {
  switch (action.type) {
    case types.RESET_ELECTRIC_USAGE_DATA:
    case types.FETCH_ELECTRIC_USAGE_DATA:
      return {
        ...state,
        loading: true,
        error: '',
        noElectricDataAvailable: true,
      };
    case types.FETCH_ELECTRIC_USAGE_DATA_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
        noElectricDataAvailable: false,
      };
    case types.FETCH_ELECTRIC_USAGE_DATA_SUCCESS:
      return {
        ...state,
        error: '',
        loading: false,
        noElectricDataAvailable: !action.payload[0],
      };
    default:
      return state;
  }
}

export default combineReducers({
  electric,
  electricResponseMetaData,
  meta,
});

export const selectElectricUsageEntity = (
  state: TSState
): TSElectricUsageEntityState => state.entities.electricUsage;

// sagas
function* fetchElectricUsageSaga({
  type,
  ...request
}: TSResourceUsageDataRequest & { type: string }): Generator<any, void, any> {
  try {
    const electricUsageData: TSUsageDataResponse = yield call(
      API.fetchUsageData,
      {
        ...request,
        resourceType: ResourceType.ELECTRICITY,
      }
    );

    // pass through demo filter, will apply any relevant filters if this is a demo user
    // do the filter before enhancing to make sure any masked fields used in the enhance are covered
    filterDemoCircuitData(electricUsageData);

    const { data, ...metaData } = electricUsageData;

    yield put({
      type: types.FETCH_ELECTRIC_USAGE_DATA_SUCCESS,
      payload: data.map((group) => {
        return enhanceGroupUsageData(group, ResourceType.ELECTRICITY);
      }),
    });
    yield put({
      type: types.FETCH_ELECTRIC_USAGE_META_DATA_RESPONSE_SUCCESS,
      payload: metaData,
    });
  } catch (e) {
    yield handleSagaError(types.FETCH_ELECTRIC_USAGE_DATA_ERROR, e as Error);
  }
}

export const sagas = [
  takeLatest(types.FETCH_ELECTRIC_USAGE_DATA, fetchElectricUsageSaga),
];
