import moment from 'moment';

// common types

export enum Resolution {
  DAILY = '1day',
  HOURLY = '1hour',
  MINUTES = '15min',
  MONTHLY = '1month',
}

export enum Grouping {
  SITE = 'site',
  PANEL = 'panel',
  BUILDING_SYSTEM = 'buildingSystem',
  EQUIPMENT = 'equipment',
  CIRCUIT = 'circuit',
  METER = 'meter',
  CATEGORY_VALUE = 'categoryValue',
}

export enum MeasurementTypes {
  ACTIVE_ENERGY = 'activeEnergy',
  RMS_VOLTAGE = 'rmsVoltage',
  VOLUME = 'volume',
}

export enum Period {
  LAST_3_MONTHS = 'LAST_3_MONTHS',
  LAST_6_MONTHS = 'LAST_6_MONTHS',
  LAST_7_DAYS = 'LAST_7_DAYS',
  LAST_12_MONTHS = 'LAST_12_MONTHS',
  LAST_MONTH = 'LAST_MONTH',
  LAST_30_DAYS = 'LAST_30_DAYS',
  LAST_60_DAYS = 'LAST_60_DAYS',
  LAST_YEAR = 'LAST_YEAR',
  THIS_MONTH = 'THIS_MONTH',
  CUSTOM = 'CUSTOM',
}

export interface TSQuerySearchParams {
  panel?: string;
  buildingSystem?: string;
  categoryValue?: string;
  circuit?: string;
  equipment?: string;
  fromDate?: string;
  grouping: Grouping;
  groupingId?: string;
  meter?: string;
  period: Period;
  resolution: Resolution;
  resourceType: ResourceType;
  measurementTypes?: string;
  site: string;
  toDate?: string;
}

// this is what the API sends back for the response data:
export interface TSItemUsage {
  groupName: string;
  groupId: string;
  calcSpend?: number;
  activeEnergy?: Array<number>;
  volume?: Array<number>;
  rmsVoltage?: Array<number>;
}

// this is what the API sends back for the response:
export interface TSUsageDataResponse {
  data: Array<TSItemUsage>;
  from: string;
  to: string;
  grouping: Grouping;
  resolution: Resolution;
  measurementTypes: Array<string>;
  units: Array<string>;
  ts: Array<string>;
}

export enum ResourceType {
  ELECTRICITY = 'electricity',
  NATURAL_GAS = 'natural-gas',
  WATER = 'water',
  ALL = 'all',
}

export type TSDateRange = {
  toDate: moment.Moment;
  fromDate: moment.Moment;
};

export interface TSMetaState {
  loading: boolean;
  error: string;
}

export const initialMetaState = {
  loading: false,
  error: '',
};

export const loadingMetaState = {
  loading: true,
  error: '',
};

export interface TSListResponseMetaState extends TSMetaState {
  noResults: boolean;
  currentId: string;
}

export const initialListResponseMetaState = {
  ...initialMetaState,
  noResults: false,
  currentId: '',
};

export const successAction = (baseAction: string) => {
  return `${baseAction}_SUCCESS`;
};

export const errorAction = (baseAction: string) => {
  return `${baseAction}_ERROR`;
};
