const customerSavedQueries = {
  'd39fe31f-22ef-41f0-ab74-0b00ebb24f3b': [
    {
      id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
      customerId: 'd39fe31f-22ef-41f0-ab74-0b00ebb24f3b',
      name: 'Magnolia',
      parameters: {
        version: 'xwz',
        query: {
          fromDate: '2023-10-31T16:21:46.414Z',
          toDate: '2023-10-31T16:21:46.414Z',
          period: '',
          resolution: '1day',
          resource: 'electricity',
          grouping: 'site',
          groupingId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
          buildingSystem: [],
          equipment: [],
          categoryValue: [],
          circuit: [],
          site: [],
          meter: [],
        },
      },
    },
  ],
};

export const savedQueries = (customerId) => {
  return {
    results: customerSavedQueries[customerId] ?? [],
  };
};
