import { DefaultTheme } from "styled-components";

export const colors = {
  primary100: '#485DA0',
  primary80: '#6D7DB3',
  primary80a: 'rgba(109 125 179 / 40%)',
  primary60: '#919EC6',
  primary40: '#B6B3D9',
  primary20: '#DADFEC',
  primary16: '#DEE4F8',
  primary10: '#EDEFF5',
  secondary100: '#9DBD79',
  secondary80: '#B1C894',
  secondary60: '#74D7AF',
  secondary40: '#D8E5C9',
  secondary20: '#EBF2E4',
  deepBlue100: '#162447',
  deepBlue80: '#45506C',
  deepBlue60: '#737C91',
  deepBlue40: '#A2A7B5',
  deepBlue20: '#D0D3DA',
  success100: '#F2A91C',
  success80: '#F5BA49',
  success60: '#F7CB77',
  success40: '#FADDA4',
  success20: '#FCEED2',
  red100: '#C02F2F',
  red80: '#CD5959',
  red60: '#D98282',
  red40: '#E6ACAC',
  red20: '#F2D5D5',
  lightGrey100: '#E0E0E0',
};

const theme: DefaultTheme = {
  colors: {
    background: '#f5f5f5',
    emphasized: colors.success100,
    error: colors.red100,
    errorHover: colors.red20,
    focused: colors.primary20,
    fontMain: colors.deepBlue100,
    fontSecondary: colors.primary80,
    messageError: colors.red100,
    messageWarning: colors.red60,
    messageInfo: colors.success60,
    primary: colors.primary100,
    primaryLight: colors.primary16,
    primaryLighter: colors.primary10,
    secondary: colors.secondary100,
    secondaryFocused: colors.secondary20,
    secondaryLight: colors.secondary40,
    spinnerColor: colors.primary100,
    spinnerBackgroundColor: colors.primary40,
    waterConsumptionColor: colors.primary40,
    gasConsumptionColor: colors.success20,
    paginationNextColor: colors.primary100,
    pageNumberActiveColor: colors.success100,
    switchButtonColor: colors.secondary100,
    focus: colors.primary80a,
    barPrimary: colors.primary100,
    barSecondary: colors.primary60,
    borderColor: colors.lightGrey100,
  },
  fontFamily: "'Public Sans', sans-serif",
  secondaryFontFamily: 'Montserrat',
};

export default theme;
