import { UilSyncExclamation } from '@iconscout/react-unicons';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import styled from 'styled-components';
import { getDateRange, getRequestMonthRange } from '../../api/utils';
import Grid from '../../components/Grid';
import CalendarWithTime from '../../components/Icons/CalendarWithTime';
import ESNoDataIcon from '../../components/Icons/ESNoDataIcon';
import ListSelector from '../../components/ListSelector';
import MetricCard from '../../components/MetricCard';
import RedaptiveReactTable7, {
  DefaultColumnFilter,
  ReactTableWrapperStyles,
} from '../../components/RedaptiveReactTable7';
import Spinner from '../../components/Spinner';
import Stack from '../../components/Stack';
import { SUSTAINABILITY_REPORTING_PATH } from '../../constants/paths';
import {
  ES_COORDINATOR_GROUP_ID,
  REDAPTIVE_ONE_PAGE_TITLE,
} from '../../constants/strings';
import {
  TSCustomersEntityState,
  selectCustomersEntity,
} from '../../ducks/customers';
import {
  ResourceNameEnum,
  TSEnergyStarEntityState,
  TSEnergyStarLastMonthData,
  TSMappedMeter,
  actions,
  energyStarEntity,
} from '../../ducks/energyStar/energyStar';
import { TSSiteTabs, selectSitesEntity } from '../../ducks/sites';
import { isCurrentUserRedaptiveDemoUser } from '../../utils/demo';
import { getUrlSearchParams } from '../../utils/getUrlSearchParams';
import CalenderDisabledIcon from '../../components/Icons/CalenderDisabledIcon';
import { useUserHasPermissionGroup } from '../../queries/permissions';
import { useDocumentTitle } from '@mantine/hooks';

export const SUBMISSIONS_CREATE_DATE = 9;

const MeterTypeToolipStyled = styled.div`
  text-align: left;
  p {
    margin: 0px;
  }
  ul {
    margin: 0px;
  }
`;

export const meterTypeToolip = ({
  meterList,
}: {
  meterList: TSMappedMeter[];
}) => {
  return (
    <MeterTypeToolipStyled>
      <p>
        <b>Following Submissions were made to ENERGY STAR:</b>
      </p>
      <ul>
        {meterList.map(({ name, value, date }) => (
          <li key={name}>
            <u> {value}</u> submitted for {name} on {date}.
          </li>
        ))}
      </ul>
    </MeterTypeToolipStyled>
  );
};

export const valueNotSentTooltip = () => (
  <p>
    Consumption details were not sent to ENERGY STAR. Check with{' '}
    <a className='customer-mail-link' href='mailto:support@redaptiveinc.com'>
      Redaptive Customer Care
    </a>{' '}
    for details.
  </p>
);

export const ALL_VALUE = { name: 'All', id: 'all' };

const NoDataWrapper = styled.div`
  border-radius: 8px;
  height: 400px;
  border: 1px solid #e0e0e0;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  svg {
    margin-bottom: 20px;
  }
  p {
    margin: 0px;
    color: #6c6d6e;
    font-size: 14px;
    font-weight: 600;
  }
  b {
    color: #162447;
    font-size: 14px;
    font-weight: 600;
    text-decoration-line: underline;
  }
`;

const StyledToolip = styled(Tooltip)`
  z-index: 100;
`;

const ListSelectorStyled = styled(ListSelector)`
  text-align: left;
`;

const TableCardTitleStyled = styled.h2`
  color: ${({ theme }) => theme.colors.fontMain};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 14px;
  font-weight: 600;
  padding-left: 30px;
  position: absolute;
  margin-top: 24px;
`;

export const CellStyled = styled.div`
  color: #162447;
  font-size: 14px;
  font-family: Public Sans;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0.28px;
`;

export const CellStyledBold = styled(CellStyled)`
  font-weight: 600;
`;

export const TableWrapper = styled.div<{
  isMenuOpen?: boolean;
  resourceLength?: number;
}>`
  position: relative;
  ${ReactTableWrapperStyles} {
    ${({ isMenuOpen, resourceLength }) =>
      isMenuOpen
        ? `min-height: ${Math.max(resourceLength || 0, 2) * 125}px;`
        : ''}
  }

  .customer-mail-link {
    color: white;
    text-decoration: underline;
    font-weight: bold;
  }
`;

const MetricValueStyled = styled.div`
  font-size: 20px;
  span {
    font-size: 16px;
  }
`;

const MetricCardsGrid = ({
  data,
}: {
  data: TSEnergyStarLastMonthData | null;
}) => {
  const currentDate = new Date();
  const showZeroValue =
    currentDate.getUTCDate() < SUBMISSIONS_CREATE_DATE &&
    !isCurrentUserRedaptiveDemoUser()
      ? 0
      : null;
  const subtitle = `For: ${moment().subtract(1, 'month').format(' MMM YYYY')}`;
  const dataArray = [
    {
      title: 'Latest Submission Date',
      value: data?.latestSubmissionDate
        ? moment(data?.latestSubmissionDate).format('MMM DD, YYYY')
        : '-',
    },
    {
      title: 'Electric Meters Submitted',
      value:
        showZeroValue ?? (
          <MetricValueStyled>
            {data?.sitesSubmittedForElectricity}
            <span>/{data?.totalSitesForElectricity}</span>
          </MetricValueStyled>
        ) ??
        '-',
      subtitle,
      valueTooltip: `${data?.sitesSubmittedForElectricity ?? 0} submitted`,
    },
    {
      title: 'Water Meters Submitted',
      value:
        showZeroValue ?? (
          <MetricValueStyled>
            {data?.sitesSubmittedForWater}
            <span>/{data?.totalSitesForWater}</span>
          </MetricValueStyled>
        ) ??
        '-',
      subtitle,
      valueTooltip: `${data?.sitesSubmittedForWater ?? 0} submitted`,
    },
    {
      title: 'Gas Meters Submitted',
      value:
        showZeroValue ?? (
          <MetricValueStyled>
            {data?.sitesSubmittedForGas}
            <span>/{data?.totalSitesForGas}</span>
          </MetricValueStyled>
        ) ??
        '-',
      subtitle,
      valueTooltip: `${data?.sitesSubmittedForGas ?? 0} submitted`,
    },
  ];
  return (
    <Grid colsLg={4}>
      {dataArray.map(({ title, value, subtitle, valueTooltip }) => (
        <MetricCard
          key={title}
          title={title}
          subtitle={subtitle}
          value={value}
          valueTooltip={valueTooltip}
        />
      ))}
    </Grid>
  );
};

const ESGReporting = () => {
  useDocumentTitle(`Sustainability Reporting | ${REDAPTIVE_ONE_PAGE_TITLE}`);

  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  // The serialize function here would be responsible for
  // creating an object of { key: value } pairs from the
  // fields in the form that make up the query.
  const serializeFormQuery = useMemo(() => {
    return Object.fromEntries(searchParams);
  }, [searchParams]);

  const { items: sites } = useSelector(selectSitesEntity) || {};

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const { data: isESCoordinator } = useUserHasPermissionGroup(
    ES_COORDINATOR_GROUP_ID
  );

  const {
    customerStatus: { isEnabled, numberOfPendingProperties },
    meta: { loading, secondaryLoading },
    summaryData: { mappedData, lastMonthData, listOfMonths },
  }: TSEnergyStarEntityState = useSelector(energyStarEntity);

  const {
    currentCustomerId,
    meta: { loading: customersLoading },
  }: TSCustomersEntityState = useSelector(selectCustomersEntity);
  const navigate = useNavigate();

  const availableResourceTypes = useMemo(() => {
    const uniqueResourceNamesSet = new Set(
      mappedData.map(({ resourceName }) => resourceName)
    );
    const uniqueResourceNamesArray = Array.from(uniqueResourceNamesSet);
    return uniqueResourceNamesArray;
  }, [mappedData]);

  useEffect(() => {
    if (!loading && !isEnabled) navigate('/');
  }, [isEnabled, loading, navigate]);

  useEffect(() => {
    const { fromDate, toDate, period } = serializeFormQuery;
    const { startDate, endDate } = getDateRange(fromDate, toDate, period);
    const { from, to } = getRequestMonthRange(
      startDate.toString(),
      endDate.toString()
    );

    if (currentCustomerId && isEnabled) {
      dispatch(
        actions.fetchSubmissionData({
          startYearMonth: from,
          endYearMonth: to,
          customerId: currentCustomerId,
        })
      );
    }
  }, [currentCustomerId, dispatch, serializeFormQuery, isEnabled]);

  useEffect(() => {
    if (!customersLoading && currentCustomerId && isEnabled) {
      dispatch(actions.fetchSummaryData({ customerId: currentCustomerId }));
    }
  }, [currentCustomerId, customersLoading, dispatch, isEnabled]);

  useEffect(() => {
    if (Object.keys(serializeFormQuery).length === 0) {
      navigate(
        `${SUSTAINABILITY_REPORTING_PATH}?${getUrlSearchParams(
          { ...serializeFormQuery },
          TSSiteTabs.PORTFOLIO_CONSUMPTION,
          sites,
          false
        )}`
      );
    }
  }, [navigate, serializeFormQuery, sites]);

  const DropdownFilter = useCallback(
    ({ column: { filterValue, setFilter } }) => {
      const resourceTypes = Object.entries(ResourceNameEnum)
        .filter(([, key]) => availableResourceTypes.includes(key as any))
        .map(([value, key]) => ({
          name: key,
          id: value,
        }));

      const selectedItem = resourceTypes.find(
        ({ id }) => id === filterValue?.value
      );

      return (
        <ListSelectorStyled
          unsettable={false}
          searchable={false}
          selectedItem={selectedItem || ALL_VALUE}
          items={[ALL_VALUE, ...resourceTypes]}
          updateValue={setFilter}
          gainsightTagId='sustainability-table-resource-filter'
          onMenuOpen={() => setIsMenuOpen(true)}
          onMenuClose={() => setIsMenuOpen(false)}
        />
      );
    },
    [availableResourceTypes]
  );

  const resourceTypeFilterFunction = useCallback(
    (rows, rowId, filterValue) => {
      dispatch(actions.setSelectedResource(filterValue));
      if (filterValue?.label === ALL_VALUE.name) return rows;
      return rows.filter(
        (row) => row.original.resourceName === filterValue?.label
      );
    },
    [dispatch]
  );

  const siteNameFilterFunction = useCallback(
    (rows, rowId, filterValue) => {
      dispatch(actions.setSearchText(filterValue));
      if (!filterValue || !filterValue.length) return rows;
      return rows.filter((row) =>
        row.original.siteName.toLowerCase().includes(filterValue.toLowerCase())
      );
    },
    [dispatch]
  );

  const renderMonthCellValue = ({
    value,
    id,
    submissionNotGenerated,
    submissionIsInvalid,
    tooltipContent,
  }) => {
    const renderValue = () => {
      if (submissionIsInvalid) return <CalenderDisabledIcon />;
      if (submissionNotGenerated) return <CalendarWithTime />;
      if (value) return value;
      return <UilSyncExclamation color='#D42E2E99' />;
    };
    return (
      <>
        <CellStyled id={id} data-tooltip-id={value}>
          {renderValue()}
        </CellStyled>
        {tooltipContent && (
          <StyledToolip
            style={{ maxWidth: '360px', textAlign: 'center' }}
            anchorSelect={`#${id}`}
            content={tooltipContent}
            clickable
          />
        )}
      </>
    );
  };

  const getColumns = useMemo(() => {
    const customSort = (rowA, rowB, columnId) => {
      const nameA =
        rowA.original[columnId].originalValue ?? Number.MIN_SAFE_INTEGER;
      const nameB =
        rowB.original[columnId].originalValue ?? Number.MIN_SAFE_INTEGER;

      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    };

    const columns: any[] = [
      {
        accessor: 'siteName',
        Header: 'Site',
        gainsightTagId: 'sustainability-table-site-filter',
        Cell: ({ value }: { value: string }) => {
          return <CellStyledBold>{value}</CellStyledBold>;
        },
        Filter: DefaultColumnFilter,
        filter: siteNameFilterFunction,
        locked: true,
      },
      {
        accessor: 'resourceName',
        Header: 'Resource',
        Cell: ({ value }: { value: string }) => {
          return <CellStyled>{value}</CellStyled>;
        },
        Filter: DropdownFilter,
        filter: resourceTypeFilterFunction,
      },
    ];
    listOfMonths.forEach((month) => {
      columns.push({
        accessor: month,
        Header: month,
        Cell: ({ value }: { value: string; row: any; column: any }) => {
          return renderMonthCellValue(value as any);
        },
        Filter: <></>,
        sortType: customSort,
        className: 'align-right',
      });
    });
    return columns;
  }, [
    DropdownFilter,
    listOfMonths,
    resourceTypeFilterFunction,
    siteNameFilterFunction,
  ]);

  const renderNoDataBox = () => {
    return (
      <NoDataWrapper h='400px' justify='center' align='center'>
        <ESNoDataIcon />
        <p>No sites available.</p>

        {isESCoordinator && (
          <p>
            You have{' '}
            <Link to='connect-properties'>
              <b>{numberOfPendingProperties} properties to connect.</b>
            </Link>
          </p>
        )}
      </NoDataWrapper>
    );
  };

  return (
    <Stack>
      {secondaryLoading || lastMonthData === null ? (
        <Spinner size='sm' centered />
      ) : (
        <MetricCardsGrid data={lastMonthData} />
      )}
      {loading && <Spinner size='sm' centered />}
      {!loading && !!mappedData?.length && (
        <TableWrapper
          isMenuOpen={isMenuOpen}
          resourceLength={availableResourceTypes?.length}
        >
          <TableCardTitleStyled>
            Submitted Monthly Consumption Per Site
          </TableCardTitleStyled>
          <RedaptiveReactTable7
            data={mappedData}
            columns={getColumns ?? []}
            showTableScrollArrows={true}
            autoResetExpanded={false}
            globalFilterable={false}
            defaultSort={[{ id: 'site' }]}
            enablePagination
            filterable
          />
        </TableWrapper>
      )}
      {!loading && !mappedData?.length && renderNoDataBox()}
    </Stack>
  );
};

export default ESGReporting;
