/* eslint-disable @typescript-eslint/no-unused-vars */
import moment from 'moment-timezone';
import { combineReducers } from 'redux';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { handleSagaError } from '../../api/utils';
import { TSState } from '../../reducers/rootReducer';
import { numberCompareTo } from '../../utils';
import {
  getWeekendRanges,
  getXAxisTickIntervals,
} from '../../utils/chartHelper';
import { filterDemoCircuitData } from '../../utils/demo';
import PeakPowerChartHelper, {
  TSAverageUnit,
  TSGroupAvg,
  TSPeakAndDaysPeakPower,
} from '../../utils/peakPowerChartHelper';

import { TSSite, selectSitesEntity } from '../sites';
import {
  Grouping,
  Resolution,
  ResourceType,
  TSItemUsage,
  TSMetaState,
  TSQuerySearchParams,
  TSUsageDataResponse,
} from '../types';

import { API } from '../usageByCustomer/utils';

export type TSRange = {
  from: number;
  to: number;
};

export interface TSGetCircuitData extends TSQuerySearchParams {
  customerId: string;
  tz: string;
}

export interface TSGroupUsageEnhanced {
  groupName: string;
  groupId: string;
  calcSpend: number;
  usage: Array<number>;
  kWUsage: Array<number>;
  totalUsage?: number; // calculating this after data is returned
  totalOutUsage?: number;
  totalInUsage?: number;
}

interface TSUsageDataEnhancedForCharts extends TSUsageDataResponse {
  max: number;
  maxTs: string;
  min: number;
  minTs: string;
  totalUsage: number;
  largestConsumerName: string;
  calcSpendTotal: number;
  weekends: Array<TSRange>;
  missingData: Array<TSRange>;
  xAxisMajorTickInterval: number;
  xAxisMinorTickInterval: number;
  operatingHoursPreparedData: Array<any>;
  operatingHoursTotalsActive: number;
  operatingHoursTotalsInactive: number;
  largestConsumerOutHoursName: string;
  peakAndDaysPeakPower: TSPeakAndDaysPeakPower;
  groupsAvgPower: TSGroupAvg[];
  groupsAvgPowerKWh: TSGroupAvg[];
  data: Array<TSGroupUsageEnhanced>;
}

interface TSCircuitDataMetaState extends TSMetaState {
  noUsageData: boolean;
}

export interface TSCircuitDataState {
  items: Array<TSGroupUsageEnhanced>;
  summary: TSUsageDataEnhancedForCharts;
  meta: TSCircuitDataMetaState;
}

// this represents a data point passed to the Operating Hours heatmap
export interface OperatingHoursChartDataPoint {
  x: number;
  y: number;
  value: number;
  color?: string; // these are added afterward;
  day: string; // the y-val is the y-axis day, we also need actual day of week for shift info
}

export const types = {
  FETCH_CIRCUIT_DATA: 'FETCH_CIRCUIT_DATA',
  FETCH_CIRCUIT_DATA_SUCCESS: 'FETCH_CIRCUIT_DATA_SUCCESS',
  FETCH_CIRCUIT_DATA_ERROR: 'FETCH_CIRCUIT_DATA_ERROR',
  FETCH_CIRCUIT_DATA_SUMMARY_SUCCESS: 'FETCH_CIRCUIT_DATA_SUMMARY_SUCCESS',
};

export const enhanceGroupUsageResponses = (
  groups: TSItemUsage[],
  resourceType: ResourceType
): TSGroupUsageEnhanced[] => {
  return groups
    ? groups.map((group) => enhanceGroupUsageResponse(group, resourceType))
    : [];
};

export const enhanceGroupUsageResponse = (
  group: TSItemUsage,
  resourceType: ResourceType
): TSGroupUsageEnhanced => {
  const { activeEnergy, volume, ...rest } = group;
  const usage =
    (resourceType == ResourceType.ELECTRICITY ? activeEnergy : volume) || [];
  return {
    ...rest,
    usage,
    kWUsage: usage.map((value) => value * 4), // convert to kW
  } as TSGroupUsageEnhanced;
};

export const actions = {
  fetchCircuitData: (payload: TSGetCircuitData): any => ({
    type: types.FETCH_CIRCUIT_DATA,
    ...payload,
  }),
};

export const initialState: TSCircuitDataState = {
  items: [],
  summary: {
    ts: [],
    grouping: Grouping.SITE,
    from: '',
    to: '',
    measurementTypes: [],
    units: [],
    resolution: Resolution.DAILY,
    data: [],
    max: 0,
    maxTs: '',
    min: 0,
    minTs: '',
    totalUsage: 0,
    largestConsumerName: '',
    calcSpendTotal: 0,
    weekends: [],
    missingData: [],
    xAxisMajorTickInterval: 0,
    xAxisMinorTickInterval: 0,
    operatingHoursPreparedData: [],
    operatingHoursTotalsActive: 0,
    operatingHoursTotalsInactive: 0,
    largestConsumerOutHoursName: '',
    peakAndDaysPeakPower: {
      peak: { ts: '', value: 0, average: 0 },
      daysPeak: new Map(),
    },
    groupsAvgPower: [], // kW
    groupsAvgPowerKWh: [], // kWh
  },
  meta: {
    loading: true,
    error: '',
    noUsageData: true,
  },
};

function entityItems(action, state) {
  const newItems: Array<TSGroupUsageEnhanced> = Object.values(action.payload);
  return state
    .filter(
      (item) => !newItems.find((newItem) => newItem.groupId === item.groupId)
    )
    .concat(newItems);
}

function items(state = initialState.items, action) {
  switch (action.type) {
    case types.FETCH_CIRCUIT_DATA:
      return initialState.items;
    case types.FETCH_CIRCUIT_DATA_SUCCESS:
      return entityItems(action, state);
    default:
      return state;
  }
}

function summary(state = initialState.summary, action) {
  switch (action.type) {
    case types.FETCH_CIRCUIT_DATA_SUMMARY_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

function meta(state = initialState.meta, action) {
  switch (action.type) {
    case types.FETCH_CIRCUIT_DATA:
      return {
        ...state,
        error: '',
        loading: true,
        noUsageData: true,
      };
    case types.FETCH_CIRCUIT_DATA_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case types.FETCH_CIRCUIT_DATA_SUCCESS:
      return {
        ...state,
        error: '',
        loading: false,
        noUsageData: !action.payload[0],
      };

    default:
      return state;
  }
}

export default combineReducers({
  items,
  summary,
  meta,
});

export const selectCircuitsDataEntity = (state: TSState): TSCircuitDataState =>
  state.entities.circuitData;

export const enhanceSummaryCircuitData = ({
  response,
  requestParams,
  sitesById,
}: {
  response: TSUsageDataResponse;
  requestParams: TSGetCircuitData;
  sitesById: {
    [id: string]: TSSite;
  };
}): TSUsageDataEnhancedForCharts => {
  const { data, ...circuitDataSummaryResponse } = response;
  const ts = response.ts || [];
  const { resolution, from, to } = circuitDataSummaryResponse;
  const { tz: siteTimezone, resourceType } = requestParams;
  let totalUsage = 0;
  let calcSpendTotal = 0;
  let minValue, maxValue;
  let minValueTs, maxValueTs;
  let largestConsumerName = '';
  let largestConsumerTotal = 0;
  let weekends: Array<TSRange> = [];

  const operatingHoursPreparedData: Array<OperatingHoursChartDataPoint> = [];

  const largestConsumerOutHoursName = '';
  const operatingHoursTotals = {
    active: 0,
    inactive: 0,
  };
  let peakAndDaysPeakPower = {} as TSPeakAndDaysPeakPower;
  let groupsAvgPower = [] as TSGroupAvg[];
  let groupsAvgPowerKWh = [] as TSGroupAvg[];
  const missingData: Array<TSRange> = [];
  const momentFrom = moment.tz(from, siteTimezone);
  const momentTo = moment.tz(to, siteTimezone);

  // create enhanced group data from the original group data:
  const enhancedGroups = enhanceGroupUsageResponses(
    data,
    requestParams.resourceType
  );

  enhancedGroups.forEach((group) => {
    const groupsTotal = group.usage.reduce((acc, cur) => acc + cur, 0);
    group.totalUsage = groupsTotal; // set this for later use
    totalUsage += groupsTotal;
    calcSpendTotal += group.calcSpend;

    if (largestConsumerTotal < groupsTotal) {
      largestConsumerTotal = groupsTotal;
      largestConsumerName = group.groupName;
    }
  });

  if (ts && ts.length) {
    ts.forEach((timestamp, index) => {
      // get usage total by current timestamp for all groups
      const usageByTsTotal = enhancedGroups
        .map((group) => group.usage[index])
        .filter((item) => item) // avoid undefined values
        .reduce((acc, cur) => acc + cur, 0);

      if (!minValue || usageByTsTotal < minValue) {
        minValue = usageByTsTotal;
        minValueTs = timestamp;
      }
      if (usageByTsTotal > maxValue) {
        maxValue = usageByTsTotal;
        maxValueTs = timestamp;
      }
    });
  }

  weekends = getWeekendRanges(ts);

  const { xAxisMajorTickInterval, xAxisMinorTickInterval } =
    getXAxisTickIntervals(momentFrom, momentTo, resolution);

  // reverse (desc) sort by total usage:
  enhancedGroups.sort((g1, g2) =>
    numberCompareTo(g2?.totalUsage || 0, g1?.totalUsage || 0)
  );

  // enhance data for peak power
  peakAndDaysPeakPower = PeakPowerChartHelper.getPeakAndDaysPeak(
    ts,
    enhancedGroups,
    siteTimezone,
    resourceType
  );
  groupsAvgPower = PeakPowerChartHelper.groupsAverage(
    ts,
    enhancedGroups,
    TSAverageUnit.KW
  );
  groupsAvgPowerKWh = PeakPowerChartHelper.groupsAverage(
    ts,
    enhancedGroups,
    TSAverageUnit.kWh
  );

  // reverse (desc) sort by max peak-power usage:
  groupsAvgPower.sort((g1, g2) => numberCompareTo(g2?.max || 0, g1?.max || 0));

  return {
    ...circuitDataSummaryResponse,
    data: enhancedGroups,
    totalUsage: totalUsage,
    min: minValue || 0,
    max: maxValue || 0,
    minTs: minValueTs || '',
    maxTs: maxValueTs || '',
    largestConsumerName,
    calcSpendTotal,
    weekends: weekends,
    missingData: missingData,
    ts,
    xAxisMajorTickInterval,
    xAxisMinorTickInterval,
    operatingHoursTotalsActive: operatingHoursTotals['active'],
    operatingHoursTotalsInactive: operatingHoursTotals['inactive'],
    largestConsumerOutHoursName,
    operatingHoursPreparedData,
    peakAndDaysPeakPower,
    groupsAvgPower,
    groupsAvgPowerKWh,
  };
};

function* fetchCircuitDataSaga({
  type,
  ...payload
}: TSGetCircuitData & { type: string }): Generator<any, void, any> {
  const { byId } = yield select(selectSitesEntity);
  try {
    const circuitsData: TSUsageDataResponse = yield call(
      API.fetchUsageData,
      payload
    );
    // pass through demo filter, will apply any relevant filters if this is a demo user
    // do the filter before enhancing to make sure any masked fields used in the enhance are covered
    filterDemoCircuitData(circuitsData);
    const enhancedResponse = enhanceSummaryCircuitData({
      response: circuitsData,
      requestParams: payload,
      sitesById: byId,
    });

    yield put({
      type: types.FETCH_CIRCUIT_DATA_SUMMARY_SUCCESS,
      payload: enhancedResponse,
    });
    yield put({
      type: types.FETCH_CIRCUIT_DATA_SUCCESS,
      payload: enhancedResponse.data,
    });
  } catch (e) {
    yield handleSagaError(types.FETCH_CIRCUIT_DATA_ERROR, e as Error);
  }
}

export const sagas = [
  takeLatest(types.FETCH_CIRCUIT_DATA, fetchCircuitDataSaga),
];
