import { Grouping, Resolution, TSUsageDataResponse } from '../../ducks/types';

const voltageResponse: TSUsageDataResponse = {
  ts: [
    '2023-08-01T00:00:00',
    '2023-08-01T00:15:00',
    '2023-08-01T00:30:00',
    '2023-08-01T00:45:00',
    '2023-08-01T01:00:00',
    '2023-08-01T01:15:00',
    '2023-08-01T01:30:00',
    '2023-08-01T01:45:00',
    '2023-08-01T02:00:00',
    '2023-08-01T02:15:00',
    '2023-08-01T02:30:00',
    '2023-08-01T02:45:00',
    '2023-08-01T03:00:00',
    '2023-08-01T03:15:00',
    '2023-08-01T03:30:00',
    '2023-08-01T03:45:00',
    '2023-08-01T04:00:00',
    '2023-08-01T04:15:00',
    '2023-08-01T04:30:00',
    '2023-08-01T04:45:00',
    '2023-08-01T05:00:00',
    '2023-08-01T05:15:00',
    '2023-08-01T05:30:00',
    '2023-08-01T05:45:00',
    '2023-08-01T06:00:00',
    '2023-08-01T06:15:00',
    '2023-08-01T06:30:00',
    '2023-08-01T06:45:00',
    '2023-08-01T07:00:00',
    '2023-08-01T07:15:00',
    '2023-08-01T07:30:00',
    '2023-08-01T07:45:00',
    '2023-08-01T08:00:00',
    '2023-08-01T08:15:00',
    '2023-08-01T08:30:00',
    '2023-08-01T08:45:00',
    '2023-08-01T09:00:00',
    '2023-08-01T09:15:00',
    '2023-08-01T09:30:00',
    '2023-08-01T09:45:00',
    '2023-08-01T10:00:00',
    '2023-08-01T10:15:00',
    '2023-08-01T10:30:00',
    '2023-08-01T10:45:00',
    '2023-08-01T11:00:00',
    '2023-08-01T11:15:00',
    '2023-08-01T11:30:00',
    '2023-08-01T11:45:00',
    '2023-08-01T12:00:00',
    '2023-08-01T12:15:00',
    '2023-08-01T12:30:00',
    '2023-08-01T12:45:00',
    '2023-08-01T13:00:00',
    '2023-08-01T13:15:00',
    '2023-08-01T13:30:00',
    '2023-08-01T13:45:00',
    '2023-08-01T14:00:00',
    '2023-08-01T14:15:00',
    '2023-08-01T14:30:00',
    '2023-08-01T14:45:00',
    '2023-08-01T15:00:00',
    '2023-08-01T15:15:00',
    '2023-08-01T15:30:00',
    '2023-08-01T15:45:00',
    '2023-08-01T16:00:00',
    '2023-08-01T16:15:00',
    '2023-08-01T16:30:00',
    '2023-08-01T16:45:00',
    '2023-08-01T17:00:00',
    '2023-08-01T17:15:00',
    '2023-08-01T17:30:00',
    '2023-08-01T17:45:00',
    '2023-08-01T18:00:00',
    '2023-08-01T18:15:00',
    '2023-08-01T18:30:00',
    '2023-08-01T18:45:00',
    '2023-08-01T19:00:00',
    '2023-08-01T19:15:00',
    '2023-08-01T19:30:00',
    '2023-08-01T19:45:00',
    '2023-08-01T20:00:00',
    '2023-08-01T20:15:00',
    '2023-08-01T20:30:00',
    '2023-08-01T20:45:00',
    '2023-08-01T21:00:00',
    '2023-08-01T21:15:00',
    '2023-08-01T21:30:00',
    '2023-08-01T21:45:00',
    '2023-08-01T22:00:00',
    '2023-08-01T22:15:00',
    '2023-08-01T22:30:00',
    '2023-08-01T22:45:00',
    '2023-08-01T23:00:00',
    '2023-08-01T23:15:00',
    '2023-08-01T23:30:00',
    '2023-08-01T23:45:00',
    '2023-08-02T00:00:00',
    '2023-08-02T00:15:00',
    '2023-08-02T00:30:00',
    '2023-08-02T00:45:00',
    '2023-08-02T01:00:00',
    '2023-08-02T01:15:00',
    '2023-08-02T01:30:00',
    '2023-08-02T01:45:00',
    '2023-08-02T02:00:00',
    '2023-08-02T02:15:00',
    '2023-08-02T02:30:00',
    '2023-08-02T02:45:00',
    '2023-08-02T03:00:00',
    '2023-08-02T03:15:00',
    '2023-08-02T03:30:00',
    '2023-08-02T03:45:00',
    '2023-08-02T04:00:00',
    '2023-08-02T04:15:00',
    '2023-08-02T04:30:00',
    '2023-08-02T04:45:00',
    '2023-08-02T05:00:00',
    '2023-08-02T05:15:00',
    '2023-08-02T05:30:00',
    '2023-08-02T05:45:00',
    '2023-08-02T06:00:00',
    '2023-08-02T06:15:00',
    '2023-08-02T06:30:00',
    '2023-08-02T06:45:00',
    '2023-08-02T07:00:00',
    '2023-08-02T07:15:00',
    '2023-08-02T07:30:00',
    '2023-08-02T07:45:00',
    '2023-08-02T08:00:00',
    '2023-08-02T08:15:00',
    '2023-08-02T08:30:00',
    '2023-08-02T08:45:00',
    '2023-08-02T09:00:00',
    '2023-08-02T09:15:00',
    '2023-08-02T09:30:00',
    '2023-08-02T09:45:00',
    '2023-08-02T10:00:00',
    '2023-08-02T10:15:00',
    '2023-08-02T10:30:00',
    '2023-08-02T10:45:00',
    '2023-08-02T11:00:00',
    '2023-08-02T11:15:00',
    '2023-08-02T11:30:00',
    '2023-08-02T11:45:00',
    '2023-08-02T12:00:00',
    '2023-08-02T12:15:00',
    '2023-08-02T12:30:00',
    '2023-08-02T12:45:00',
    '2023-08-02T13:00:00',
    '2023-08-02T13:15:00',
    '2023-08-02T13:30:00',
    '2023-08-02T13:45:00',
    '2023-08-02T14:00:00',
    '2023-08-02T14:15:00',
    '2023-08-02T14:30:00',
    '2023-08-02T14:45:00',
    '2023-08-02T15:00:00',
    '2023-08-02T15:15:00',
    '2023-08-02T15:30:00',
    '2023-08-02T15:45:00',
    '2023-08-02T16:00:00',
    '2023-08-02T16:15:00',
    '2023-08-02T16:30:00',
    '2023-08-02T16:45:00',
    '2023-08-02T17:00:00',
    '2023-08-02T17:15:00',
    '2023-08-02T17:30:00',
    '2023-08-02T17:45:00',
    '2023-08-02T18:00:00',
    '2023-08-02T18:15:00',
    '2023-08-02T18:30:00',
    '2023-08-02T18:45:00',
    '2023-08-02T19:00:00',
    '2023-08-02T19:15:00',
    '2023-08-02T19:30:00',
    '2023-08-02T19:45:00',
    '2023-08-02T20:00:00',
    '2023-08-02T20:15:00',
    '2023-08-02T20:30:00',
    '2023-08-02T20:45:00',
    '2023-08-02T21:00:00',
    '2023-08-02T21:15:00',
    '2023-08-02T21:30:00',
    '2023-08-02T21:45:00',
    '2023-08-02T22:00:00',
    '2023-08-02T22:15:00',
    '2023-08-02T22:30:00',
    '2023-08-02T22:45:00',
    '2023-08-02T23:00:00',
    '2023-08-02T23:15:00',
    '2023-08-02T23:30:00',
    '2023-08-02T23:45:00',
  ],
  grouping: Grouping.BUILDING_SYSTEM,
  from: '2023-08-01T00:00:00',
  to: '2023-08-02T23:59:59',
  resolution: Resolution.MINUTES,
  data: [
    {
      groupName: 'LP-1 GROUP 1',
      groupId: '06fd5f1d-c63c-4994-9f7d-ab57e7f8c277',
      rmsVoltage: [
        844.48, 844.15, 841.45, 835.83, 835.48, 835.18, 830.87, 829.86, 829.29,
        827.85, 828.32, 828.18, 828.29, 826.5, 827.42, 824.87, 824.41, 826.0,
        827.11, 825.24, 825.03, 824.71, 827.89, 826.75, 825.94, 825.2, 826.45,
        831.19, 829.82, 832.0, 833.4, 834.24, 833.02, 834.26, 833.03, 833.34,
        833.33, 834.17, 834.99, 835.4, 836.5, 836.95, 849.12, 850.1, 850.98,
        849.27, 850.38, 848.99, 849.54, 849.39, 849.23, 849.33, 849.68, 848.69,
        848.14, 848.77, 848.05, 847.64, 846.13, 845.37, 824.79, 821.29, 819.85,
        816.95, 814.88, 812.74, 810.54, 811.56, 810.78, 811.8, 814.09, 816.3,
        814.85, 816.13, 813.96, 813.55, 814.09, 814.13, 813.96, 813.52, 813.55,
        816.27, 817.99, 821.32, 823.57, 823.02, 825.32, 828.58, 830.69, 830.31,
        831.81, 827.4, 828.94, 827.29, 830.71, 833.28, 844.48, 844.15, 841.45,
        835.83, 835.48, 835.18, 830.87, 829.86, 829.29, 827.85, 828.32, 828.18,
        828.29, 826.5, 827.42, 824.87, 824.41, 826.0, 827.11, 825.24, 825.03,
        824.71, 827.89, 826.75, 825.94, 825.2, 826.45, 831.19, 829.82, 832.0,
        833.4, 834.24, 833.02, 834.26, 833.03, 833.34, 833.33, 834.17, 834.99,
        835.4, 836.5, 836.95, 849.12, 850.1, 850.98, 849.27, 850.38, 848.99,
        849.54, 849.39, 849.23, 849.33, 849.68, 848.69, 848.14, 848.77, 848.05,
        847.64, 846.13, 845.37, 824.79, 821.29, 819.85, 816.95, 814.88, 812.74,
        810.54, 811.56, 810.78, 811.8, 814.09, 816.3, 814.85, 816.13, 813.96,
        813.55, 814.09, 814.13, 813.96, 813.52, 813.55, 816.27, 817.99, 821.32,
        823.57, 823.02, 825.32, 828.58, 830.69, 830.31, 831.81, 827.4, 828.94,
        827.29, 830.71, 833.28,
      ],
    },
    {
      groupName: 'Exit Signs - South Tenant Space GROUP 2',
      groupId: 'caf25380-d058-4b99-8326-4b5d825399fe',
      rmsVoltage: [
        283.25, 283.49, 283.81, 283.21, 283.54, 283.12, 283.28, 283.24, 283.16,
        283.21, 283.34, 282.99, 282.82, 283.02, 282.81, 282.69, 282.16, 281.89,
        281.57, 281.54, 280.61, 278.88, 278.7, 278.59, 277.15, 276.82, 276.58,
        276.11, 276.37, 276.42, 276.46, 275.91, 276.28, 275.54, 275.3, 275.87,
        276.24, 275.75, 275.6, 275.44, 276.52, 276.16, 275.89, 275.68, 276.1,
        275.52, 274.4, 273.86, 273.01, 272.32, 271.65, 271.06, 271.37, 271.16,
        271.52, 272.3, 273.12, 272.61, 273.01, 272.28, 272.1, 272.4, 272.37,
        272.32, 272.2, 272.18, 273.12, 273.72, 274.85, 275.64, 275.39, 276.17,
        277.2, 277.85, 277.72, 278.2, 276.66, 277.16, 276.52, 277.67, 278.48,
        277.72, 277.35, 278.09, 278.55, 278.8, 278.36, 278.7, 278.33, 278.39,
        278.35, 278.57, 278.77, 278.88, 279.29, 279.38, 283.25, 283.49, 283.81,
        283.21, 283.54, 283.12, 283.28, 283.24, 283.16, 283.21, 283.34, 282.99,
        282.82, 283.02, 282.81, 282.69, 282.16, 281.89, 281.57, 281.54, 280.61,
        278.88, 278.7, 278.59, 277.15, 276.82, 276.58, 276.11, 276.37, 276.42,
        276.46, 275.91, 276.28, 275.54, 275.3, 275.87, 276.24, 275.75, 275.6,
        275.44, 276.52, 276.16, 275.89, 275.68, 276.1, 275.52, 274.4, 273.86,
        273.01, 272.32, 271.65, 271.06, 271.37, 271.16, 271.52, 272.3, 273.12,
        272.61, 273.01, 272.28, 272.1, 272.4, 272.37, 272.32, 272.2, 272.18,
        273.12, 273.72, 274.85, 275.64, 275.39, 276.17, 277.2, 277.85, 277.72,
        278.2, 276.66, 277.16, 276.52, 277.67, 278.48, 277.72, 277.35, 278.09,
        278.55, 278.8, 278.36, 278.7, 278.33, 278.39, 278.35, 278.57, 278.77,
        278.88, 279.29, 279.38,
      ],
    },
    {
      groupName:
        'Wall Heater #Electric Water Heater - 2 - Sprinkler Room GROUP 3',
      groupId: 'ee38e1aa-9e0e-4a20-9bff-10dd3243b561',
      rmsVoltage: [
        283.52, 283.92, 284.19, 283.63, 284.02, 283.51, 283.66, 283.59, 283.52,
        283.57, 283.67, 283.32, 283.09, 283.29, 283.09, 282.89, 282.4, 282.22,
        281.91, 281.74, 280.83, 279.08, 279.02, 278.95, 277.43, 277.04, 276.83,
        270.54, 271.28, 272.06, 271.6, 272.05, 271.27, 271.12, 271.35, 271.3,
        271.21, 271.08, 271.13, 271.97, 272.45, 273.56, 274.42, 274.17, 274.95,
        276.33, 276.43, 276.31, 276.35, 275.77, 276.01, 275.13, 274.94, 275.51,
        275.93, 275.07, 275.15, 275.08, 276.12, 275.82, 275.47, 275.27, 275.65,
        275.1, 273.88, 273.42, 272.42, 271.75, 270.97, 270.14, 270.48, 270.2,
        276.13, 276.92, 276.78, 277.23, 275.73, 276.29, 275.75, 276.9, 277.76,
        277.04, 276.55, 277.23, 277.72, 278.03, 277.63, 278.14, 277.71, 277.86,
        277.84, 278.14, 278.54, 278.71, 279.0, 279.21, 283.52, 283.92, 284.19,
        283.63, 284.02, 283.51, 283.66, 283.59, 283.52, 283.57, 283.67, 283.32,
        283.09, 283.29, 283.09, 282.89, 282.4, 282.22, 281.91, 281.74, 280.83,
        279.08, 279.02, 278.95, 277.43, 277.04, 276.83, 270.54, 271.28, 272.06,
        271.6, 272.05, 271.27, 271.12, 271.35, 271.3, 271.21, 271.08, 271.13,
        271.97, 272.45, 273.56, 274.42, 274.17, 274.95, 276.33, 276.43, 276.31,
        276.35, 275.77, 276.01, 275.13, 274.94, 275.51, 275.93, 275.07, 275.15,
        275.08, 276.12, 275.82, 275.47, 275.27, 275.65, 275.1, 273.88, 273.42,
        272.42, 271.75, 270.97, 270.14, 270.48, 270.2, 276.13, 276.92, 276.78,
        277.23, 275.73, 276.29, 275.75, 276.9, 277.76, 277.04, 276.55, 277.23,
        277.72, 278.03, 277.63, 278.14, 277.71, 277.86, 277.84, 278.14, 278.54,
        278.71, 279.0, 279.21,
      ],
    },
    {
      groupName: 'Feed to Panel DP-2 GROUP 4',
      groupId: '9d8e3873-9a7f-478d-9394-83cc6d5ba3cc',
      rmsVoltage: [
        626.79, 629.22, 629.1, 629.26, 627.59, 626.99, 625.96, 625.39, 626.96,
        626.15, 626.27, 626.05, 625.77, 626.96, 627.66, 627.09, 626.35, 627.55,
        699.67, 650.65, 651.72, 699.97, 651.09, 699.69, 650.27, 650.09, 699.92,
        650.02, 650.37, 699.39, 696.62, 699.95, 696.76, 696.35, 696.66, 696.07,
        629.79, 631.96, 631.56, 632.95, 626.99, 630.07, 626.39, 631.72, 639.26,
        632.12, 630.61, 632.99, 639.37, 635.17, 633.99, 635.16, 633.93, 639.21,
        695.16, 699.66, 692.26, 637.26, 636.95, 636.65, 632.29, 630.69, 630.16,
        625.69, 622.93, 620.97, 616.22, 616.13, 613.69, 611.77, 612.76, 612.01,
        613.0, 615.23, 617.59, 616.16, 617.95, 615.3, 619.63, 615.39, 615.37,
        615.29, 619.65, 619.63, 617.5, 619.16, 622.59, 629.62, 629.26, 626.59,
        639.16, 639.96, 635.77, 636.19, 637.29, 637.72, 626.79, 629.22, 629.1,
        629.26, 627.59, 626.99, 625.96, 625.39, 626.96, 626.15, 626.27, 626.05,
        625.77, 626.96, 627.66, 627.09, 626.35, 627.55, 699.67, 650.65, 651.72,
        699.97, 651.09, 699.69, 650.27, 650.09, 699.92, 650.02, 650.37, 699.39,
        696.62, 699.95, 696.76, 696.35, 696.66, 696.07, 629.79, 631.96, 631.56,
        632.95, 626.99, 630.07, 626.39, 631.72, 639.26, 632.12, 630.61, 632.99,
        639.37, 635.17, 633.99, 635.16, 633.93, 639.21, 695.16, 699.66, 692.26,
        637.26, 636.95, 636.65, 632.29, 630.69, 630.16, 625.69, 622.93, 620.97,
        616.22, 616.13, 613.69, 611.77, 612.76, 612.01, 613.0, 615.23, 617.59,
        616.16, 617.95, 615.3, 619.63, 615.39, 615.37, 615.29, 619.65, 619.63,
        617.5, 619.16, 622.59, 629.62, 629.26, 626.59, 639.16, 639.96, 635.77,
        636.19, 637.29, 637.72,
      ],
    },
    {
      groupName: 'Jockey Pump GROUP 5',
      groupId: '47c2426e-c1e0-46f3-af0e-a158c0c57e4f',
      rmsVoltage: [
        849.57, 850.53, 851.41, 849.66, 850.78, 849.4, 849.95, 849.79, 849.62,
        849.7, 850.04, 849.06, 848.51, 849.12, 848.43, 848.02, 846.47, 845.75,
        844.85, 844.55, 841.87, 836.66, 836.34, 836.03, 831.58, 830.49, 829.79,
        828.39, 828.85, 828.77, 828.94, 827.21, 828.14, 825.73, 825.16, 826.8,
        827.91, 826.06, 825.8, 825.5, 828.78, 827.63, 826.8, 826.15, 827.34,
        825.68, 822.22, 820.77, 818.01, 815.96, 813.7, 811.62, 812.6, 811.88,
        812.86, 815.08, 817.46, 816.01, 817.28, 815.12, 814.62, 815.27, 815.21,
        815.07, 814.72, 814.7, 817.38, 819.09, 822.41, 824.66, 824.07, 826.35,
        829.54, 831.72, 831.36, 832.74, 828.26, 829.88, 828.12, 831.53, 834.05,
        831.81, 830.53, 832.69, 834.07, 834.84, 833.63, 834.85, 833.65, 833.91,
        833.89, 834.71, 835.5, 835.91, 836.99, 837.45, 849.57, 850.53, 851.41,
        849.66, 850.78, 849.4, 849.95, 849.79, 849.62, 849.7, 850.04, 849.06,
        848.51, 849.12, 848.43, 848.02, 846.47, 845.75, 844.85, 844.55, 841.87,
        836.66, 836.34, 836.03, 831.58, 830.49, 829.79, 828.39, 828.85, 828.77,
        828.94, 827.21, 828.14, 825.73, 825.16, 826.8, 827.91, 826.06, 825.8,
        825.5, 828.78, 827.63, 826.8, 826.15, 827.34, 825.68, 822.22, 820.77,
        818.01, 815.96, 813.7, 811.62, 812.6, 811.88, 812.86, 815.08, 817.46,
        816.01, 817.28, 815.12, 814.62, 815.27, 815.21, 815.07, 814.72, 814.7,
        817.38, 819.09, 822.41, 824.66, 824.07, 826.35, 829.54, 831.72, 831.36,
        832.74, 828.26, 829.88, 828.12, 831.53, 834.05, 831.81, 830.53, 832.69,
        834.07, 834.84, 833.63, 834.85, 833.65, 833.91, 833.89, 834.71, 835.5,
        835.91, 836.99, 837.45,
      ],
    },
    {
      groupName: 'Feed to Panel DP-1 GROUP 6',
      groupId: '6b4278a4-3cce-4a8c-97ff-cbfd65af28ae',
      rmsVoltage: [
        850.02, 850.99, 851.87, 850.14, 851.25, 849.84, 850.43, 850.24, 850.06,
        850.16, 850.52, 849.54, 848.96, 849.6, 848.92, 848.51, 847.02, 846.25,
        845.33, 845.04, 842.39, 837.41, 837.09, 836.8, 832.44, 831.01, 830.35,
        828.9, 829.4, 829.26, 829.41, 827.68, 828.57, 826.1, 825.52, 827.11,
        828.28, 826.39, 826.19, 825.91, 829.11, 827.99, 827.19, 826.45, 827.65,
        826.0, 822.53, 821.08, 818.34, 816.24, 814.0, 811.88, 812.88, 812.12,
        813.12, 815.35, 817.72, 816.3, 817.58, 815.43, 814.95, 815.53, 815.51,
        815.36, 814.97, 814.97, 817.65, 819.32, 822.69, 824.97, 824.42, 826.66,
        829.91, 832.07, 831.68, 833.06, 828.61, 830.21, 828.46, 831.85, 834.41,
        832.23, 830.93, 833.08, 834.46, 835.26, 834.03, 835.27, 834.06, 834.34,
        834.3, 835.09, 835.89, 836.32, 837.43, 837.88, 850.02, 850.99, 851.87,
        850.14, 851.25, 849.84, 850.43, 850.24, 850.06, 850.16, 850.52, 849.54,
        848.96, 849.6, 848.92, 848.51, 847.02, 846.25, 845.33, 845.04, 842.39,
        837.41, 837.09, 836.8, 832.44, 831.01, 830.35, 828.9, 829.4, 829.26,
        829.41, 827.68, 828.57, 826.1, 825.52, 827.11, 828.28, 826.39, 826.19,
        825.91, 829.11, 827.99, 827.19, 826.45, 827.65, 826.0, 822.53, 821.08,
        818.34, 816.24, 814.0, 811.88, 812.88, 812.12, 813.12, 815.35, 817.72,
        816.3, 817.58, 815.43, 814.95, 815.53, 815.51, 815.36, 814.97, 814.97,
        817.65, 819.32, 822.69, 824.97, 824.42, 826.66, 829.91, 832.07, 831.68,
        833.06, 828.61, 830.21, 828.46, 831.85, 834.41, 832.23, 830.93, 833.08,
        834.46, 835.26, 834.03, 835.27, 834.06, 834.34, 834.3, 835.09, 835.89,
        836.32, 837.43, 837.88,
      ],
    },
    {
      groupName: 'GROUP 7',
      groupId: '7b4278a4-3cce-4a8c-97ff-cbfd65af28ae',
      rmsVoltage: [],
    },
    {
      groupName: 'GROUP 8',
      groupId: '8b4278a4-3cce-4a8c-97ff-cbfd65af28ae',
      rmsVoltage: [],
    },
    {
      groupName: 'GROUP 9',
      groupId: '9b4278a4-3cce-4a8c-97ff-cbfd65af28ae',
      rmsVoltage: [],
    },
    {
      groupName: 'GROUP 10',
      groupId: '104278a4-3cce-4a8c-97ff-cbfd65af28ae',
      rmsVoltage: [],
    },
    {
      groupName: 'GROUP 11',
      groupId: '114278a4-3cce-4a8c-97ff-cbfd65af28ae',
      rmsVoltage: [],
    },
    {
      groupName: 'GROUP 12',
      groupId: '124278a4-3cce-4a8c-97ff-cbfd65af28ae',
      rmsVoltage: [],
    },
    {
      groupName: 'GROUP 13',
      groupId: '134278a4-3cce-4a8c-97ff-cbfd65af28ae',
      rmsVoltage: [],
    },
    {
      groupName: 'GROUP 14',
      groupId: '144278a4-3cce-4a8c-97ff-cbfd65af28ae',
      rmsVoltage: [],
    },
    {
      groupName: 'GROUP 15',
      groupId: '154278a4-3cce-4a8c-97ff-cbfd65af28ae',
      rmsVoltage: [],
    },
    {
      groupName: 'GROUP 16',
      groupId: '164278a4-3cce-4a8c-97ff-cbfd65af28ae',
      rmsVoltage: [],
    },
    {
      groupName: 'GROUP 17',
      groupId: '174278a4-3cce-4a8c-97ff-cbfd65af28ae',
      rmsVoltage: [],
    },
    {
      groupName: 'GROUP 18',
      groupId: '184278a4-3cce-4a8c-97ff-cbfd65af28ae',
      rmsVoltage: [],
    },
    {
      groupName: 'GROUP 19',
      groupId: '194278a4-3cce-4a8c-97ff-cbfd65af28ae',
      rmsVoltage: [],
    },
    {
      groupName: 'GROUP 20',
      groupId: '204278a4-3cce-4a8c-97ff-cbfd65af28ae',
      rmsVoltage: [],
    },
    {
      groupName: 'GROUP 21',
      groupId: '214278a4-3cce-4a8c-97ff-cbfd65af28ae',
      rmsVoltage: [],
    },
  ],
  measurementTypes: ['rmsVoltage'],
  units: ['V'],
};

export { voltageResponse };
