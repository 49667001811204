import CheckCircleIcon from '@iconscout/react-unicons/icons/uil-check-circle';
import {
  Badge,
  ButtonProps,
  Button as MantineButton,
  createPolymorphicComponent,
  rem,
} from '@mantine/core';
import { isNumber } from 'lodash';
import VerticalBarsLoader from '../VerticalBarsLoader';
import classes from './Button.module.css';

type CustomVariants =
  | 'primary'
  | 'primary-outline'
  | 'secondary'
  | 'secondary-outline'
  | 'danger'
  | 'outline'
  | 'transparent'
  | 'filled';

interface CustomButtonProps extends ButtonProps {
  countBadge?: number;
  isSaved?: boolean;
  isSaving?: boolean;
  variant?: CustomVariants;
}

const _Button = ({
  children,
  countBadge,
  h = 40,
  isSaved = false,
  isSaving = false,
  leftSection,
  size = 'lg',
  variant = 'primary',
  ...rest
}: CustomButtonProps) => {
  const rightSection = isNumber(countBadge) && (
    <Badge variant={variant} h={rem(24)} py={0} px={8} fz={13}>
      {countBadge}
    </Badge>
  );
  const savingIcon = isSaving && (
    <VerticalBarsLoader data-testid='vertical-bars-loader' width='23px' />
  );
  const savedIcon = isSaved && (
    <CheckCircleIcon data-testid='check-circle-icon' />
  );

  return (
    <MantineButton
      className={classes.button}
      data-testid='button-component'
      disabled={isSaving}
      h={h}
      rightSection={rightSection}
      size={size}
      variant={variant}
      {...rest}
      leftSection={savingIcon || savedIcon || leftSection}
    >
      {(isSaving && 'Saving') || (isSaved && 'Saved') || children}
    </MantineButton>
  );
};

const Button = createPolymorphicComponent<'button', CustomButtonProps>(_Button);

export default Button;
