export * from './energyStarTypes';
import { combineReducers } from 'redux';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { handleSagaError } from '../../api/utils';
import {
  TSBenchmarkingDataOriginal,
  TSEnergyStarCustomerState,
  TSEnergyStarEntityState,
  TSEnergyStarSubmissionDataPayload,
  TSEnergyStarSummaryDataOriginal,
  TSState,
} from './energyStarTypes';

import {
  benchmarkingMapper,
  espmMeterMapper,
  customerStatusMapper,
  submissionsMapper,
  pendingPropertiesMapper,
  espmMeterResponseMapper,
  WPT_CUSTOMER_ID,
} from './utils/mapper';

import { ALL_VALUE } from '../../pages/Sustainability/ESGReportingPage';
import { energyStarApis as API } from './energyStarApis';
import { isCurrentUserRedaptiveDemoUser } from '../../utils/demo';
import { demoUserMonthlySummaryPayload } from './utils/demoUserMapper';

export const types = {
  FETCH_CUSTOMER_STATUS: 'FETCH_CUSTOMER_STATUS',
  FETCH_CUSTOMER_STATUS_SUCCESS: 'FETCH_CUSTOMER_STATUS_SUCCESS',
  FETCH_CUSTOMER_STATUS_ERROR: 'FETCH_CUSTOMER_STATUS_ERROR',
  FETCH_ACCOUNT_ACCEPT_DATA: 'FETCH_ACCOUNT_ACCEPT_DATA',
  FETCH_ACCOUNT_ACCEPT_DATA_SUCCESS: 'FETCH_ACCOUNT_ACCEPT_DATA_SUCCESS',
  FETCH_ACCOUNT_ACCEPT_DATA_ERROR: 'FETCH_ACCOUNT_ACCEPT_DATA_ERROR',
  FETCH_SUMMARY_DATA: 'FETCH_SUMMARY_DATA',
  FETCH_SUMMARY_DATA_SUCCESS: 'FETCH_SUMMARY_DATA_SUCCESS',
  FETCH_SUMMARY_DATA_ERROR: 'FETCH_SUMMARY_DATA_ERROR',
  FETCH_SUBMISSIONS_DATA: 'FETCH_SUBMISSIONS_DATA',
  FETCH_SUBMISSIONS_DATA_SUCCESS: 'FETCH_SUBMISSIONS_DATA_SUCCESS',
  FETCH_SUBMISSIONS_DATA_ERROR: 'FETCH_SUBMISSIONS_DATA_ERROR',
  SET_SEARCH_TEXT: 'SET_SEARCH_TEXT',
  SET_SELECTED_RESOURCE: 'SET_SELECTED_RESOURCE',
  SET_INACTIVE_PROPERTIES_NUMBER: 'SET_INACTIVE_PROPERTIES_NUMBER',
  FETCH_BENCHMARKING_DATA: 'FETCH_BENCHMARKING_DATA',
  FETCH_BENCHMARKING_DATA_SUCCESS: 'FETCH_BENCHMARKING_DATA_SUCCESS',
  FETCH_BENCHMARKING_DATA_ERROR: 'FETCH_BENCHMARKING_DATA_ERROR',
  FETCH_CONNECT_PROPERTIES_DATA: 'FETCH_CONNECT_PROPERTIES_DATA',
  FETCH_CONNECT_PROPERTIES_DATA_ERROR: 'FETCH_CONNECT_PROPERTIES_DATA_ERROR',
  FETCH_CONNECT_PROPERTIES_DATA_SUCCESS:
    'FETCH_CONNECT_PROPERTIES_DATA_SUCCESS',
  ATTACH_PROPERTIES: 'ATTACH_PROPERTIES',
  ATTACH_PROPERTIES_SUCCESS: 'ATTACH_PROPERTIES_SUCCESS',
  ATTACH_PROPERTIES_ERROR: 'ATTACH_PROPERTIES_ERROR',
  SET_ENABLE_SITE_POPUP: 'SET_ENABLE_SITE_POPUP',
  RESET_DATA: 'RESET_DATA',
};

export const actions = {
  fetchCustomerStatus: ({ customerId }: { customerId: string }) => ({
    type: types.FETCH_CUSTOMER_STATUS,
    customerId,
  }),
  fetchSummaryData: ({ customerId }: { customerId: string }) => ({
    type: types.FETCH_SUMMARY_DATA,
    customerId,
  }),
  fetchSubmissionData: ({
    startYearMonth,
    endYearMonth,
    customerId,
  }: TSEnergyStarSubmissionDataPayload) => ({
    type: types.FETCH_SUBMISSIONS_DATA,
    startYearMonth,
    endYearMonth,
    customerId,
  }),
  fetchAccountAccessData: ({ accountId }: { accountId: number }) => ({
    type: types.FETCH_ACCOUNT_ACCEPT_DATA,
    accountId,
  }),
  setSearchText: (searchText: string) => ({
    type: types.SET_SEARCH_TEXT,
    searchText,
  }),
  setSelectedResource: (selectedResource: Record<string, string>) => ({
    type: types.SET_SELECTED_RESOURCE,
    selectedResource,
  }),
  setNumberOfPendingProperties: ({
    numberOfPendingProperties,
  }: {
    numberOfPendingProperties: number;
  }) => ({
    type: types.SET_INACTIVE_PROPERTIES_NUMBER,
    numberOfPendingProperties,
  }),
  fetchBenchmarkingData: ({
    customerId,
    yearMonth,
  }: {
    customerId: string;
    yearMonth: string;
  }) => ({
    type: types.FETCH_BENCHMARKING_DATA,
    customerId,
    yearMonth,
  }),
  resetData: () => ({
    type: types.RESET_DATA,
  }),
  fetchConnectPropertiesData: ({ accountId, customerId }) => ({
    type: types.FETCH_CONNECT_PROPERTIES_DATA,
    accountId,
    customerId,
  }),
  attachProperties: ({ reqData, accountId, customerId }) => ({
    type: types.ATTACH_PROPERTIES,
    reqData,
    accountId,
    customerId,
  }),
  setEnableSitePopup: (payload: boolean) => ({
    type: types.SET_ENABLE_SITE_POPUP,
    payload,
  }),
};

export const initialState: TSEnergyStarEntityState = {
  customerStatus: {
    isEnabled: false,
    sitesCount: null,
    energyStarCustomerId: null,
    showEnableSitePopup: true,
  },
  summaryData: {
    originalData: [],
    mappedData: [],
    listOfMonths: [],
    lastMonthData: null,
    searchText: null,
    selectedResource: ALL_VALUE,
  },
  connectPropertiesData: {
    pendingPropertiesList: [],
    originalData: null,
    mappedData: null,
  },
  benchmarkingData: {
    originalData: [],
    mappedData: [],
    cardData: {
      siteWithLowestEUI: null,
      siteWithLowestGHGSubmissions: null,
      sitesEligibleForCertification: null,
    },
  },
  meta: {
    loading: false,
    secondaryLoading: false,
    error: '',
  },
};

const customerStatus = (state = initialState.customerStatus, action) => {
  switch (action.type) {
    case types.FETCH_CUSTOMER_STATUS:
      return {
        ...initialState.customerStatus,
        numberOfPendingProperties: state.numberOfPendingProperties,
      };
    case types.FETCH_CUSTOMER_STATUS_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case types.FETCH_ACCOUNT_ACCEPT_DATA_SUCCESS:
      return {
        ...state,
        numberOfPendingProperties: action.payload.numberOfInactiveProperties,
      };
    case types.SET_INACTIVE_PROPERTIES_NUMBER:
      return {
        ...state,
        numberOfPendingProperties: action.numberOfPendingProperties,
      };
    case types.FETCH_CONNECT_PROPERTIES_DATA_SUCCESS:
      return {
        ...state,
        numberOfPendingProperties: action.payload.propertiesData.length,
      };
    case types.SET_ENABLE_SITE_POPUP:
      return {
        ...state,
        showEnableSitePopup: action.payload,
      };
    case types.RESET_DATA:
      return initialState.customerStatus;
    default:
      return state;
  }
};

const summaryData = (state = initialState.summaryData, action) => {
  switch (action.type) {
    case types.FETCH_CUSTOMER_STATUS:
      return { ...initialState.summaryData };
    case types.FETCH_SUBMISSIONS_DATA_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case types.FETCH_SUMMARY_DATA_SUCCESS:
      return {
        ...state,
        lastMonthData: { ...action.payload },
      };
    case types.SET_SEARCH_TEXT:
      return {
        ...state,
        searchText: action.searchText,
      };
    case types.SET_SELECTED_RESOURCE:
      return {
        ...state,
        selectedResource: action.selectedResource,
      };
    case types.RESET_DATA:
      return initialState.summaryData;
    default:
      return state;
  }
};

const benchmarkingData = (state = initialState.benchmarkingData, action) => {
  switch (action.type) {
    case types.FETCH_CUSTOMER_STATUS:
      return { ...initialState.benchmarkingData };
    case types.FETCH_BENCHMARKING_DATA_SUCCESS:
      return {
        ...state,
        mappedData: [...action.payload.mappedData],
        cardData: { ...action.payload.cardData },
      };
    case types.RESET_DATA:
      return initialState.benchmarkingData;
    default:
      return state;
  }
};

const connectPropertiesData = (
  state = initialState.connectPropertiesData,
  action
) => {
  switch (action.type) {
    case types.FETCH_CONNECT_PROPERTIES_DATA:
      return { ...initialState.connectPropertiesData };
    case types.FETCH_CONNECT_PROPERTIES_DATA_SUCCESS:
      return {
        ...state,
        mappedData: [...action.payload.mappedData],
        pendingPropertiesList: [...action.payload.propertiesData],
      };
    case types.RESET_DATA:
      return initialState.connectPropertiesData;
    default:
      return state;
  }
};

const meta = (state = initialState.meta, action) => {
  switch (action.type) {
    case types.FETCH_CUSTOMER_STATUS:
    case types.FETCH_SUBMISSIONS_DATA:
    case types.FETCH_BENCHMARKING_DATA:
    case types.FETCH_CONNECT_PROPERTIES_DATA:
    case types.ATTACH_PROPERTIES:
      return {
        ...state,
        error: '',
        loading: true,
      };
    case types.FETCH_ACCOUNT_ACCEPT_DATA:
    case types.FETCH_SUMMARY_DATA:
      return {
        ...state,
        error: '',
        secondaryLoading: true,
      };
    case types.FETCH_CUSTOMER_STATUS_ERROR:
    case types.FETCH_SUBMISSIONS_DATA_ERROR:
    case types.FETCH_BENCHMARKING_DATA_ERROR:
    case types.FETCH_CONNECT_PROPERTIES_DATA_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case types.FETCH_CUSTOMER_STATUS_SUCCESS:
    case types.FETCH_SUBMISSIONS_DATA_SUCCESS:
    case types.FETCH_BENCHMARKING_DATA_SUCCESS:
    case types.FETCH_CONNECT_PROPERTIES_DATA_SUCCESS:
      return {
        ...state,
        error: '',
        loading: false,
      };
    case types.FETCH_SUMMARY_DATA_ERROR:
    case types.FETCH_ACCOUNT_ACCEPT_DATA_ERROR:
      return {
        ...state,
        error: action.error,
        secondaryLoading: false,
      };
    case types.FETCH_SUMMARY_DATA_SUCCESS:
    case types.FETCH_ACCOUNT_ACCEPT_DATA_SUCCESS:
      return {
        ...state,
        error: '',
        secondaryLoading: false,
      };
    default:
      return state;
  }
};

export default combineReducers({
  benchmarkingData,
  customerStatus,
  summaryData,
  connectPropertiesData,
  meta,
});

export const energyStarEntity = (state: TSState): TSEnergyStarEntityState =>
  state.entities.energyStar;

function* fetchBenchmarkingDataSaga({
  customerId,
  yearMonth,
}: {
  customerId: string;
  yearMonth: string;
}): Generator<any, void, any> {
  try {
    const payload: TSBenchmarkingDataOriginal[] = yield call(
      API.fetchBenchmarkingData,
      { customerId, yearMonth }
    );

    const unitFormatPreference = yield select(
      (state) => state?.entities?.settings?.preferences?.unitFormatPreference
    );

    yield put({
      type: types.FETCH_BENCHMARKING_DATA_SUCCESS,
      payload: benchmarkingMapper(payload, customerId, unitFormatPreference),
    });
  } catch (e) {
    yield handleSagaError(types.FETCH_BENCHMARKING_DATA_ERROR, e as Error);
  }
}

function* fetchAccountAcceptDataSaga({
  accountId,
}: {
  accountId: string;
}): Generator<any, void, any> {
  try {
    const payload: TSEnergyStarCustomerState = yield call(
      API.onboardingConnectAccount,
      { accountId }
    );

    yield put({
      type: types.FETCH_ACCOUNT_ACCEPT_DATA_SUCCESS,
      payload: payload,
    });
  } catch (e) {
    yield handleSagaError(types.FETCH_ACCOUNT_ACCEPT_DATA_ERROR, e as Error);
  }
}

function* fetchCustomersStatusSaga({
  customerId,
}: {
  customerId: string;
}): Generator<any, void, any> {
  try {
    const payload: TSEnergyStarCustomerState = yield call(
      API.fetchCustomerStatus,
      customerId
    );

    yield put({
      type: types.FETCH_CUSTOMER_STATUS_SUCCESS,
      payload: customerStatusMapper(payload, customerId),
    });
  } catch (e) {
    yield handleSagaError(types.FETCH_CUSTOMER_STATUS_ERROR, e as Error);
  }
}

function* fetchSummaryDataSaga({
  customerId,
}: {
  customerId: string;
}): Generator<any, void, any> {
  try {
    let payload = yield call(API.fetchSummaryData, customerId);

    if (isCurrentUserRedaptiveDemoUser() && customerId === WPT_CUSTOMER_ID) {
      payload = demoUserMonthlySummaryPayload(payload);
    }

    yield put({
      type: types.FETCH_SUMMARY_DATA_SUCCESS,
      payload,
    });
  } catch (e) {
    yield handleSagaError(types.FETCH_SUMMARY_DATA_ERROR, e as Error);
  }
}

function* fetchSubmissionsDataSaga(reqData: TSEnergyStarSubmissionDataPayload) {
  try {
    const originalData: TSEnergyStarSummaryDataOriginal[] = yield call(
      API.fetchSubmissionsData,
      reqData
    );

    const unitFormatPreference = yield select(
      (state) => state?.entities?.settings?.preferences?.unitFormatPreference
    );

    const { mappedData, listOfMonths } = submissionsMapper(
      originalData,
      reqData,
      reqData.customerId,
      unitFormatPreference
    );

    yield put({
      type: types.FETCH_SUBMISSIONS_DATA_SUCCESS,
      payload: { originalData, mappedData, listOfMonths },
    });
  } catch (e) {
    yield handleSagaError(types.FETCH_SUBMISSIONS_DATA_ERROR, e as Error);
  }
}

function* fetchConnectPropertiesDataSaga({ accountId, customerId }) {
  try {
    const propertiesData = yield call(API.fetchPendingProperties, accountId);

    const meterMappingDats = yield call(API.getESPMMeterMapping, customerId);

    yield put({
      type: types.FETCH_CONNECT_PROPERTIES_DATA_SUCCESS,
      payload: {
        mappedData: espmMeterMapper(meterMappingDats),
        propertiesData: pendingPropertiesMapper(propertiesData),
      },
    });
  } catch (e) {
    yield handleSagaError(
      types.FETCH_CONNECT_PROPERTIES_DATA_ERROR,
      e as Error
    );
  }
}

function* attachPropertiesSaga({ reqData, accountId, customerId }) {
  try {
    yield call(
      API.attachProperties,
      espmMeterResponseMapper(reqData, customerId)
    );
    const propertiesData = yield call(API.fetchPendingProperties, accountId);
    const meterMappingDats = yield call(API.getESPMMeterMapping, customerId);

    yield put({
      type: types.FETCH_CONNECT_PROPERTIES_DATA_SUCCESS,
      payload: {
        mappedData: espmMeterMapper(meterMappingDats),
        propertiesData: pendingPropertiesMapper(propertiesData),
      },
    });
  } catch (e) {
    yield handleSagaError(
      types.FETCH_CONNECT_PROPERTIES_DATA_ERROR,
      e as Error
    );
  }
}

export const sagas = [
  takeLatest(types.FETCH_CUSTOMER_STATUS as any, fetchCustomersStatusSaga),
  takeLatest(types.FETCH_SUMMARY_DATA as any, fetchSummaryDataSaga),
  takeLatest(types.FETCH_SUBMISSIONS_DATA as any, fetchSubmissionsDataSaga),
  takeLatest(types.FETCH_BENCHMARKING_DATA as any, fetchBenchmarkingDataSaga),
  takeLatest(
    types.FETCH_ACCOUNT_ACCEPT_DATA as any,
    fetchAccountAcceptDataSaga
  ),
  takeLatest(
    types.FETCH_CONNECT_PROPERTIES_DATA as any,
    fetchConnectPropertiesDataSaga
  ),
  takeLatest(types.ATTACH_PROPERTIES as any, attachPropertiesSaga),
];
