/* eslint-disable jsx-a11y/no-autofocus */
import {
  UilAngleDoubleDown,
  UilAngleDoubleUp,
  UilFileAlt,
  UilPaperclip,
  UilPlusCircle,
  UilTimes,
} from '@iconscout/react-unicons';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CreatableSelect from 'react-select/creatable';
import styled from 'styled-components';
import { EMPTY_METRIC_STRING } from '../../constants/strings';
import {
  TSCustomersEntityState,
  selectCustomersEntity,
} from '../../ducks/customers';
import { UserTrackedAction } from '../../ducks/gainsight';
import {
  TSOpportunityDetailModal,
  actions as modalActions,
} from '../../ducks/modal';
import {
  actions as opportunityActions,
  selectOpportunityEntity,
} from '../../ducks/opportunity/opportunities';
import {
  TSAddLabelsRequestPayload,
  TSEditBatchPayload,
  TSEditOpportunityLabelsPayload,
  TSOpportunityBase,
  TSOpportunityBatch,
  TSOpportunitySeed,
  TSTransitionOpportunityPayloadPlusGainsight,
  getParentBatchForSeed,
  getSeedsForBatch,
  actions as opportunitySeedsActions,
  selectOpportunitySeedsEntity,
} from '../../ducks/opportunity/opportunitySeeds';

import InfoCircle from '@iconscout/react-unicons/icons/uil-info-circle';
import {
  Badge,
  Box,
  Divider,
  Flex,
  Group,
  Button as MantineButton,
  Grid as MantineGrid,
  Paper,
  Stack,
  Text,
  Textarea,
} from '@mantine/core';
import { useScrollIntoView } from '@mantine/hooks';
import { Tooltip } from 'react-tooltip';
import {
  ecmTypeToMlovMapping,
  getLabelOptionsByGroupForReactSelect,
  TSCustomOpportunityField,
} from '../../ducks/opportunity/opportunityTypes';
import {
  extractFileNameInfo,
  formatNumberToDecimals,
  locationSearchToObject,
} from '../../utils';
import Button from '../Button';
import FileThumbnailPreview from '../FileThumbnailPreview';
import {
  CloseModalButtonStyled,
  DefaultModalStyled,
  ModalBodyStyled,
  ModalContentStyled,
  ModalTitleWithSubTitleStyled,
  ModalWrapperStyled,
} from '../GlobalModal/Components';
import Grid from '../Grid';
import Input from '../Input';
import Message from '../Message';
import MetricCard from '../MetricCard';
import ModalPopup, { ModalPopupTitle } from '../ModalPopup';
import AddToBatchModal from '../OpportunityFeed/AddToBatchModal';
import ChildOpportunities from '../OpportunityFeed/ChildOpportunities';
import CommentSection from '../OpportunityFeed/CommentSection';
import Followers from '../OpportunityFeed/Followers';
import OpportunityDetailsHeader from '../OpportunityFeed/OpportunityDetailsHeader';
import ProjectTasksSection from '../OpportunityFeed/ProjectTasksSection';
import { MLOVFieldType } from '../OpportunityFeed/SortableTable';
import Spinner from '../Spinner';

export const OpportunityModalBodyStyled = styled(ModalBodyStyled)`
  box-shadow: 1px 0px 5px 1px #999;
  background: linear-gradient(
      0deg,
      rgba(224, 224, 224, 0.32) 0%,
      rgba(224, 224, 224, 0.32) 100%
    ),
    #fff;
  width: 45%;
`;

const ErrorMessageWrapper = styled.div`
  margin: 10px 24px;
`;

const FormInputWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  input {
    width: 100%;
  }
`;

export const InlineFormWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 6px;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  background: #fff;
`;

export const PopupFormButtonWrapperStyled = styled.div`
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  flex-shrink: 0;
  margin-top: 16px;
`;

const InlineTextWrapper = styled.div`
  margin-bottom: 15px;
  font-size: 14px;
`;

const FilePreviewWrapper = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 10px;
`;

const ModalContentReStyled = styled(ModalContentStyled)`
  padding: 24px 0;
  ${ModalTitleWithSubTitleStyled} {
    margin-bottom: 0;
    padding-bottom: 0;
  }
`;

const GridStyled = styled(Grid)`
  margin: 20px 0;
`;

const OpportunityDetailModal = ({
  opportunitySeedId,
  opportunityType,
}: TSOpportunityDetailModal) => {
  const {
    items: seeds,
    batches,
    labels,
    updateOpportunityMeta: {
      loading: updateOpportunityLoading,
      error: updateOpportunityError,
    },
    labelsMeta: { loading: labelsLoading },
    addLabelsMeta: { loading: addLabelsLoading, error: addLabelsError },
    editBatchMeta: { loading: editBatchLoading, error: editBatchError },
    editBatchMetadataMeta: {
      loading: editBatchMetadataLoading,
      error: editBatchMetadataError,
    },
    editOpportunityLabelsMeta: {
      loading: editOpportunityLabelsLoading,
      error: editOpportunityLabelsError,
    },
    masterListOfValues,
    fetchMasterListOfValuesMeta: { loading: isMasterListOfValuesLoading },
  } = useSelector(selectOpportunitySeedsEntity);

  const navigate = useNavigate();

  const isBatch = useMemo(() => opportunityType == 'batch', [opportunityType]);
  const {
    attachments,
    attachmentsMeta: { loading: attachmentsLoading, error: attachmentsError },
    batchAttachments,
    batchAttachmentsMeta: {
      loading: batchAttachmentsLoading,
      error: batchAttachmentsError,
    },
    attachmentsUploadMeta: {
      loading: isUploadingAttachment,
      error: attachmentsUploadError,
    },
    deleteOpportunityAttachmentMeta: {
      loading: isDeletingAttachment,
      error: deleteAttachmentError,
    },
    deleteBatchAttachmentMeta: { loading: isDeletingBatchAttachment },
    comments,
    commentsMeta: { loading: commentsLoading, error: commentsError },
    batchComments,
    batchCommentsMeta: {
      loading: batchCommentsLoading,
      error: batchCommentsError,
    },
    createCommentMeta: {
      loading: createCommentLoading,
      error: createCommentError,
    },
    createBatchCommentMeta: {
      loading: createBatchCommentLoading,
      error: createBatchCommentError,
    },
    tasks,
    tasksMeta: { loading: tasksloading, error: tasksError },
    createCustomerTaskMeta: {
      loading: createTaskLoading,
      error: createTaskError,
    },
    deleteCustomerTaskMeta: {
      loading: deleteTaskLoading,
      error: deleteTaskError,
    },
    updateCustomerTaskMeta: {
      loading: updateTaskLoading,
      error: updateTaskError,
    },
  } = useSelector(selectOpportunityEntity);
  const getOpportunity = (): TSOpportunityBase => {
    const opportunity = !isBatch
      ? seeds.find((seed) => seed.id === opportunitySeedId)
      : batches.find((batch) => batch.id === opportunitySeedId);
    return opportunity || ({} as TSOpportunityBase);
    // TODO: if we don't have the opportunity here, throw an error or something
  };

  const opportunitySeed = getOpportunity();
  const opportunityAsBatch = opportunitySeed as TSOpportunityBatch;
  const opportunityAsSeed = opportunitySeed as TSOpportunitySeed;
  const parentBatch = !isBatch
    ? getParentBatchForSeed(opportunityAsSeed, batches)
    : null;
  const childSeeds = isBatch
    ? getSeedsForBatch(opportunityAsBatch, seeds)
    : null;

  const currentProjectTasks = tasks.filter((task) => {
    if (isBatch) {
      return task.projectBatchId == opportunitySeedId;
    } else {
      return task.projectOpportunityId == opportunitySeedId;
    }
  });

  const { currentCustomerId }: TSCustomersEntityState = useSelector(
    selectCustomersEntity
  );

  const dispatch = useDispatch();
  const [commentText, setCommentText] = useState('');
  const [removeReasonText, setRemoveReasonText] = useState('');
  const [showRemoveOpportunityForm, setShowRemoveOpportunityForm] =
    useState(false);

  const [showAddLabelSelector, setShowAddLabelSelector] = useState(false);
  const [showDeleteDocumentModal, setShowDeleteDocumentModal] = useState(false);
  const [showDeleteTaskModal, setShowDeleteTaskModal] = useState(false);
  const [showRemoveChildFromBatchModal, setShowRemoveChildFromBatchModal] =
    useState(false);
  const [opportunitySelectedforRemoval, setOpportunitySelectedforRemoval] =
    useState('');
  const [taskSelectedforRemoval, setTaskSelectedforRemoval] = useState('');
  const [showAddToBatchModal, setShowAddToBatchModal] = useState(false);
  const [documentSelectedforDeletion, setDocumentSelectedforDeletion] =
    useState('');
  const [
    inputRemoveReasonValidationError,
    setInputRemoveReasonValidationError,
  ] = useState('');
  const [inputAddCommentValidationError, setInputAddCommentValidationError] =
    useState('');
  const [fileUploadValidationError, setFileUploadValidationError] =
    useState('');

  const [selectedLabels, setSelectedLabels] = useState<
    readonly { value: string; label: string }[]
  >([]);

  useEffect(() => {
    if (opportunitySeedId) {
      dispatch(opportunitySeedsActions.fetchUserEmails(currentCustomerId));
      if (isBatch) {
        dispatch(
          opportunityActions.fetchBatchComments(
            opportunitySeedId,
            currentCustomerId
          )
        );
        dispatch(
          opportunityActions.fetchBatchAttachments(
            opportunitySeedId,
            currentCustomerId
          )
        );

        // Re-fetch attachments every 2 hours
        const interval = setInterval(
          () => {
            dispatch(
              opportunityActions.fetchBatchAttachments(
                opportunitySeedId,
                currentCustomerId
              )
            );
          },
          2 * 60 * 60 * 1000
        ); // 2 hours in milliseconds
        return () => {
          clearInterval(interval);
        };
      } else {
        dispatch(
          opportunityActions.fetchOpportunityComments(
            opportunitySeedId,
            currentCustomerId
          )
        );
        dispatch(
          opportunityActions.fetchOpportunityAttachments(
            opportunitySeedId,
            currentCustomerId
          )
        );

        // Re-fetch attachments every 2 hours
        const interval = setInterval(
          () => {
            dispatch(
              opportunityActions.fetchOpportunityAttachments(
                opportunitySeedId,
                currentCustomerId
              )
            );
          },
          2 * 60 * 60 * 1000
        ); // 2 hours in milliseconds
        return () => {
          clearInterval(interval);
        };
      }
    }
  }, [isBatch, currentCustomerId, dispatch, opportunitySeedId]);

  const transitionProjectStage = (newStage) => {
    const payload = {
      opportunityId: opportunitySeed.id,
      projectStage: newStage,
      oldProjectStage: opportunitySeed.projectStage,
      gainsightUserAction:
        UserTrackedAction.OPPORTUNITY_CHANGE_PROJECT_STAGE_FROM_DETAIL,
    } as TSTransitionOpportunityPayloadPlusGainsight;

    if (isBatch) {
      dispatch(opportunitySeedsActions.transitionBatch(payload));
    } else {
      dispatch(opportunitySeedsActions.transitionOpportunity(payload));
    }
  };

  const closeModal = useCallback(() => {
    navigate(`${location?.pathname}`);
    dispatch(modalActions.hideModal());
  }, [dispatch, navigate]);

  const locationSearchObj = locationSearchToObject(location.search);

  const { scrollIntoView, targetRef, scrollableRef } = useScrollIntoView<
    HTMLDivElement,
    HTMLDivElement
  >();

  // separate method to update both the stored removal reason and the validation when the text changes
  const handleChangeRemoveReasonText = (strText: string) => {
    setRemoveReasonText(strText);
    setInputRemoveReasonValidationError('');
  };

  const handleRemoveOpportunity = useCallback(() => {
    if (!removeReasonText || removeReasonText == '') {
      setInputRemoveReasonValidationError('Please enter a reason');
      return;
    }
    const removeOpportunityRequest = {
      opportunityId: opportunitySeedId,
      reason: removeReasonText,
    };
    if (isBatch) {
      dispatch(opportunitySeedsActions.rejectBatch(removeOpportunityRequest));
    } else {
      dispatch(opportunityActions.rejectOpportunity(removeOpportunityRequest));
    }
    setShowRemoveOpportunityForm(false);
    closeModal();
    setRemoveReasonText('');
  }, [isBatch, closeModal, dispatch, opportunitySeedId, removeReasonText]);

  // separate method to update both the stored comment and the validation when the text changes
  const handleChangeCommentText = (strComment: string) => {
    setCommentText(strComment);
    setInputAddCommentValidationError('');
  };

  const handleFileAcceptedForUpload = () => {
    setFilesSelectedForUpload(acceptedFiles);
    setFileUploadValidationError('');
  };
  const handleFileRejectedForUpload = (files) => {
    files.map((file) => {
      if (file.errors[0].code == 'file-too-large') {
        setFileUploadValidationError('File cannot be larger than 50 MB');
      } else {
        setFileUploadValidationError(file.errors[0].message);
      }
    });
  };

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    // Disable default zone click and keydown behavior
    noClick: true,
    noKeyboard: true,
    maxSize: 52428800,
    onDropRejected: handleFileRejectedForUpload,
    onDropAccepted: handleFileAcceptedForUpload,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg'],
      'text/csv': ['.csv'],
      'application/msword': ['.doc', '.docx'],
      'application/pdf': ['.pdf'],
      'application/vnd.ms-excel': ['.xls', '.xlsx'],
    },
  });

  const [filesSelectedForUpload, setFilesSelectedForUpload] =
    useState(acceptedFiles);
  useEffect(() => {
    setFilesSelectedForUpload(acceptedFiles);
  }, [acceptedFiles]);

  const handleAttachFile = useCallback(() => {
    open();
  }, [open]);

  const handleDeleteDocument = () => {
    if (documentSelectedforDeletion != '') {
      isBatch
        ? dispatch(
            opportunityActions.deleteBatchAttachment(
              opportunitySeedId,
              documentSelectedforDeletion
            )
          )
        : dispatch(
            opportunityActions.deleteOpportunityAttachment(
              opportunitySeedId,
              documentSelectedforDeletion
            )
          );
      setDocumentSelectedforDeletion('');
      setShowDeleteDocumentModal(false);
    }
  };

  const handleAddComment = useCallback(() => {
    if (
      filesSelectedForUpload.length == 0 &&
      (commentText == '' || !commentText)
    ) {
      setInputAddCommentValidationError('Please enter a proper comment');
      return;
    }
    const createCommentRequest = {
      opportunityId: opportunitySeedId,
      comment: commentText,
      acceptedFiles:
        filesSelectedForUpload.length != 0 ? filesSelectedForUpload : undefined,
    };
    if (isBatch) {
      dispatch(opportunityActions.createBatchComment(createCommentRequest));
    } else {
      dispatch(
        opportunityActions.createOpportunityComment(createCommentRequest)
      );
    }
    setCommentText('');
    setFilesSelectedForUpload([]);
  }, [
    isBatch,
    commentText,
    dispatch,
    filesSelectedForUpload,
    opportunitySeedId,
  ]);

  const isAttachmentsLoading = () => {
    if (isBatch) {
      if (
        batchAttachmentsLoading ||
        isDeletingBatchAttachment ||
        isUploadingAttachment
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      if (attachmentsLoading || isDeletingAttachment || isUploadingAttachment) {
        return true;
      } else {
        return false;
      }
    }
  };
  const isCommentsLoading = () => {
    if (isBatch) {
      if (batchCommentsLoading || createBatchCommentLoading) return true;
      else return false;
    } else {
      if (commentsLoading || createCommentLoading) return true;
      else return false;
    }
  };

  const isLabelsLoading = () => {
    if (isBatch) {
      if (addLabelsLoading || editBatchMetadataLoading) return true;
      else return false;
    } else {
      if (editOpportunityLabelsLoading || addLabelsLoading) return true;
      else return false;
    }
  };
  const isTasksLoading = () => {
    if (
      tasksloading ||
      deleteTaskLoading ||
      updateTaskLoading ||
      createTaskLoading
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleRemoveFromBatch = () => {
    if (opportunitySelectedforRemoval != '') {
      const editBatchPayload = {
        batchId: opportunitySeedId,
        opportunityIds:
          opportunityAsBatch.opportunityIds?.filter(
            (id) => id != opportunitySelectedforRemoval
          ) ?? [],
        removedOpportunityId: opportunitySelectedforRemoval,
      } as TSEditBatchPayload;
      dispatch(opportunitySeedsActions.editBatch(editBatchPayload));
      setShowRemoveChildFromBatchModal(false);
      setOpportunitySelectedforRemoval('');
    }
  };
  const handleRemoveTask = () => {
    if (taskSelectedforRemoval != '') {
      dispatch(
        opportunityActions.deleteCustomerTask({
          taskId: taskSelectedforRemoval,
        })
      );
      setShowDeleteTaskModal(false);
      setTaskSelectedforRemoval('');
    }
  };

  const dynamicAttachments = isBatch ? batchAttachments : attachments;
  const dynamicComments = isBatch ? batchComments : comments;

  const opportunityLabels = useMemo(
    () => labels.filter((label) => opportunitySeed.labelIds.includes(label.id)),
    [labels, opportunitySeed.labelIds]
  );

  const [labelsToShow, setLabelsToShow] = useState(opportunityLabels);
  useEffect(() => {
    setLabelsToShow(opportunityLabels);
  }, [opportunityLabels]);

  const [documentsToShow, setDocumentsToShow] = useState(
    dynamicAttachments.slice(0, 4)
  );
  useEffect(() => {
    setDocumentsToShow(dynamicAttachments.slice(0, 4));
  }, [dynamicAttachments]);
  useEffect(() => {
    if (locationSearchObj.shouldFocusToComments) {
      scrollIntoView();
    }
  }, [locationSearchObj.shouldFocusToComments, scrollIntoView]);

  const handeLabelClose = (labelId) => {
    if (isBatch) {
      const editLabelRequestPayload = {
        batchId: opportunitySeedId,
        title: opportunitySeed.title,
        description: opportunitySeed.description,
        labelIds: opportunitySeed.labelIds.filter((label) => label != labelId),
        annualAvoidedCo2e: opportunitySeed.annualAvoidedCo2e,
        annualNetCostSavings: opportunitySeed.annualNetCostSavings,
        simplePaybackPeriod: opportunitySeed.simplePaybackPeriod,
        annualAvoidedEnergyCost: opportunitySeed.annualAvoidedEnergyCost,
        annualAvoidedMaintenanceCost:
          opportunitySeed.annualAvoidedMaintenanceCost,
        annualAvoidedOtherCost: opportunitySeed.annualAvoidedOtherCost,
        annualAvoidedTotalCost: opportunitySeed.annualAvoidedTotalCost,
        customFields: opportunitySeed.customFields,
        sequenceDetails: opportunitySeed.sequenceDetails,
      };
      dispatch(
        opportunitySeedsActions.editBatchMetadata(editLabelRequestPayload)
      );
    } else {
      const editLabelRequestPayload = {
        opportunityId: opportunitySeedId,
        labelIds: opportunityAsSeed.labelIds.filter(
          (label) => label != labelId
        ),
        removedLabelId: labelId,
      } as TSEditOpportunityLabelsPayload;
      dispatch(
        opportunitySeedsActions.editOpportunityLabels(editLabelRequestPayload)
      );
    }
  };

  const labelsForSelector = useMemo(
    () => getLabelOptionsByGroupForReactSelect(labels),
    [labels]
  );

  const handleAddLabels = () => {
    const formattedLabels = selectedLabels.map((selectedLabel) => {
      const foundLabel = labels.find(
        (label) => label.text === selectedLabel.label
      );

      if (foundLabel) {
        return {
          id: foundLabel.id,
          text: foundLabel.text,
        };
      } else {
        return { text: selectedLabel.label };
      }
    });

    const addLabelsRequestPayload: TSAddLabelsRequestPayload = {
      customerId: currentCustomerId,
      labels: formattedLabels,
      opportunityIds: isBatch ? undefined : [opportunitySeedId],
      batchIds: isBatch ? [opportunitySeedId] : undefined,
    };

    dispatch(opportunitySeedsActions.addLabels(addLabelsRequestPayload));
    setSelectedLabels([]);
    setShowAddLabelSelector(false);
  };

  // Define any contextual labels here
  const contextualLabels = [
    {
      label: 'preliminary estimates',
      contextMessage: `These are preliminary estimates for prioritization
      of potential projects. Additional information is needed to create an
      indicative proposal. Leave a comment below if you are interested in moving forward.`,
    },
    {
      label: 'indicative estimates',
      contextMessage: `These are indicative estimates for planning purposes.
      Additional information and potentially an audit are needed to create an
      executable contract. Leave a comment below if you are interested in moving forward.`,
    },
  ];

  const getContextualSeedLabels = () => {
    const seedLabelsByName = labels
      .filter((label) => opportunitySeed.labelIds.includes(label.id))
      .map((label) => label.text.toLowerCase());
    const matchingContextualLabel = contextualLabels.filter((item) =>
      seedLabelsByName.includes(item.label.toLowerCase())
    );
    return matchingContextualLabel;
  };
  const opportunitySequenceDetails = useMemo(() => {
    return opportunityAsSeed.sequenceDetails ?? [];
  }, [opportunityAsSeed.sequenceDetails]);

  {
    /* Prepare custom fields Fields */
  }
  const customFields: TSCustomOpportunityField[] = useMemo(() => {
    return masterListOfValues
      .filter((item) => item.type === 'CUSTOM_FIELD_TITLE')
      .map((field) => {
        const parsedFieldData = field.metadata
          ? JSON.parse(field.metadata)
          : {};
        return {
          ...field,
          ...parsedFieldData,
        } as TSCustomOpportunityField;
      });
  }, [masterListOfValues]);

  const opportunityCustomFields = useMemo(() => {
    return (
      opportunityAsSeed.customFields?.map((field) => {
        const mlovField =
          customFields.find((mlovField) => mlovField.id == field.id) ||
          ({} as any);
        const parsedFieldData = mlovField?.metadata
          ? JSON.parse(mlovField?.metadata)
          : {};
        return {
          ...parsedFieldData,
          ...field,
          isCustomField: true,
          isPrimary: opportunitySequenceDetails.find(
            (item) => item.id === field.id
          )?.isPrimary,
        };
      }) ?? []
    );
  }, [
    opportunityAsSeed.customFields,
    opportunitySequenceDetails,
    customFields,
  ]);
  {
    /* Prepare custom fields Fields ends */
  }
  {
    /* Prepare ecmSpecific Fields */
  }
  const opportunityEcmFieldsType =
    ecmTypeToMlovMapping[
      opportunitySeed.ecmType
        .replace(/\s+/g, '')
        .replace(/-/g, '')
        .toLowerCase()
    ];
  // Filter masterListOfValues based on the selectedType
  const mlovFieldsRawData = masterListOfValues.filter(
    (item) => item.type === opportunityEcmFieldsType
  )[0]?.value;
  let ecmSpecificFieldsData = [];
  let secondaryGenericFields = [];
  let genericFields = [];
  const parsedData = JSON.parse(
    mlovFieldsRawData ??
      masterListOfValues.filter((item) => item.type === 'GLOBAL_FIELDS')[0]
        ?.value ??
      '[]'
  );
  ecmSpecificFieldsData = parsedData
    .filter(
      (field) => field.fieldType == 'ECM_SPECIFIC' && field.showOnDetailsView
    )
    .map((field: MLOVFieldType) => {
      return {
        id: field.apiFieldName,
        title: field.title,
        value: opportunitySeed?.ecmTypeSpecificFields
          ? opportunitySeed.ecmTypeSpecificFields[field.apiFieldName]
          : '0',
        unit: field.unit,
        isCustomField: false,
        isPrimary:
          opportunitySequenceDetails.length > 0
            ? opportunitySequenceDetails.find(
                (item) => item.id == field.apiFieldName
              )?.isPrimary
            : field.isPrimary,
        sequenceNumber: field.sequenceNumber,
        helperText: field.helperText,
        numberOfDecimalPlaces: field.numberOfDecimalPlaces,
      };
    });

  genericFields = parsedData
    .filter((field) => field.fieldType == 'GENERIC' && field.showOnDetailsView)
    .map((field: MLOVFieldType) => {
      return {
        id: field.apiFieldName,
        title: field.title,
        value: opportunityAsSeed[field.apiFieldName],
        unit: field.unit,
        isCustomField: false,
        isPrimary:
          opportunitySequenceDetails.length > 0
            ? opportunitySequenceDetails.find(
                (item) => item.id == field.apiFieldName
              )?.isPrimary
            : field.isPrimary,
        sequenceNumber: field.sequenceNumber,
        helperText: field.helperText,
        numberOfDecimalPlaces: field.numberOfDecimalPlaces,
      };
    });
  secondaryGenericFields = parsedData
    .filter(
      (field) =>
        field.fieldType == 'SECONDARY_GENERIC' && field.showOnDetailsView
    )
    .map((field: MLOVFieldType) => {
      return {
        id: field.apiFieldName,
        title: field.title,
        value: opportunityAsSeed[field.apiFieldName],
        unit: field.unit,
        isCustomField: false,
        isPrimary:
          opportunitySequenceDetails.length > 0
            ? opportunitySequenceDetails.find(
                (item) => item.id == field.apiFieldName
              )?.isPrimary
            : field.isPrimary,
        sequenceNumber: field.sequenceNumber,
        helperText: field.helperText,
        numberOfDecimalPlaces: field.numberOfDecimalPlaces,
      };
    });
  const batchFields = isBatch
    ? JSON.parse(
        masterListOfValues.filter((item) => item.type === 'BATCH_FIELDS')[0]
          ?.value ?? '[]'
      ).map((field: MLOVFieldType) => {
        return {
          id: field.apiFieldName,
          title: field.title,
          value: opportunityAsSeed[field.apiFieldName],
          unit: field.unit,
          isCustomField: false,
          isPrimary:
            opportunitySequenceDetails.length > 0
              ? opportunitySequenceDetails.find(
                  (item) => item.id == field.apiFieldName
                )?.isPrimary
              : field.isPrimary,
          sequenceNumber: field.sequenceNumber,
          helperText: field.helperText,
          formula: field.formula,
        };
      })
    : [];

  {
    /* Prepare ecmSpecific Fields ends */
  }
  const tableFields = isBatch
    ? [...batchFields, ...opportunityCustomFields]
    : [
        ...genericFields,
        ...opportunityCustomFields,
        ...secondaryGenericFields,
        ...ecmSpecificFieldsData,
      ];
  const sequenceMapping = {};
  // When opp sequence is not present, use default sequencing from ecmSpecificFields
  const sequenceArray =
    opportunitySequenceDetails.length > 0
      ? opportunitySequenceDetails
      : [...genericFields, ...ecmSpecificFieldsData, ...secondaryGenericFields];
  sequenceArray.forEach((item) => {
    sequenceMapping[item.id] = item.sequenceNumber;
  });

  tableFields.sort((a, b) => {
    const sequenceA = sequenceMapping[a.id];
    const sequenceB = sequenceMapping[b.id];
    return sequenceA - sequenceB;
  });
  const primaryMetrics = tableFields.filter((field) => field.isPrimary);
  const getMetricCardValue = (item) => {
    return formatNumberToDecimals(
      Number(item.value),
      item.numberOfDecimalPlaces
    );
  };
  const fieldTableRows = tableFields.map((element) => (
    <Paper key={element.id} mx={'24px'} my={'8px'} withBorder radius={'8px'}>
      <Group justify='space-between' px={'24px'} py={'16px'}>
        <Flex gap={'8px'} align={'center'}>
          <Text>{element.title}</Text>
          {element.helperText && (
            <span>
              <InfoCircle
                color='#9D9D9F'
                data-tooltip-id={element.id}
                size={20}
              />
              {
                <Tooltip
                  style={{ maxWidth: 300, zIndex: 3 }}
                  id={element.id}
                  place='right'
                >
                  <Text>{element.helperText}</Text>
                </Tooltip>
              }
            </span>
          )}
        </Flex>

        <Text c={'blue.5'} size='20px' fw={'700'}>
          {element.value
            ? `${getMetricCardValue(element)} ${element.unit}`
            : EMPTY_METRIC_STRING}
        </Text>
      </Group>
    </Paper>
  ));

  const [showFields, setShowFields] = useState(!(primaryMetrics.length > 0));
  if (!opportunitySeed || !opportunitySeed.displayId) {
    closeModal();
    return null;
  }
  return (
    <DefaultModalStyled>
      <ModalWrapperStyled>
        <CloseModalButtonStyled onClick={closeModal}>
          <span />
          <span />
          <span />
        </CloseModalButtonStyled>
        <OpportunityModalBodyStyled>
          {showRemoveOpportunityForm && (
            <ModalPopup width='45%'>
              <ModalPopupTitle>Remove This Project</ModalPopupTitle>
              <InlineTextWrapper>
                Why do you want to remove this project? After removing you
                won&apos;t be able to see this project.
              </InlineTextWrapper>
              <FormInputWrapperStyled>
                <Input
                  onChange={(e) =>
                    handleChangeRemoveReasonText(e.currentTarget.value || '')
                  }
                  placeholder='Please enter your reason for removing'
                  error={inputRemoveReasonValidationError}
                />
                <PopupFormButtonWrapperStyled>
                  <Button
                    onClick={() => setShowRemoveOpportunityForm(false)}
                    borderRadius='small'
                  >
                    {'Cancel'}
                  </Button>
                  <Button
                    onClick={handleRemoveOpportunity}
                    borderRadius='small'
                    buttonType='secondary'
                  >
                    {'Remove'}
                  </Button>
                </PopupFormButtonWrapperStyled>
              </FormInputWrapperStyled>
            </ModalPopup>
          )}
          {showDeleteDocumentModal && (
            <ModalPopup width='45%'>
              <ModalPopupTitle>Delete Document?</ModalPopupTitle>
              <InlineTextWrapper>
                After deleting, you won’t be able to see this document.
              </InlineTextWrapper>
              <FormInputWrapperStyled>
                <PopupFormButtonWrapperStyled>
                  <Button
                    onClick={() => {
                      setShowDeleteDocumentModal(false);
                      setDocumentSelectedforDeletion('');
                    }}
                    borderRadius='small'
                  >
                    {'Cancel'}
                  </Button>
                  <Button
                    onClick={handleDeleteDocument}
                    borderRadius='small'
                    buttonType='secondary'
                  >
                    {'Remove'}
                  </Button>
                </PopupFormButtonWrapperStyled>
              </FormInputWrapperStyled>
            </ModalPopup>
          )}
          {showAddToBatchModal && (
            <AddToBatchModal
              opportunitySeed={opportunitySeed}
              opportunitySeedId={opportunitySeedId}
              setShowAddToBatchModal={setShowAddToBatchModal}
            />
          )}
          {showRemoveChildFromBatchModal && (
            <ModalPopup width='45%'>
              <ModalPopupTitle>
                {opportunityAsBatch.locked
                  ? // eslint-disable-next-line max-len
                    'This project is locked from editing. Please contact your Redaptive representative if you have questions.'
                  : 'Remove project'}
              </ModalPopupTitle>
              {!opportunityAsBatch.locked && (
                <InlineTextWrapper>
                  Do you want to remove the project from this batch?
                </InlineTextWrapper>
              )}
              <FormInputWrapperStyled>
                <PopupFormButtonWrapperStyled>
                  <Button
                    onClick={() => {
                      setOpportunitySelectedforRemoval('');
                      setShowRemoveChildFromBatchModal(false);
                    }}
                    borderRadius='small'
                  >
                    {opportunityAsBatch.locked ? 'Close' : 'Cancel'}
                  </Button>
                  {!opportunityAsBatch.locked && (
                    <Button
                      onClick={handleRemoveFromBatch}
                      borderRadius='small'
                      buttonType='secondary'
                    >
                      {'Remove'}
                    </Button>
                  )}
                </PopupFormButtonWrapperStyled>
              </FormInputWrapperStyled>
            </ModalPopup>
          )}
          {showDeleteTaskModal && (
            <ModalPopup width='45%'>
              <ModalPopupTitle>
                {opportunityAsBatch.locked
                  ? // eslint-disable-next-line max-len
                    'This project is locked from editing. Please contact your Redaptive representative if you have questions.'
                  : 'Are you sure you want remove this task? '}
              </ModalPopupTitle>
              {!opportunityAsBatch.locked && (
                <InlineTextWrapper>
                  {`Once you delete the task you won't be able to see this task again.`}
                </InlineTextWrapper>
              )}
              <FormInputWrapperStyled>
                <PopupFormButtonWrapperStyled>
                  <Button
                    onClick={() => {
                      setTaskSelectedforRemoval('');
                      setShowDeleteTaskModal(false);
                    }}
                    borderRadius='small'
                  >
                    {opportunityAsBatch.locked ? 'Close' : 'Cancel'}
                  </Button>
                  {!opportunityAsBatch.locked && (
                    <Button
                      onClick={handleRemoveTask}
                      borderRadius='small'
                      buttonType='secondary'
                    >
                      {'Remove'}
                    </Button>
                  )}
                </PopupFormButtonWrapperStyled>
              </FormInputWrapperStyled>
            </ModalPopup>
          )}
          {updateOpportunityLoading && (
            <Flex
              h={'100%'}
              direction={'column'}
              justify={'center'}
              align={'center'}
            >
              <Spinner />
            </Flex>
          )}
          {!updateOpportunityLoading && (
            <ModalContentReStyled ref={scrollableRef}>
              {/* Opportunity Details View Header Starts */}
              <Box px={'24px'}>
                <OpportunityDetailsHeader
                  headerData={{
                    opportunitySeed: opportunityAsSeed,
                    isBatch,
                    opportunitySeedId,
                    parentBatch,
                    setShowAddToBatchModal,
                    setShowRemoveOpportunityForm,
                    transitionProjectStage,
                  }}
                />
              </Box>
              {/* Opportunity Details View Header Ends */}

              {/* Begin the cards section */}
              {!isMasterListOfValuesLoading && !editBatchMetadataLoading ? (
                <Box px={'24px'}>
                  <GridStyled colsLg={2}>
                    {primaryMetrics.map((item) => {
                      return (
                        <MetricCard
                          key={item.id}
                          title={item.title}
                          value={
                            item.value
                              ? `${getMetricCardValue(item)} ${item.unit}`
                              : EMPTY_METRIC_STRING
                          }
                          infoTooltip={
                            item.helperText && <Text>{item.helperText}</Text>
                          }
                        />
                      );
                    })}
                  </GridStyled>
                </Box>
              ) : (
                <Spinner centered />
              )}
              {/* End the cards section */}

              {!isMasterListOfValuesLoading && (
                <Stack my={'xl'} gap={'lg'}>
                  {/* Fields Table Section Starts */}

                  {showFields && (
                    <Flex direction={'column'}>{fieldTableRows}</Flex>
                  )}

                  {fieldTableRows.length > 0 && (
                    <Flex
                      gap={'0px'}
                      px={'24px'}
                      align='center'
                      onClick={() => setShowFields(!showFields)}
                      style={{ cursor: 'pointer' }}
                    >
                      <Text c={'blue.6'}>
                        {showFields
                          ? 'Show fewer fields'
                          : `Show all ${tableFields.length} fields`}
                      </Text>
                      {showFields ? (
                        <UilAngleDoubleUp color='#43558E' />
                      ) : (
                        <UilAngleDoubleDown color='#43558E' />
                      )}
                    </Flex>
                  )}
                  {/* Contextual Messages Section Starts */}
                  {getContextualSeedLabels().length > 0 &&
                    getContextualSeedLabels().map((item) => (
                      <ErrorMessageWrapper key={item.label}>
                        <Message type='info'>{item.contextMessage}</Message>
                      </ErrorMessageWrapper>
                    ))}
                  {/* Contextual Messages Section Ends */}
                </Stack>
              )}

              {/* Fields Table Section Ends*/}
              <Divider
                bg={'linear-gradient(0deg, #E0E0E0 0%, #E0E0E0 100%), #FFF'}
              />
              <Box px={'24px'}>
                <Stack my={'xl'} gap={'lg'}>
                  <Text c={'black'} fw={'700'}>
                    Description
                  </Text>
                  <Text>
                    {opportunitySeed.description != ''
                      ? opportunitySeed.description
                      : 'No description added'}
                  </Text>
                </Stack>
              </Box>

              <Divider
                bg={'linear-gradient(0deg, #E0E0E0 0%, #E0E0E0 100%), #FFF'}
              />

              {/* Begin the Labels section */}
              <Box px={'24px'}>
                <Stack my={'xl'} gap={'lg'}>
                  <Group justify='space-between' h={30}>
                    <Text c={'black'} fw={'700'}>
                      Labels
                    </Text>
                    {!showAddLabelSelector && (
                      <MantineButton
                        leftSection={<UilPlusCircle size={16} />}
                        variant='default'
                        radius={'md'}
                        size='compact-md'
                        px={'12px'}
                        onClick={() => setShowAddLabelSelector(true)}
                      >
                        Add label
                      </MantineButton>
                    )}
                  </Group>

                  {showAddLabelSelector && (
                    <Flex
                      align={'center'}
                      gap={'xs'}
                      bg={'white'}
                      style={{
                        borderRadius: '8px',
                        border: '1px solid #e0e0e0',
                      }}
                      p={'xs'}
                    >
                      <CreatableSelect
                        isMulti
                        options={labelsForSelector}
                        closeMenuOnSelect={false}
                        placeholder={
                          !labelsLoading
                            ? 'Type to search or create a new label'
                            : 'Labels are still loading...'
                        }
                        onChange={(values) => setSelectedLabels(values)}
                        value={selectedLabels}
                        styles={{
                          container: (baseStyles) => ({
                            ...baseStyles,
                            flexBasis: '100%',
                            cursor: 'pointer',
                          }),
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            border: state.menuIsOpen ? 0 : 0,
                            boxShadow: 'none',
                            minHeight: '36px',
                          }),
                        }}
                        components={{
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null,
                        }}
                      />

                      <Flex align={'center'} gap={'sm'}>
                        <MantineButton
                          onClick={() => {
                            setShowAddLabelSelector(false);
                            setSelectedLabels([]);
                          }}
                          variant='transparent'
                          radius={'md'}
                          size={'compact-md'}
                          c={'black'}
                          fw={'400'}
                        >
                          {' '}
                          Cancel
                        </MantineButton>
                        <MantineButton
                          onClick={handleAddLabels}
                          disabled={selectedLabels.length <= 0}
                          variant='filled'
                          radius={'md'}
                          size={'md'}
                        >
                          Add Labels
                        </MantineButton>
                      </Flex>
                    </Flex>
                  )}
                  {!isLabelsLoading() && opportunityLabels.length != 0 && (
                    <>
                      <Flex gap={'md'} wrap={'wrap'}>
                        {labelsToShow.map((label) => (
                          <Badge
                            key={label.id}
                            color={`#${label.color}`}
                            p={'8px 12px'}
                            fw={'500'}
                            radius={'8px'}
                            h={'32px'}
                            rightSection={
                              <Flex
                                align={'center'}
                                bg={'rgb(255,255,255,0.8)'}
                                style={{
                                  borderRadius: '100%',
                                  cursor: 'pointer',
                                }}
                                onClick={() => handeLabelClose(label.id)}
                                ml={'14px'}
                              >
                                <UilTimes size={16} color={'#6C6D6E'} />
                              </Flex>
                            }
                          >
                            <Text
                              tt={'none'}
                              size={'md'}
                              fw={'500'}
                              c={'black'}
                            >
                              {label.text}
                            </Text>
                          </Badge>
                        ))}
                      </Flex>
                      {opportunityLabels.length > 3 && (
                        <Group
                          gap={'0px'}
                          align='center'
                          style={{ cursor: 'pointer' }}
                          w={'max-content'}
                          onClick={() =>
                            setLabelsToShow(
                              labelsToShow.length > 3
                                ? opportunityLabels.slice(0, 3)
                                : opportunityLabels
                            )
                          }
                        >
                          <Text c={'blue.6'}>
                            {labelsToShow.length > 3
                              ? `Show fewer labels`
                              : `Show all ${opportunityLabels.length} labels`}
                          </Text>
                          {labelsToShow.length > 3 ? (
                            <UilAngleDoubleUp color='#43558E' />
                          ) : (
                            <UilAngleDoubleDown color='#43558E' />
                          )}
                        </Group>
                      )}
                    </>
                  )}
                  {!isLabelsLoading() && opportunityLabels.length == 0 && (
                    <Text c={'gray.6'}>No labels added</Text>
                  )}

                  {isLabelsLoading() && <Spinner centered />}
                </Stack>
              </Box>
              {/* Labels Section Ends */}

              <Divider
                bg={'linear-gradient(0deg, #E0E0E0 0%, #E0E0E0 100%), #FFF'}
              />

              {/* Begin the Documents section */}
              <Box px={'24px'}>
                <Stack my={'xl'} gap={'lg'}>
                  <Text c={'black'} fw={'700'}>
                    Documents
                  </Text>

                  {isAttachmentsLoading() && <Spinner centered />}

                  {attachmentsError && (
                    <ErrorMessageWrapper>
                      <Message type='error'>{attachmentsError}</Message>
                    </ErrorMessageWrapper>
                  )}
                  {addLabelsError && (
                    <ErrorMessageWrapper>
                      <Message type='error'>{addLabelsError}</Message>
                    </ErrorMessageWrapper>
                  )}
                  {batchAttachmentsError && (
                    <ErrorMessageWrapper>
                      <Message type='error'>{batchAttachmentsError}</Message>
                    </ErrorMessageWrapper>
                  )}
                  {deleteAttachmentError && (
                    <ErrorMessageWrapper>
                      <Message type='error'>{deleteAttachmentError}</Message>
                    </ErrorMessageWrapper>
                  )}
                  {attachmentsUploadError && (
                    <ErrorMessageWrapper>
                      <Message type='error'>{attachmentsUploadError}</Message>
                    </ErrorMessageWrapper>
                  )}
                  {editBatchError && (
                    <ErrorMessageWrapper>
                      <Message type='error'>{editBatchError}</Message>
                    </ErrorMessageWrapper>
                  )}
                  {updateOpportunityError && (
                    <ErrorMessageWrapper>
                      <Message type='error'>{updateOpportunityError}</Message>
                    </ErrorMessageWrapper>
                  )}
                  {editBatchMetadataError && (
                    <ErrorMessageWrapper>
                      <Message type='error'>{editBatchMetadataError}</Message>
                    </ErrorMessageWrapper>
                  )}
                  {editOpportunityLabelsError && (
                    <ErrorMessageWrapper>
                      <Message type='error'>
                        {editOpportunityLabelsError}
                      </Message>
                    </ErrorMessageWrapper>
                  )}
                  {fileUploadValidationError &&
                    fileUploadValidationError != '' && (
                      <ErrorMessageWrapper>
                        <Message type='error'>
                          {fileUploadValidationError}
                        </Message>
                      </ErrorMessageWrapper>
                    )}
                  {commentsError && (
                    <ErrorMessageWrapper>
                      <Message type='error'>{commentsError}</Message>
                    </ErrorMessageWrapper>
                  )}
                  {batchCommentsError && (
                    <ErrorMessageWrapper>
                      <Message type='error'>{batchCommentsError}</Message>
                    </ErrorMessageWrapper>
                  )}
                  {createCommentError && (
                    <ErrorMessageWrapper>
                      <Message type='error'>{createCommentError}</Message>
                    </ErrorMessageWrapper>
                  )}
                  {createBatchCommentError && (
                    <ErrorMessageWrapper>
                      <Message type='error'>{createBatchCommentError}</Message>
                    </ErrorMessageWrapper>
                  )}
                  {tasksError && (
                    <ErrorMessageWrapper>
                      <Message type='error'>{tasksError}</Message>
                    </ErrorMessageWrapper>
                  )}
                  {createTaskError && (
                    <ErrorMessageWrapper>
                      <Message type='error'>{createTaskError}</Message>
                    </ErrorMessageWrapper>
                  )}
                  {updateTaskError && (
                    <ErrorMessageWrapper>
                      <Message type='error'>{updateTaskError}</Message>
                    </ErrorMessageWrapper>
                  )}
                  {deleteTaskError && (
                    <ErrorMessageWrapper>
                      <Message type='error'>{deleteTaskError}</Message>
                    </ErrorMessageWrapper>
                  )}

                  {dynamicAttachments.length != 0 &&
                    !isAttachmentsLoading() && (
                      <>
                        <MantineGrid columns={2} gutter={'lg'}>
                          {documentsToShow.map((attachment) => (
                            <MantineGrid.Col key={attachment.id} span={1}>
                              <FileThumbnailPreview
                                href={attachment.attachmentUrl}
                                onClose={() => {
                                  setDocumentSelectedforDeletion(attachment.id);
                                  setShowDeleteDocumentModal(true);
                                }}
                                title={decodeURIComponent(
                                  extractFileNameInfo(attachment.filename).name
                                )}
                                metaText={[
                                  extractFileNameInfo(attachment.filename)
                                    .extension,
                                ]}
                                fileIcon={
                                  <UilFileAlt size='24' color={'#485DA0'} />
                                }
                              />
                            </MantineGrid.Col>
                          ))}
                        </MantineGrid>
                        {dynamicAttachments.length > 4 && (
                          <Group
                            gap={'0px'}
                            align='center'
                            onClick={() =>
                              setDocumentsToShow(
                                documentsToShow.length > 4
                                  ? dynamicAttachments.slice(0, 4)
                                  : dynamicAttachments
                              )
                            }
                            style={{ cursor: 'pointer' }}
                            w={'max-content'}
                          >
                            <Text c={'blue.6'}>
                              {documentsToShow.length > 4
                                ? `Show fewer documents`
                                : `Show all ${dynamicAttachments.length} documents`}
                            </Text>
                            {documentsToShow.length > 4 ? (
                              <UilAngleDoubleUp color='#43558E' />
                            ) : (
                              <UilAngleDoubleDown color='#43558E' />
                            )}
                          </Group>
                        )}
                      </>
                    )}
                  {!isAttachmentsLoading() &&
                    dynamicAttachments.length == 0 && (
                      <Text c={'gray.6'}>No attached Documents</Text>
                    )}
                </Stack>
              </Box>
              {/* Documents Section Ends */}

              <Divider
                bg={'linear-gradient(0deg, #E0E0E0 0%, #E0E0E0 100%), #FFF'}
              />

              {/* Begin the Tasks section */}
              <Box pr={'24px'}>
                <ProjectTasksSection
                  isTasksLoading={isTasksLoading()}
                  projectTasks={currentProjectTasks}
                  projectId={opportunitySeedId}
                  isBatch={isBatch}
                  currentCustomerId={currentCustomerId}
                  setShowDeleteTaskModal={setShowDeleteTaskModal}
                  setTaskSelectedforRemoval={setTaskSelectedforRemoval}
                />
              </Box>
              {/* Tasks Section Ends */}

              <Divider
                bg={'linear-gradient(0deg, #E0E0E0 0%, #E0E0E0 100%), #FFF'}
              />

              {/* Begin the comments section */}
              <Box px={'24px'} ref={targetRef}>
                <CommentSection
                  comments={dynamicComments}
                  attachments={dynamicAttachments}
                  setDocumentSelectedforDeletion={
                    setDocumentSelectedforDeletion
                  }
                  setShowDeleteDocumentModal={setShowDeleteDocumentModal}
                  isCommentsLoading={isCommentsLoading()}
                  isAttachmentsLoading={isAttachmentsLoading()}
                />
              </Box>
              {/* Comments Section Ends */}

              <Divider
                bg={'linear-gradient(0deg, #E0E0E0 0%, #E0E0E0 100%), #FFF'}
              />

              {/* Begin the Followers section */}
              <Box px={'24px'}>
                <Followers
                  isBatch={isBatch}
                  opportunitySeedId={opportunitySeedId}
                  opportunityAsSeed={opportunityAsSeed}
                  opportunityAsBatch={opportunityAsBatch}
                />
              </Box>

              {/* Comments Section Ends */}
              {isBatch && (
                <Divider
                  bg={'linear-gradient(0deg, #E0E0E0 0%, #E0E0E0 100%), #FFF'}
                />
              )}

              {/* Child Opportunities section */}
              {isBatch &&
                childSeeds &&
                childSeeds.length > 0 &&
                (!editBatchLoading ? (
                  <Box px={'24px'}>
                    <ChildOpportunities
                      childSeeds={childSeeds}
                      batch={opportunityAsBatch}
                      setOpportunitySelectedforRemoval={
                        setOpportunitySelectedforRemoval
                      }
                      setShowRemoveChildFromBatchModal={
                        setShowRemoveChildFromBatchModal
                      }
                    />
                  </Box>
                ) : (
                  <Spinner centered />
                ))}
            </ModalContentReStyled>
          )}
          {/* Footer Section Starts */}
          <Stack
            p={'16px 24px'}
            style={{ boxShadow: '0px -1px 14px 0px rgba(0, 0, 0, 0.12)' }}
          >
            <Group>
              {filesSelectedForUpload.length != 0 && (
                <FilePreviewWrapper>
                  {filesSelectedForUpload.map((file, ix) => (
                    <FileThumbnailPreview
                      key={ix}
                      title={decodeURIComponent(file.name)}
                      variant='micro'
                    />
                  ))}
                </FilePreviewWrapper>
              )}
            </Group>

            <Flex
              align={'center'}
              gap={'lg'}
              bg={'white'}
              style={{ borderRadius: '8px', border: '1px solid #e0e0e0' }}
              p={'xs'}
              {...getRootProps({ className: 'dropzone' })}
            >
              <Textarea
                onChange={(e) =>
                  handleChangeCommentText(e.currentTarget.value || '')
                }
                p={'0 10px'}
                autosize
                autoFocus={!!locationSearchObj.shouldFocusToComments}
                w={'100%'}
                size='md'
                variant='unstyled'
                rightSectionWidth={'140px'}
                rightSection={
                  <Flex gap={'lg'}>
                    <Group
                      onClick={handleAttachFile}
                      style={{ cursor: 'pointer' }}
                      c={'black'}
                    >
                      <input {...getInputProps()} />
                      <UilPaperclip size={20} />
                    </Group>
                    <MantineButton
                      onClick={handleAddComment}
                      variant='primary'
                      radius={'md'}
                      size='compact-md'
                    >
                      Add Comment
                    </MantineButton>
                  </Flex>
                }
                value={commentText}
                placeholder='Add a comment or ask a question...'
                error={inputAddCommentValidationError}
                withErrorStyles
                inputWrapperOrder={['label', 'description', 'error', 'input']}
              />
            </Flex>
          </Stack>
        </OpportunityModalBodyStyled>
      </ModalWrapperStyled>
    </DefaultModalStyled>
  );
};

export default OpportunityDetailModal;
