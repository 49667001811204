/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { getDateRange } from '../../api/utils';
import {
  DATE_FORMAT_DATA_API_REQUEST,
  DATE_YEAR_MONTH_DAY_FORMAT,
} from '../../constants';
import {
  actions as circuitsMetaDataActions,
  selectCircuitMetaDataEntity,
} from '../../ducks/circuit/circuitMeta';
import {
  TSCustomersEntityState,
  selectCustomersEntity,
} from '../../ducks/customers';
import {
  actions as accountSavedQueriesActions,
  selectAccountSavedQueriesEntity,
} from '../../ducks/savedQueries/accountQueries';
import { TSSiteTabs, selectSitesEntity } from '../../ducks/sites';
import { Period, ResourceType, TSQuerySearchParams } from '../../ducks/types';
import { usePrevious } from '../../hooks/usePrevious';
import { defaultGroupingOptions } from '../../pages/BuildingInsights/useBuildingInsightFilters';
import { naturallySortItems } from '../../utils';
import {
  defaultQuerySearchParams,
  getResolutionOptions,
  getUrlSearchParams,
  isSingleSite,
} from '../../utils/getUrlSearchParams';
import { TSOption, allOption } from '../ListSelector';
interface TSSelectedValuesByGroup {
  filterType: string;
  filterSelectedValues: string[];
}

const filtersType = [
  'site',
  'panel',
  'buildingSystem',
  'equipment',
  'circuit',
  'categoryValue',
  'meter',
];

const containsAll = (arr1: string[], arr2: string[]) =>
  arr2.every((arr2Item) => arr1.includes(arr2Item));

const sameMembers = (arr1: string[], arr2: string[]) =>
  containsAll(arr1, arr2) && containsAll(arr2, arr1);

export const selectedValuesByGroupInitialState = (
  searchParams: Partial<TSQuerySearchParams>
) => {
  return filtersType.map((param) => ({
    filterType: param,
    filterSelectedValues: searchParams[param]
      ? searchParams[param].split(',')
      : [],
  }));
};

type TSProps = {
  currentTab: TSSiteTabs;
  onClose: any;
};

const useDrawerFilters = ({ currentTab, onClose }: TSProps) => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  // The serialize function here would be responsible for
  // creating an object of { key: value } pairs from the
  // fields in the form that make up the query.
  const serializeFormQuery = useMemo(() => {
    return Object.fromEntries(searchParams);
  }, [searchParams]);

  const {
    items: circuitMetaFilters,
    meta: {
      loading: circuitMetaLoading,
      error: circuitMetaError,
      noCircuitMetaData,
    },
  } = useSelector(selectCircuitMetaDataEntity);
  const {
    byId: sitesById,
    items: sites,
    meta: { loading: sitesLoading },
  } = useSelector(selectSitesEntity);
  const { currentCustomerId }: TSCustomersEntityState = useSelector(
    selectCustomersEntity
  );

  const { selectedQueryId: reduxSelectedQueryId } = useSelector(
    selectAccountSavedQueriesEntity
  );

  const [filtersObject, setFiltersObject]: [
    Partial<TSQuerySearchParams>,
    (TSQuerySearchParams) => void,
  ] = useState(serializeFormQuery as Partial<TSQuerySearchParams>);

  const [menuOpen, setMenuOpen]: [boolean, (boolean) => void] = useState(false);
  const [siteMenuOpen, setSiteMenuOpen]: [boolean, (boolean) => void] =
    useState(false);
  const [changingFilter, setChangingFilter]: [string, (string) => void] =
    useState('');
  const [selectedValuesbyGroup, setSelectedValuesbyGroup]: [
    Array<TSSelectedValuesByGroup>,
    (string) => void,
  ] = useState([]);

  useEffect(() => {
    setFiltersObject(serializeFormQuery);
  }, [serializeFormQuery]);

  useEffect(() => {
    setSelectedValuesbyGroup(selectedValuesByGroupInitialState(filtersObject));
  }, [filtersObject]);

  const selectedSitesId = useMemo(() => {
    const { site = '' } = filtersObject;
    return site?.split(',') ?? [];
  }, [filtersObject]);

  const resolutionOptions =
    getResolutionOptions(currentTab, filtersObject?.resourceType, {
      fromDate: filtersObject.fromDate,
      toDate: filtersObject.toDate,
    }) ?? [];

  const previousSelectedValuesbyGroup = usePrevious({
    selectedValuesbyGroup,
    setSelectedValuesbyGroup,
  });
  const previousSelectedSitesId = usePrevious({
    selectedSitesId,
  });

  const circuitMetaFiltersNoSite = useMemo(() => {
    return circuitMetaFilters.filter((item) => item.fieldType !== 'site');
  }, [circuitMetaFilters]);

  const selectedPanels = useMemo(() => {
    const selectedPanels = selectedValuesbyGroup.filter(
      (option) => option.filterType === 'panel'
    );
    return selectedPanels[0]?.filterSelectedValues || [];
  }, [selectedValuesbyGroup]);

  const selectedBuildingSystems = useMemo(() => {
    const selectedBuildingSystems = selectedValuesbyGroup.filter(
      (option) => option.filterType === 'buildingSystem'
    );
    return selectedBuildingSystems[0]?.filterSelectedValues || [];
  }, [selectedValuesbyGroup]);

  const selectedEquipment = useMemo(() => {
    const selectedEquipment = selectedValuesbyGroup.filter(
      (option) => option.filterType === 'equipment'
    );
    return selectedEquipment[0]?.filterSelectedValues || [];
  }, [selectedValuesbyGroup]);

  const selectedResolution = useMemo(() => {
    return resolutionOptions.filter(
      (resolution) => resolution.id === filtersObject.resolution
    )[0];
  }, [filtersObject.resolution]);

  useEffect(() => {
    if (circuitMetaFilters.length) {
      const updatedSelectedValues = selectedValuesbyGroup.map((group) => {
        if (group.filterType !== 'categoryValue') {
          const groupCircuitMetaFilters = circuitMetaFilters.find(
            (filter) => filter.fieldType === group.filterType
          );
          const updateFilterSelectdValues = group.filterSelectedValues.filter(
            (id) => {
              return groupCircuitMetaFilters?.values.some(
                (value) => value.id === id
              );
            }
          );
          return {
            ...group,
            filterSelectedValues: updateFilterSelectdValues,
          };
        }
        return group;
      });
      setSelectedValuesbyGroup(updatedSelectedValues);
    }
  }, [circuitMetaFilters]);

  useEffect(() => {
    if (currentTab) {
      const validatedParams = getUrlSearchParams(
        serializeFormQuery,
        currentTab,
        sites,
        false
      );
      setSearchParams(validatedParams);
    }
  }, [serializeFormQuery, currentTab]);

  const sortedSitesItems = useMemo(
    () =>
      sites
        .map((site) => ({
          id: site.id,
          name: site.validName,
        }))
        .sort(naturallySortItems),
    [sites]
  );

  const selectedSitesItems = useMemo(
    () => sortedSitesItems.filter((item) => selectedSitesId.includes(item.id)),
    [sortedSitesItems, selectedSitesId]
  );

  const unselectedSitesItems = useMemo(
    () => sortedSitesItems.filter((item) => !selectedSitesId.includes(item.id)),
    [sortedSitesItems, selectedSitesId]
  );

  const sitesItems = useMemo(
    () => [...selectedSitesItems, ...unselectedSitesItems],
    [selectedSitesItems, unselectedSitesItems]
  );

  const groupingOptions = useMemo(() => {
    if (!circuitMetaLoading && !circuitMetaError) {
      const categoryGroupOptions = circuitMetaFilters.map((category) => {
        return {
          name: category.name,
          id: category.id,
        };
      });
      return categoryGroupOptions;
    }
    return defaultGroupingOptions;
  }, [circuitMetaFilters, circuitMetaLoading, circuitMetaError]);

  const getProgramStartDate = useCallback(() => {
    if (sites.length && !sitesLoading) {
      const sitesId = selectedSitesId[0]
        ? selectedSitesId
        : sites.map((site) => site.id);
      const selectedSitesIngestionDate = sitesId.map((site) =>
        moment(sitesById[site]?.ingestionDataStart)
      );

      return moment(
        moment.min(selectedSitesIngestionDate),
        DATE_FORMAT_DATA_API_REQUEST
      );
    }
    return moment('2010', 'YYYY');
  }, [selectedSitesId, sitesById, sitesLoading, sites]);

  const valuesMultiAlphaSort = useCallback(
    (values, group) => {
      const groupSelected =
        selectedValuesbyGroup.filter((option) => option.filterType === group) ||
        [];
      const groupSelectedValues = groupSelected[0]?.filterSelectedValues || [];
      const currentGroupValues = [...values];

      if (groupSelectedValues.length || !menuOpen) {
        groupSelectedValues.map((item) =>
          currentGroupValues.sort((x, y) => {
            if (x.id === item) {
              return -1;
            }
            if (y.id === item) {
              return 1;
            } else {
              return 0;
            }
          })
        );

        return currentGroupValues.map((item) => ({
          ...item,
        }));
      }
      return currentGroupValues.sort(naturallySortItems);
    },
    [selectedValuesbyGroup, menuOpen]
  );

  const selectedGrouping = useMemo(() => {
    if (filtersObject.grouping) {
      if (filtersObject.groupingId) {
        // remove .split.join once real ids are flowing through
        return groupingOptions.find(
          (item) => item.id === filtersObject?.groupingId?.split('+').join(' ')
        );
      }
      return groupingOptions.find((item) => item.id === filtersObject.grouping);
    }
    return groupingOptions.find((item) => item.id === 'buildingSystem');
  }, [filtersObject, groupingOptions]);

  const handleMenuOpen = useCallback(() => {
    return setMenuOpen(!menuOpen);
  }, [menuOpen]);

  const handleMenuClose = useCallback(() => {
    const { selectedValuesbyGroup: previousSelectedValuesByGroup } =
      previousSelectedValuesbyGroup;
    // If either buildingSystem or equipment filters changes we need to re-fetch meta data
    const previousChangingFilter = previousSelectedValuesByGroup.find(
      (group) => group.filterType === changingFilter
    );
    const previousSelectedValues =
      previousChangingFilter?.filterSelectedValues || [];

    const currentChangingFilter = selectedValuesbyGroup.find(
      (group) => group.filterType === changingFilter
    );
    const currentSelectedValues =
      currentChangingFilter?.filterSelectedValues || [];

    if (
      ['panel', 'buildingSystem', 'equipment'].includes(changingFilter) &&
      !sameMembers(previousSelectedValues, currentSelectedValues)
    ) {
      const payload = {
        customerId: currentCustomerId,
        site: selectedSitesId.join(','),
        panel: selectedPanels.length ? selectedPanels.join(',') : '',
        buildingSystem: selectedBuildingSystems.length
          ? selectedBuildingSystems.join(',')
          : '',
        equipment: selectedEquipment.length ? selectedEquipment.join(',') : '',
        resourceType: filtersObject.resourceType ?? ResourceType.ELECTRICITY,
      };
      dispatch(circuitsMetaDataActions.fetchCircuitMetaData(payload));
    }
    return setMenuOpen(false);
  }, [selectedValuesbyGroup, previousSelectedValuesbyGroup, changingFilter]);

  const handleSiteMenuOpen = useCallback(() => {
    return setSiteMenuOpen(true);
  }, []);

  const handleSiteMenuClose = useCallback(() => {
    if (
      !sameMembers(previousSelectedSitesId?.selectedSitesId, selectedSitesId)
    ) {
      onChangeSelectedSite(selectedSitesId.join(','));
    }
    setSiteMenuOpen(false);
  }, [selectedSitesId, previousSelectedSitesId]);

  const onChangeSelectedSite = useCallback(
    (newSiteIds: string) => {
      const payload = {
        customerId: currentCustomerId,
        site: newSiteIds,
        buildingSystem: selectedBuildingSystems.length
          ? selectedBuildingSystems.join(',')
          : '',
        // TODO: persisting the equipment when changing sites causes a bug if you had some equipment
        // selected that does not belong to the sites you're now selecting
        equipment:
          !isSingleSite(currentTab) && selectedEquipment.length
            ? selectedEquipment.join(',')
            : '',
        resourceType: filtersObject.resourceType ?? ResourceType.ELECTRICITY,
      };

      dispatch(circuitsMetaDataActions.fetchCircuitMetaData(payload));
    },
    [selectedBuildingSystems, selectedEquipment]
  );

  const selectedOptionsItems = useCallback(
    (group: string) => {
      const parseGroupName = group.includes('categoryValue')
        ? 'categoryValue'
        : group;
      const groupSelected = selectedValuesbyGroup.filter(
        (option) => option.filterType === parseGroupName
      );
      const groupOptions = circuitMetaFilters.find(({ fieldType, name }) => {
        const categoryValueName = group.split('-')[1];
        return categoryValueName
          ? name === categoryValueName
          : fieldType === parseGroupName;
      });

      if (!groupSelected[0]?.filterSelectedValues.length) {
        return [];
      }
      return groupOptions?.values.filter(
        (option) => groupSelected[0]?.filterSelectedValues.includes(option.id)
      );
    },
    [selectedValuesbyGroup, circuitMetaFilters]
  );

  const updateSelectedOptions = (
    values: string[],
    filterName: string,
    action?: string
  ): Array<TSSelectedValuesByGroup> => {
    return selectedValuesbyGroup.map((option) => {
      if (option.filterType === filterName) {
        const updatedCategoryValues =
          action === 'deselect-option'
            ? [
                ...new Set(
                  [...option.filterSelectedValues].filter(
                    (valueId) => !values.includes(valueId)
                  )
                ),
              ]
            : [...new Set([...option.filterSelectedValues, ...values])];

        return {
          ...option,
          filterSelectedValues:
            filterName === 'categoryValue' ? updatedCategoryValues : values,
        };
      }
      return option;
    });
  };

  const handleUpdateValueMulti = useCallback(
    (newValues: Array<TSOption>, actionMeta) => {
      const {
        name,
        action,
        option: { value },
      } = actionMeta;
      const newSelectedValues: Array<string> = newValues
        .map((newValue) => newValue.value)
        .filter((value) => value !== allOption.label);

      setChangingFilter(name);
      let updatedSelectedValues: Array<TSSelectedValuesByGroup>;

      const groupOptions = circuitMetaFilters.find(({ fieldType }) => {
        return fieldType === name;
      });
      const groupOptionsValues = groupOptions?.values || [];

      if (value === allOption.value) {
        if (action === 'deselect-option') {
          updatedSelectedValues = updateSelectedOptions([], name);
          return setSelectedValuesbyGroup(updatedSelectedValues);
        } else {
          updatedSelectedValues = updateSelectedOptions(
            groupOptionsValues.map((item) => item.id),
            name
          );
          return setSelectedValuesbyGroup(updatedSelectedValues);
        }
      } else {
        updatedSelectedValues = updateSelectedOptions(newSelectedValues, name);
        return setSelectedValuesbyGroup(updatedSelectedValues);
      }
    },
    [circuitMetaFilters, selectedValuesbyGroup]
  );

  const handleCategoryValueMulti = useCallback(
    (newValues: Array<TSOption>, actionMeta) => {
      const {
        name,
        action,
        option: { value },
      } = actionMeta;
      const newSelectedValues = newValues.map((newValue) => newValue.value);
      const groupName = name.split('-')[0];
      const categoryValueName = name.split('-')[1];
      let updatedSelectedValues;
      setChangingFilter(groupName);

      const groupOptions = circuitMetaFilters.find(({ name }) => {
        return name === categoryValueName;
      });
      const groupOptionsValues = groupOptions?.values || [];

      if (value === allOption.value) {
        if (action === 'deselect-option') {
          updatedSelectedValues = updateSelectedOptions(
            groupOptionsValues.map(({ id }) => id),
            groupName,
            action
          );
          return setSelectedValuesbyGroup(updatedSelectedValues);
        } else {
          updatedSelectedValues = updateSelectedOptions(
            groupOptionsValues.map((item) => item.id),
            groupName
          );
          return setSelectedValuesbyGroup(updatedSelectedValues);
        }
      } else {
        if (action === 'deselect-option') {
          updatedSelectedValues = updateSelectedOptions(
            [value],
            groupName,
            action
          );
          return setSelectedValuesbyGroup(updatedSelectedValues);
        } else {
          updatedSelectedValues = updateSelectedOptions(
            newSelectedValues,
            groupName
          );
          return setSelectedValuesbyGroup(updatedSelectedValues);
        }
      }
    },
    [circuitMetaFilters, selectedValuesbyGroup]
  );

  const handleResetFilters = useCallback(() => {
    const updateUrlParams = Object.fromEntries(
      getUrlSearchParams(defaultQuerySearchParams, currentTab, sites, false)
    );
    // if single site, try to preserve the existing site:
    if (isSingleSite(currentTab)) {
      updateUrlParams.site = filtersObject.site;
    }
    setSelectedValuesbyGroup(
      selectedValuesByGroupInitialState(updateUrlParams as TSQuerySearchParams)
    );
    dispatch(accountSavedQueriesActions.switchSelectedQuery(''));
    setSearchParams(updateUrlParams);
    onClose();
  }, [currentTab, filtersObject]);

  const handleApplyFilters = useCallback(() => {
    const updatedUrlParams = { ...filtersObject };
    selectedValuesbyGroup.forEach((option) => {
      const filterSelectAll = option.filterSelectedValues.filter(
        (option) => option !== allOption.value
      );
      updatedUrlParams[option.filterType] = filterSelectAll.join(',');
    });
    setSearchParams(updatedUrlParams);
    onClose();
  }, [selectedValuesbyGroup, selectedSitesId, filtersObject]);

  const handleUpdateDateRange = useCallback(
    ({ startDate, endDate }) => {
      const start = moment(startDate)?.isValid()
        ? moment(startDate)
        : moment(filtersObject.fromDate, DATE_YEAR_MONTH_DAY_FORMAT);
      const end = moment(endDate)?.isValid()
        ? moment(endDate)
        : moment(filtersObject.toDate, DATE_YEAR_MONTH_DAY_FORMAT);

      setFiltersObject({
        ...filtersObject,
        fromDate: start.startOf('day').format(DATE_YEAR_MONTH_DAY_FORMAT),
        toDate: end.endOf('day').format(DATE_YEAR_MONTH_DAY_FORMAT),
        period: Period.CUSTOM,
      });
    },
    [filtersObject]
  );

  const handleMultiSiteValueUpdate = useCallback(
    (newValues: Array<TSOption>, actionMeta: any) => {
      const {
        action,
        option: { value },
      } = actionMeta;
      let selectedSitesId = newValues.map((item) => item.value);

      if (value === allOption.value) {
        if (action === 'deselect-option') {
          selectedSitesId = [];
        } else {
          selectedSitesId = sitesItems.map((item) => item.id);
        }
      }

      setFiltersObject({
        ...filtersObject,
        site: selectedSitesId.filter((id) => id !== allOption.value).join(','),
      });
    },
    [sitesItems, selectedSitesId, filtersObject]
  );

  const handleSingleSiteValueUpdate = useCallback(
    (newValue: TSOption) => {
      const selectedSiteId = newValue.value;

      setFiltersObject({
        ...filtersObject,
        site: selectedSiteId,
      });

      onChangeSelectedSite(selectedSiteId);
    },
    [selectedSitesId, filtersObject]
  );

  const handleGroupingUpdate = useCallback(
    ({ value }: { value: string }) => {
      const defaultOptionsValues = defaultGroupingOptions.map(
        (option) => option.id
      );

      setFiltersObject({
        ...filtersObject,
        grouping: defaultOptionsValues.includes(value)
          ? value
          : 'categoryValue',
        groupingId: defaultOptionsValues.includes(value) ? '' : value,
      });
    },
    [filtersObject]
  );

  const handleResolutionUpdate = useCallback(
    ({ value }: { value: string }) => {
      setFiltersObject({
        ...filtersObject,
        resolution: value.toLocaleLowerCase(),
      });
    },
    [filtersObject]
  );

  const handleResourceTypeUpdate = useCallback(
    ({ value }: { value: string }) => {
      setFiltersObject({
        ...filtersObject,
        resourceType: value,
      });
    },
    []
  );

  const urlParamsFromToDates = useMemo(() => {
    const { fromDate, toDate, period = Period.CUSTOM } = filtersObject;
    const { startDate, endDate } = getDateRange(fromDate, toDate, period);

    return {
      startDate: moment(startDate).toDate(),
      endDate: moment(endDate).toDate(),
    };
  }, [filtersObject]);

  return {
    circuitMetaError,
    circuitMetaFiltersNoSite,
    circuitMetaLoading,
    filtersObject,
    setFiltersObject,
    selectedSitesId,
    getProgramStartDate,
    groupingOptions,
    handleApplyFilters,
    handleResetFilters,
    handleCategoryValueMulti,
    handleGroupingUpdate,
    handleMenuClose,
    handleMenuOpen,
    handleResolutionUpdate,
    handleResourceTypeUpdate,
    handleSiteMenuClose,
    handleSiteMenuOpen,
    handleMultiSiteValueUpdate,
    handleSingleSiteValueUpdate,
    handleUpdateDateRange,
    handleUpdateValueMulti,
    noCircuitMetaData,
    selectedGrouping,
    selectedOptionsItems,
    selectedResolution,
    selectedSitesItems,
    siteMenuOpen,
    sitesItems,
    valuesMultiAlphaSort,
    setSelectedValuesbyGroup,
    reduxSelectedQueryId,
    resolutionOptions,
    urlParamsFromToDates,
  };
};

export default useDrawerFilters;
