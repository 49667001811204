import { queryOptions, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { defaultHeaders, gcApiBaseUrl } from '../api';
import { getUserId } from '../api/utils';

export type PermissionsResponse = {
  groups: string[];
  permissions: string[];
};

// API Calls
const fetchUserPermissions = () => {
  const url = `${gcApiBaseUrl()}/permissions`;
  return axios
    .get<PermissionsResponse>(url, { headers: defaultHeaders() })
    .then(({ data }) => data);
};

// Shared Options
const sharedOptions = () => {
  const currentUserId = getUserId();
  return queryOptions({
    queryKey: ['permissions', currentUserId],
    queryFn: fetchUserPermissions,
    staleTime: 1000 * 60 * 60 * 24, // Cache for 24hrs
    enabled: !!currentUserId,
  });
};

// Queries with Selectors
export const useUserHasPermissionGroup = (group: string) => {
  return useQuery({
    ...sharedOptions(),
    select: (data) => data.groups?.includes(group),
  });
};
