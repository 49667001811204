import axios, { AxiosError } from 'axios';
import { combineReducers } from 'redux';
import { call, put, takeLatest } from 'redux-saga/effects';
import { apiBaseUrl, defaultHeaders, gcApiBaseUrl } from '../api/index';
import {
  handleAxiosError,
  handleSagaError,
  queryStringify,
} from '../api/utils';
import {
  STORAGE_IMPERSONATE_CUSTOMER,
  STORAGE_LOGIN_TOKEN,
  STORAGE_USERNAME,
  STORAGE_USER_ID,
} from '../constants/api';
import sessionsMockData from '../mockData/sessions';
import { emptyEntityState } from '../reducers/entities';
import { isVariantActive } from '../utils/variants';
import { TSMetaState } from './types';

export interface TSSessionResponse {
  expiry: string;
  sessionToken: string;
  userId: string;
}
interface TSUserRolesAndPermissions {
  permissions: { [key: string]: string };
  groups: Array<string>;
}

interface TSAuthState {
  forgotPassword: TSMetaState;
  login: TSMetaState;
  logout: TSMetaState;
  resetPassword: TSMetaState;
  signup: TSMetaState;
  userRolesAndPermissions: TSUserRolesAndPermissions;
  userRolesAndPermissionsMeta: TSMetaState;
}

interface TSState {
  auth: TSAuthState;
}

export interface TSForgotPasswordAction {
  username: string;
}

export interface TSLoginAction {
  password: string;
  username: string;
}

export interface TSResetPasswordAction {
  password: string;
  resetId: string;
}

export interface TSSignupAction {
  password: string;
  signupId: string;
}

export const types = {
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  FORGOT_PASSWORD_ERROR: 'FORGOT_PASSWORD_ERROR',
  FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
  INITIALIZE_STATE: 'INITIALIZE_STATE',
  LOGIN: 'LOGIN',
  LOGIN_ERROR: 'LOGIN_ERROR',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGOUT: 'LOGOUT',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  PASSWORD_EXPIRED: 'PASSWORD_EXPIRED',
  RESET_PASSWORD: 'RESET_PASSWORD',
  RESET_PASSWORD_ERROR: 'RESET_PASSWORD_ERROR',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  SIGNUP: 'SIGNUP',
  SIGNUP_ERROR: 'SIGNUP_ERROR',
  SIGNUP_SUCCESS: 'SIGNUP_SUCCESS',
  GET_USER_ROLES_AND_PERMISSIONS: 'GET_USER_ROLES_AND_PERMISSIONS',
  GET_USER_ROLES_AND_PERMISSIONS_SUCCESS:
    'GET_USER_ROLES_AND_PERMISSIONS_SUCCESS',
  GET_USER_ROLES_AND_PERMISSIONS_ERROR: 'GET_USER_ROLES_AND_PERMISSIONS_ERROR',
};

export const actions = {
  forgotPassword: (params: TSForgotPasswordAction) => ({
    type: types.FORGOT_PASSWORD,
    ...params,
  }),
  initializeState: () => ({
    type: types.INITIALIZE_STATE,
    undefined,
  }),
  login: (params: TSLoginAction) => ({ type: types.LOGIN, ...params }),
  logout: () => ({
    type: types.LOGOUT,
  }),
  resetPassword: (params: TSResetPasswordAction) => ({
    type: types.RESET_PASSWORD,
    ...params,
  }),
  signup: (params: TSSignupAction) => ({ type: types.SIGNUP, ...params }),
  getUserRolesAndPermissions: () => ({
    type: types.GET_USER_ROLES_AND_PERMISSIONS,
  }),
};

export const initialState: TSAuthState = {
  forgotPassword: emptyEntityState.meta,
  login: emptyEntityState.meta,
  logout: emptyEntityState.meta,
  resetPassword: emptyEntityState.meta,
  signup: emptyEntityState.meta,
  userRolesAndPermissions: { permissions: {}, groups: [] },
  userRolesAndPermissionsMeta: emptyEntityState.meta,
};

const forgotPassword = (state = initialState.forgotPassword, action) => {
  switch (action.type) {
    case types.FORGOT_PASSWORD:
      return {
        error: '',
        loading: true,
      };
    case types.FORGOT_PASSWORD_ERROR:
      return {
        error: action.error,
        loading: false,
      };
    case types.FORGOT_PASSWORD_SUCCESS:
      return initialState.forgotPassword;
    default:
      return state;
  }
};

const login = (state = initialState.login, action) => {
  switch (action.type) {
    case types.LOGIN:
      return {
        error: '',
        loading: true,
      };
    case types.LOGIN_ERROR:
    case types.PASSWORD_EXPIRED:
      return {
        error: action.error,
        loading: false,
      };
    case types.LOGIN_SUCCESS:
      return initialState.login;
    default:
      return state;
  }
};

const logout = (state = initialState.logout, action) => {
  switch (action.type) {
    case types.LOGOUT:
      return {
        error: '',
        loading: true,
      };
    case types.LOGOUT_SUCCESS:
      return initialState.logout;
    default:
      return state;
  }
};

const resetPassword = (state = initialState.resetPassword, action) => {
  switch (action.type) {
    case types.RESET_PASSWORD:
      return {
        error: '',
        loading: true,
      };
    case types.RESET_PASSWORD_ERROR:
      return {
        error: action.error,
        loading: false,
      };
    case types.RESET_PASSWORD_SUCCESS:
      return initialState.resetPassword;
    default:
      return state;
  }
};

const signup = (state = initialState.signup, action) => {
  switch (action.type) {
    case types.SIGNUP:
      return {
        error: '',
        loading: true,
      };
    case types.SIGNUP_ERROR:
      return {
        error: action.error,
        loading: false,
      };
    case types.SIGNUP_SUCCESS:
      return initialState.resetPassword;
    default:
      return state;
  }
};

function userRolesAndPermissions(
  state = initialState.userRolesAndPermissions,
  action
) {
  switch (action.type) {
    case types.GET_USER_ROLES_AND_PERMISSIONS:
      return initialState.userRolesAndPermissions;
    case types.GET_USER_ROLES_AND_PERMISSIONS_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
const userRolesAndPermissionsMeta = (
  state = initialState.userRolesAndPermissionsMeta,
  action
) => {
  switch (action.type) {
    case types.GET_USER_ROLES_AND_PERMISSIONS:
      return {
        error: '',
        loading: true,
      };
    case types.GET_USER_ROLES_AND_PERMISSIONS_ERROR:
      return {
        error: action.error,
        loading: false,
      };
    case types.GET_USER_ROLES_AND_PERMISSIONS_SUCCESS:
      return initialState.resetPassword;
    default:
      return state;
  }
};

export default combineReducers({
  forgotPassword,
  login,
  logout,
  resetPassword,
  signup,
  userRolesAndPermissions,
  userRolesAndPermissionsMeta,
});

export const selectForgotPasswordMeta = (state: TSState): TSMetaState =>
  state.auth.forgotPassword;

export const selectLoginMeta = (state: TSState): TSMetaState =>
  state.auth.login;

export const selectLogoutMeta = (state: TSState): TSMetaState =>
  state.auth.logout;

export const selectResetPasswordMeta = (state: TSState): TSMetaState =>
  state.auth.resetPassword;

export const selectSignupMeta = (state: TSState): TSMetaState =>
  state.auth.signup;

export const selectUserRolesAndPermissions = (
  state: TSState
): TSUserRolesAndPermissions => state.auth.userRolesAndPermissions;

export const selectUserRolesAndPermissionsMeta = (
  state: TSState
): TSMetaState => state.auth.userRolesAndPermissionsMeta;

interface TSPasswordExpiredException {
  message: string;
  name: string;
  passwordResetId: string;
  toString: string;
}

const PasswordExpiredException = (
  passwordResetId: string
): TSPasswordExpiredException => ({
  message: 'Password Expired',
  name: 'PasswordExpiredException',
  passwordResetId,
  toString: 'PasswordExpiredException',
});

const handleLoginError = (error: AxiosError<any>): any => {
  const { response } = error || {};
  const { status, data } = response || {};
  const { message } = data || {};

  if (status === 419 && message === 'Password Expired') {
    const { passwordResetId } = data;
    throw PasswordExpiredException(passwordResetId);
  } else {
    handleAxiosError(error);
  }
};

const API = {
  forgotPassword({ username }: TSForgotPasswordAction): any {
    const url = `${gcApiBaseUrl()}/passwordreset`;
    const theme = 'DENALI';
    const postData = { username, theme };

    return axios
      .post(url, postData)
      .then(({ data }) => data)
      .catch(handleAxiosError);
  },

  login: ({ username, password }: TSLoginAction) => {
    if (isVariantActive('mock')) {
      return Promise.resolve(sessionsMockData).then(
        (data) =>
          new Promise((resolve) => setTimeout(() => resolve(data), 1000))
      );
    }

    const url = `${apiBaseUrl()}/sessions`;
    const query = queryStringify({ username, password });
    const headers = {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    };

    return axios
      .post(url, query, headers)
      .then(({ data }: { data: TSSessionResponse }) => data)
      .catch(handleLoginError);
  },

  resetPassword({ password, resetId }: TSResetPasswordAction): any {
    const url = `${gcApiBaseUrl()}/passwordreset/${resetId}`;
    const postData = { password };

    return axios
      .post(url, postData)
      .then(({ data }) => data)
      .catch(handleAxiosError);
  },

  signup({ password, signupId }: TSSignupAction): any {
    const url = `${apiBaseUrl()}/signups/activate/${signupId}`;
    const postData = { password };

    return axios
      .post(url, postData)
      .then(({ data }) => data)
      .catch(handleAxiosError);
  },
  getUserRolesAndPermissions() {
    const url = `${gcApiBaseUrl()}/permissions`;
    return axios
      .get(url, { headers: defaultHeaders() })
      .then(({ data }) => data)
      .catch(handleAxiosError);
  },
};

function* forgotPasswordSaga({
  username,
}: { type: string } & TSForgotPasswordAction): Generator<any, void, any> {
  try {
    yield call(API.forgotPassword, { username });
    yield put({ type: types.FORGOT_PASSWORD_SUCCESS });
  } catch (error) {
    yield handleSagaError(types.FORGOT_PASSWORD_ERROR, error as Error);
  }
}

function* loginSaga({
  username,
  password,
}: { type: string } & TSLoginAction): Generator<any, void, any> {
  try {
    const payload: TSSessionResponse = yield call(API.login, {
      username,
      password,
    });
    yield put({
      type: types.LOGIN_SUCCESS,
      payload,
    });
    localStorage.setItem(STORAGE_LOGIN_TOKEN, payload.sessionToken);
    localStorage.setItem(STORAGE_USERNAME, username);
    localStorage.setItem(STORAGE_USER_ID, payload.userId);
  } catch (e) {
    localStorage.removeItem(STORAGE_LOGIN_TOKEN);
    localStorage.removeItem(STORAGE_USERNAME);
    localStorage.removeItem(STORAGE_USER_ID);

    const error = e as Error;
    if (error.name && error.name === 'PasswordExpiredException') {
      yield put({ type: types.PASSWORD_EXPIRED, error });
    } else {
      yield handleSagaError(types.LOGIN_ERROR, error);
    }
  }
}

function* logoutSaga(): Generator<any, void, any> {
  localStorage.removeItem(STORAGE_LOGIN_TOKEN);
  localStorage.removeItem(STORAGE_USERNAME);
  localStorage.removeItem(STORAGE_USER_ID);
  localStorage.removeItem(STORAGE_IMPERSONATE_CUSTOMER);
  sessionStorage.clear();
  yield put({ type: types.LOGOUT_SUCCESS });
  yield put(actions.initializeState());
}

function* resetPasswordSaga({
  password,
  resetId,
}: { type: string } & TSResetPasswordAction): Generator<any, void, any> {
  try {
    yield call(API.resetPassword, { password, resetId });
    yield put({ type: types.RESET_PASSWORD_SUCCESS });
  } catch (error) {
    yield handleSagaError(types.RESET_PASSWORD_ERROR, error as Error);
  }
}

function* signupSaga({
  password,
  signupId,
}: { type: string } & TSSignupAction): Generator<any, void, any> {
  try {
    yield call(API.signup, { password, signupId });
    yield put({ type: types.SIGNUP_SUCCESS });
  } catch (error) {
    yield handleSagaError(types.SIGNUP_ERROR, error as Error);
  }
}

function* getUserRolesAndPermissionsSaga(): Generator<any, void, any> {
  try {
    const payload = yield call(API.getUserRolesAndPermissions);
    yield put({ type: types.GET_USER_ROLES_AND_PERMISSIONS_SUCCESS, payload });
  } catch (error) {
    yield handleSagaError(
      types.GET_USER_ROLES_AND_PERMISSIONS_ERROR,
      error as Error
    );
  }
}

export const sagas = [
  takeLatest(types.FORGOT_PASSWORD, forgotPasswordSaga),
  takeLatest(types.LOGIN, loginSaga),
  takeLatest(types.LOGOUT, logoutSaga),
  takeLatest(types.RESET_PASSWORD, resetPasswordSaga),
  takeLatest(types.SIGNUP, signupSaga),
  takeLatest(
    types.GET_USER_ROLES_AND_PERMISSIONS,
    getUserRolesAndPermissionsSaga
  ),
];
