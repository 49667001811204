import styled from '@emotion/styled';
import {
  Drawer,
  DrawerProps,
  Group,
  Paper,
  PaperProps,
  createPolymorphicComponent,
} from '@mantine/core';

export const DrawerRoot = styled(Drawer.Root)`
  .mantine-Drawer-inner {
    ${({ position }) => `${position}: 0;`}
  }

  .mantine-Drawer-close {
    background: var(--mantine-color-blue-5);
    border-radius: 0;
    color: white;
    height: 40px;
    left: -40px;
    position: absolute;
    top: 0;
    width: 40px;

    &:hover {
      background: var(--mantine-color-blue-6);
    }
  }

  .mantine-Drawer-content {
    overflow: visible;
    background-color: var(--mantine-color-gray-0);
    display: flex;
    flex-direction: column;
  }

  .mantine-Drawer-header {
    background-color: transparent;
  }

  .mantine-Drawer-header {
    height: 5rem;
    padding: var(--mantine-spacing-xl);
  }

  .mantine-Drawer-overlay {
    backdrop-filter: blur(4px);
  }

  .mantine-Drawer-title {
    color: var(--mantine-color-black);
    font-size: var(--mantine-font-size-md);
    font-style: normal;
    font-weight: 600;
    line-height: 1.2;
  }

  .mantine-Drawer-body {
    flex: 1;
    overflow-y: auto;
    padding: 0 var(--mantine-spacing-xl);
  }
`;

const _Footer = styled(Paper)`
  border-top: 0.0625rem solid var(--mantine-color-gray-3);
`;
const Footer = createPolymorphicComponent<'div', PaperProps>(_Footer);

type DrawerCustomProps = {
  headerActions?: React.ReactNode;
  footerActions?: React.ReactNode;
};

const DrawerCustom = ({
  children,
  footerActions,
  headerActions,
  onClose,
  opened,
  position = 'right',
  size = 'lg',
  withCloseButton = true,
  title,
  ...rest
}: DrawerProps & DrawerCustomProps) => {
  return (
    <DrawerRoot
      opened={opened}
      onClose={onClose}
      position={position}
      size={size}
      zIndex={1002}
      {...rest}
    >
      <Drawer.Overlay />
      <Drawer.Content>
        {(title || withCloseButton || headerActions) && (
          <Drawer.Header>
            <Drawer.Title fz={18}>{title}</Drawer.Title>
            {withCloseButton && <Drawer.CloseButton iconSize='1.5rem' />}
            {headerActions && <Group>{headerActions}</Group>}
          </Drawer.Header>
        )}
        <Drawer.Body>{children}</Drawer.Body>
        {footerActions && (
          <Footer bg='white' radius={0} py='xs' px='xl'>
            <Group gap='xl' justify='flex-end'>
              {footerActions}
            </Group>
          </Footer>
        )}
      </Drawer.Content>
    </DrawerRoot>
  );
};

export default DrawerCustom;
