import styled from '@emotion/styled';
import { Flex, ModalProps, Stack, Text, Title } from '@mantine/core';
import Button from '../mantine/Button';
import Modal from '../mantine/Modal';

export type TSDeleteSavedFilterModal = {
  onConfirm: () => void;
  filterName: string;
} & ModalProps;

const ModalStyles = styled(Modal)`
  .mantine-Modal-title {
    font-size: 18px;
    font-weight: 700;
  }
`;

const DeleteSavedFilterModal = ({
  opened,
  onClose,
  filterName,
  onConfirm,
}: TSDeleteSavedFilterModal) => {
  return (
    <ModalStyles
      centered
      opened={opened}
      onClose={onClose}
      title='Delete Saved Filter'
      size='md'
      padding='xl'
    >
      <Stack align='center' justify='center' display='flex' gap='xl'>
        <Title order={3}>
          Are you sure want to delete the Saved Filter{' '}
          <Text fw='700' span c='black'>
            {filterName}?
          </Text>
        </Title>
        <Flex gap='xl'>
          <Button fw={400} size='lg' variant='outline' onClick={onClose}>
            Cancel
          </Button>
          <Button color='red.5' fw={400} size='lg' onClick={onConfirm}>
            Confirm
          </Button>
        </Flex>
      </Stack>
    </ModalStyles>
  );
};

export default DeleteSavedFilterModal;
