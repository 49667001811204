/* eslint-disable max-len */

interface TSProps {
  color?: string;
  size?: string;
}

const EditIcon = ({ color = '#162447', size = '14' }: TSProps) => (
  <svg fill='none' height={size} viewBox='0 0 14 14' width={size}>
    <path
      d='M13.2961 7.00203C13.1105 7.00203 12.9326 7.07576 12.8013 7.20699C12.6701 7.33823 12.5963 7.51623 12.5963 7.70183V11.9006C12.5963 12.0862 12.5226 12.2642 12.3914 12.3954C12.2601 12.5267 12.0821 12.6004 11.8966 12.6004H2.09939C1.91379 12.6004 1.7358 12.5267 1.60456 12.3954C1.47332 12.2642 1.39959 12.0862 1.39959 11.9006V2.10345C1.39959 1.91785 1.47332 1.73986 1.60456 1.60862C1.7358 1.47738 1.91379 1.40365 2.09939 1.40365H6.29817C6.48377 1.40365 6.66177 1.32992 6.793 1.19869C6.92424 1.06745 6.99797 0.889453 6.99797 0.703855C6.99797 0.518257 6.92424 0.340261 6.793 0.209023C6.66177 0.077786 6.48377 0.00405766 6.29817 0.00405766H2.09939C1.5426 0.00405766 1.00861 0.225243 0.614898 0.618955C0.221185 1.01267 0 1.54666 0 2.10345V11.9006C0 12.4574 0.221185 12.9914 0.614898 13.3851C1.00861 13.7788 1.5426 14 2.09939 14H11.8966C12.4533 14 12.9873 13.7788 13.381 13.3851C13.7748 12.9914 13.9959 12.4574 13.9959 11.9006V7.70183C13.9959 7.51623 13.9222 7.33823 13.791 7.20699C13.6597 7.07576 13.4817 7.00203 13.2961 7.00203ZM2.79919 7.53387V10.501C2.79919 10.6866 2.87292 10.8646 3.00415 10.9958C3.13539 11.1271 3.31339 11.2008 3.49899 11.2008H6.46613C6.55822 11.2013 6.64952 11.1837 6.73478 11.1489C6.82004 11.114 6.89759 11.0627 6.96298 10.9979L11.8056 6.14828L13.793 4.20284C13.8586 4.13778 13.9107 4.06039 13.9462 3.97511C13.9817 3.88983 14 3.79837 14 3.70598C14 3.6136 13.9817 3.52214 13.9462 3.43686C13.9107 3.35158 13.8586 3.27418 13.793 3.20913L10.8259 0.206999C10.7608 0.141408 10.6834 0.089347 10.5981 0.0538192C10.5129 0.0182915 10.4214 0 10.329 0C10.2366 0 10.1452 0.0182915 10.0599 0.0538192C9.9746 0.089347 9.8972 0.141408 9.83215 0.206999L7.85872 2.18742L3.00213 7.03702C2.93727 7.10241 2.88596 7.17996 2.85113 7.26522C2.81631 7.35048 2.79866 7.44178 2.79919 7.53387ZM10.329 1.69057L12.3094 3.67099L11.3157 4.66471L9.33529 2.68428L10.329 1.69057ZM4.19878 7.82079L8.34858 3.67099L10.329 5.65142L6.17921 9.80122H4.19878V7.82079Z'
      fill={color}
    />
  </svg>
);

export default EditIcon;
