import BuildingIcon from '@iconscout/react-unicons/icons/uil-building';
import CircuitsIcon from '@iconscout/react-unicons/icons/uil-circuit';
import LayerGroupIcon from '@iconscout/react-unicons/icons/uil-layer-group';
import SignOutIcon from '@iconscout/react-unicons/icons/uil-sign-out-alt';
import SlidersIcon from '@iconscout/react-unicons/icons/uil-sliders-v';
import { BadgeProps } from '@mantine/core';
import { PolymorphicComponentProps } from '@mantine/core/lib/core/factory/create-polymorphic-component';
import { JSX } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import {
  CONSUMPTION_ANALYSIS_PATH,
  MANAGE_CIRCUITS_PATH,
  PORTFOLIO_CONSUMPTION_PATH,
  PORTFOLIO_SITES_PATH,
  PROJECTS_PATH,
  PROJECTS_PERFORMANCE_PATH,
  SETTINGS_PATH,
  SUSTAINABILITY_BENCHMARKING_PATH,
  SUSTAINABILITY_REPORTING_PATH,
} from '../../constants/paths';
import { selectCustomersEntity } from '../../ducks/customers';
import {
  energyStarEntity,
  TSEnergyStarEntityState,
} from '../../ducks/energyStar/energyStar';
import { selectOpportunityEntity } from '../../ducks/opportunity/opportunities';
import { TSSiteTabs, selectSitesEntity } from '../../ducks/sites';
import { Period } from '../../ducks/types';
import { useUserHasPermissionGroup } from '../../queries/permissions';
import { isCurrentUserRedaptiveDemoUser } from '../../utils/demo';
import {
  defaultQuerySearchParams,
  getUrlSearchParams,
} from '../../utils/getUrlSearchParams';
import GlobeIcon from '../Icons/GlobeIcon';
import CollapsedNavContent from './CollapsedNavContent';
import ExpandedNavContent from './ExpandedNavContent';
import NavToggleButton from './NavToggleButton';

type Title = {
  component: 'title';
  label: string;
};
type Divider = {
  component: 'divider';
  label: string;
};
type NavLink = {
  badge?: PolymorphicComponentProps<'div', BadgeProps>;
  component: 'navLink';
  gainsightId: string;
  icon?: JSX.Element;
  isActiveMatcher?: RegExp;
  isHidden?: boolean;
  label: string;
  to?: string;
};
export type ParentNavLink = NavLink & {
  children?: NavLink[];
};

export type MenuItem = Title | Divider | ParentNavLink;

type MainNavProps = {
  expanded: boolean;
  toggle: () => void;
};

export const PendingActionIcon = styled.div<{ ml?: number }>`
  height: 7px;
  width: 7px;
  background-color: red;
  border-radius: 7px;
  ${({ ml }) => ml && `margin-left: ${ml}px`}
`;

const pendingActionBadge = {
  children: <PendingActionIcon />,
  color: 'transparent',
};

const NewMainNav = ({ toggle, expanded }: MainNavProps) => {
  // Gainsight widget custom styles override
  const GlobalStyle = createGlobalStyle`
    #apt-widget #apt-widget-controller {
      inset: auto auto 52px 17px !important
    }
    #apt-widget button {
      background: var(--mantine-color-blue-0);
      border-radius: 8px;
      color: var(--mantine-color-gray-8);
      cursor: pointer;
      font-weight: 600;
      height: 38px;
      line-height: 1;
      padding: 0;
      vertical-align: middle;
    }
    ${
      expanded
        ? `
        #apt-widget button {
          font-size: 14px;
          padding-left: 16px;
          text-align: left;
          width: 239px;
        }
        #apt-widget button:after {
          content: 'Need some help?';
        }
    `
        : `
        #apt-widget button {
          font-size: 18px;
          text-align: center;
          width: 38px;
        }
        #apt-widget button:after {
          content: '?';
        }
        `
    }
  `;

  const { currentCustomerId } = useSelector(selectCustomersEntity);
  const {
    customerStatus: { isEnabled: sustainabilityReportingEnabledCustomer },
  } = useSelector(energyStarEntity);
  const {
    customerStatus: { numberOfPendingProperties = 0 },
  }: TSEnergyStarEntityState = useSelector(energyStarEntity);
  const { customersById: opportunitiesCustomersById } = useSelector(
    selectOpportunityEntity
  );
  const opportunitiesEnabledCustomer =
    !isCurrentUserRedaptiveDemoUser() &&
    Object.keys(opportunitiesCustomersById).includes(currentCustomerId);
  const { data: userHasProjectPermissions } = useUserHasPermissionGroup(
    'r1-performance-invoice-views'
  );

  const { items: sites } = useSelector(selectSitesEntity);
  const [searchParams] = useSearchParams();
  const queryParams = Object.fromEntries(searchParams);
  const siteId = queryParams.site ?? '';

  const navContent: MenuItem[] = [
    { component: 'title', label: 'Manage' },
    {
      component: 'navLink',
      label: 'Portfolio',
      icon: <BuildingIcon size={18} />,
      isActiveMatcher: /\/portfolio\/*/,
      gainsightId: 'primary-nav-portfolio',
      children: [
        {
          component: 'navLink',
          label: 'Sites',
          to: PORTFOLIO_SITES_PATH,
          badge: { children: 'New', color: 'green' },
          gainsightId: 'secondary-nav-portfolio-sites',
        },
        {
          component: 'navLink',
          label: 'Consumption',
          to: `${PORTFOLIO_CONSUMPTION_PATH}?${getUrlSearchParams(
            {
              period: Period.LAST_6_MONTHS,
            },
            TSSiteTabs.PORTFOLIO_CONSUMPTION,
            sites,
            true
          )}`,
          gainsightId: 'secondary-nav-portfolio-consumption',
        },
        {
          component: 'navLink',
          label: 'Sustainability Reporting',
          to: SUSTAINABILITY_REPORTING_PATH,
          isHidden: !sustainabilityReportingEnabledCustomer,
          gainsightId: 'secondary-nav-portfolio-sustainability-reporting',
          ...(numberOfPendingProperties > 0 && { badge: pendingActionBadge }),
        },
        {
          component: 'navLink',
          label: 'Benchmarking',
          to: SUSTAINABILITY_BENCHMARKING_PATH,
          isHidden: !sustainabilityReportingEnabledCustomer,
          gainsightId: 'secondary-nav-portfolio-sustainability-benchmarking',
          badge: { children: 'Beta', color: 'blue.4' },
        },
      ],
    },
    {
      component: 'navLink',
      label: 'Projects',
      icon: <LayerGroupIcon size={18} />,
      isActiveMatcher: /\/projects\/*/,
      gainsightId: 'primary-nav-projects',
      children: [
        {
          component: 'navLink',
          label: 'All Projects',
          to: PROJECTS_PATH,
          isHidden: !opportunitiesEnabledCustomer,
          gainsightId: 'secondary-nav-projects-all-projects',
          badge: { children: 'Beta', color: 'blue.4' },
        },
        {
          component: 'navLink',
          label: 'Performance',
          to: `${PROJECTS_PERFORMANCE_PATH}?${getUrlSearchParams(
            {
              ...defaultQuerySearchParams,
              period: Period.LAST_6_MONTHS,
              site: siteId,
            },
            TSSiteTabs.PROJECTS_PERFORMANCE,
            sites,
            true
          )}`,
          isHidden: !userHasProjectPermissions,
          gainsightId: 'secondary-nav-projects-performance',
        },
        {
          component: 'navLink',
          label: 'Invoices',
          to: `/projects/invoices?${getUrlSearchParams(
            {
              ...defaultQuerySearchParams,
              period: Period.LAST_6_MONTHS,
              site: siteId,
            },
            TSSiteTabs.PROJECTS_INVOICE,
            sites,
            true
          )}`,
          isHidden: !userHasProjectPermissions,
          gainsightId: 'secondary-nav-projects-invoices',
        },
      ],
    },
    { component: 'divider', label: 'insights-divider' },
    { component: 'title', label: 'Insights' },
    {
      component: 'navLink',
      label: 'Data Explorer',
      to: `${CONSUMPTION_ANALYSIS_PATH}?${getUrlSearchParams(
        { ...defaultQuerySearchParams },
        TSSiteTabs.BASIC_CONSUMPTION,
        sites,
        true
      )}`,
      icon: <GlobeIcon size={18} />,
      isActiveMatcher: /\/data-explorer\/*/,
      gainsightId: 'primary-nav-data-explorer',
    },
    { component: 'divider', label: 'more-divider' },
    { component: 'title', label: 'Account' },
    {
      icon: <SlidersIcon size={18} />,
      component: 'navLink',
      label: 'Settings',
      to: SETTINGS_PATH,
      gainsightId: 'primary-nav-settings',
    },
    {
      icon: <CircuitsIcon size={18} />,
      component: 'navLink',
      label: 'Manage Circuits',
      to: MANAGE_CIRCUITS_PATH,
      gainsightId: 'primary-nav-manage-circuits',
    },
    {
      icon: <SignOutIcon size={18} />,
      component: 'navLink',
      label: 'Log Out',
      to: '/logout',
      gainsightId: 'primary-nav-log-out',
    },
  ];

  return (
    <>
      <GlobalStyle />
      <NavToggleButton expanded={expanded} toggle={toggle} />
      {expanded ? (
        <ExpandedNavContent navContent={navContent} />
      ) : (
        <CollapsedNavContent navContent={navContent} />
      )}
    </>
  );
};

export default NewMainNav;
