import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserId, isAuthenticated } from '../api/utils';
import { selectCustomersEntity } from '../ducks/customers';
import {
  actions as energyStarActions,
  energyStarEntity,
  TSEnergyStarEntityState,
} from '../ducks/energyStar/energyStar';

import {
  selectPreferencesEntity,
  actions as userPreferenceActions,
} from '../ducks/Profile/Preferences';
import { ES_COORDINATOR_GROUP_ID } from '../constants/strings';
import { useUserHasPermissionGroup } from '../queries/permissions';

const useFetchUserData = () => {
  const dispatch = useDispatch();

  const {
    customerStatus: { isEnabled: isEnergyStarEnabled, energyStarCustomerId },
  }: TSEnergyStarEntityState = useSelector(energyStarEntity);

  const { data: userIsEsCoordinator } = useUserHasPermissionGroup(
    ES_COORDINATOR_GROUP_ID
  );

  const {
    preferencesMeta: { loading: preferencesLoading },
  } = useSelector(selectPreferencesEntity) || {};
  const { currentCustomerId } = useSelector(selectCustomersEntity);
  const currentUserId = getUserId();
  const isSignedIn = isAuthenticated();

  useEffect(() => {
    if (isSignedIn && !preferencesLoading) {
      dispatch(userPreferenceActions.fetchUserPreferences(currentUserId));
    }
    // prefencesLoading should not trigger a re-fetch
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSignedIn, currentUserId, dispatch]);

  useEffect(() => {
    if (currentCustomerId)
      dispatch(
        energyStarActions.fetchCustomerStatus({
          customerId: currentCustomerId,
        })
      );
  }, [dispatch, currentCustomerId]);

  useEffect(() => {
    if (userIsEsCoordinator && isEnergyStarEnabled && energyStarCustomerId) {
      dispatch(
        energyStarActions.fetchAccountAccessData({
          accountId: energyStarCustomerId,
        })
      );
    }
  }, [
    dispatch,
    energyStarCustomerId,
    isEnergyStarEnabled,
    userIsEsCoordinator,
  ]);
};

export default useFetchUserData;
