import { isArray } from 'highcharts';
import { mapKeys } from 'lodash';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { DATE_FORMAT_DATA_API_REQUEST } from '../../constants';
import {
  TSCustomersEntityState,
  selectCustomersEntity,
} from '../../ducks/customers';
import { actions as accountSavedQueriesActions } from '../../ducks/savedQueries/accountQueries';
import { Period } from '../../ducks/types';
import { convertToArray } from '../../utils';

const useSavedFilters = () => {
  const dispatch = useDispatch();

  const { currentCustomerId }: TSCustomersEntityState = useSelector(
    selectCustomersEntity
  );

  const [searchParams] = useSearchParams();
  const serializeFormQuery = useMemo(() => {
    return Object.fromEntries(searchParams);
  }, [searchParams]);
  const [period, setPeriod]: [string, (string) => void] = useState(
    serializeFormQuery.period ?? Period.CUSTOM
  );
  const [isSaveFilterModalOpen, updateIsSaveFilterModalOpen] = useState(false);

  const updateSelectedQuery = (query: string | undefined) => {
    const selectedSavedQuery = JSON.parse(query || '');
    setPeriod(selectedSavedQuery.period);
    mapKeys(selectedSavedQuery, function (value, key) {
      if (isArray(value)) {
        selectedSavedQuery[key] = value.join(',');
      }
      if (key === 'resource') {
        // TODO: consolidate BE and FE to both use either resource or resourceType
        delete selectedSavedQuery['resource'];
        selectedSavedQuery['resourceType'] = value;
      }
    });

    if (selectedSavedQuery.period !== Period.CUSTOM) {
      delete selectedSavedQuery['fromDate'];
      delete selectedSavedQuery['toDate'];
    }
    return selectedSavedQuery;
  };

  const resetQueryParams = () => {
    dispatch(accountSavedQueriesActions.switchSelectedQuery(''));
    setPeriod('');
  };

  const saveQuerySubmit = (name: string, currentUrlParams: any) => {
    const {
      buildingSystem,
      equipment,
      fromDate,
      grouping,
      groupingId,
      meter,
      period,
      resolution,
      resourceType,
      site,
      toDate,
      categoryValue,
      circuit,
    } = currentUrlParams;

    const createAccountSavedQueryPayload = {
      customerId: currentCustomerId,
      name: name,
      parameters: {
        query: {
          buildingSystem: convertToArray(buildingSystem),
          categoryValue: convertToArray(categoryValue),
          circuit: convertToArray(circuit),
          equipment: convertToArray(equipment),
          fromDate: moment(fromDate).format(DATE_FORMAT_DATA_API_REQUEST),
          grouping: grouping,
          groupingId: groupingId ?? null,
          meter: convertToArray(meter),
          period: period ?? null,
          resolution: resolution,
          resource: resourceType,
          site: convertToArray(site),
          toDate: moment(toDate).format(DATE_FORMAT_DATA_API_REQUEST),
        },
      },
    };
    dispatch(
      accountSavedQueriesActions.createAccountSavedQuery(
        createAccountSavedQueryPayload
      )
    );
    updateIsSaveFilterModalOpen(false);
  };

  return {
    saveQuerySubmit,
    isSaveFilterModalOpen,
    period,
    resetQueryParams,
    setPeriod,
    updateIsSaveFilterModalOpen,
    updateSelectedQuery,
  };
};

export default useSavedFilters;
