import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { TSSiteConfigModal, actions as modalActions } from '../../ducks/modal';
import { actions as siteActions } from '../../ducks/sites';
import { zIndices } from '../../utils';
import SiteConfigurationView from './SiteConfigurationView';

const ModalSiteConfigStyled = styled.div`
  backdrop-filter: blur(4px);
  background-color: rgba(200, 200, 200, 0.5);
  color: ${({ theme }) => theme.colors.primary};
  font-family: 'Public Sans', sans-serif;
  letter-spacing: 0.02em;
  min-height: 100vh;
  overflow-y: auto;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: ${zIndices.siteConfigModal};

  * {
    box-sizing: border-box;
  }
`;

const CloseModalButtonStyled = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  height: 40px;
  padding: 10px;
  width: 40px;

  span {
    display: block;
    margin: 3px 0;
    height: 2px;
    width: 20px;
    background: #fff;
    border-radius: 2px;
  }

  span {
    &:nth-child(1) {
      transform: rotate(135deg) translate(4px, -3px);
    }

    &:nth-child(2) {
      opacity: 0;
    }

    &:nth-child(3) {
      transform: rotate(-135deg) translate(4px, 4px);
    }
  }
`;

const ModalWrapperStyled = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ModalBodyStyled = styled.div`
  background-color: #fff;
  padding: 24px;
  position: relative;
  width: 730px;
  min-height: 100vh;
`;

const ModalTitleStyled = styled.div`
  color: ${({ theme }) => theme.colors.fontMain};
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 16px;
`;

const ConfigViewWrapperStyled = styled.div`
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  width: 100%;
  height: 95%;
  position: relative;
`;

const SiteConfigurationModal = (props: TSSiteConfigModal) => {
  const { siteId, onlyOpHours } = props;
  const dispatch = useDispatch();

  const closeConfigModal = useCallback(() => {
    dispatch(modalActions.hideModal());
    dispatch(siteActions.fetchSites());
  }, [dispatch]);

  return (
    <ModalSiteConfigStyled>
      <ModalWrapperStyled>
        <CloseModalButtonStyled onClick={closeConfigModal}>
          <span />
          <span />
          <span />
        </CloseModalButtonStyled>
        <ModalBodyStyled>
          <ModalTitleStyled>Site Configuration</ModalTitleStyled>
          <ConfigViewWrapperStyled>
            <SiteConfigurationView siteId={siteId} onlyOpHours={onlyOpHours} />
          </ConfigViewWrapperStyled>
        </ModalBodyStyled>
      </ModalWrapperStyled>
    </ModalSiteConfigStyled>
  );
};

export default SiteConfigurationModal;
