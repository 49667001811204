import { ErrorBoundary, Provider as RollbarProvider } from '@rollbar/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/notifications/styles.css';
import './index.css';
import './init';
import App from './pages/App';
import ErrorPage from './pages/ErrorPage';
import configureStore from './store/configureStore';
import RedaptiveThemeProvider from './themes/RedaptiveThemeProvider';
import { CustomMantineProvider } from './themes/mantine';
import { Notifications } from '@mantine/notifications';

const store = configureStore();
export type TSRootState = ReturnType<typeof store.getState>;
const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

const rollbarConfig = {
  accessToken: import.meta.env.VITE_APP_ROLLBAR_DENALI_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: import.meta.env.MODE,
    source_map_enabled: true,
    server: {
      root: '/',
    },
    code_version: import.meta.env.VITE_COMMIT_HASH,
    guess_uncaught_frames: true,
  },
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // ✅ globally default caching to 20 seconds
      staleTime: 1000 * 20,
    },
  },
});

root.render(
  <BrowserRouter>
    <RedaptiveThemeProvider>
      <CustomMantineProvider>
        <Notifications zIndex={1500} />
        <RollbarProvider config={rollbarConfig}>
          <QueryClientProvider client={queryClient}>
            <ErrorBoundary fallbackUI={ErrorPage}>
              <Provider store={store}>
                <App />
              </Provider>
            </ErrorBoundary>
          </QueryClientProvider>
        </RollbarProvider>
      </CustomMantineProvider>
    </RedaptiveThemeProvider>
  </BrowserRouter>
);
