import { Moment } from 'moment';
import { useCallback, useRef } from 'react';
import styled from 'styled-components';

import CalendarIcon from '../components/Icons/CalendarIcon';
import { baseOptions } from '../constants';
import useComponentToggle from '../hooks/useComponentToggle';
import DatePickerDropdown, { DropdownInnerStyles } from './DatePickerDropdown';
import { Period } from '../ducks/types';
interface TSProps {
  end: Moment | null;
  options?: Array<any>;
  period?: string;
  getProgramStartDate: () => Moment;
  start: Moment | null;
  text: string;
  updateDateRange: (arg: any) => void;
  maxDays?: number;
  calendarInfo?: React.ComponentType;
  isVerticalOrientation?: boolean;
  gainsightTagId?: string;
  isMonthlyDateRangePicker?: boolean;
  monthsLimit?: number;
}

export const DatePickerStyles = styled.div`
  align-items: center;
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: space-between;
  padding: 0 16px;
  position: relative;
  text-align: right;
  user-select: none;
  width: 250px;

  ${DropdownInnerStyles} {
    top: 10px;
  }
`;

export const DatePickerTextWrapperStyled = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 11px;
  margin-left: 8px;
  margin-right: 8px;
`;

const DatePicker = ({
  end = null,
  options = [],
  period = '',
  getProgramStartDate,
  start = null,
  text = '',
  updateDateRange,
  maxDays,
  calendarInfo,
  isVerticalOrientation = false,
  gainsightTagId = '',
  isMonthlyDateRangePicker = false,
  monthsLimit,
}: TSProps) => {
  const componentRef = useRef(null);
  const toggleRef = useRef(null);

  const { isComponentVisible, setIsComponentVisible } = useComponentToggle({
    componentRef,
    toggleRef,
  });

  const hideDropdown = useCallback(() => {
    setIsComponentVisible(false);
  }, [setIsComponentVisible]);

  const updateDateRangeSelection = useCallback(
    (value) => {
      hideDropdown();
      updateDateRange && updateDateRange(value);
    },
    [updateDateRange, hideDropdown]
  );

  const calendarText = () => {
    if (period !== Period.CUSTOM) {
      return baseOptions.find((option) => option.value === period)?.label;
    }
    return text;
  };

  return (
    <>
      <DatePickerStyles
        className='DatePicker'
        data-gainsight-id={gainsightTagId}
        ref={toggleRef}
      >
        <span>{calendarText()}</span>
        <CalendarIcon size='16' />
      </DatePickerStyles>
      {isComponentVisible && (
        <div ref={componentRef}>
          <DatePickerDropdown
            hideDropdown={hideDropdown}
            options={options}
            period={period}
            start={start}
            end={end}
            maxDays={maxDays}
            getProgramStartDate={getProgramStartDate}
            updateDateRange={updateDateRangeSelection}
            calendarInfo={calendarInfo}
            isVerticalOrientation={isVerticalOrientation}
            isMonthlyDateRangePicker={isMonthlyDateRangePicker}
            monthsLimit={monthsLimit}
          />
        </div>
      )}
    </>
  );
};

export default DatePicker;
