/* eslint-disable max-len */

interface TSProps {
  color?: string;
  size?: string;
}

const ClockIcon = ({ color = '#162447', size = '20' }: TSProps) => (
  <svg fill='none' height={size} viewBox='0 0 20 22' width={size}>
    <path
      d='M11 2C9.02219 2 7.08879 2.58649 5.4443 3.6853C3.79981 4.78412 2.51809 6.3459 1.76121 8.17317C1.00433 10.0004 0.806299 12.0111 1.19215 13.9509C1.578 15.8907 2.53041 17.6725 3.92894 19.0711C5.32746 20.4696 7.10929 21.422 9.0491 21.8079C10.9889 22.1937 12.9996 21.9957 14.8268 21.2388C16.6541 20.4819 18.2159 19.2002 19.3147 17.5557C20.4135 15.9112 21 13.9778 21 12C21 10.6868 20.7413 9.38642 20.2388 8.17317C19.7363 6.95991 18.9997 5.85752 18.0711 4.92893C17.1425 4.00035 16.0401 3.26375 14.8268 2.7612C13.6136 2.25866 12.3132 2 11 2ZM11 20C9.41775 20 7.87104 19.5308 6.55544 18.6518C5.23985 17.7727 4.21447 16.5233 3.60897 15.0615C3.00347 13.5997 2.84504 11.9911 3.15372 10.4393C3.4624 8.88743 4.22433 7.46197 5.34315 6.34315C6.46197 5.22433 7.88743 4.4624 9.43928 4.15372C10.9911 3.84504 12.5997 4.00346 14.0615 4.60896C15.5233 5.21447 16.7727 6.23984 17.6518 7.55544C18.5308 8.87103 19 10.4177 19 12C19 14.1217 18.1572 16.1566 16.6569 17.6569C15.1566 19.1571 13.1217 20 11 20ZM11 6C10.7348 6 10.4804 6.10536 10.2929 6.29289C10.1054 6.48043 10 6.73478 10 7V11.42L7.9 12.63C7.70736 12.7392 7.5564 12.9092 7.47078 13.1134C7.38517 13.3176 7.36975 13.5444 7.42695 13.7583C7.48414 13.9722 7.61072 14.1611 7.78682 14.2953C7.96292 14.4296 8.17859 14.5015 8.4 14.5C8.57518 14.5012 8.7476 14.4564 8.9 14.37L11.5 12.87L11.59 12.78L11.75 12.65C11.7891 12.6005 11.8226 12.5468 11.85 12.49C11.8826 12.4363 11.9094 12.3793 11.93 12.32C11.9572 12.2564 11.9741 12.1889 11.98 12.12L12 12V7C12 6.73478 11.8946 6.48043 11.7071 6.29289C11.5196 6.10536 11.2652 6 11 6Z'
      fill={color}
    />
  </svg>
);

export default ClockIcon;
